import React, { Component } from 'react';

import Firebase from '../Firebase';
import PushId from 'pushid';

import Style from '../styles';
import { Card, Button, Switch, Container } from '@material-ui/core/';
import { KeyboardArrowDown } from '@material-ui/icons/';
import { FiHome, FiMapPin, FiMail, FiPhone, FiLink, FiDollarSign, FiImage, FiCopy, FiTrash } from 'react-icons/fi';
import { FaCaretUp, FaCaretDown, FaPlus, FaMinus, FaCircle as RadioSelect, FaRegCircle as RadioBlank, FaRegSquare as BoxBlank, FaCheckSquare as BoxSelect, FaDivide  } from 'react-icons/fa';
import { MdFavorite, MdAdd, MdSecurity, MdSettingsPower, MdAddCircleOutline, MdKeyboardArrowDown } from 'react-icons/md';

import { directUrlQrUrlParams, stringIncludes,  qrIconWithLocation, navButton, trimUrl, qrIcon, placeOrder, updateOgMetaTags, domain, hint3, uploadButton2, closeButton, image2, scrollTo2, ButtonNav, Button2, handleError, lb, addItem, numToMON, getPriceWithOptions, getVariables, SaveCancelButtons2, readyPage, venueName, trim, Loading, capitalize, isManager, getClientKey, trimAmount, getLanguage, getIcon, convertToNumber, footer, renderHelper, getAppText, languageEditWarning, notifications, getAppText2, getIcon2, goBack, getLocationsFlat, handleLink2, Input2, isAvailable, Switch2, log2, Card2, RenderTaxIds } from '../Mini-components/Functions'





let height = window.innerHeight

class Item extends Component {

	constructor() {
		super();
		this.state = {
            loading:false,
            optionArray: [],
            modal1: [false, ''],
            noteFocus: false,
            // dateTime : {
            //     date 	: new Date().getDate(),
            //     month 	: new Date().getMonth(),
            //     hours 	: new Date().getHours() + 1,
            //     minutes	: 0
            // }
		};
	}

    componentDidMount() {
        let v = this.props.v 
        readyPage({ props:this.props, v })
        this.mapStoreToState()
    }
    
    mapStoreToState() {
        let v    =  this.props.v
        let item =  this.props?.location?.state?.item || this.props.item

		if   (item) this.setState({ item:{...item, quantitySelected:item.quantitySelected||1} }) 
		else 		this.props.history.push( '/' + v.venueUrl + '/menu' )
    }

    handleChange3 = ({ type, variant, option, value }) => {

        let v               = { ...this.props.v }
        let item            = { ...this.state.item }

        if      (type=='input')             item.variance[variant.key].options[option.key].selected = value
        else if (type=='taxIds')            item.taxIds = value

        this.setState({item, toggleEdit:true})
        // setTimeout(()=>{ this.setState({item, toggleEdit:true}) }, 200)
    }

    handleSelect = (tit, opt, manager) => {

        let item            = {...this.state.item}
        let title           = item.variance[tit.key]
		let titleOptions    = item.variance[tit.key].options

        let option          = item.variance[tit.key].options[opt.key]

        if (title.readOnly && !manager) return null
        else if (title.multipleSelect) { option.selected = !option.selected }
        else if (manager) { option.selected = !option.selected }
		else {
                Object.entries(titleOptions).map(option => { 
                    

                    if (option[0] === opt.key) { 
                        console.log('qs,', title, option, option[1].changeQuantityValue)
                        option[1].selected = true 
                        
                        // Change Quantity Value (Co-working Membership Passes)
                        if (option[1].changeQuantityValue) { item.quantitySelected = option[1].changeQuantityValue }
                    }
					else { option[1].selected = false }
                })
        }
        
        this.setState({item, toggleEdit:true})
        setTimeout(()=>{ this.setState({item, toggleEdit:true}) }, 200)
    }

    checkRequiredSelected = ({v}) => {

        let requiredOptions = []

        if (this.state.item.variance!==undefined) {

            Object.entries(this.state.item.variance).map(tit => {

                let optSel = []

                if (tit[1].required && tit[1].options) {

					Object.entries(tit[1].options).map(opt => {
						if (opt[1].selected) optSel.push(true) 
					})
                    if ( !optSel.length ) requiredOptions.push(capitalize( tit[1]["name_"+v.language] || tit[1].name ))
				}
            })
        }

        if ( requiredOptions.length > 0 ) {
            this.props.updateToast({ icon:'choose', text:`${requiredOptions[0]}` })
            scrollTo2(requiredOptions[0], -100)
		}

        return requiredOptions.length > 0 ? false : true
    }

    renderCustomNotes = (p) => {

        let { v, manager }           = p
        let language 		= v.language

        if (manager) {
            return (
                <div style={Object.assign({}, Style.card, { backgroundColor:'white' })}>
                    <div style={Object.assign({}, Style.flexRow,{justifyContent:'space-between'})}>
                        <div style={Object.assign({}, Style.textSB, {textTransform:'uppercase'})} onClick={() => console.log(this.state) }>Custom</div> 
                        <MdKeyboardArrowDown style={Object.assign({}, { fontSize:'2rem' })} onClick={()=> this.setState({ showCustomNotes:this.state.showCustomNotes?false:true }) } />
                    </div>
                    
                    {this.state.showCustomNotes && 
                        <div style={{marginTop:20}}>
                            <div style={Object.assign({}, Style.flexRow,{marginTop:30})}>
                                <input style={ Object.assign({}, Style.input, Style.textS, { marginTop:0 })} className="form-control input" placeholder="Custom Progress Options (comma seperated)"  value={ this.state["customNotesAdd_"+language] || this.state.customNotesAdd } onChange={(e) => this.setState({customNotesAdd:e.target.value}) } />
                                <Button onClick={() => this.handleChange('customNotesAdd')}>{getIcon('add', height/30)}</Button>
                                <Button onClick={() => this.handleChange('customNoteClear')}>{getIcon('delete', height/30)}</Button>
                            </div>
                            {   this.state.item.customNotes &&
                                <div style={Object.assign({}, Style.flexRow, {marginTop:20})}>
                                    { (this.state.item.customNotes).map((item,index) => { 
                                        return( <div style={Object.assign({}, Style.textS, { marginLeft:5 })}>{item}</div> )
                                    })}
                                </div>
                            }
                        </div>
                    }
                </div>
            )
        }
    }
    
    renderDietTags = ({ v, managerStyle, manager, language, customer }) => {

        let dietTags        = this.state.item.dietTags||[]

        if ( customer && !dietTags.length ) return null
        else return (
            <div style={Object.assign({}, Style.flexColOnly, manager?managerStyle:null, { overflow:'hidden' })}>
                {manager?
                    <div onClick={() => console.log(this.state) } style={Object.assign({}, Style.flexRow, Style.textB, { textTransform:'uppercase', padding:'1.5rem', justifyContent:'space-between' })}>
                        { getAppText2({v, text:'Diet Tags'}) }
                        <Button onClick={() => this.props.history.push('/manager/config/diet') } style={Object.assign({}, v.button, Style.buttonIcon, { })}>{ getIcon2({ icon:'settings', fontSize:'1.25rem' }) }</Button>
                    </div>
                :null}
            
                { v.info.dietTags && dietTags?
                    <div style={Object.assign({}, Style.flexRow, { flexWrap:'wrap', padding:'1rem', fontFamily:v.fontFamily })}>
                        { Object.entries(v.info.dietTags).map((item,index) => { item = { ...item[1], key: item[0], index  } 
                            if (manager || (customer&&dietTags.includes(item.key))) return <Button key={item.key} style={Object.assign({}, dietTags.includes(item.key)? v.button:v.div, Style.buttonBadge, { })} onClick={() => manager?this.handleChange('handleAddDietTag', item):null }>{item["name_"+language] || item.name}</Button>
                        })}
                    </div>
                :null}
            </div>
        )
    }

    renderDirectUrl = ({ v, item, managerStyle }) => {

        return (
            <div style={Object.assign({}, Style.flexColOnly, managerStyle, {padding:'1.5rem' })}>
                <div style={Object.assign({}, Style.textSB, {textTransform:'uppercase'})}>{ getAppText2({ v, text:'Page Url' }) }</div>
                <input style={ Object.assign({}, Style.input, { marginTop:10 })} placeholder="pepperoni-pizza"  value={ item.directUrl } onChange={(e) => this.handleChange2({v, item:{ ...item, directUrl:e.target.value }}) } />
                { hint3({ link:item.directUrl?`/${v.venueUrl}/${item.directUrl}`:"", v, text:`${domain}/${v.venueUrl}/${item.directUrl||'pepperoni-pizza'}`, style:{ marginTop:20, fontSize:'0.9rem' } })}
            </div>
        )
    }

    renderDiscount = ({ v, item, managerStyle }) => {

        let itemDiscount = isAvailable({ available:item.discount, discount:true }) || 0
        return (
            <div style={Object.assign({}, Style.flexRowSP, managerStyle, {padding:'1.5rem' })}>
                <div style={Object.assign({}, Style.textSB, {textTransform:'uppercase'})}>{ getAppText2({ v, text:'discount' })} {itemDiscount||0}%</div>
                <Button onClick={()=> { v.updateDialog({ v, setAvailable:true, available:item.discount, icon:'discount', text:'item discount', onClick:(value) => { item.discount=value;  this.setState({item, toggleEdit:true})}  })}}>{getIcon2({icon:'discount', fontSize:'2rem'})}</Button>
            </div>
        )
    }

    renderCustomMainButton = ({ v, item, managerStyle }) => {

        return (
            <div style={Object.assign({}, Style.flexColOnly, managerStyle, {padding:'1.5rem' })}>
                <div style={Object.assign({}, Style.textSB, {textTransform:'uppercase' })}>{ getAppText2({ v, text:'custom main button' }) }</div>
                <Input2 id={'customMainButtonText'} twenty v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus}   placeholder={ getAppText2({ v, text:'button text' }) }   value={ item["customMainButtonText_"+v.language] || item.customMainButtonText } onChange={(e) => this.handleChange4({ type:'customMainButtonText', v, value:e.target.value })} />
                <Input2 id={'customMainButtonUrl'}  twenty v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus}   placeholder={ getAppText2({ v, text:'button url' }) }    value={ item.customMainButtonUrl  }    onChange={(e) => this.handleChange2({ v, item:{ ...item, customMainButtonUrl:e.target.value }}) } />

                { hint3({  v, text:`Entering URL '/chat' will direct the user to ${domain}/${v.venueUrl}/chat`, style:{ marginTop:20, fontSize:'0.9rem', textTransform:'none' } })}
            </div>
        )
    }

    renderQrIcon = ({ v, item, managerStyle }) => {

        return (
            <div style={Object.assign({}, Style.flexColOnly, managerStyle, {padding:'1.5rem' })}>
                <div style={Object.assign({}, Style.textSB, {textTransform:'uppercase'})}>{ getAppText2({ v, text:'QR & Link Settings' }) }</div>
                
                { directUrlQrUrlParams({ v, state:this.state, setState:(e)=>this.setState(e), url:`https://${domain}/${v.venueUrl}/${item.directUrl||""}` }) }

                { hint3({  v, text:`Click the Button above to configure additional URL and QR settings for this page`, style:{ marginTop:20, fontSize:'0.9rem' } })}
            </div>
        )
    }

    renderOtherOptions = ({ v, item, managerStyle, manager, language, customer }) => {

        return (
            <Card2 v={v} title='Other' onClick={()=> this.setState({ otherOptions:!this.state.otherOptions }) } >
                    
                {this.state.otherOptions?
                    <div>
                        <RenderTaxIds v={v}       taxIds={item.taxIds}           change={(value)=>this.handleChange3({ v, type:'taxIds', value })}  />

                        {this.renderDirectUrl({ v, item, managerStyle })}
                        {this.renderDiscount({ v, item, managerStyle })}
                        {this.renderCustomMainButton({ v, item, managerStyle })}
                        {this.renderQrIcon({ v, item, managerStyle })}
                        {this.renderDisableQuickAdd({ v, item, manager, customer, managerStyle })}
                        {this.renderOrderImmediately({ v, item, manager, customer, managerStyle })}
                        {this.renderOtherItems({ v, item, manager, customer, managerStyle })}
                        
                        {/* { this.renderCustomNotes({ v, manager }) } For Co-working space - Coco Kala */}
                    </div>
                :null}
            </Card2>
        )
    }

    afterAddItemAction = ({ orderImmediately }) => {

        let v   = this.props.v

        if      (v.pos)  return this.props.backToPos()
        else if (orderImmediately) {
            

            v.updateDialog({ confirmSendOrder:true, icon:'send', text:'confirm order', buttonText:'send order' })

            // placeOrder({ 
            //     props:this.props, v, 
            //     afterPlaceOrder:()=>{ 
            //         this.props.history.push('/'+v.venueUrl ) 
            //         this.props.updateDialog({ text:'Order Sent', description:'To Track Your Order, Please Click ORDERS in the App Menu',  icon:'tick'})
            //     } 
            // }) 
        }
        else goBack({ v, history:this.props.history })  // this.props.history.push('/'+v.venueUrl+'/menu', {scrollTo:this.state.item.key} )
    }

	render() {

        let v               =   this.props.v
		let orderlinaStore 	=   {...this.props.orderlinaStore}
        let item 			=   this.state.item

		if (this.state.loading || orderlinaStore.loading || !v || !item ) return <Loading />

		else {

            let language 		    =   v.language
            let manager			    =   v.manager
            let customer            =   v.customer
            let info                =   v.info
            let ContButt		    =   manager?Container:Button
            let ordering            =   info.ordering
            let readOnly            =   v.ordering=='readOnly'
            let priceWithOptions    =   getPriceWithOptions({ item })
            let color               =   v.tc||'black'
            let managerStyle        =   manager? { ...Style.card, backgroundColor:'white', padding:0 } : { ...v.div2, ...v.style.card, marginLeft:'1rem', marginRight:'1rem' }
            let update              =   this.props.location?.state?.update
            let orderImmediately    =   update?false: item.orderImmediately || v.searchParams.get("ordflo")=='imm'
            let afterAddItem        =   ()=>this.afterAddItemAction({ orderImmediately })


			return (
							<div className='item' style={Object.assign({}, Style.div1, v.customer?{paddingLeft:0, paddingRight:0}:{}, { paddingTop:0, paddingBottom:'10rem' })} >

                                    { notifications({props:this.props, v}) }
                                    { manager?  <div style={Object.assign({}, Style.flexRow , Style.textMB, { marginTop:20 })}><Switch  checked={this.state.item.active} color="secondary" onChange={() => this.handleChange('handleItemActivation') } /></div>
                                                :null}
                                    { this.props.item? navButton({v, onClick:()=> goBack({ v, history:this.props.history }), icon:'back', style:{margin:'1rem', position:'fixed'} }):null }
                                    { item.image && !v.pos?  
                                        <div style={{position:'relative'}}>
                                            { image2({ image:item.image, style:{width:'100%'} })}
                                            { v.manager? 
                                                <div>
                                                    {closeButton({ v, onClick:()=>this.handleImageChange(""), styleCont:{top:10} })}
                                                    {/* <select style={Object.assign({}, Style.selectDropdown, v.div4, { marginTop:0, bottom:0, margin:5, position:'absolute' })} selected={false} onChange={(e) => this.setState({item:{...item, imageStyle:JSON.parse(e.target.value)}, toggleEdit:true}) }>
                                                        { [{ name:"Image Size", imageStyle:"" }, { name:"Default", imageStyle:"" }, { name:"Contain", imageStyle:{ backgroundSize:'contain' }}, { name:"400PX", imageStyle:{ height:'400px' }}, { name:"500PX", imageStyle:{ height:'500px' }}, { name:"600PX", imageStyle:{ height:'600px' }}].map((item, index) => { return( <option key={index} style={Style.textXSB} value={JSON.stringify(item.imageStyle)}>{item.name}</option>) })}
                                                    </select> */}
                                                    { hint3({ v, text:'To replace image first click the X icon to remove current image', style:{ ...Style.buttonBadge, ...v.div4, bottom:5, margin:5, position:'absolute', fontSize:'0.9rem' } })}
                                                </div>
                                            :null}
                                        </div>
                                        :
                                        v.pos||v.customer?<div style={{marginTop:'4rem'}} />:uploadButton2({ style:{alignSelf:'center'}, image:item.image,  v, uploadingChange:(e)=>this.setState({ mainImageUp:e }), uploading:this.state.mainImageUp, handleChange:(e)=>this.handleImageChange(e) })
                                    }
                                    {/* <input style={ Object.assign({}, Style.input, { textAlign:'center', marginTop:20 })}  placeholder="Image URL"  value={ item.image } onChange={(e) => this.handleImageChange(e.target.value) } /> */}
                                    {/* item.image&&!v.pos?    <div style={ Object.assign({}, Style.imageBackground, {backgroundImage: "url(" + item.image + ")", minHeight:height/2.5 })} />:null} */}
                                    
                                    { manager?  <textarea style={ Object.assign({}, Style.textArea, Style.textMB, Style.flexRow, { marginTop:20, textAlign:'center', fontFamily:'inherit' })} placeholder="Item Name"  value={ item["title_"+language] || item.title } onChange={(e) => this.handleTitleChange(e.target.value) } />
                                                :<div onClick={() => console.log(this.props, this.state) } style={ Object.assign({}, Style.textMB, Style.flexRow, Style.wrap, { textAlign:'center', padding:40, paddingBottom:0 })}>{item["title_"+language] || item.title}</div>}
                                    { manager?  <textarea style={ Object.assign({}, Style.textArea, { marginTop:20, textAlign:'center', fontFamily:'inherit' })} placeholder="Description"  value={ item["description_"+language] || item.description } onChange={(e) => this.handleDescriptionChange(e.target.value) } />
                                                :item.description?<div onClick={() => console.log( this.props, this.state )} style={ Object.assign({}, Style.text1_2, { textAlign:'center', padding:40, paddingBottom:0, fontWeight:300, whiteSpace:'pre-line', textTransform:'none' })}>{item["description_"+language] || item.description}</div>:null}
                                

                                    { this.renderDietTags({v, managerStyle, manager, language, customer})}
                                    {/* <div style={Object.assign({}, Style.flexCol, { marginTop:30, marginBottom:30 })}>
                                        <a class="button1">
                                            <span>press me</span>
                                        </a>
                                    </div>

                                    <div style={Object.assign({}, Style.flexCol, { marginTop:30, marginBottom:30 })}>
                                        <div className="button2" style={Object.assign({}, {  })} >
                                            <div><BoxSelect /></div>
                                            
                                        </div>
                                    </div> */}


                                    {/* ITEM VARIANTS  */}
									<div style={{marginTop:40}} />
									{ 	item.variance!==undefined && 
										Object.entries(item.variance).map((item,index) => { item = { ...item[1], key: item[0], titleKey:item[0], index:index  } 
											let title = item
                                            let variant = item
											return (

												<div id={ title["name_"+v.language] || title.name } className='itemOption' key={item.key} style={Object.assign({}, managerStyle, { marginTop:20, fontWeight:'normal', maxWidth:'100%', overflow:'hidden' })} >
													
													<div style={Object.assign({}, Style.flexRow, v.div3, { padding:'1.5rem', justifyContent:'space-between' })}>

                                                        { manager?  <input  style={ Object.assign({}, Style.text, Style.inputBasic, { fontWeight:'bold' })} className="form-control input" placeholder="Item Name"  value={ title["name_"+language] || title.name } onChange={(e) => this.handleOptionTitleChange(e.target.value, title) } />
                                                                    :<div   style={Object.assign({}, Style.text, { fontWeight:'bold', textTransform:'uppercase' })} >{ title["name_"+language] || title.name }</div> }
														
                                                        { title.required && <div style={Object.assign({}, Style.badge, v.div4, { })}>{ getAppText2({v, text:'Required'}) }</div>}
													
                                                    </div>

                                                    {manager && 
                                                        <div  style={Object.assign({}, Style.flexColOnly, v.div, Style.textSSB, { padding:'1.5rem', paddingTop:'0.5rem' })}>
                                                            <div style={Object.assign({}, Style.flexRow,{ justifyContent:'space-between' })}>
                                                                <div onClick={() => this.handleChange('duplicateOptionTitle', title)}><FiCopy style={{ fontSize:'1.5rem' }} /></div>
                                                                <div onClick={() => this.deleteOptionTitle(title)}><FiTrash style={{ fontSize:'1.5rem' }} /></div>
                                                            </div>
                                                            <div style={Object.assign({}, Style.flexRow,{ justifyContent:'space-between', marginTop:'1rem' })}><div>{getAppText2({v, text:'required'})}</div><Switch  checked={title.required}           color="secondary" onChange={() => this.handleOptionRequiredChange(title) } /></div>
                                                            <div style={Object.assign({}, Style.flexRow,{ justifyContent:'space-between' })}><div>{getAppText2({v, text:'multi'})}</div><Switch  checked={title.multipleSelect}   color="secondary" onChange={() => this.handleOptionMultiSelectChange(title) } /></div>
                                                            <div style={Object.assign({}, Style.flexRow,{ justifyContent:'space-between' })}><div>{getAppText2({v, text:'read only'})}</div><Switch  checked={title.readOnly}           color="secondary" onChange={() => this.handleChange('handleOptionReadOnly', title) } /></div>
                                                            <div style={Object.assign({}, Style.flexRow,{ justifyContent:'space-between' })}><div>{getAppText2({v, text:'change quantity'})}</div><Switch  checked={title.changeQuantity}     color="secondary" onChange={() => this.handleChange('changeQuantity', title) } /></div>
                                                        </div>
                                                    }


													{ item.options && Object.entries(item.options).map((item,index) => { item = { ...item[1], key:item[0], optionKey:item[0], index:index  } 
														
                                                        let option = item
                                                        if          ( item.input && v.customer) return <Input2 icon={option.upload?'upload':option.dateTime?'clock':'edit'} id={option.key} v={v} setState={(e)=>this.setState(e)} upload={option.upload} uploadButtonFull inputFocus={this.state.inputFocus} placeholder={item["option_"+language]||item.option}  value={option.selected||""}    onChange={(e) => this.handleChange3({ type:'input', variant, option, value:e.target.value }) } styleCont={{margin:'1rem'}} button={option.dateTime? ()=>this.props.updateDialog({ orderDateTime:true, icon:'clock', link:(value)=> this.handleChange3({ type:'input', variant, option, value:value.dateTimeSelectedString }) }):null } />
                                                        else return (
                                                            <ContButt onClick={()=> manager?null:this.handleSelect(title,item,manager)} key={item.key} style={Object.assign({}, Style.flexRow, Style.inherit, { padding:'1rem', paddingLeft:'2rem', paddingRight:'2rem', width:'100%', fontWeight:300 })}>
																
                                                                <div onClick={()=> manager?this.handleSelect(title,item,manager):null} style={{marginRight:'2rem', display:'flex' }}>
                                                                    { title.multipleSelect ? 
                                                                        item.selected?<BoxSelect /> : <BoxBlank /> 
                                                                        : item.selected? <RadioSelect /> : <RadioBlank /> 
                                                                    }
                                                                </div>
                                                                { manager?  <input  style={ Object.assign({}, Style.textSS, Style.inputBasic, { textTransform:'none', display:'flex', flex:4, marginRight:'1rem' })} className="form-control input" placeholder="Option Name"  value={ item["option_"+language] || capitalize(item.option) } onChange={(e) => this.handleOptionNameChange(title, item, e.target.value) } />
                                                                            :<div   style={ Object.assign({}, Style.textSS,                   { textTransform:'none', display:'flex', flex:4, marginRight:'1rem', overflow:'hidden', textOverflow:'ellipsis' })}>{item["option_"+language] || capitalize(item.option)}</div>}                                                           
                                                                
                                                                { title.changeQuantity ?
                                                                        manager?    <input  style={ Object.assign({}, Style.textSS, Style.inputBasic, { display:'flex', flex:1, textAlign:'right' })} className="form-control input" placeholder="#"  value={ item.changeQuantityValue } onChange={(e) => this.handleChange('changeQuantityValue', title, item, e.target.value) } />
                                                                                    :<div   style={ Object.assign({}, Style.textSS,                   { display:'flex', textAlign:'right' })}>{item.priceAdjust!==0?info.currencySymbol:null}{trimAmount(item.priceAdjust * (item.changeQuantityValue||1))}</div>
                                                                :null}

                                                                { manager? <input  style={ Object.assign({}, Style.textSS, Style.inputBasic, { display:'flex', flex:1, textAlign:'right' })} className="form-control input" placeholder="$"  value={ item.priceAdjust } onChange={(e) => this.handleOptionPriceChange(title, item, e.target.value) } />
                                                                            :!title.changeQuantity && <div   style={ Object.assign({}, Style.textSS, { display:'flex', textAlign:'right' })}>{(item.priceAdjust>0 && this.state.item.price!==0) ? "+":null}{item.priceAdjust!==0?info.currencySymbol:null}{item.priceAdjust===0 ? null : item.priceAdjust && trimAmount(item.priceAdjust) }</div>}

                                                                { manager?  <div onClick={() => this.handleChange('optionDelete', title, item)} style={{marginLeft:30, display:'flex' }}><FiTrash style={{ fontSize:'1rem' }} /></div>:null}


															</ContButt>
														)
													})}

                                                    {/* Add Option */}
                                                    {manager? 
                                                        <div style={Object.assign({}, Style.flexRow, {padding:'1rem'})}>
                                                            <Button onClick={() => this.addOption({ other:{                           }, title })} style={{  }}>{getIcon2({icon:'addCircle', fontSize:'2rem' })}</Button>
                                                            <Button onClick={() => this.addOption({ other:{ input:true                }, title })} style={{  }}>{getIcon2({icon:'edit', fontSize:'2rem' })}</Button>
                                                            <Button onClick={() => this.addOption({ other:{ input:true, upload:true   }, title })} style={{  }}>{getIcon2({icon:'image', fontSize:'2rem' })}</Button>
                                                            <Button onClick={() => this.addOption({ other:{ input:true, dateTime:true }, title })} style={{  }}>{getIcon2({icon:'clock', fontSize:'2rem' })}</Button>
                                                        </div>
                                                    :null}
												</div>
											)
										})}

                                    {/* Add Option Category */}
                                    {manager && <Button onClick={() => this.addOptionTitle()} style={{ marginTop:10, width:'100%' }}><MdAddCircleOutline style={{ fontSize:height/14 }} /></Button>}

                                    {this.renderChangeQuantity({ v, item, manager, customer, managerStyle })}

                                    { manager?
                                        <div>
                                            {/* // PRICE */}
                                            <div style={Object.assign({}, Style.card, Style.flexColOnly, {})}>
                                                <input style={ Object.assign({}, Style.input, Style.textL, { textAlign:'center', boxShadow:'unset' })} placeholder="$"  value={ this.state.item.price } onChange={(e) => this.handlePriceChange(e.target.value) } />
                                                {priceWithOptions!==0? <div style={Object.assign({}, { marginTop:20, textAlign:'center', textTransform:'uppercase' })}>{getAppText2({v, text:'Price With Options' })} {v.info.currencySymbol}{ trimAmount(priceWithOptions) }</div>:null}
                                            </div>
                                        </div>
                                    :null}

                                    {v.customer && !item.hideNotes?
                                        <div style={Object.assign({}, Style.flexColOnly, managerStyle, {marginTop:20, overflow:'hidden'})}>
                                            {/* Notes */}
                                            <div style={Object.assign({}, Style.flexRow, Style.textB, v.div3, { textTransform:'uppercase', padding:'1.5rem', justifyContent:'flex-start' })}>{ getAppText2({ v, text:'Notes' }) }</div>
                                            <input placeholder={getAppText2({ v, text:'Notes' })}  value={item.notes} onChange={(e) => this.handleChange('notes', e.target.value) } style={ Object.assign({}, Style.cardFlat, v.input, { marginTop:0, boxShadow:'unset' })} />
                                        </div>
                                    :null}

                                    { manager? this.renderOtherOptions({ v, item, managerStyle, manager, language, customer }):null}

                                    { manager? 
                                        SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, cancel:()=>this.mapStoreToState, save:()=>this.save })
                                        :
                                        <ButtonNav onClick={ () => { 
                                                if (item.customMainButtonUrl) handleLink2({props:this.props, v, link:item.customMainButtonUrl})
                                                else if (this.checkRequiredSelected({v})) addItem({ v, item, props:this.props, afterAddItem, update }) 
                                            }} v={v} >
                                            <div style={Style.textB}>{ readOnly?null: ordering? getAppText2({v, text: update&&'update' || item.customMainButtonText || (orderImmediately?'Submit':'Add') }): getIcon2({ icon:'heart', color:v.bt, fontSize:'1.5rem' }) }</div>
                                            {item.quantitySelected&&item.quantitySelected>1? <div style={Style.textSSB}>{'x'+item.quantitySelected}</div> :null}
                                            {priceWithOptions? <div style={Style.textSSB}>{v.info.currencySymbol}{ trimAmount(priceWithOptions) }</div> :getIcon2({icon:'send'})}
                                        </ButtonNav>
                                    }

					</div>

			)
        }

	}

    renderChangeQuantity = (p) => {

        let { v, manager, customer, item, managerStyle }       =   p
 
        if (customer && item.quantitySelectedHide) return null
        else {
            return(
                <div style={Object.assign({}, Style.flexColOnly, managerStyle, { marginTop:20, overflow:'hidden' })}>
                    <div style={Object.assign({}, Style.flexRow, Style.textB, v.div3, { textTransform:'uppercase', padding:'1.5rem', justifyContent:'flex-start' })}>{ getAppText2({v, text:'quantity'}) }</div>
                    <div style={Object.assign({}, Style.flexRow, { minHeight:78, justifyContent:'space-around' })}>
                        <Button onClick={() => this.handleChange('quantitySelected',-1) } style={{ borderRadius:999 }}>{getIcon2({icon:'minus', fontSize:'2rem', color:v.tc })}</Button>
                        <div style={Object.assign({}, Style.textB, {minWidth:'3rem', textAlign:'center'})}>{item.quantitySelected}</div>
                        <Button onClick={() => this.handleChange('quantitySelected',1) } style={{ borderRadius:999 }}>{getIcon2({icon:'plus', fontSize:'2rem', color:v.tc })}</Button>
                    </div>
                    {manager?<div style={Object.assign({}, Style.flexRow, Style.textSB, {textTransform:'uppercase', margin:20, justifyContent:'space-between' })}><div>{getAppText2({v, text:'Hide' })}</div><Switch  checked={this.state.item.quantitySelectedHide || false} color="secondary" onChange={() => this.handleChange('quantitySelectedHide') } /></div>:null}
                </div>
            )
        }
    }

    renderDisableQuickAdd = ({ v, manager, customer, item, managerStyle }) => {
 
        if (customer) return null
        else {
            return(
                <div style={Object.assign({}, Style.flexColOnly, managerStyle, {marginTop:20, padding:'1.5rem' })}>
                    <div style={Object.assign({}, Style.flexRow, Style.textSB, {textTransform:'uppercase', justifyContent:'space-between' })}>
                        <div>{ getAppText2({ v, text:'Disable Quick Add' }) }</div>
                        <Switch  checked={this.state.item.disableQuickAdd || false} color="secondary" onChange={() => this.handleChange('disableQuickAdd') } />
                    </div>
                    { hint3({ v, text:'Item can be added to the basket directly from the menu page (without viewing item description)', style:{ marginTop:20, fontSize:'0.9rem' } })}
                </div>
                
            )
        }
    }

    renderOrderImmediately = ({ v, manager, customer, item, managerStyle }) => {
 
        if (customer) return null
        else {
            return(
                <div style={Object.assign({}, Style.flexColOnly, managerStyle, {marginTop:20, padding:'1.5rem' })}>
                    <div style={Object.assign({}, Style.flexRow, Style.textSB, {textTransform:'uppercase', justifyContent:'space-between' })}>
                        <div>{ getAppText2({ v, text:'Order Immediately' }) }</div>
                        <Switch  checked={item.orderImmediately?true:false} color="secondary" onChange={(e) => this.handleChange2({v, item:{ ...item, orderImmediately:!item.orderImmediately } }) } />
                    </div>
                    { hint3({ v, text:'Item will be ordered immediately.', style:{ marginTop:20, fontSize:'0.9rem' } })}
                </div>
            )
        }
    }

    renderOtherItems = ({ v, manager, customer, item, managerStyle }) => {

        let queues = v.info.queues && Object.entries(v.info.queues).map(item => ({ ...item[1], key:item[0] }))

        return(
            <div style={Style.flexColOnly}>

                {/* Queues */}
                <div style={Object.assign({}, Style.flexColOnly, managerStyle, {marginTop:20, padding:'1.5rem' })}>
                    <div style={Object.assign({}, Style.flexRow, Style.textSB, {textTransform:'uppercase', justifyContent:'space-between' })}>{ getAppText2({ v, text:'Order Queue' }) }</div>
                    { queues? 
                        <select style={Object.assign({}, Style.selectDropdown, { })} value={item.queue||"Select"} onChange={(e) => this.handleChange2({v, item:{ ...item, queue:e.target.value }}) }>
                            { [ {name:getAppText2({ v, text:'Select' })}, ...queues].map(item => {
                                return <option style={Object.assign({}, { textTransform:'uppercase' })} key={item.key} value={item.key}>{item.name}</option>
                            })}

                        </select>   
                    :null}                 
                    { hint3({ v, onClick:()=>this.props.history.push("/manager/config/editqueues"), text:'Select the Order Queue that this item should be directed. Click Here to Create new Order Queues.', style:{ marginTop:20, fontSize:'0.9rem' } })}
                </div>

                {/* hide Notes */}
                <div style={Object.assign({}, Style.flexColOnly, managerStyle, {marginTop:20, padding:'1.5rem' })}>
                    <Switch2 v={v} text='hide notes' checked={item.hideNotes} onChange={()=> this.handleChange2({v, item:{ ...item, hideNotes:!item.hideNotes }}) } />
                </div>
            </div>
        )

    }

    handleChange4 = ({ v, type, value }) => {

        let language 		= v.language
        let defaultLanguage = v.info.languageDefault?.name
        let item            = this.state.item
        
        if (type=='customMainButtonText') {
            if (language==undefined || language==null || language==defaultLanguage) {  // Maybe needs to be undefined/null only as if !language then will change for when is ""
                item.customMainButtonText                       = value
                item["customMainButtonText_"+defaultLanguage]   = value
            }
            else item["customMainButtonText_"+language]         = value
        }

        this.setState({item, toggleEdit:true})
    }

    handleChange2 = ({ v, item }) => {

        let language 		= v.language
        let defaultLanguage = v.info.languageDefault && v.info.languageDefault.name

        this.setState({item, toggleEdit:true})
    }

    handleChange = (type, var0, var1, var2) => {

        let v               = this.props.v
        let orderlinaStore  = {...this.props.orderlinaStore}
        let clientKey       = orderlinaStore.lastManaged
        let language 		= v.language
        let defaultLanguage = v.languageDefault

        let item            = {...this.state.item}

        if (type==='handleItemActivation') {
            item.active = !item.active
        }

        else if (type==='duplicateOptionTitle') {

            let option = var0

            // Copy Client
            let optCopy = JSON.parse(JSON.stringify(item.variance[option.key]))
            optCopy.name = optCopy.name + ' (Copy)'

            // Push into Menu

            item.variance[PushId()] = optCopy        
        }

        else if (type==='customNotesAdd') {
            if (!item.customNotes || item.customNotes==="") { item.customNotes=[] }
            item.customNotes.push(this.state.customNotesAdd)
            this.setState({customNotesAdd:""})
        }
        else if (type==='customNoteClear') {
            item.customNotes=""
        }

        else if (type==='toggleTime') {
            if  (!item.dateTime)    item.dateTime = { name:"Time", required:false }
            else                    item.dateTime = false
        }

        else if (type==='dateTitle') {
            if  (!item.dateTime)    item.dateTime = { name:"Time", required:false }
            item.dateTime.name = var0
        }

        else if (type==='toggleTimeRequired') {
            item.dateTime.required = item.dateTime.required? false:true
        }

        else if (type==='handleOptionReadOnly') {
            let title = var0
            item.variance[title.titleKey].readOnly? 
                item.variance[title.titleKey].readOnly=false
                :item.variance[title.titleKey].readOnly=true    
        }

        else if (type==='changeQuantity') {
            let title = var0
            item.variance[title.titleKey].changeQuantity? 
                item.variance[title.titleKey].changeQuantity=false
                :item.variance[title.titleKey].changeQuantity=true    
        }
        else if (type==='disableQuickAdd') item.disableQuickAdd? item.disableQuickAdd=false:item.disableQuickAdd=true
        else if (type==='changeQuantityValue') {
            let title   = var0
            let option  = var1
            let value   = var2
            item.variance[title.titleKey].options[option.key].changeQuantityValue = value  
        }
        else if (type==='optionDelete') {
            let title   = var0
            let option  = var1
  
            delete item.variance[title.titleKey].options[option.key]
        }
        
        else if (type==='notes') {
            item.notes   = var0  || ""
        }

        else if (type==='quantitySelected') {
            
            let increment           = var0
            item.quantitySelected   = item.quantitySelected
            item.quantitySelected   += increment
    
            if ( item.quantitySelected < 0 ) { item.quantitySelected = 0 }
        }

        else if (type==='quantitySelectedHide') {

            if (item.quantitySelectedHide) { item.quantitySelectedHide=false }
            else { item.quantitySelectedHide=true }
        }

        else if (type==='handleAddDietTag') {

            let dietTagKey = var0.key

            if (!item.dietTags) item.dietTags = []
            if (item.dietTags.includes(dietTagKey)) {
                let index = item.dietTags.indexOf(dietTagKey)
                item.dietTags.splice(index, 1);
            }
            else item.dietTags.push(dietTagKey)
        }


        this.setState({item, toggleEdit:true})
        // setTimeout(()=>{ this.setState({item, toggleEdit:true}) }, 100)
    }

    

    defaultSelections(title) {

        let item = {...this.state.item}
        let optionTitle = item.variance[title.titleKey]
        let defaultSelections = ""


            Object.entries(optionTitle.options).map(option => {
                if (option[1].selected) { 
                    defaultSelections += ", " + option[1].option
                }
            })

        return defaultSelections.slice(1)
    }

    convertAllToNumber({item}) {

        item.price  = convertToNumber(item.price)
        
        if (item.quantitySelected) { item.quantitySelected  = convertToNumber(item.quantitySelected) }

        if ( item.variance && Object.keys(item.variance).length!==0 ) {

            Object.entries(item.variance).map(title => {
                title[1].options && Object.entries(title[1].options).map(option => {
                    option[1].priceAdjust = convertToNumber(option[1].priceAdjust)
                    if (option[1].changeQuantityValue) { option[1].changeQuantityValue = convertToNumber(option[1].changeQuantityValue) }
                })
            })
            setTimeout(()=>{ this.setState({item}) }, 100)
        }
        else {  setTimeout(()=>{ this.setState({item}) }, 100) }
    }

    handleImageChange = (text) => {
        let item = {...this.state.item}
        item.image = text
        this.setState({item, toggleEdit:true})
    }
    
    handleTitleChange = (text) => {

        let v               = this.props.v
        let orderlinaStore  = {...this.props.orderlinaStore}
        let clientKey       = orderlinaStore.lastManaged
        let language 		= v.language
        let defaultLanguage = v.languageDefault

        let item = {...this.state.item}

        if (language===undefined || language===null || language===defaultLanguage) {
            item.title = text
            item["title_"+defaultLanguage] = text
            this.setState({item, toggleEdit:true})
        }
        else {
            item["title_"+language] = text
            this.setState({item, toggleEdit:true})
        }
    }

    handleDescriptionChange = (text) => {

        let v               = this.props.v
        let orderlinaStore  = {...this.props.orderlinaStore}
        let clientKey       = orderlinaStore.lastManaged
        let language 		= v.language
        let defaultLanguage = v.languageDefault

        let item = {...this.state.item}

        if (language===undefined || language===null || language===defaultLanguage) {
            item.description = text
            item["description_"+defaultLanguage] = text
            this.setState({item, toggleEdit:true})
        }
        else {
            item["description_"+language] = text
            this.setState({item, toggleEdit:true})
        }
    }

    handleOptionTitleChange = (text, title) => {

        let v               = this.props.v
        let orderlinaStore  = {...this.props.orderlinaStore}
        let clientKey       = orderlinaStore.lastManaged
        let language 		= v.language
        let defaultLanguage = v.languageDefault


        let item = {...this.state.item}

        if (language===undefined || language===null || language===defaultLanguage) {
            item.variance[title.titleKey].name = text
            item.variance[title.titleKey]["name_"+defaultLanguage] = text
            this.setState({item, toggleEdit:true})
        }
        else {
            item.variance[title.titleKey]["name_"+language] = text
            this.setState({item, toggleEdit:true})
        }
    }
    
    handleOptionRequiredChange = (title) => {        
        let item = {...this.state.item}
        item.variance[title.titleKey].required = !item.variance[title.titleKey].required
        this.setState({item, toggleEdit:true})
    }

    handleOptionMultiSelectChange = (title) => {        
        let item = {...this.state.item}
        item.variance[title.titleKey].multipleSelect = !item.variance[title.titleKey].multipleSelect
        
        let titleOptions    = item.variance[title.titleKey].options
        Object.entries(titleOptions).map(option => { option[1].selected = false })

        this.setState({item, toggleEdit:true})
    }

    handleOptionNameChange = (title, option, text) => {

        let v               = this.props.v
        let clientKey       = v.clientKey
        let language 		= v.language
        let defaultLanguage = v.languageDefault


        let item = {...this.state.item}

        if (language===undefined || language===null || language===defaultLanguage) {
            item.variance[title.titleKey].options[option.optionKey].option = text
            item.variance[title.titleKey].options[option.optionKey]["option_"+defaultLanguage] = text
            this.setState({item, toggleEdit:true})
        }
        else {
            item.variance[title.titleKey].options[option.optionKey]["option_"+language] = text
            this.setState({item, toggleEdit:true})
        }
    }

    handleOptionPriceChange = (title, option, text) => {

        let item = {...this.state.item}
        item.variance[title.titleKey].options[option.optionKey].priceAdjust = text
        this.setState({item, toggleEdit:true})
    }

    handlePriceChange = (text) => {
        let item = {...this.state.item}
        item.price = text  
        this.setState({item, toggleEdit:true})
    }

    deleteOptionTitle = (title) => {
        console.log('running del')
        let item = {...this.state.item}
        let titleKey = title.titleKey
        
        delete item.variance[titleKey]
        this.setState({item, toggleEdit:true})
    }

    addOptionTitle = async () => {

        let item = {...this.state.item}
        
        if (item.variance===undefined ||  Object.keys(item.variance).length===0 ) {
            console.log('item add option 1')

            item.variance = {}
            item.variance[PushId()] = {
                "multipleSelect" : true,
                "name" : "Enter Option Title Here",
                "options" : {
                "-LOhv82mCmFG7_p8jMhi" : {
                    "selected" : false,
                    "option" : "large",
                    "priceAdjust" : 0
                }
                },
                "required" : false
            }
            this.setState({item, toggleEdit:true})
        }

        else {

            console.log('item add option 2')

            item.variance[PushId()] = {
                "multipleSelect" : true,
                "name" : "Enter Option Title Here",
                "options" : {
                    "-LOhv82mCmFG7_p8jMhi" : {
                        "selected" : false,
                        "option" : "large",
                        "priceAdjust" : 0
                    }
                },
                "required" : false
            }
            this.setState({item, toggleEdit:true})
        }
    }

    addOption = async ({ other, title}) => {
        let item = {...this.state.item}
        let titleKey = title.titleKey

        // Create Options node if empty
        if (!item.variance[titleKey].options) item.variance[titleKey].options={}

        item.variance[titleKey].options[PushId()] =  {
            "selected" : false,
            "option" : "New Option",
            "priceAdjust" : 0,
            ...other
        }
        this.setState({item, toggleEdit:true})
    }

    save = async () => {

        let item    = this.state.item
		let v       = this.props.v 
        this.setState({ lb:true });

        // Save OG Preview Info
        if (!item.directUrl) item.directUrl = trimUrl(item.title)
        let { directUrl, title, description, image } = item
        if (v) updateOgMetaTags({ url:`${v.venueUrl}/${directUrl}`, ogMetaTags: {title, description, image} })

        this.convertAllToNumber({item})

        setTimeout(()=>{ 

            let clientKey   = this.props.orderlinaStore.lastManaged;
            let categoryKey = item.categoryKey;
            let menuItemKey = item.menuItemKey;
    
            Firebase.database().ref("clients/" + clientKey + "/info/menuItemGroupsNew/" + categoryKey + "/menuItems")
                .update({  [menuItemKey]:item })
                .then(() => {  this.setState({ lb:false, toggleEdit:false }) })
                .catch((error) => { handleError({ error, v }); this.setState({ lb:false, toggleEdit:false, error}) })

        },1000)
    }

}
export default Item

