import React, { Component } from 'react';

import Firebase from '../Firebase';
import { Card, Button } from '@material-ui/core/';

import Style from '../styles';
import PushId from 'pushid';


import Notifications from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Send from '@material-ui/icons/Send';

import { Card2, languagesCodes, logo2, RenderHelper7, closeButton, goBack, navButton, Loading, getIcon2, searchParams, handleLink3, trimUrl2, Input2, RenderImage2, fileAttachmentPic, getCredentials2, RenderAttachment, log2, languageButtonsB, getKeyByValue, getFullDate, getAppText2, translate2, handleError, cloudFunction, updateData2, getTime2, getData, getData2, getTime2HHMM, loadingDots, getOrders2, navButtonMini, Cover, updateArray, updateOrder, getIcon3 } from '../Mini-components/Functions'
import { ChatListTG } from '../components'


class Advizir extends Component {

	constructor(props) {
		super(props);
		this.state = {
            message: ''
        };
	}


    componentDidMount() {

        let v                   = this.props.v
        let newChatCustomer     = v.manager? this.props.newChatCustomer||searchParams("newChatCustomer") : v.customerId

        
        this.setState({ newChatCustomer})
        this.getChat()
	}

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.v.state) != JSON.stringify(this.props.v.state)) {
            
            console.log('curr did update newChatsWlcW', this.props.v.state.newChatsWlcW )

        }
	}

    componentWillUnmount() {
        clearInterval(this.renderDelayMessage)
    }

    getChat = () => {

        let v       = this.props.v

        console.log('curr ', v )
        console.log('curr newChatsWlcW', v.state.newChatsWlcW )

        if (v.pathname2.includes('manager/chat')) {

            // ALL CHATS
            getOrders2({ key:v.wlc.key, then:({orders})=>{
                console.log('curr listen chats', orders)
                let chatList = this.state.chatList || {}
                orders.forEach(item => {
                    let chat = chatList[item.customerId] || {};
                    chat[item.key] = item
                    chatList[item.customerId] = chat 
                })

                console.log('sss', chatList)
                this.setState({ chatList })
                this.scrollIntoView()
            }})
        }
        else {
            // JUST SINGLE CUSTOMER CHATS
            getOrders2({ key:v.wlc.key, query:["customerId", "==", v.userInfo.customerId], then:({orders})=>{
                console.log('curr listen chats CUSTOMER ONLY', orders)
                this.setState({ chats:orders })
                this.scrollIntoView()
            }})
        }


        // Firebase.firestore().collection('clients').doc(v.wlc.key).collection('orders').where("customerId", "==", v.userInfo.customerId)
        //     .onSnapshot( (doc) => {

        //         let chats        = doc?.docs.map(doc => ({ ...doc.data(), key:doc.id }))
        //         console.log('curr listen', chats)
        //         this.setState({ chats })
        //         this.scrollIntoView()
        //     });
    }

    updateChat = async ({ key, update }) => {

        let v           = this.props.v

        // UPDATE STATE
        let chats       = await updateArray({ array:this.state.chats, key, update})
        this.setState({ chats })

        // UPDATE FIRESTORE
        updateOrder({ v, clientKey:v.wlc.key, key, update })
    }

    time = () => {
        // returns time in minutes
        return ( Math.floor(Date.now()/1000/60) )
    }

    elapsedTime = (time) => {
        // returns time in minutes since passed in time
        return( this.time() - time )
    }

    scrollIntoView = () => {

        if      (this.newData) this.newData.scrollIntoView({ behavior: "smooth" }) 
        else    setTimeout(()=>{ this.scrollIntoView() }, 500)
    }

    handleChange = async ({ type, newChatCustomer, messageObject, target, customer, message, chatType, chatFrom, chatCustomerId }) => {

        let v                       =   this.props.v
        let chat                    //  DEPRECATED
        let chats                   =   this.state.chats || []
            newChatCustomer         =   newChatCustomer || this.state.newChatCustomer
        let ncc                     =   newChatCustomer
        let { chatAdmin, venueTitle, venueLogo }  = v.chatInfo 

        if (type==='openCustomer2') {

            this.setState({ newChatCustomer })
            console.log('run open cust')

            if (!chat[newChatCustomer]) return null

            chat[newChatCustomer].newMessageFromCustomer = false
            chat[newChatCustomer].readTimeByVenue = this.time()

            setTimeout(()=>{  this.setState({ chat }) },500)
            setTimeout(()=>{ this.save() },1000)
            this.scrollIntoView()
        }

        else if (type==='sendMessageNotHelpful' ) {

            this.handleChange({ type:'sendMessage2', chatType:'chat', message:'This is not Helpful',  })

            setTimeout(() => { 
                
                let email = v.userInfo.email
                message = email
                    ?   `Sorry about that. I'll find the answer and get back to you on chat and by email (${email}) as soon as possible.`
                    :   `Sorry about that. Can I please have your email address. I'll find the answer and get back to you on chat and via email as soon as I can.`

                this.handleChange({ type:'sendMessage2', chatType:'chat', chatFrom:'venue', message })

            }, 2000);
        }


        else if (type==='sendMessage2' ) {

            console.log('xx chats', chats)

            message                     =   message || this.state.message || ""
            if (!message) return
            if (chatAdmin)              { let chats=this.state.chats; let lastChat = chats[Object.keys(chats)[0]]; chatCustomerId=lastChat.customerId; chatFrom='venue'; chatType='chat'; console.log('last', lastChat)  }

            let history                 =   chats.map(chat => chat = { role:chat.from=='customer'?'user':'assistant', content:chat.message||"" }) // history Expected Format = [ ['hi', 'Hello! How can I assist you today?'], ['my name is peter', 'Hello! How can I assist you peter?'], ['I want the Orderlina Elite plan', 'The Orderlina Elite plan is $19'] ]
            console.log('history', history)
            console.log('history length', JSON.stringify(history).length)

            history                     =   history.slice((history.length - 10), history.length) // ONLY INCLUDE THE LAST 10 MESSAGES
            console.log('history 2', history)
            console.log('history 2 length', JSON.stringify(history).length)


            let messages                =   [
                                                {"role": "system", "content": `You are a professional assistant that is helping customers with a digital product. Format your answers clearly with line breaks and dot points where applicable. Use the message history and the following data to answer questions. Answer questions as concisely as possible and without elaborating. If you don't know the answer, just say that you don't know. Don't mention what deficiencies of the digital product unless asked directly. Data:
                                                
                                                Hi to hide menu categories or items, go to:

                                                ADMIN > TYPES > TYPE (EG. DINE IN) > MENU > Then select the categories or items you want to appear.

                                                To create a backup go to: ORDERLINA ADMIN > BACKUP. Please note that the backups are available for Pro Subscribers only.
                                                                            The Facebook Pixel id which is a number of 10-20 digits in length. For example 99358295524235
                            Hello and thanks for getting in touch with us! What can we help you with today?
                            Hi Kristine, only Loyverse POS integration is free. Orderlina is free to use, please sign up and give it a go.
                            Hi Joe we recommend Paypal in the Philipines.

                            Yes you can provide a payment QR on pay page. To set this up:

                            ADMIN > TYPES > TYPE (EG. DINE IN) > PAYMENT OPTIONS > Select QR

                            Hi Jose, to cancel your subscription, go to:

                            ADMIN > PLAN/UPGRADE > Follow the steps to cancel.

                            Can I ask the reason for cancelling? Is there are feature that was missing that or anything that we could have done better?

                            Thank you
                            Hi Zaid,

                            To print:

                            - You can print in orderlina by clicking on the print button on the order. ORDERLINA > ORDERS > ORDER > Click Print button
                            - You can set up auto printing in Orderlina: ADMIN > ALERTS > Autoprint
                            - You can print from Loyverse. LOYVERSE > Receipts
                            Hi Gabriele,

                            Thanks for contacting Orderlina and apologies for the delayed response to your inquiry.

                            Yes currently there is a 250 limit on items downloaded from Loyverse.

                            We recommend that you if you have a single item replicated for different sizes, we recommend you consolidate them into one item using Loyverse Variants and Modifiers.

                            For Example:

                            Cheese 50g
                            Cheese 100g
                            Cheese Cold
                            Cheese Room Temp

                            Can be converted to 1 item:

                            Cheese - Variant 50g/100g, Modifer Cold/Room Temp
                            If you are a Loyverse connected customer:

                            The order goes to:

                            - Orderlina Orders queue
                            - Loyverse Receipts and Stock are adjusted

                            Printing:

                            - You can set up auto printing in Orderlina (ADMIN > ALERTS)
                            - You can print manually from Loyverse Receipts

                            Currently we aren't able to send the order directly to Loyverse order queues but we understand that this would be useful. If you could request this feature from Loyverse directly this might help them prioritise and deliver this functionality sooner. Once Loyverse this functionality we would enable it as it is a popular request.

                            Hi Gabriele,

                            Thanks for contacting Orderlina and apologies for the delayed response to your inquiry.

                            Yes currently there is a 250 limit on items downloaded from Loyverse.

                            We recommend that you if you have a single item replicated for different sizes, we recommend you consolidate them into one item using Loyverse Variants and Modifiers.

                            For Example:

                            Cheese 50g
                            Cheese 100g
                            Cheese Cold
                            Cheese Room Temp

                            Can be converted to 1 item:

                            Cheese - Variant 50g/100g, Modifer Cold/Room Temp
                            If you are a Loyverse connected customer:

                            The order goes to:

                            - Orderlina Orders queue
                            - Loyverse Receipts and Stock are adjusted

                            Printing:

                            - You can set up auto printing in Orderlina (ADMIN > ALERTS)
                            - You can print manually from Loyverse Receipts

                            Currently we aren't able to send the order directly to Loyverse order queues but we understand that this would be useful. If you could request this feature from Loyverse directly this might help them prioritise and deliver this functionality sooner. Once Loyverse this functionality we would enable it as it is a popular request.
                            Hi Moses, currently we don't integrate with Ipay88. We will be choosing our new payment integrations in January 2024, please check back after this date.
                            Sorry about this. We hope to improve our payment integrations soon.
                            Hi Josh, currently when an order is sent using Orderlina:
                            It must be received in ORDERLINA ADMIN. Stock levels and receipts are adjusted in Loyverse.

                            Hi Josh, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Josh, thanks for contacting Orderlina. As an Elite plan subscriber you get a custom domain. Apologies for the delay in sending these instructions. Please select from a domain option:

                            1. YOUR DOMAIN: maple.cr
                            2. YOUR DOMAIN SUBDOMAIN: menu.maple.cr
                            3. NEW DOMAIN: Eg. maplemenu.com (or any domain that is available)

                            To use your domains, we'll send you some simple instructions to connect. For new domains we will manage it all for you. Please let us know which option you would like.

                            Hi Josh,

                            Please follow these instructions to setup your custom domain menu.maple.cr.

                            STEP 1

                            Add the following TXT record:

                            TXT maple.cr google-site-verification=kQjQCcDPlHL6oPLz0pabu2UtQzglDPYgPZPhyJpWw0Q

                            * The txt should be for maple.cr NOT menu.maple.cr
                            * If you have not done this before, here are some basic and generic instructions for adding a TXT record to a domain:

                            https://www.youtube.com/watch?v=ti02YUtBIyo
                            https://www.name.com/support/articles/115004972547-Adding-a-TXT-Record

                            STEP 2

                            Add these A records using the same method you followed for the TXT record:

                            A Record - menu.maple.cr - 199.36.158.100
                            A Record - www.menu.maple.cr - 199.36.158.100

                            STEP 3

                            Remove any redirects and old A records and AAAA records for the domains in STEP 2. Do not remove any other type of record

                            DONE

                            Once these steps are done, please reply to this email and we will complete the remaining steps in the setup. The remaining steps can take 48-72 hours to complete. Your new site will show a security certificate warning for 48 hours, until a new certificate has been provisioned.

                            If you have any problem completing these step, please provide the login and password for your domain registrar and we can complete the steps for you.

                            HI Josh,

                            Thanks for following those instructions. Unfortunately you were given the wrong TXT. Can you please add the following:

                            TXT
                            maple.cr
                            google-site-verification=5wEbPwBGsntE9bPCqQ6m66jPXwVhTnaeubMotpp9jHw

                            (You can remove the previous TXT)

                            Please advise when done.

                            Regards,
                            Orderlina Tech Support
                            Hi Josh, there will be a solution to assist with this by the end of the month.
                            Hi Josh, for Loyverse customers you can make the font for the whole item larger through customer CSS but unfotunately you can't make just the price larger.

                            If you wanted to do this, you would enter the following css code:

                            .menuItem div div {
                            font-size: 1.5rem !important;
                            }

                            Into the custom styling box located at:

                            INFO > STYLE > CUSTOM CSS
                            The result would look something like this:

                            Hi Fabian, we are in the process of integrating ePosNow and it should be available in September 2023.

                            Hi Fabian, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            in your inquiry.
                            HI Melody, currently there aren't sub categories. The only way would be to add a 'Add a protein' into each item in the salad category. Sorry I can be of more help.
                            Hi Fusionx, yes you can set up autoprint on Orderlina:

                            * MAC / IOS / Android - currently requires confirmation
                            * Windows - yes


                            For more information go to:

                            ORDERLINA ADMIN > ALERTS > AUTOPRINT
                            Our Orderlina native IOS/Android apps are set to be released in October 2023. In the meantime, please use our web app.
                            Hi there, if you are a Loyverse connected customer:

                            The order goes to:

                            - Orderlina Orders queue
                            - Loyverse Receipts and Stock are adjusted

                            You can set up printing in Orderlina or print from Loyverse Receipts.

                            Hi Joon,

                            - Yes you can use Orderlina with Loyverse integration for 150+ tables (there is no table limit).
                            - Regarding payment fees, if you setup Google Pay or Apple Pay (using our Stripe integration) there are no fees apart from the Stripe processing fees: https://stripe.com/pricing. Orderlina does not charge fees.
                            Hi Hanne, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Hanne,

                            Thanks for contacting Orderlina. Here are the answers to your questions:

                            1. Currently you can order categories alphabetically (but not the individual items). To order categories:

                            ORDERLINA ADMIN > MENU > Click the 'Settings' icon next to the 'Read/Like/Ordering' switches. An option will appear to sort categories alphabetically.
                            I just read that you manually changed the order but the items revert back. Are you integrated with a POS provider? This might cause this to happen. I couldn't find your venue. Can you please send me the link to your venue eg. orderlina.menu/arubabluevillage. Once received I will investigate further.
                            Custom styling, colors, fonts etc are available once you upgrade to a PRO plan. All other options are free to use.
                            Regarding stock, if an item is out of stock in Loyverse, it will not appear in Orderlina.
                            Regarding languages, not all languages are available currently. Pro customers can request additional languages and this is done for free within 30 days of the request.
                            Regarding languages, you can add Dutch language for each menu item yourself. Once in the menu item, you simply click the Dutch language icon, then type the dutch text.

                            However the main app text (non-menu) may be missing dutch at the moment. We are working to add languages as requested by customers. Sorry for this inconvenience.

                            Hi Hanne, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            HI Andrew, thanks for contacting Orderlina, at the moment when an order is sent in Orderlina:

                            * It must be received in Orderlina Manager
                            * Stock and receipts are adjusted in Loyverse

                            Currently Loyverse don't allow partners to send orders to their KDS. This might change in the future however you'll have to request this from Loyverse. Apologies for this inconvenience.

                            Hi Andrew, at the moment the integration is only for the menu sync. We hope that this will change in the future.

                            Hi Mike, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Mike, thanks for contacting us. To change the currency symbol go to:

                            ORDERLINA ADMIN > INFO > Currency Symbol

                            Hi Mike, unfortunately at the moment there is not a way to do that.

                            The 2 decimal places in settings will only restrict larger numbers Eg.

                            7.98765
                            7.99
                            1 Aug 2023, 12:38
                            Hi Mike, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Mike, customers can checkout using credit cards, but not credit point yet I'm sorry.

                            Hi there, thanks for contacting Orderlina, yes these features are all available as below:

                            - Changing the order of the menu and items -
                            Your can change order of categories. Item order coming soon. If you are connected to an external POS menu, category order will follow the pos order

                            - Turning off some categories -
                            This can be done in TYPES

                            - Changing colours for phone usage
                            Yes this is available
                            Hi, yes you can set a table number in the QR code:

                            ADMIN > QRS > MORE
                            Hi Prashanthini, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Prashanthini, did you save after you made the changes. I've added two of the items now. Please have a look at the screenshot. Let me know if you have any further trouble.

                            Hi Prashanthini, Orderlina is free to use and the Loyverse integration is also free. There are some other features like colors and fonts that require a PRO subscription.

                            The PRO plan is $19/month. Full pricing is available here: https://orderlina.com/pricing/

                            You're welcome
                            HI Teoh, thanks for contacting Orderlina. Due to the high demand at the moment, phone support is only available to Elite Subscribers ($99/mon). I am happy to try and assist over chat if you tell me the problem?

                            Hi Teoh, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            22 Jul 2023, 09:59
                            Hi Teoh,

                            1. Go to

                            ORDERLINA ADMIN > LOYVERSE > Select a different Payment method.

                            If the method foes not appear here, please let me know.

                            2. I will enable the newest loyverse tax module for you. This will be available Thursday 27th.

                            3. I will send you instructions. Are you using a PC, Mac, Android, or iOS?Create appointment
                            Hi Teoh,

                            1. I see now. That makes sense. I have send this feedback to the product team. They will prioritise this based on other customer requests for this.

                            3. Regarding printing, you must first install the printer driver on your device, then set it as the default printer. What is the model of your printer?

                            To connect your Epson printer:

                            1. Plug the printer into your ios/android device. Depending on the device, it will prompt you to install the driver automatically.

                            OR

                            Search the App store for: EPSON TM UTILITY. This app will install your Epson driver on your device. Once successfully installed you will be able to select it as your default printer in Chrome.
                            HI to sign up for Orderlina please go to:

                            https://orderlina.menu/manager/register
                            Yes to print you can either:

                            1. Click the print icon on the order, or
                            2. Enable auto print: ADMIN > ALERTS > AUTOPRINT
                            Hi Shiro, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            10 Jul 2023, 11:03
                            Hi Shiro, here are the answers to your questions:

                            Is it possible to automatically assign Order Numbers to each order made by the customer ?
                            Yes, an order number appears at the bottom of the order and is also available on the printed receipt.

                            And is it possible to skip the part where the customer chooses a table Nr ?
                            Yes you can go to TYPES > TYPE > LOCATIONS > Click ‘Do not use locations’

                            And a last question, is it possible to connect the App with a POS System, or at least with a Ticket printer and an electronic cash drawer ?
                            Yes you can currently integrate with Loyverse by going to:
                            ORDERLINA > LOYVERSE
                            From August 2023 we will be adding another 8 POS integrations.
                            Yes you can enable autoprint by going to:
                            ORDERLINA > ALERTS > Select ‘autoprint’.
                            You can also print each order individually by clicking the ‘Print’ button.

                            Hi Shiro, we can enable these features on your account, can you please tell me your venue name.

                            Currently the only option for a custom order number is to display the last 5 digits of the existing order id in large print at the top. We can add this feature and allow only manager's to delete orders.

                            Hi Shiro, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            12 Jul 2023, 15:36
                            Thanks for trying Orderlina. These services will be added to your account next week.

                            Hi Shiro, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            17 Jul 2023, 15:33
                            Hi Shiro, the new pos systems are expected to be connected between September 15th and October 31st. Unfortunately our pos partners are not able to provide definite timelines for us.

                            Please note that the new POS integrations will require the $99 plan. The lovyerse integration will still be available on the Free and $19 plans.

                            Hi Shiro, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            24 Jul 2023, 19:20
                            Hi Shiro, at the moment, the Loyverse integration is only 'READ ONLY'. We hope to have an READ-WRITE integration in the future however nothing there is no date set for this feature.
                            Hi Jonathan, Orderlina's integration with Loyverse is available under the free plan.

                            To connect to Loyverse go to:

                            ORDERLINA ADMIN > LOYVERSE

                            To set a minimum order for deliveries go to:

                            ORDERLINA ADMIN > DELIVERY > MINIMUM ORDER

                            Hi Johnathan, unfortunately there is currently no way to set a minimum order for dine in orders.
                            Hi Dalar, currently we are unable to send orders to the Loyverse printer or Orders queue. Currently you can:

                            * Receive the order in ORDERLINA ADMIN > ORDERS
                            * Setup printing in ORDERLINA ADMIN > ALERTS > AUTOPRINT
                            Hi Nixx, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Nixx, to change the main app language:

                            Go to:
                            INFO > LANGUAGES

                            I have done this for you
                            Hi there please register or login at:

                            https://orderlina.menu/manager/register
                            Hi Achille, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Achille, thanks for the message. Which items are you not seeing in Orderlina?

                            Regarding the tax, can you please tell me your venue name and I will look into how we can improve the look for you.

                            Hi Achille, I have passed on this inquiry. Someone will message you soon, usually within 24hours.

                            HI Achille,

                            MISSING ITEMS

                            Regarding the issue of non-appearing items. We currently get a maximum of 250 items from Loyverse and your menu has reached this limit. This is likely why the other items are not appearing.

                            250 items is a lot for the customer to scroll through so anything above this makes the menu very difficult for the customer to use.

                            We also recommend that you use VARIANTS in Loyverse rather than duplicating the same item. Please see the images attached for an example. This will reduce the size of your menu, should speed up service, and will also allow Orderlina to download your full menu.

                            I understand this may not be the solution you want but there are some limits to the integration at the moment. We apologise for this inconvenience.

                            Someone will respond to the Tax related issue shortly.
                            Hi Achille, there will be a new version of the Loyverse-Orderlina integration released on May 23rd. This will include the 'tax included' options to be reflected in the bill.

                            So if you have tax settings set in Loyverse, this will be automatically shown in Orderlina after the 23rd of May.

                            In the meantime I have set the message "Included in the price" in TYPES > SUR PLACE
                            Hi Achille, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Achille, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Achille, to enter the code please click the UPGRADE tile, then click the plan you want, then the 'enter code' link.

                            Hi Jonny, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Jonny, thanks for contacting Orderlina.
                            Please check that the items are marked 'available for sale' and 'in stock' in Loyverse. If both of these are enabled for the missing items, can you please give us the name of one of the missing items and we will investigate...
                            Regarding printing, there is a manual print option in the Orderlina orders queue. We will be adding additional Auto-print options in mid June.
                            Hope this helps, please let me know if not.
                            Regarding the missing items, we are currently looking into it and will get back to you by the end of the day.
                            Hi Jonny, I have increased the limits on your account. Please refresh the page and check for missing items.

                            Hi Jonny, apologies for the delay in releasing our autoprint feature. We have a manual print feature at the moment. Unfortunately the auto print has been delayed due to some technical issues.
                            You can sign up on our free plan to see how the features work.

                            Hi Jonny, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Unfortunately the auto-print feature is still not available. It is scheduled for sometime this month but I can't provide a set date. If you could wait till the end of the month it would be appreciated.

                            Hi Jonny, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Farhan, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Farhan, to connect Orderlina to Loyverse go to:
                            ORDERLINA ADMIN > LOYVERSE
                            To set up autoprint go to:

                            ORDERLINA ADMIN > ALERTS > Enable Autoprint

                            Hi Farhan, Orderlina currently does not have an app to download. It is a web app. But we have IOS/Android apps coming later in the year.

                            To connect Loyverse go to:

                            ORDERLINA ADMIN > LOYVERSE
                            Hi Wade, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Wade, just a moment.
                            Hi Wade, I have added the subscription to your new venue.
                            Please give me a moment to Investigate the menu
                            That's completely fine. I have escalated the issue. It will be resolved shortly.
                            Hi Wade that issue has been resolved for you now. Please let us know if you need anything else.
                            This is your current venue url: https://orderlina.menu/634coffeecafe16

                            Yes of course. The same token will work.
                            You can also add another guest type Eg.

                            DINE IN
                            DELIVERY
                            SPECIAL LIMITED MENU

                            Then you can limit the items ween in that Limited menu
                            You can do it either way
                            You can also change the url back from ...cafe16 to ...cafe if you like
                            Hi Cheska, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Cheska, thanks for contacting Orderlina.

                            To cancel please go to:

                            ADMIN > PLAN > Click MANAGE > Click cancel
                            Can I ask why you want to cancel. How could we have done better to improve?
                            Sorry to hear that. Thanks for trying us.
                            Sent
                            HI Torsten, I have cancelled all the subscription under torsten.berger2@gmail.com so you may no continue to sign up and upgrade your preferred email account.

                            Hi Torston I have added the subscription to:

                            https://orderlina.menu/totospoolrestaurant
                            (totospoolrestaurant@gmail.com)

                            Please let me know if there are any outstanding issues.
                            Hi there, once an order is place, the order goes to:

                            * Orderlina Admin > Orders Queue
                            * Receipt and stock are adjusted in Loyverse

                            I hope this helps.
                            Hello and thanks for getting in touch with us! What can we help you with today?
                            HI Mikayle, thanks for contacting us. Yes Orderlina is a web app it can be used by going to a website eg. orderlina.menu/marcelas or scanning a QR code. No app download or installation required.

                            Yes you are able to integrate it into a website using an iframe or using a subdomain domain eg. menu.yourwebsite.com

                            Hi Mikayle, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Mikayle, to create QRs for individual locations or tables, go to:

                            ORDELRINA ADMIN > QR > Click 'OPTIONAL SETTINGS' > Select the location

                            This will create a QR for that location
                            To create new locations, go to:

                            ORDERLINA DASHBOARD > LOCATIONS
                            Please let us know if you need any more help.

                            Hi Mikalye, if you have ordered the categories in Loyverse that should come through to Orderlina. Can you please send us a screenshot of the numbering system on Loyverse and we will investigate.
                            Regarding printing, only receipts and stock are synced in Loyverse. To print using loyverse, you can go to:

                            LOYVERSE > RECEIPTS > RECEIPT > Click Print

                            Orderlina will be releasing an autoprint system through the app before the end of the month.

                            Hi Mikayle I have requested that your menu be upgraded to the latest Loyverse Integration version. If you check back on Wednesday next week, it will allow you to arrange categories in alphabetical order.

                            Hi Mikayle, thank you for contacting Orderlina Hotefy. We are currently experiencing high numbers of inquiries but will respond to you as soon as possible (within 24hours). If you are an exsisting customer please include your Venue Name in your inquiry.
                            Hi Mikayle, I will respond to you once the new release is out. It will be before the end of the week.Create appointment
                            Hi Mikayle, Orderlina should now allows ordering alphabetically. Can you please go to:

                            ORDERLINA ADMIN > MENU > SETTING BUTTON > Enable ORDER ALPHABETICALLY

                            Can you please reply and let me know if your Orderlina menu now matches the Loyverse.

                            "To add the Facebook Pixel to the platform, go to:

                            ADMIN > MARKETING > FACEBOOK PIXEL"
                            The Elite Plan is $99. The Elite plan includes a custom domain and footer"

                                                `},
                                                ...history,
                                                {"role":"user", "content":message||"" }
                                            ]
            let chat                    =   { from:chatFrom||'customer', status:v.wlc.chatNotify?1:"", messages, message, clientKey:v.wlc.key, customerId:chatCustomerId||v.userInfo.customerId, time:getTime2(), time2:getTime2HHMM() } //model:"gpt-3.5-turbo",

            console.log('messages', messages)
            console.log('length', JSON.stringify(messages).length)

            // CREATE CHAT 

            // STATE
            this.setState({ message:"", chats:[ ...chats, {key:PushId(), ...chat} ] })

            // SEND TO FIRESTORE OR AI
            cloudFunction({ name:'advizir', params:{ type:chatType||'chatAi', chat }})

            // SCROLL
            setTimeout(()=>{  this.scrollIntoView() }, 1000)
        }

        else if (type==='readMessage2') {

            if (chat?.[ncc]) {

                if      ( v.manager && 
                        ( chat[ncc].newMessageFromCustomer || 
                        ( chat[ncc].newMessageFromCustomerTime >= chat[ncc].readTimeByVenue))  
                ) {
                        chat[ncc].newMessageFromCustomer    = false
                        chat[ncc].readTimeByVenue           = this.time()
                        setTimeout(()=>{  this.setState({ chat }) },500)
                        setTimeout(()=>{ this.save() },1000)
                }

                else if ( v.customer && 
                        ( chat[ncc].newMessageFromVenue || 
                        ( chat[ncc].newMessageFromVenueTime >= chat[ncc].readTimeByCustomer)) 
                ) {
                        chat[ncc].newMessageFromVenue       = false
                        chat[ncc].readTimeByCustomer        = this.time()
                        setTimeout(()=>{  this.setState({ chat }) },500)
                        setTimeout(()=>{ this.save() },1000)
                }
                else return null
            }
            else return null
        }
        else if (type=='translateText') {

            this.setState({ loadingLanguage:target })
            var result = await translate2({ text:messageObject.message, target })

            if (result?.translation) {

                let { targetLanguage, translation }  = result 

                if (chat[newChatCustomer]?.messages?.[messageObject?.key]) chat[newChatCustomer].messages[messageObject.key]['message_'+targetLanguage] = translation||"" // if there are chat messages

                this.props.updateSimple({ language:targetLanguage||"" } )

                setTimeout(()=>{  this.setState({ chat, loadingLanguage:"" }) },500)
                setTimeout(()=>{ this.save() },1000)
            }
            else { console.log('no trans'); this.setState({ loadingLanguage:"" }) }
        }

        else if (type=='deleteChat') {

                let confirmed  = window.confirm(`Delete Chat For Customer ${customer.credentials} #${customer.key}`)
                if (confirmed && chat[customer.key]) {
                    
                    // Delete from State
                    delete chat[customer.key]
                    setTimeout(()=>{  this.setState({ chat }) },500)

                    // Delete on Server
                    Firebase.database().ref("clients/" + v.clientKey + "/chat/" + customer.key )
                        .remove()
                        .then(() => log2('Customer Chat Deleted'))
                        .catch((error) => handleError({ v, error, noDialog:true }) ) 
                }
        }
    }

    newChatCustomer = () => {

        let v                       = this.props.v
        v.info                      = v.info || {}
        let {venueTitle, venueLogo, chatAdmin} = v.chatInfo
        
        let chats                   = this.state.chats || []
        let newChatCustomer         = this.state.newChatCustomer
        let welcomeMessage          = getAppText2({v, text:'Welcome To'}) + " " + venueTitle + ". " + getAppText2({v, text:'how can I help you?'}) || `Welcome to ${venueTitle}. How can I help you?`
        let delayMessage            = v.info?.['chatDelayMessage_'+v.language] || v.info?.chatDelayMessage || getAppText2({v, text:'We are currently experiencing a high volume of inquiries but will respond as soon as we can, usually within 24hours. For urgent inquiries please call or visit reception.'})
        chats                       = [
                                        { key:0, time:0, from:'venue', message:welcomeMessage, status:2 }, 
                                        ...chats,  
                                      ].sort((a,b) =>  new Date(a.time) - new Date(b.time))

        console.log('curr advizir render', this.state.chats)

        // LOADING DOTS
        if (chats?.[chats.length-1]?.from=='customer') chats.push({ key:0, from:'venue', message:'loading', status:2 }) // && chats?.[chats.length-1]?.status==2

        return (
            <div style={Object.assign({}, v.div2, Style.boxShadow, {  padding:0, position:'relative', height:'unset', paddingBottom:0, overflow:'hidden', borderRadius:20, width:'100%' })}>
                    
                    <Cover show={!this.state.chats && chatAdmin} v={v} style={{justifyContent:'center'}}><p className="mt-1 text-sm text-gray-500" style={{...Style.flexRowOnly, alignItems:'center'}}>To initiate a chat, click the chat icon {getIcon2({ icon:'chat', style:{marginRight:10, marginLeft:10} }) } on a customer order.</p></Cover>

                    {/* Messages */}
                    <div style={Object.assign({}, { padding:'1rem', flex:999, overflow:'scroll', height:'60vh' })}>
                        { chats?.map((item,index) => {
                                
                            let fromVenue                   = item?.from=='venue'
                            let messageInLanguage           = item?.['message_'+v.language]
                            
                            if (item.key=='delayMessage' && (chats[index-1]?.from=='venue' || this.elapsedTime(chats[index-1].time)<2) ) return null
                            return(
                                <div key={item.key} onClick={()=>log2(item)} style={Object.assign({}, Style.flexRowOnly, Style.boxShadowLite, {borderRadius:10, margin:5, padding:0, borderWidth:1, alignItems:'center', maxWidth:'100%', position:'relative', overflow:'hidden' }, item.from==='venue'?{  ...v.div2, marginRight:'20%'}:{  ...v.div4, marginLeft:'20%'})}>
                                    <Cover v={v} onClick={()=>{ this.updateChat({ key:item.key, update:{status:2} }) }} show={item.status==1 && ((item.from=='venue'&&!chatAdmin) || (item.from=='customer'&&chatAdmin)) } style={{...Style.inherit, ...Style.flexRow }}>{getIcon2({ icon:'email', marginRight:20 })} Click to View Message</Cover>
                                    <div style={{flex:1, maxWidth:'100%' }}>
                                        {item.message?.includes?.('firebasestorage')?
                                            RenderAttachment({ v, img:item.message, defaultPic:fileAttachmentPic, enlarge:true, clickToOpenInNewTab:true })
                                            :
                                            <div onClick={()=> { if (item.message?.includes('http')) handleLink3({ newTab:trimUrl2({ text:item.message }) })  }} style={Style.flexRowOnly}>
                                                <div style={Object.assign({}, Style.flexCol1, {maxWidth:'100%'})}>
                                                    <div style={Object.assign({}, Style.flexRow, { padding:'0.75rem 0.75rem 0 1rem', minHeight:50, alignItems:'unset' })}>
                                                        <div style={Object.assign({}, Style.wrap, Style.textXS, { flex:1, textTransform:'none', whiteSpace:'pre-line' })}>{item.message=='loading'? RenderImage2({ v, img:loadingDots, contStyle:{boxShadow:'none'}, style:{ maxHeight:'3rem', objectFit:'contain', boxShadow:'none' } }):item.message}</div>
                                                        {fromVenue? logo2({ img:venueLogo, size:'2.5rem', style:{ margin:0, marginLeft:5, backgroundColor:v.pc } }) :null}
                                                    </div>

                                                    {/* {languageButtonsB({v, setState:(index)=>this.setState({show:index}), show:this.state.show, index, loadingLanguage:this.state.loadingLanguage, fromVenue, messageInLanguage, onClick:(obj)=>  this.handleChange({ type:'translateText', messageObject:item, target:obj.iconText } ) }) } */}
                                                    {/* {messageInLanguage? <div style={Object.assign({}, Style.wrap, Style.textXSB, {padding:'1rem', textTransform:'none'})}>{messageInLanguage}</div> :<div />} */}
                                                </div>
                                            </div>
                                        }

                                        <div style={Object.assign({}, Style.textXXS, {display:'flex', flexDirection:'row', justifyContent:'space-between', padding:'0.5rem 1rem 0.5rem 1rem', alignItems:'center' })}>
                                            {/* <div style={Style.textXXS}>{this.elapsedTime(item.time)+" mins"}</div> */}
                                            <div style={Style.textXXS}>{item.time2}</div>
                                            {/* <div style={Style.textXXS}>{item.credentials}</div> */}
                                            <div style={Style.inherit}>{item.from=='venue'? venueTitle.toUpperCase():('Cust#'+(v.userInfo?.customerId||"").slice(-5)).toUpperCase() }</div>
                                            {/* <div style={Style.inherit}>{chats[newChatCustomer]?.[item.from=='venue'?'readTimeByCustomer':'readTimeByVenue'] >= item.time? 'Read':''}</div> */}
                                        </div>

                                        {item.from=='venue' && index && (index==(chats.length-1))  && !item.message?.includes?.("Can I please have your email address") && !(item.message=='loading') && !chatAdmin?
                                            <div>
                                                <span style={{padding:5, flex:1, width:'100%'}} className="isolate inline-flex rounded-md shadow-sm">
                                                    <button style={{flex:1}} type="button" className="justify-center relative inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                                                        {getIcon3({ icon:'thumbsUp' }) }
                                                    </button>
                                                    <button onClick={()=> this.handleChange({ type:'sendMessageNotHelpful' }) } style={Object.assign({}, this.state.notHelpful==item.key? v.div4:{}, { flex:1 })} type="button" className="justify-center relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                                                        Not Helpful
                                                    </button>
                                                </span>
                                                {/* {this.state.notHelpful==item.key?
                                                    <div style={{padding:5}}>
                                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                                                        <div className="mt-2">
                                                          <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            value={this.state.notHelpfulEmail||""}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            placeholder="you@example.com"
                                                            aria-describedby="email-description"
                                                            onChange={e=>this.setState({ notHelpfulEmail:e.target.value })}
                                                          />
                                                        </div>
                                                        <p className="mt-2 text-sm text-gray-500" id="email-description">Please enter your email address and someone will get back to you soon.</p>
                                                      </div>
                                                :null} */}
                                            </div>
                                        :null}
                                    </div>
                                </div>
                        )})}

                        {/* LOADING DOTS */}
                        {/* { chats?.[chats.length-1]?.from=='venue' || true? null : RenderImage2({ v, img:loadingDots, style:{ maxHeight:'3rem', objectFit:'contain', boxShadow:'none' } })} */}

                        {/* SCROLL TO REFERENCE */}
                        <div ref={(ref) => this.newData = ref} style={{marginTop:'25vh', color:'white'}} />
                    </div>

                    {/* SEND BUTTONS */}
                    <div style={Object.assign({}, Style.flexColOnly, v.div, {width:'100%', backgroundColor:'transparent', zIndex:999, position:'fixed', bottom:0, minHeight:'5rem', boxShadow:Style.boxShadowTop, position:'relative', flex:0 })}>
                        <Input2 noIcon translation={false} v={v} onKeyDown={(e) => { if (e.key=='Enter') this.handleChange({type:'sendMessage2'}) }} styleCont={{ ...v.div, margin:'0.5rem', borderRadius:10, border:'none', boxShadow:'none', backgroundColor:'inherit' }} placeholder='Ask a question..' instructions="Chat With Us" value={this.state.message?.includes('firebasestorage')?"":this.state.message} onChange={(e) => { console.log(v, this.state); this.setState({message:e.target.value}) }} uploadChange={(val)=>{ this.setState({message:val}); this.handleChange({type:'sendMessage2'}) }} button={()=>this.handleChange({type:'sendMessage2'})} iconButton='send' />
                        {/* <div style={Object.assign({}, Style.flexRow, v.div4, Style.text1B, { minHeight:60, textTransform:'uppercase' })}>{getIcon2({ icon:'advizir', color:'white', marginRight:5 })}ADVIZIR</div> */}
                    </div>

                    {/* Cover if inactive */}
                    {/* {!v.info.chatActive? <Card2 v={v} icon="clock" title='we are not available to chat right now, please check back later' style={Object.assign({}, v.div4, Style.cover, {  })} /> :null } */}
            </div>
        )
    }

	render() {


        let v                                   = this.props.v
        let {venueTitle, venueLogo, chatAdmin}  = v.chatInfo


		if   ( !v ) return <Loading v={v} />

        else if (chatAdmin) return (
            <div style={{...Style.flexRowOnly, flex:1}}>
                <ChatListTG v={v} chatList={this.state.chatList} openChat={(chats)=>{ this.setState({ chats }); this.scrollIntoView() }} />
                <div style={{ padding:'1rem', paddingLeft:0, flex:1, display:'flex' }}>{this.newChatCustomer()}</div>
                
            </div>
        )
        else {
            return (
                <div style={Object.assign({}, Style.flexColOnly, { alignItems:'flex-end', position:'fixed', zIndex:9999999, bottom:'1rem', right:'1rem', height:'unset' })}>

                    { this.state.chatExpanded ? <div style={{width:500, maxWidth:'90vw'}}>{this.newChatCustomer()}</div> :null }

                    {/* CHAT COUNT / CHAT WITH US */}
                    { this.state.chatExpanded
                        ?   null
                        :   v.state.newChatsWlcM 
                            ?   navButtonMini({ v, text:v.state.newChatsWlcM, size:'2.2rem', style:{ ...v.div8, zIndex:9999, right:0, position:'absolute', pointerEvents:'none' } }) 
                            :   <div  className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20" style={v.div}>Ask a question.. {' '}<a onClick={()=>this.setState({ chatExpanded:true})} className="font-semibold text-indigo-600"><span className="absolute inset-0" aria-hidden="true" />Chat <span aria-hidden="true">&rarr;</span></a></div>}

                    { navButton({ v, icon:this.state.chatExpanded?'caretDown':'chat', fontSize:'2rem', style:v.div, onClick:()=>{this.setState({ chatExpanded:!this.state.chatExpanded }); this.scrollIntoView() } }) }
                </div>
            )
        }
	}


    save = async () => {

        let { v }                       =   this.props
        let { chat, newChatCustomer }   =   this.state

        if (chat?.[newChatCustomer]) {
            Firebase.database().ref("clients/" + v.clientKey + "/chat/" + newChatCustomer )
                .update( chat[newChatCustomer] )
                .then(()=>{ console.log('chat reply updated') })
                .catch((error) => { console.log('chat reply NOT updated', error) })
        }
        else console.log('chat reply', 'no chat')
    }

}


export default Advizir

