import React, { Component } from 'react';

import Firebase from '../Firebase';
import PushId from 'pushid';

import Style from '../styles';
import { Card, Button, CircularProgress, Container, Switch } from '@material-ui/core/';
import { KeyboardArrowDown } from '@material-ui/icons/';
import { IoIosCloseCircle } from 'react-icons/io';
import { FaCaretDown, FaCaretUp, FaExpandArrowsAlt } from 'react-icons/fa';
import { FiTrash } from 'react-icons/fi';
import { MdAddCircleOutline } from 'react-icons/md';

import { Text2, hint3, setLocation, image2, SaveCancelButtons2, uploadToFBStorage2, iconPage2, notifications, getVariables, SaveCancelButtons, trim, readyPage, social, iconPage, renderNotes, basketItems, Loading, getClientKey, isManager, getLanguage, convertToNumber, footer, isGuestTypeDelivery, getIcon, hint, getAppText, languageEditWarning, getIcon2, getAppText2, log2, getFilteredTables } from '../Mini-components/Functions'

import Delivery from '../Mini-components/delivery/Delivery';




class RoomBooking extends Component {

	constructor() {
		super();
		this.state = {
			viewCategory:[]
		};
	}

    componentDidMount() {

        let v = this.props.v

        this.setState({ tables:v.info?.tables || "" })
        readyPage({ props:this.props, v })
    }

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.v.info) != JSON.stringify(this.props.v.info)) {
        }
	}



	render() {

        let v               = this.props.v

		if          (!v)           return <Loading />
        else if     (v.manager)    {
            return (
                <div>
                    {v.manager? SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, cancel:()=>this.mapStoreToState, save:()=>this.save }):null}
                </div>
            )
        }
		else {
			return (
				<div style={Object.assign({}, Style.div1, v.div, { minHeight:'80vh' })} >
                    { notifications({props:this.props, v}) }


				</div>
			)
		}

	}

    save = () => {

        this.setState({ lb:true })
        let clientKey       = this.props.orderlinaStore.lastManaged

        Firebase.database().ref("clients/" + clientKey + "/info")
            .update({  tables:this.state.tables })
            .then(() => {  this.setState({ lb:false, toggleEdit:false }) })
            .catch((error) => { this.setState({ lb:false, toggleEdit:false, error }) })
    }


    createLocation = async () => {
        let tables = {...this.state.tables}
        let newLocation = {name:'New Location'}
        
        let newKey = PushId()
        tables[newKey] = newLocation
        this.setState({tables, toggleEdit:newKey})      
    }

    createCategory = async () => {

        let tables = {...this.state.tables}
        let newCategory =
            {
                "active" : true,
                "locations" : {
                    [PushId()] : { "name" : "Map", imageNew:"https://firebasestorage.googleapis.com/v0/b/orderlina-single-region/o/pics%2FpoolMap2.png?alt=media&token=b07c3a2e-e5a8-4976-b53b-de979bd72cdf" },
                    [PushId()] : { "name" : "Location 1" },
                    [PushId()] : { "name" : "Location 2" }
                },
                "name" : "Location Category"
            }

        let newKey = PushId()
        tables[newKey] = newCategory
        this.setState({tables, toggleEdit:newKey})  
    }

    createNest = async () => {

        let tables = {...this.state.tables}
        let newCategory =
            {
                "active" : true,
                "locations" : {
                    [PushId()] : { "name" : "Map", imageNew:'https://firebasestorage.googleapis.com/v0/b/orderlina-single-region/o/pics%2FgardenMap2.png?alt=media&token=623a72b7-cef0-4fe9-92c1-b2ce76a88889' },
                    [PushId()] : {
                        "active" : true,
                        "image" : "",
                        "locations" : {
                            [PushId()] : { "name" : "Map", imageNew:'https://firebasestorage.googleapis.com/v0/b/orderlina-single-region/o/pics%2FgardenMap2.png?alt=media&token=623a72b7-cef0-4fe9-92c1-b2ce76a88889' },
                            [PushId()] : { "name" : "Room 101" },
                            [PushId()] : { "name" : "Room 102" }
                        },
                        "name" : "Rooms L1"
                    },
                    [PushId()] : {
                        "active" : true,
                        "image" : "",
                        "locations" : {
                            [PushId()] : { "name" : "Map", imageNew:'https://firebasestorage.googleapis.com/v0/b/orderlina-single-region/o/pics%2FgardenMap2.png?alt=media&token=623a72b7-cef0-4fe9-92c1-b2ce76a88889' },
                            [PushId()] : { "name" : "Room 201" },
                            [PushId()] : { "name" : "Room 202" }
                        },
                        "name" : "Rooms L2"
                    }
                },
                "name" : "Rooms"
            }

        let newKey = PushId()
        tables[newKey] = newCategory
        this.setState({tables, toggleEdit:newKey})  
    }

    createSubCategoryLocation = async (category, subCategory) => {
        let tables = {...this.state.tables}
        tables[category.key].locations[subCategory.key].locations[PushId()] = {name:'New Location'}
		this.setState({tables, toggleEdit:true})
    }

    createCategoryLocation = async (category) => {
        let tables = {...this.state.tables}
        tables[category.key].locations[PushId()] = {name:'New Location'}
		this.setState({tables, toggleEdit:true})
    }

    createSubCategory = async (category) => {

        let tables = {...this.state.tables}

        tables[category.key].locations[PushId()] = 
            {
                "active" : true,
                "image" : "",
                "locations" : {
                    [PushId()] : { "name" : "Map", imageNew:'https://firebasestorage.googleapis.com/v0/b/orderlina-single-region/o/pics%2FgardenMap2.png?alt=media&token=623a72b7-cef0-4fe9-92c1-b2ce76a88889' },
                    [PushId()] : { "name" : "Location 1" },
                    [PushId()] : { "name" : "Location 2" }
                },
                "name" : "Location Sub Category"
            }

		this.setState({tables, toggleEdit:true})
			// this.forceUpdate()  
    }

    handleChange = async ({type, deleteIt, category, subCategory, item, text, v}) => {
        
        let change          = type
        let orderlinaStore 	= {...this.props.orderlinaStore}
        let language 		= v.language
        let clientKey       = v.clientKey
        let defaultLanguage = v.info.languageDefault && v.info.languageDefault.name

        let tables 			= {...this.state.tables}


        if (change==='image') {

            this.setState({ mapUpload:item.key })

            let uploadedUrl = await uploadToFBStorage2(this.props, text)
            if (uploadedUrl) { 

                if      (subCategory)   tables[category.key].locations[subCategory.key].locations[item.key].imageNew = uploadedUrl
                else if (category)      tables[category.key].locations[item.key].imageNew = uploadedUrl
                else                    tables[item.key].imageNew = uploadedUrl

                setTimeout(() => { this.setState({ toggleEdit:true, tables, mapUpload:false }) }, 50);
            }
        }

        else if (change==='addMap') {

            let tables = {...this.state.tables}
            let newLocation = {
                imageNew: "https://firebasestorage.googleapis.com/v0/b/orderlina-single-region/o/pics%2FgardenMap2.png?alt=media&token=623a72b7-cef0-4fe9-92c1-b2ce76a88889",
                name: "View Map"
            }
            tables[PushId()] = newLocation
            this.setState({tables, toggleEdit:true})  
        }
        else if (change==='location') {
            if     (subCategory)    {
                if (language)                                tables[category.key].locations[subCategory.key].locations[item.key]['name_'+language] = text
                if (!language || language===defaultLanguage) tables[category.key].locations[subCategory.key].locations[item.key].name = text
                if (deleteIt) delete                         tables[category.key].locations[subCategory.key].locations[item.key]
            }
            else if (category)      {
                if (language)                                tables[category.key].locations[item.key]['name_'+language] = text
                if (!language || language===defaultLanguage) tables[category.key].locations[item.key].name = text
                if (deleteIt) delete                         tables[category.key].locations[item.key]
            }
            else                    {
                if (language)                                tables[item.key]['name_'+language] = text
                if (!language || language===defaultLanguage) tables[item.key].name = text
                if (deleteIt) delete                         tables[item.key]
            }
            setTimeout(() => { this.setState({ toggleEdit:true, tables, mapUpload:false }) }, 50);
        }
        else if (change==='moveDown') {
            
            // Copy
            let itemCopy = {...tables[item.key]};

            // Push into Menu
            tables[PushId()] = itemCopy        

            // Delete item
            delete tables[item.key]

            setTimeout(() => { this.setState({ toggleEdit:true, tables }) }, 50);
        }
        else return null
    }

}
export default RoomBooking

