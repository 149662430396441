import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import PushId from 'pushid';
import { handleError } from './Mini-components/Functions';
// import Firebase from 'firebase';



const getInitialState = () => {

    let redSto      = localStorage.getItem('reduxStore')
    let redStoSize  = redSto && JSON.stringify(redSto).length

    // If the redux store is not too big then use existing one
    if (  redSto && redStoSize<2000000  ) { 
        console.log('redux - size ok ', redStoSize)
        return JSON.parse(localStorage.getItem('reduxStore'))   
    }
    else if (redSto) { 
        console.log('redux - size too large', redStoSize)
        return  ({ orderlinaStore: { user:null, config:null }}) 
    }
    else { 
        console.log('redux - new Store', redStoSize)
        console.error('redux - new Store', redStoSize)
        return  ({ orderlinaStore: { user:null, config:null, tmpuid:'tmp'+PushId() }}) 
    }
}

// Initial State...

const initialState = getInitialState()



// Action Type

export const setOrderlinaStore = (orderlinaStore) => {
    return {
      type: "setOrderlinaStore",
      value: orderlinaStore
    };
};


// Action

export const updateOrderlinaStore   = (data) => { return function(dispatch) { dispatch( setOrderlinaStore(data) )}}
export const updateSimple           = (data) => { return function(dispatch) { dispatch( {type: "updateSimple"       , value:data } )}}
export const updateConfig           = (data) => { return function(dispatch) { dispatch( {type: "updateConfig"       , value:data } )}}
export const updateWhiteLabelConfig = (data) => { return function(dispatch) { dispatch( {type: "updateWhiteLabelConfig" , value:data } )}}
export const updateClient           = (data) => { return function(dispatch) { dispatch( {type: "updateClient"       , value:data } )}}
export const updateUser             = (data) => { return function(dispatch) { dispatch( {type: "updateUser"         , value:data } )}}
export const updateUserConfig       = (data) => { return function(dispatch) { dispatch( {type: "updateUserConfig"   , value:data } )}}
export const updateUserConfigVenue  = (data) => { return function(dispatch) { dispatch( {type: "updateUserConfigVenue"   , value:data } )}}
export const updateUserConfigVenueGuestType  = (data) => { return function(dispatch) { dispatch( {type: "updateUserConfigVenueGuestType"   , value:data } )}}
export const updateLoading          = (data) => { return function(dispatch) { dispatch( {type: "updateLoading"      , value:data } )}}
export const updateDialog           = (data) => { return function(dispatch) { dispatch( {type: "updateDialog"       , value:data } )}}
export const updateVisitCount       = (data) => { return function(dispatch) { dispatch( {type: "updateVisitCount"   , value:data } )}}
export const updateFbsync           = (data) => { return function(dispatch) { dispatch( {type: "updateFbsync"       , value:data } )}}
export const updateBasket           = (data) => { return function(dispatch) { dispatch( {type: "updateBasket"       , value:data } )}}
export const updateStripePay        = (data) => { return function(dispatch) { dispatch( {type: "updateStripePay"    , value:data } )}}
export const updateManagerNewOrder  = (data) => { return function(dispatch) { dispatch( {type: "updateManagerNewOrder"  , value:data } )}}
export const updateCustomerExistingOrder    = (data) => { return function(dispatch) { dispatch( {type: "updateCustomerExistingOrder"      , value:data } )}}
export const updateToast            = (data) => { return function(dispatch) { dispatch( {type: "updateToast"       , value:data } )}}
export const updateTmpuid           = (data) => { return function(dispatch) { dispatch( {type: "updateTmpuid"      , value:data } )}}
export const updateVerifiedStatus   = (data) => { return function(dispatch) { dispatch( {type: "updateVerifiedStatus"      , value:data } )}}
export const updateKeyboardOpen     = (data) => { return function(dispatch) { dispatch( {type: "updateKeyboardOpen"      , value:data } )}}
export const updateLocation2        = (data) => { return function(dispatch) { dispatch( {type: "updateLocation2"     , value:data } )}}
export const updateGuestTypeKey2    = (data) => { return function(dispatch) { dispatch( {type: "updateGuestTypeKey2"    , value:data } )}}
export const updateAwaitingPayment  = (data) => { return function(dispatch) { dispatch( {type: "updateAwaitingPayment"    , value:data } )}}



// export const watchOrderlinaStore = () => {
//     return function(dispatch) {
//         Firebase.database().ref("person")
//             .on("value", function(snapshot){ 
//                 console.log(snapshot.val())
//                 var orderlinaStore = snapshot.val();
//                 var actionSetOrderlinaStore = setOrderlinaStore(orderlinaStore);
//                 dispatch(actionSetOrderlinaStore);
//             }, function(error) { console.log(error); });
//     }
// };


// Reducer...

const reducer = (state = initialState, action) => {

    let clientKey           = window.location.pathname.includes('/manager')?state.orderlinaStore.lastManaged:state.orderlinaStore.lastVenue
    let userConfigVenue     = state.orderlinaStore.userConfig?.venues?.[clientKey]
    let guestTypeDefault    = userConfigVenue?.guestTypeDefault
    let guestTypeKey        = userConfigVenue?.guestTypeKey || guestTypeDefault || "noGuestTypeKey"


    switch(action.type) {
        case "setOrderlinaStore"    : return { ...state, orderlinaStore: action.value };
        case "updateSimple"         : return { orderlinaStore: { ...state.orderlinaStore, ...action.value } };
        case "updateConfig"         : return { orderlinaStore: { ...state.orderlinaStore, config:action.value } };
        case "updateWhiteLabelConfig": return { orderlinaStore: { ...state.orderlinaStore, whiteLabelConfig:action.value } };
        case "updateUser"           : return { orderlinaStore: { ...state.orderlinaStore, user:action.value[0], language:action.value[1], lastVenue:action.value[2], lastManaged:action.value[3] } };
        case "updateUserConfig"     : return { orderlinaStore: { ...state.orderlinaStore, userConfig:action.value } };
        case "updateLoading"        : return { orderlinaStore: { ...state.orderlinaStore, loading:action.value } };
        case "updateDialog"         : return { orderlinaStore: { ...state.orderlinaStore, dialog:action.value } };
        case "updateVisitCount"     : return { orderlinaStore: { ...state.orderlinaStore, visitCount:action.value } };
        case "updateStripePay"      : return { orderlinaStore: { ...state.orderlinaStore, stripePay:action.value } };
        case "updateManagerNewOrder": return { orderlinaStore: { ...state.orderlinaStore, managerNewOrder:action.value } };
        case "updateCustomerExistingOrder"  : return { orderlinaStore: { ...state.orderlinaStore, customerExistingOrder:action.value } };
        case "updateToast"          : return { orderlinaStore: { ...state.orderlinaStore, toast:action.value } };
        case "updateTmpuid"         : return { orderlinaStore: { ...state.orderlinaStore, tmpuid:action.value } };
        case "updateKeyboardOpen"   : return { orderlinaStore: { ...state.orderlinaStore, keyboardOpen:action.value } };
        case "updateAwaitingPayment": return { orderlinaStore: { ...state.orderlinaStore, awaitingPayment:action.value } };
        

        case "updateBasket"         : {

            guestTypeKey = action.value?.basketGuestTypeKey || guestTypeKey // IF GUESTTYPE USES ANOTHER BASKET
            return { 
                orderlinaStore: { 
                    ...state.orderlinaStore, 
    
                    userConfig:{
                        ...state.orderlinaStore.userConfig,
                        venues:{
                            ...state.orderlinaStore?.userConfig?.venues,
                            [clientKey]:{
                                ...state.orderlinaStore.userConfig?.venues?.[clientKey],
                                basket:{
                                    ...state.orderlinaStore.userConfig?.venues?.[clientKey]?.basket,
                                    [guestTypeKey]:action.value
                                }
                            }
                        }
                    }
                }
            }
        };

        case "updateLocation2"        : {

            guestTypeKey = action.value.guestTypeKey || guestTypeKey
            return { 
                orderlinaStore: { 
                    ...state.orderlinaStore, 
    
                    userConfig:{
                        ...state.orderlinaStore.userConfig,
                        venues:{
                            ...state.orderlinaStore?.userConfig?.venues,
                            [clientKey]:{
                                ...state.orderlinaStore.userConfig?.venues?.[clientKey],
                                location:{
                                    ...state.orderlinaStore.userConfig?.venues?.[clientKey]?.location,
                                    [guestTypeKey]:action.value.location
                                }
                            }
                        }
                    }
                }
            }
        };
        case "updateVerifiedStatus"  : return { 
            // orderlinaStore: { 
            //     ...state.orderlinaStore, 
            //     [clientKey]:{
            //         ...state.orderlinaStore[clientKey],
            //         verified:action.value
            //     } 
            // }
            orderlinaStore: { 
                ...state.orderlinaStore, 

                userConfig:{
                    ...state.orderlinaStore.userConfig,
                    venues:{
                        ...state.orderlinaStore?.userConfig?.venues,
                        [clientKey]:{
                            ...state.orderlinaStore.userConfig?.venues?.[clientKey],
                            verified:action.value
                        }
                    }
                }
            }
        };
        case "updateGuestTypeKey2"  : return { 
            orderlinaStore: { 
                ...state.orderlinaStore, 


                // Guest Type
                userConfig:{
                    ...state.orderlinaStore.userConfig,
                    venues:{
                        ...state.orderlinaStore?.userConfig?.venues,
                        [clientKey]:{
                            ...state.orderlinaStore.userConfig?.venues?.[clientKey],
                            guestTypeKey:action.value.guestTypeKey
                        }
                    }
                }
            }
        };

        case "updateClient"           :     // CLIENT INFO NOW SAVED IN USER CONFIG 
        case "updateUserConfigVenue"  : return { 
            orderlinaStore: { 
                ...state.orderlinaStore, 

                userConfig:{
                    ...state.orderlinaStore.userConfig,
                    venues:{
                        ...state.orderlinaStore?.userConfig?.venues,
                        [clientKey]:{
                            ...state.orderlinaStore.userConfig?.venues?.[clientKey],
                            ...action.value
                        }
                    }
                }
            }
        };
        case "updateUserConfigVenueGuestType"  : return { 
            orderlinaStore: { 
                ...state.orderlinaStore, 

                userConfig:{
                    ...state.orderlinaStore.userConfig,
                    venues:{
                        ...state.orderlinaStore?.userConfig?.venues,
                        [clientKey]:{
                            ...state.orderlinaStore.userConfig?.venues?.[clientKey],
                            [guestTypeKey]:{
                                ...state.orderlinaStore.userConfig?.venues?.[clientKey]?.[guestTypeKey],
                                ...action.value
                            }
                        }
                    }
                }
            }
        };

        case "updateFbsync"         : return { 
            orderlinaStore: { 
                ...state.orderlinaStore, 
                [action.value[0]]: {
                     ...state.orderlinaStore[action.value[0]],
                     info       :action.value[1].info,
                     newOrdersM :action.value[1].newOrdersM,
                     newOrdersC :action.value[1].newOrdersC,
                     newChatsM  :action.value[1].newChatsM,
                     newChatsC  :action.value[1].newChatsC
                }
            } };
        default: return state;
    }
}


// Store...

export const store = createStore(reducer, applyMiddleware(thunkMiddleware));


// Subsciber

store.subscribe(()=> {

    try     { localStorage.setItem('reduxStore', JSON.stringify(store.getState())) } 
    catch   (error) { 

        handleError({  error: error + '. Avoid this error by using Google Chrome. If the problem persists, please contact support.' }) 
    }
})