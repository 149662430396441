import React, { Component } from 'react';

import Firebase from '../Firebase';
import PushId from 'pushid';

import Style from '../styles';
import { Card, Button, CircularProgress, Container, Switch } from '@material-ui/core/';
import { KeyboardArrowDown, Delete, LocalHotel, LocalBar, RestaurantMenu } from '@material-ui/icons/';
import { IoIosCloseCircle } from 'react-icons/io';
import { FiTrash } from 'react-icons/fi';
import { MdAddCircleOutline, MdRestaurant } from 'react-icons/md';
import { FaCaretDown, FaCaretUp, FaExpandArrowsAlt, FaPlus, FaMinus, FaCircle as RadioSelect, FaRegCircle as RadioBlank, FaRegSquare as BoxBlank, FaCheckSquare as BoxSelect  } from 'react-icons/fa';

import { hint3, Input2, RenderHelper7, getIcon2, getVariables, SaveCancelButtons2, trim, readyPage, social, iconPage, renderNotes, basketItems, Loading, getClientKey, isManager, getLanguage, removeSpecialChars, Select3, log2 } from '../Mini-components/Functions'




class Verify extends Component {

	constructor() {
		super();
		this.state = {
		};
	}

    componentDidMount() {
        let v = this.props.v
        this.mapStoreToState() 
        readyPage({ props:this.props, v })
	}
	
    mapStoreToState = async () => {

        let v               =   this.props.v

        this.setState({   
            verify:         v.info?.verify && JSON.parse(JSON.stringify(v.info?.verify)),
        });
    }


    



	render() {

        let v   		    = this.props.v

		if   ( !v ) return <Loading />
		else {
            let language 		= v.language
            let verify		    = {...this.state.verify}

			return (
				<div style={Style.div1} >
                    { RenderHelper7({ props:{ v, icon:'user', h1:'Custom Fields', message:'Create Field Groups for Each Type of Guest', style:{marginTop:20} }}) }

                    <div style={Object.assign({}, Style.flexColOnly, { paddingBottom:40 })}>

                        {/* NONE */}
                        {/* <Card style={Object.assign({}, Style.card, Style.flexColOnly, {fontWeight:'normal', padding:0 })}>
                            { RenderHelper7({ props:{ v, icon:'user', title:'None', style:{ borderRadius:'unset', boxShadow:'unset' } }}) }
                            <textarea style={Object.assign({}, Style.textArea, { margin:'1.5rem' })} placeholder='Instructions' autoCapitalize='none' selectTextOnFocus={true} multiline={true} autoCorrect={false} dataDetectorTypes='all' value={verify["noneInstructions_"+language] || verify.noneInstructions } onChange={(e) => this.handleChange('noneInstructions', e.target.value) } />
                        </Card> */}

                        {/* Custom Types */}

                        { verify.customTypes && Object.entries(verify.customTypes).map((item,index) => { item = { ...item[1], key: item[0], index:index  } 

                            let customType = item
                            let concatFieldNames = Object.entries(item.fields).map((item,index) => { item = { ...item[1], key: item[0], index:index  } 
                                return item["name_"+language] || item.name + " "
                            })
                            return (
                                <Card style={Object.assign({}, Style.card, Style.flexColOnly, {fontWeight:'normal', padding:0 })}>
                                    { RenderHelper7({ props:{ v, icon:'user', title:concatFieldNames, style:{ borderRadius:'unset', boxShadow:'unset' } }}) }
                                    
                                    <div style={Object.assign({}, Style.flexColOnly, {padding:'1.5rem'})}>
                                        <textarea style={Object.assign({}, Style.textArea, { })} placeholder='Instructions' autoCapitalize = 'none' selectTextOnFocus={true} multiline={true} autoCorrect={false} dataDetectorTypes='all' value={customType["instructions_"+language] || customType.instructions} onChange={(e) => this.handleChange('customTypeInstructions', customType, e.target.value) } />

                                        { customType.fields && Object.entries(customType.fields).map((item,index) => { item = { ...item[1], key: item[0], index:index  } 
                                            let value = item["name_"+language] || item.name
                                            return (
                                                <div style={Style.flexRowOnly}>
                                                    <Input2 twenty v={v} id={item.key} value={value} onChange={(e) => this.handleChange('customTypeName', customType, item, e.target.value) } />
                                                    {item.fieldType=='checkbox'?
                                                    <Input2 twenty v={v} id={item.key+'cb'} noIcon instructions="Url" styleCont={{marginLeft:5}} value={item.url} onChange={(e) => this.handleChange('customTypeUrl', customType, item, e.target.value) } /> :null}
                                                    <Select3 v={v} styleCont={{ marginLeft:5, width:200}} noIcon twenty placeholder={'default'} array={[ {value:'location', name:'location'}, {value:'phone', name:'phone'}, {value:'checkbox', name:'checkbox'} ]} value={item.fieldType||""} optionValue='value' optionName='name' onChange={(value)=>this.handleChange('fieldType', customType, item, value)} />
                                                </div>
                                        )})}

                                        <div style={Object.assign({}, Style.flexRowOnly, { justifyContent:'space-between', marginTop:20 })}>
                                            <Button style={{}} onClick={() => this.handleChange('addCustomTypeName', customType)}>{getIcon2({icon:'addCircle', fontSize:'2rem'})}</Button>
                                            <Button style={{}} onClick={() => this.handleChange('deleteCustomTypeName', customType)}>{getIcon2({icon:'trash', fontSize:'2rem'})}</Button>
                                        </div>
                                    </div>
                                </Card>                                           
                        )})}

                        {/* Name Username Password */}

                        {/* <div style={{marginTop:13, flexDirection:'row', alignItems:'center', justifyContent:'flex-start' }}>
                            <div style={{backgroundColor:'transparent',width: 40}}></div>
                            <div style={{width:40}}><IconSLI name='user-following' size={15} color='black' /></div>
                            <div style={{ color: 'black', fontSize:14, flex:1}} >Name Username Password</div>
                        </div>   

                        <div  style={{marginLeft:80, marginTop:7 }}>
                            { this.state.toggleEdit==='instructions'?
                                <input underlineColorAndroid='transparent' style={{flex:1, color: 'black', fontSize:12, backgroundColor: 'lightgrey', borderRadius: 2, padding:5}} placeholder='Language' autoCapitalize = 'none' selectTextOnFocus={true} multiline={false} autoCorrect={false} dataDetectorTypes='all' value={this.state.verify.nameUserPass["instructions_"+language] || this.state.verify.nameUserPass.instructions} onChange={(e) => this.handleChange('userPassInstructions', e.target.value) } />
                                : 
                                <div onClick={() => this.setState({toggleEdit:'instructions'}) } style={{ color: 'black', fontSize:12, flex:1}} >{this.state.verify.nameUserPass["instructions_"+language] || this.state.verify.nameUserPass.instructions}</div>
                            }   
                            <div style={{fontFamily: 'Poppins-Medium', color: 'black', fontSize:12, flex:1, marginTop:20}} >LOCATION</div>
                            <div style={{flex:1, flexDirection:'row', marginTop:5}}>
                                <Button onClick={() =>  this.handleChange('location', 'welcome') } style={{marginTop:5, flex:1, flexDirection:'row', alignItems:'center', justifyContent:'flex-start' }}>
                                    <div style={{width:30}}><RadioButton selected={this.state.verify.nameUserPass.location==='welcome'} /></div>
                                    <div style={{ color: 'black', fontSize:12, flex:1 }} >Start</div>
                                </Button>
                                <Button onClick={() =>  this.handleChange('location', 'pay') } style={{flex:1, marginTop:5, flexDirection:'row', alignItems:'center', justifyContent:'flex-start' }}>
                                    <div style={{width:30}}><RadioButton selected={this.state.verify.nameUserPass.location==='pay'} /></div>
                                    <div style={{ color: 'black', fontSize:12, flex:1 }} >End</div>
                                </Button>
                            </div>

                            <div style={{fontFamily: 'Poppins-Medium', color: 'black', fontSize:12, flex:1, marginTop:25, marginBottom:5}} >USER PASS LIST</div>
                            <FlatList
                                style={{}}    
                                data={ Object.entries(this.state.verify.nameUserPass.list).map(item => ({  ...item[1], key:item[0] })) }
                                keyExtractor={(item, index) => index.toString()}
                                renderItem= {({item})=> {
                                    return (
                                        <div>
                                            { this.state.toggleEdit===item.key?
                                                <Button onClick={() =>  this.handleChange('location', 'pay')} style={{flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:5}}>
                                                    <input underlineColorAndroid='transparent' style={{marginRight:5, flex:1, color: 'black', fontSize:12, backgroundColor: 'lightgrey', borderRadius: 2, padding:5}} placeholder='Language' autoCapitalize = 'none' selectTextOnFocus={true} multiline={false} autoCorrect={false} dataDetectorTypes='all' value={item.username} onChange={(e) => this.handleChange('userPassListUsername', e.target.value, item.key) } />
                                                    <input underlineColorAndroid='transparent' style={{flex:1, color: 'black', fontSize:12, backgroundColor: 'lightgrey', borderRadius: 2, padding:5}} placeholder='Language' autoCapitalize = 'none' selectTextOnFocus={true} multiline={false} autoCorrect={false} dataDetectorTypes='all' value={item.password} onChange={(e) => this.handleChange('userPassListPassword', e.target.value, item.key) } />
                                                    <Button style={{width:40, marginLeft:5}} onClick={() => this.handleChange('deleteUserPass', item)}><div style={{ fontSize: 16, fontFamily:'Poppins-Regular', color:'black'}}>x</div></Button>
                                                </Button> 
                                                : 
                                                <Button onClick={() =>  this.setState({toggleEdit:item.key})} style={{flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:5}}>
                                                    <div style={{ color: 'black', fontSize:12, flex:1}} >{item.username}</div>
                                                    <div style={{ color: 'black', fontSize:12, flex:1, marginLeft:5}} >{item.password}</div>
                                                    <Button style={{width:40, marginLeft:5}} onClick={() => this.handleChange('deleteUserPass', item)}><div style={{ fontSize: 16, fontFamily:'Poppins-Regular', color:'black'}}>x</div></Button>
                                                </Button>                                                     
                                            }   
                                        </div>
                                )}}
                            />
                            <Button onClick={() => this.handleChange('addUserPass')}><div style={[Style.Musername, { fontSize: 12, fontFamily:'Poppins-Medium', marginTop:10 }}>Add</div></Button>
                        </div> */}

                    </div>

                    {/* Add Verify Option */}
                    <Button style={Object.assign({}, Style.flexRow,{ marginTop:20 })} onClick={() => verify?this.handleChange('addCustomType'):this.handleChange('addVerify')}>{getIcon2({icon:'addCircle', fontSize:'4rem'})}</Button>

                    { SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, save:()=>this.save, style:{backgroundColor:'purple'} }) }
				</div>
			)
		}

	}

    handleChange = (type, var0, var1, var2) => {
        
        let v               = this.props.v
        let orderlinaStore  = { ...this.props.orderlinaStore }
        let clientKey       = orderlinaStore.lastManaged

        let language 		= v.language
        let defaultLanguage = v.languageDefault

        let verify          = {...this.state.verify}

        if (type==='addVerify') {
            verify = 
                {
                    "customTypes" : {
                    "-Lkx3HtErjqkPvi6EKfY" : {
                        "fields" : {
                        "-Lkx3HtErjqkPvi6EKfZ" : {
                            "name" : "name",
                            "name_chinese" : "您的姓名"
                        }
                        },
                        "instructions" : "The amount will be added to your bill. Please submit your order below.",
                        "instructions_chinese" : "这笔钱将加到你的账单上。请在下面提交您的订单。"
                    }
                    },
                    "nameUserPass" : {
                    "instructions" : "Please enter the WIFI details provided in your room",
                    "instructions_chinese" : "请输入您房间内提供的WIFI详细信息",
                    "list" : {
                        "85-LTHl6RHSTQemGWcg0ya" : {
                        "password" : "pass1",
                        "username" : "user1"
                        }
                    },
                    "location" : "welcome"
                    },
                    "noneInstructions" : "Please submit your order below",
                    "noneInstructions_chinese" : "请在下面提交您的订单"
                }
            
            this.setState({ toggleEdit:true, verify })
        }

        if (type==='none') {

            verify.choice = 'none'
            this.setState({ toggleEdit:true, verify })
        }

        if (type==='noneInstructions') {

            if (language===undefined || language===null || language===defaultLanguage) {
                verify.noneInstructions = var0
                this.setState({ toggleEdit:'noneInstructions', verify })
            }

            else {
                verify["noneInstructions_"+language] = var0
                this.setState({ toggleEdit:'noneInstructions', verify })
            }
        }


        if (type==='nameUserPass') {

            verify.choice = 'nameUserPass'
            this.setState({ toggleEdit:true, verify })
        }

        if (type==='customType') {

            verify.choice = var0.key
            this.setState({ toggleEdit:true, verify })
        }
        
        if (type==='customTypeName') {

            if (language===undefined || language===null || language===defaultLanguage) {

                if (var2===" ") return null
                verify.customTypes[var0.key].fields[var1.key].name = removeSpecialChars(var2)
                this.setState({ toggleEdit:var1.key, verify })
            }

            else {
                verify.customTypes[var0.key].fields[var1.key]["name_"+language] = var2
                this.setState({ toggleEdit:var1.key, verify })
            }
        }

        else if (type==='customTypeUrl') {
            verify.customTypes[var0.key].fields[var1.key].url = var2
            this.setState({ toggleEdit:var1.key, verify })
        }
        else if (type=='fieldType') {
            verify.customTypes[var0.key].fields[var1.key].fieldType = var2
            this.setState({ toggleEdit:true, verify })
        }

        if (type==='customTypeInstructions') {

            if (language===undefined || language===null || language===defaultLanguage) {
                verify.customTypes[var0.key].instructions = var1
                this.setState({ toggleEdit:var0.key+"instructions", verify })
            }

            else {
                verify.customTypes[var0.key]["instructions_"+language] = var1
                this.setState({ toggleEdit:var0.key+"instructions", verify })
            }
        }


        if (type==='addCustomTypeName') {

            verify.customTypes[var0.key].fields[PushId()] = { name : "New Field" }
            this.setState({ toggleEdit:true, verify })
        }

        if (type==='deleteCustomTypeName') {

            delete verify.customTypes[var0.key]
            this.setState({ toggleEdit:true, verify })
        }

        if (type==='addCustomType') {

            if (!verify.customTypes) {
                verify.customTypes = {
                    [PushId()] : {
                        "fields" : {
                            [PushId()] : {
                                "name" : "Name",
                                "name_chinese" : "您的姓名"
                            }
                        },
                        "instructions" : "Please enter the details below and submit your order"
                    }
                }
            }
            else {
                verify.customTypes[PushId()] = {
                    "fields" : {
                        [PushId()] : {
                            "name" : "Name",
                            "name_chinese" : "您的姓名"
                        }
                    },
                    "instructions" : "Please enter the details below and submit your order"
                }
            }
            this.setState({ toggleEdit:true, verify })
        }

        if (type==='deleteUserPass') {

            delete verify.nameUserPass.list[var0.key]
            this.setState({ toggleEdit:true, verify })
        }

        

        
        
        ///////

        if (type==='userPassInstructions') {

            if (language===undefined || language===null || language===defaultLanguage) {
                verify.nameUserPass.instructions = var0
                this.setState({ toggleEdit:'instructions', verify })
            }

            else {
                verify.nameUserPass["instructions_"+language] = var0
                this.setState({ toggleEdit:'instructions', verify })
            }
        }

        if (type==='location') {
            verify.nameUserPass.location = var0
            this.setState({ toggleEdit:true, verify })
        }

        if (type==='userPassListUsername') {
            verify.nameUserPass.list[var1].username = var0
            this.setState({ toggleEdit:var1, verify })
        }

        if (type==='userPassListPassword') {
            verify.nameUserPass.list[var1].password = var0
            this.setState({ toggleEdit:var1, verify })
        }

        if (type==='addUserPass') {
            verify.nameUserPass.list[PushId()] =  {
                username : "Username",
                password : "Password"
            }
            this.setState({ toggleEdit:true, verify })
        }

    }

    save = () => {

        this.setState({ lb:true });
        let clientKey       = this.props.orderlinaStore.lastManaged

        Firebase.database().ref("clients/" + clientKey + "/info")
            .update({ verify:this.state.verify })
            .then(() => {  this.setState({ lb:false, toggleEdit:false }) })
            .catch((error) => { this.setState({ lb:false, toggleEdit:false, error}) })
    }



}
export default Verify

