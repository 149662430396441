import React, { Component } from 'react';

import Firebase from '../Firebase';

import Style from '../styles';
import { Card, Button, CircularProgress, TextField } from '@material-ui/core/';
import { KeyboardArrowDown } from '@material-ui/icons/';
import { IoIosCloseCircle } from 'react-icons/io';
import { FaCaretDown, FaCaretUp, FaExpandArrowsAlt } from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';



import { verify, getVariables, trim, readyPage, social, iconPage, renderNotes, basketItems, Loading, CardFlat, renderGuestTypeButtons, getLanguage, getGuestType, getUserEmail, Input2, verify2 } from '../Mini-components/Functions'
import { navBar } from '../Mini-components/NavBar'
import NameUserPass from './NameUserPass'





class Verify extends Component {

	constructor() {
		super();
		this.state = {
		};
	}

    componentDidMount() {
		let v = this.props.v
		readyPage({ props:this.props, v })

		// Need a better solution to this than verifying twice.
		// This used to be 2000 timeout but caused store to be overwritten by old data after 2000, seems ok for 100s
		verify({ v, props:this.props })
		setTimeout(()=>{ verify({ v, props:this.props }) }, 200)
	}
	
    capitalize(str){  return str.charAt(0).toUpperCase() + str.slice(1) }


	render() {
		let v 				= 	this.props.v
		let orderlinaStore 	= 	{...this.props.orderlinaStore}
		let guestType		= 	v.guestType

		if ( !v || this.state.loading || orderlinaStore.loading ) return <Loading />

        else if ( guestType ){
			let clientKey 		= 	v.clientKey
			let language 		= 	v.language
			let verifyChoice 	= 	guestType?.verify
			let customType 		= 	v.info?.verify?.customTypes?.[verifyChoice]

		
			if 		( verifyChoice === 'nameUserPass' ) return <NameUserPass {...this.props} /> 

			// if CUSTOM VERIFY OPTION
			else if ( customType ) {


				return (
					<div style={Object.assign({}, Style.flexColOnly, { })}>
						{/* Instructions */}
						{customType.instructions ? <div style={Object.assign({}, Style.textXSB, { marginBottom:10 })}>{customType["instructions_"+language] || customType.instructions }</div> :null}
	
						{/* Fields */}
						{ Object.entries(customType.fields).map((item,index) => { item = { ...item[1], key: item[0], index:index  } 
							let value = this.state[item.key] || v.userConfigVenue?.credentials?.[item.name] || "" 
							return (
								<Input2 v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} key={item.key} autoComplete="none"
									placeholder={item["name_"+language] || item.name} 
									value={ value } 
									onChange={(e) => { this.setState({[item.key]:e.target.value}); this.saveToRedux({ v, type:"customField", customField:item.name, text:e.target.value, customFieldObject:item }) } }
									styleCont={{marginTop:10, backgroundColor:value?'transparent':'#d5000021' }}
									checkbox={item.fieldType=='checkbox'}
									checkboxLink={item.url}
								/>
							)
						})}
					</div>
				)
			}
			else return null
		}

		else {
			console.log('verify history', this.props)
			return (
				<div>{ renderGuestTypeButtons({ v, props:this.props, padding:0 }) }</div>
			)
		}
	}


    saveToRedux = ({v, type, customField, text, customFieldObject}) => {

        let orderlinaStore 	= 	{...this.props.orderlinaStore}
		let clientKey 		= 	v.clientKey;
		let userConfigVenue = 	v.userConfigVenue
		let credentials 	= 	userConfigVenue?.credentials || {}
		

        if ( type === "customField" ) {

            credentials[customField] = text
			if      (customFieldObject.fieldType=='location') 	credentials.location = text // If fieldType is location, then set the location key in credentials as well
			else if (customFieldObject.fieldType=='phone') 	 	credentials.phone    = text // If fieldType is phone,    then set the phone    key in credentials as well
            
			v.updateUserConfigVenue({ credentials, loginError:false });
            verify({ v, props:this.props })
        }
    }
    


}
export default Verify

