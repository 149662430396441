import React, { useEffect, useRef, useState } from "react";
import Style from "../styles";
import { getFormattedShortTimeDate } from "../Mini-components/Functions";

const ChatListTG = ({ v, chatList, openChat }) => {
  return (
    <section className="bg-gray-2" style={{ padding:'1rem' }}>
      <div className="mx-auto md:container" style={{ ...Style.boxShadow, borderRadius:20, overflow:'hidden' }}>
        <div className="shadow-one mx-auto max-w-[400px] rounded-lg bg-white py-8 px-6">
          <div className="mb-8 flex items-center justify-between px-[30px]">
            <h3 className="text-xl font-semibold text-black">Messages</h3>
            <Dropdown />
          </div>

          <form className="relative mb-7">
            <input
              type="text"
              className="w-full rounded-full border border-stroke py-[10px] pl-5 pr-10 text-sm text-body-color outline-none focus:border-primary"
              placeholder="Search..."
            />
            <button className="absolute right-4 top-1/2 -translate-y-1/2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z"
                  fill="#637381"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.957 11.958C12.2499 11.6651 12.7247 11.6651 13.0176 11.958L16.2801 15.2205C16.573 15.5133 16.573 15.9882 16.2801 16.2811C15.9872 16.574 15.5124 16.574 15.2195 16.2811L11.957 13.0186C11.6641 12.7257 11.6641 12.2508 11.957 11.958Z"
                  fill="#637381"
                />
              </svg>
            </button>
          </form>

          <div className="space-y-[10px]">
            {chatList && Object.entries(chatList).map(chat => { let chats = { ...chat[1] }

                let lastChat    = chats[Object.keys(chats)[Object.keys(chats).length-1]] || {}
                let unreadChat  = Object.entries(chats).map(chat=> chat = { ...chat[1] }).find(chat => chat.from=='customer' && chat.status==1 )

                return (
                    <ChatItem
                        active
                        img="https://cdn.tailgrids.com/2.0/image/dashboard/images/chat-list/image-01.png"
                        name={"Cust"+lastChat.customerId.slice(-5)||"NA"}
                        text={lastChat.message.slice(0,40) + "..." }
                        time={getFormattedShortTimeDate(lastChat.time)}
                        number="5"
                        unread={unreadChat}
                        openChat={openChat}
                        chat={chat}
                    />
                )
        })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatListTG;

const Dropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative">
      <button
        className="text-[#98A6AD]"
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          className="fill-current"
        >
          <g clipPath="url(#clip0_3019_3039)">
            <path d="M15.75 6.75C14.5074 6.75 13.5 7.75736 13.5 9C13.5 10.2426 14.5074 11.25 15.75 11.25C16.9926 11.25 18 10.2426 18 9C18 7.75736 16.9926 6.75 15.75 6.75Z" />
            <path d="M9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75Z" />
            <path d="M2.25 6.75C1.00736 6.75 3.05336e-07 7.75736 1.96701e-07 9C8.80661e-08 10.2426 1.00736 11.25 2.25 11.25C3.49264 11.25 4.5 10.2426 4.5 9C4.5 7.75736 3.49264 6.75 2.25 6.75Z" />
          </g>
          <defs>
            <clipPath id="clip0_3019_3039">
              <rect
                width="18"
                height="18"
                fill="white"
                transform="translate(18 18) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 top-full z-40 w-[200px] space-y-1 rounded bg-white p-2 shadow-card ${
          dropdownOpen === true ? "block" : "hidden"
        }`}
      >
        <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
          All Conversations
        </button>
        <button className="w-full rounded py-2 px-3 text-left text-sm text-body-color hover:bg-gray-2">
          Mark as Read
        </button>
      </div>
    </div>
  );
};

const ChatItem = ({ img, name, text, time, active, number, unread, openChat, chat }) => {

  // OPEN NEW CUSTOMER CHATS
  let chats = Object.entries( Object.entries(chat)[1][1] ).map(item => item={ key:item[0], ...item[1] })

  return (
    <div onClick={()=>openChat?.(chats) } className="flex cursor-pointer items-center rounded py-[10px] px-3 hover:bg-gray-2" style={{ fontWeight:unread?'bold':'unset' }}>
      <div className="relative mr-[14px] h-11 w-full max-w-[44px] rounded-full">
        <img
          src={img}
          alt="profile"
          className="h-full w-full object-cover object-center"
        />
        <span
          className={`absolute bottom-0 right-0 block h-3 w-3 rounded-full border-2 border-gray-2 ${
            active ? "bg-success" : "bg-danger"
          }`} style ={{ backgroundColor: unread?'red':'' }}
        ></span>
      </div>
      <div className="w-full">
        <div className="mb-1 flex justify-between">
          <h5 className="text-sm font-medium text-black" style={{fontWeight:unread?'bold':'unset'}}>{name}</h5>
          <span className="text-xs text-body-color"> {time} </span>
        </div>
        <div className="flex justify-between">
          <p className="text-sm text-body-color">{text}</p>
          {number && (
            <span className="flex h-4 w-full max-w-[16px] items-center justify-center rounded-full bg-primary text-[10px] font-medium leading-none text-white">
              {number}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
