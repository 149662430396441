import React, { Component } from 'react';

import Firebase from '../Firebase';
import { Card, Button, Switch } from '@material-ui/core';
import Style from '../styles';


import { PlayArrow, Timelapse } from '@material-ui/icons';
import Notifications from '@material-ui/icons/Notifications';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import Check from '@material-ui/icons/Check';
import Archive from '@material-ui/icons/Archive';
import Chat from '@material-ui/icons/Chat';

import { handleLink3, Switch2, Text2, getAppText2, RenderHelper7, getVariables, Loading, social, SaveCancelButtons, footer, handleError, Card2, notifications, seperator2, Switch3 } from '../Mini-components/Functions'
// import { askForPermissioToReceiveNotifications } from '../push-notification';



class MAlerts extends Component {

	constructor(props) {
		super(props);
		this.state = {
        }
	}

    componentDidMount() {
        this.mapStoreToState()
	}

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.v.info.alerts) != JSON.stringify(this.props.v.info.alerts)) {
            this.mapStoreToState() 
        }
	}
    
    mapStoreToState() {

        this.setState({ alerts:this.props.v.info?.alerts })
    }

	render() {

        let v               = this.props.v
    
		if (!v) return <Loading />

		else {

            let alerts              = this.state.alerts || {}
            let browserConfig       = v.browserConfig || {}
            // let queues      = v.info.queues && Object.entries(v.info.queues).map(item => ({ ...item[1], key:item[0] }))
            // let filterQueueLength   = v.browserConfig.filterQueue?.length

			return (
                <div style={Style.div1}>
                    { notifications({props:this.props, v}) }

                    <Card2 v={v} icon="notify" h1='order alerts'>

                        {/* { queues? 
                            <select style={Object.assign({}, Style.selectDropdown, { marginTop:0 })} value={v.browserConfig?.queue ||"Select"} onChange={(e) => { console.log('xx', e.target.value ); if (!filterQueue.includes(e.target.value)) filterQueue.push(e.target.value); this.props.updateSimple({ browserConfig:{ ...v.browserConfig, filterQueue } } )} }>
                                { [ {name:getAppText2({ v, text:'Select' })}, ...queues].map(item => {
                                    return <option style={Object.assign({}, { textTransform:'uppercase' })} key={item.key} value={item.key}>{item.name}</option>
                                })}
                            </select>   
                        :null}      */}
                        {/* { ? <Text2 v={v} style={{color:'red'}}></Text2> :null } */}

                        {/* { RenderHelper7({ props:{ shouldRender:filterQueueLength, v, icon:'alert', message:'NB. Sound and browser alerts currently limited by Queue filters. Queue filters can be set in ORDERS' }}) }                 */}

                        <Card2 v={v} title='this device' styleChildren={{ padding:0 }} >
                            <Switch3 v={v} icon='notify'    text={'sound alerts'}         checked={ browserConfig.alertsOrdersSoundM!=false }    onChange={() => this.handleChange('toggleAlertsOrdersSoundM') } />
                            <Switch3 v={v} icon='notify'    text={'header badge'}         checked={ browserConfig.alertsOrdersBadgeM!=false }    onChange={() => { this.props.updateClient({ browserConfig:{ ...browserConfig, alertsOrdersBadgeM:browserConfig.alertsOrdersBadgeM?false:true }});       v.updateToast({ icon:'tickCircleSolid', style:v.div4, text:'Saved' })  }}     style={{marginTop:20}} />

                            {/* {Firebase.messaging.isSupported()?
                            <Switch2 v={v} text={'browser alerts'}       checked={ this.isManagerAlertActive() } onChange={() => this.handleChange('toggleBrowserAlerts')}       style={{marginTop:20}} />
                            :null} */}
                        </Card2>

                        <Card2 v={v} title='Print' styleChildren={{ padding:0 }} >
                            <Switch3 v={v} icon='notify'    text={'Auto Print'}           checked={ browserConfig.autoPrint||false }             onChange={() => { this.props.updateClient({ browserConfig:{ ...browserConfig, autoPrint:!browserConfig.autoPrint }});                                   v.updateToast({ icon:'tickCircleSolid', style:v.div4, text:'Saved' })  }}     style={{marginTop:20}} />
                            <Card2   v={v} icon='notify'    title='Windows' hide={!this.state.viewAutoPrintInstrucWindows}     onClick={()=>this.setState({ viewAutoPrintInstrucWindows:!this.state.viewAutoPrintInstrucWindows })}   dropDown    noIcon  style={{margin:'0 1.5rem 1.5rem', marginTop:0}}    >
                                <Text2 style={{fontWeight:'bold'}} >CHROME BROWSER</Text2>
                                <Text2 twenty>1. Enable 'AutoPrint' above</Text2>
                                <Text2 twenty>2. In Chrome go to {v.domain}/manager</Text2>
                                <Text2 twenty>3. Add a shortcut to the desktop: At the top right, click More More Tools. Create Shortcut.</Text2>
                                <Text2 twenty>4. Find the shortcut on your desktop, right click and select properties.</Text2>
                                <Text2 twenty>5. Append the following text to the end of the target field after a space: --enable-print-preview --kiosk --kiosk-printing</Text2>
                                <Text2 twenty>6. Save these changes and launch Google Chrome through the shortcut. It should now load in full screen (kiosk) mode</Text2>
                                <Text2 twenty>When an order comes through it will now autoprint to your default printer. To change your default printer, go to Control Panel, Printers</Text2>
                            </Card2>
                        </Card2>

                        <Card2 v={v} title='general' helperStyle={v.div}>

                            <Switch2 v={v} text={'Email Alerts'}         checked={ alerts.managerAlertEmail }                   onChange={() => this.handleChange('toggleManagerAlertEmail')}   style={{ }} />
                            <Switch2 v={v} text={'SMS Alerts'}           checked={ alerts.managerAlertSMS }                     onChange={() => this.handleChange('toggleManagerAlertSMS')}     style={{marginTop:20}} />
                            <Switch2 v={v} text={'Phone Call Alerts'}    checked={ alerts.managerAlertCall }                    onChange={() => this.handleChange('toggleManagerAlertCall')}    style={{marginTop:20}} />
                        </Card2>

                        { SaveCancelButtons( this.state.toggleEdit, this.mapStoreToState, this.save ) }
                    </Card2>
                

                    {/* chat */}
                    <Card2 v={v} icon="notify" h1='chat alerts'>

                        <Card2 v={v} title='this device' helperStyle={v.div}>
                            <Switch2 v={v} text={'sound alerts'}         checked={ browserConfig.alertsChatSoundM!=false }    onChange={() => { this.props.updateClient({ browserConfig:{ ...browserConfig, alertsChatSoundM:!browserConfig.alertsChatSoundM }});   !browserConfig.alertsChatSoundM&&handleLink3({v, link:'reload'})  }}   style={{ }} />
                            <Switch2 v={v} text={'header badge'}         checked={ browserConfig.alertsChatBadgeM!=false }    onChange={() =>   this.props.updateClient({ browserConfig:{ ...browserConfig, alertsChatBadgeM:!browserConfig.alertsChatBadgeM }})}   style={{marginTop:20}} />
                        </Card2>

                    </Card2>
                </div>
			)
		}
	}

    handleChange = (type, var0, var1, var2) => {
        
        let v               = this.props.v
        let orderlinaStore  = {...this.props.orderlinaStore}
        let clientKey       = orderlinaStore.lastManaged
        let alerts          = {...this.state.alerts}

        console.log('type', type)
        console.log('state', this.state)

        if (type==='toggleManagerAlertSound') {

            alerts.managerAlertSound ? alerts.managerAlertSound=false : alerts.managerAlertSound=true
            this.setState({ alerts, toggleEdit:true })
        }

        else if (type==='toggleManagerAlertEmail') {

            alerts.managerAlertEmail ? alerts.managerAlertEmail=false : alerts.managerAlertEmail=true
            this.setState({ alerts, toggleEdit:true })
        }

        else if (type==='toggleManagerAlertSMS') {

            alerts.managerAlertSMS ? alerts.managerAlertSMS=false : alerts.managerAlertSMS=true
            this.setState({ alerts, toggleEdit:true })
        }

        else if (type==='toggleManagerAlertCall') {

            alerts.managerAlertCall = alerts.managerAlertCall? false :true
            this.setState({ alerts, toggleEdit:true })
        }

        else if (type==='toggleBrowserAlerts') {

            if ( this.isManagerAlertActive() )  this.deActivateBrowserAlerts()
            else                                this.activateBrowserAlerts()
        }   

        else if (type=='toggleAlertsOrdersSoundM') {

            let browserConfig       = v.browserConfig || {}
            
            if (browserConfig.alertsOrdersSoundM!=false){
                this.props.updateClient({ browserConfig:{ ...browserConfig, alertsOrdersSoundM:false }})
            }
            else if (window.confirm('To enable sound alerts, this page will be reload')) { 
                this.props.updateClient({ browserConfig:{ ...browserConfig, alertsOrdersSoundM:true }});
                handleLink3({v, link:'reload'}) 
            }
            else return null
        }
    }

    isManagerAlertActive = () => {

        let orderlinaStore      =   {...this.props.orderlinaStore}
        let alertToken          =   orderlinaStore.alertToken
        let managerAlertDevices =   this.state.alerts?.managerAlertDevices || []          
    
        if ( managerAlertDevices.includes(alertToken) ) return true
        else return false
    }

    activateBrowserAlerts = async () => {

        let v                       =   this.props.v
        let orderlinaStore          =   {...this.props.orderlinaStore}
        let alerts                  =   {...this.state.alerts}
        alerts.managerAlertDevices  =   alerts.managerAlertDevices || []
    
        try {
                const messaging = Firebase.messaging();
                await messaging.requestPermission();
                const token = await messaging.getToken();
    
                if (token) {
                    orderlinaStore.alertToken = token
                    this.props.updateSimple({ alertToken:token })
                    
                    alerts.managerAlertDevices.push(token)
                    this.setState({ alerts, toggleEdit:true })
                }
    
            } catch (error) {
                handleError({ v,  props:this.props, error, message:"Browser Alerts Not Supported. Compatible with PC, Android, and MAC (chrome). Please try another Alert Type"})
            }
    }


    deActivateBrowserAlerts = async () => {

        let managerAlertDevices     =   this.state.alerts.managerAlertDevices
        let token                   =   this.props.orderlinaStore.alertToken
        let mad2                    =   managerAlertDevices.filter(e => e !== token);
        
        let alerts                  =   {...this.state.alerts}
        alerts.managerAlertDevices  =   mad2
        this.setState({ alerts, toggleEdit:true })
    }


    save = async () => {

        this.setState({ loading:true });

        Firebase.database().ref("clients/" + this.props.orderlinaStore.lastManaged + "/info" )
            .update({ alerts:this.state.alerts })
            .then(() => { this.setState({ loading:false, toggleEdit:false }) })
            .catch((error) => { console.log(error); this.setState({ loading:false, toggleEdit:false, error }) })
    }

	
}


export default MAlerts

