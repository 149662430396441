import React, { Component } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Basket from '../client/Basket'
import Total from '../client'
import Verify from '../client/Verify'
import Page from '../client';
import PushId from 'pushid';

import { Card, Button } from '@material-ui/core/';
import Style from '../styles';
import { Input2, numToDAY, Button3, getVariables, setGuestType3, customerFeedback, getUserEmail, sendEmail2, placeOrder, formatDateTime, recordCheckIn, setGuestType, updateApp, getIcon2, renderDateTime, renderHelper5, getAppText2, languageButtons, basketItems, getAppText, iconNameArray, hourPicker, minutePicker, convertToNumber, dayPicker, buttonAction3, numToMON, RenderImage2, handleLink3, navButtonMini, Loading } from './Functions'



class Dialog extends Component {

	constructor() {
		super();
		this.state = {
            lb:false
		};
	}

    componentDidMount() {
    }
    
    componentWillUnmount = () => {
    }


    sort = ({ v, dialog }) => {

        let sortIndex           = this.state.sortIndex || {}
        let sortObjectArray     = dialog.sort.map((item,index)   => ({...item, index:isNaN(sortIndex?.[item.key])?index:sortIndex?.[item.key] }))
        sortObjectArray.forEach(item=> sortIndex[item.key]=item.index ) 

        console.log('sortIndex 0: ', sortIndex)
        console.log('objArray: ', sortObjectArray)
        return(
            <div style={Style.flexColOnly}>
                { sortObjectArray
                    
                    .sort(function(a, b) { return a.index - b.index })
                    .map((item) => {
                    return (
                        <div style={Object.assign({}, Style.flexRow, { padding:'0.5rem' })}>
                            <div style={{flex:1}}>{item?.[dialog.itemName||'menuTitle']}</div>
                            {/* <div>{item.index}</div> */}
                            <div style={Object.assign({}, Style.flexRowOnly, { })}>
                                {getIcon2({ icon:'arrowUpLong'  , onClick:()=>{
                                    let itemCur                 = sortObjectArray?.[item.index]
                                    let itemAbove               = sortObjectArray?.[item.index-1] 

                                    if (!itemCur || !itemAbove) return

                                    sortIndex[itemCur.key]      = itemCur.index-1
                                    sortIndex[itemAbove.key]    = itemAbove.index+1

                                    this.setState({ sortIndex })
                                }})}
                                {getIcon2({ icon:'arrowDownLong', marginLeft:'2rem', onClick:()=>{  

                                    let itemCur                 = sortObjectArray?.[item.index]
                                    let itemBelow               = sortObjectArray?.[item.index+1] 

                                    if (!itemCur || !itemBelow) return

                                    sortIndex[itemCur.key]      = itemCur.index+1
                                    sortIndex[itemBelow.key]    = itemBelow.index-1

                                    this.setState({ sortIndex })
                                } })}
                            </div>
                        </div>
                    )
                })}

                <Button3 twenty v={v} text='Save' onClick={()=>{dialog.onChange&& dialog.onChange(this.state.sortIndex); v.updateDialog("") }} />
            </div>
        )
    }

    renderSetAvailable = ({ v, dialog }) => {

        let available           = dialog.available || {}
        let newPeriod           = { open:'00:00', close:'23:59' }
        let hours               = available.hours || ""
        let discountRelated     = dialog.text?.includes('discount')

        console.log('xx', dialog)
        return(
            <div style={Object.assign({}, Style.flexColOnly, {marginTop:20})}>
                <div style={Style.flexRow}>
                    <Button3 style={Object.assign({}, available.status==undefined||available.status==true      ?v.div4:v.div,   { flex:1                })} v={v} text={'always'} icon={'open'}   onClick={() =>  { v.updateDialog({ ...dialog, available:{ ...available, status:true } })}}    />
                    <Button3 style={Object.assign({}, available.status==false                                  ?v.div4:v.div,   { flex:1, marginLeft:10 })} v={v} text={'never'}  icon={'closed'} onClick={() =>  { v.updateDialog({ ...dialog, available:{ ...available, status:false } })}}    />
                    <Button3 style={Object.assign({}, available.status=='hours'                                ?v.div4:v.div,   { flex:1, marginLeft:10 })} v={v} text={'hours'}  icon={'clock'}  onClick={() =>  { v.updateDialog({ ...dialog, available:{ ...available, status:'hours', hours }})}} />
                </div>

                {(available.status==undefined||available.status==true) && discountRelated? 
                    <Input2 noIcon v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} instructions={'discount'} styleCont={{flex:0, marginTop:20}} placeholder="%" value={available.always||""}   onChange={(e) => v.updateDialog({ ...dialog, available:{ ...available, status:true, always:convertToNumber(e.target.value) } }) } />
                :null}

                {available?.status=='hours'?
                    <div style={Object.assign({}, Style.flexColOnly, {marginTop:10, marginBottom:10})}>

                        { hours && Object.entries(hours).map((item) => { 

                            let day         = item[0]
                            let periods     = item[1]

                            return (
                                <div style={Object.assign({}, Style.flexRowOnly, {marginTop:10})}>
                                    
                                    <div style={{ display:'flex', flex:1, alignItems:'center', paddingLeft:5 }}>{day}</div>

                                    <div style={Object.assign({}, Style.flexColOnly, { minHeight:50})}>

                                        {periods && Object.entries(periods).map((item) => { 

                                            let period  = item[0]
                                            let time    = item[1]

                                            let p1o                 = time?.open
                                            let p1c                 = time?.close
                                            let p1oh, p1om, p1ch, p1cm
                                            if (p1o)  [p1oh, p1om]  = p1o.split(':')
                                            if (p1c)  [p1ch, p1cm]  = p1c.split(':')

                                            let pickerStyle = { paddingLeft:0, paddingRight:0, flex:0, marginLeft:5, marginRight:5 }
                                            
                                            return (
                                                <div key={period} style={Object.assign({}, Style.flexRowOnlyAic, Style.textS, { textTransform:'uppercase' })}>

                                                    {/* Open */}
                                                    {getIcon2({icon:'open', fontSize:'1.5rem', marginRight:5 })}
                                                    {hourPicker({   label:false, hour:p1oh, style:pickerStyle, onClick:(e)=>{ hours[day][period]={open:`${e}:${p1om}`, close:`${p1ch}:${p1cm}`};     v.updateDialog({ ...dialog, available:{ ...available, hours }}) }})}
                                                    {minutePicker({ label:false, min:p1om,  style:pickerStyle, onClick:(e)=>{ hours[day][period]={open:`${p1oh}:${e}`, close:`${p1ch}:${p1cm}`};     v.updateDialog({ ...dialog, available:{ ...available, hours }}) }})}

                                                    {/* Close */}
                                                    {getIcon2({icon:'closed', fontSize:'1.5rem', marginRight:5, marginLeft:20 })}
                                                    {hourPicker({   label:false, hour:p1ch, style:pickerStyle, onClick:(e)=>{ hours[day][period]={open:`${p1oh}:${p1om}`, close:`${e}:${p1cm}`};     v.updateDialog({ ...dialog, available:{ ...available, hours }}) }})}
                                                    {minutePicker({ label:false, min:p1cm,  style:pickerStyle, onClick:(e)=>{ hours[day][period]={open:`${p1oh}:${p1om}`, close:`${p1ch}:${e}`};     v.updateDialog({ ...dialog, available:{ ...available, hours }}) }})}
                                                
                                                    {/* If Discount Related */}
                                                    {discountRelated? <Input2 id={period} noIcon v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} instructions={'discount'} styleCont={{flex:0}} placeholder="%" value={hours[day][period].discount||""}   onChange={(e) => { hours[day][period].discount = convertToNumber(e.target.value); v.updateDialog({ ...dialog, available:{ ...available, hours }}) } } />:null}

                                                    {/* Add/Remove time period */}
                                                    {getIcon2({icon:'close',    fontSize:'1.5rem', marginLeft:20,   onClick:()=> { delete hours[day][period];  v.updateDialog({ ...dialog, available:{ ...available, hours }}) }})}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}

                        {/* Add Day */}
                        {dayPicker({ v, icon:'add',  onClick:(val)=> { 

                            if (!available.hours)      available.hours      = {}
                            if (!available.hours[val]) available.hours[val] = {}
                            available.hours[val][PushId()]                  = newPeriod

                            v.updateDialog({ ...dialog, available }) 
                        } })}
                    </div>
                :null}
            </div>
        )
    }

    renderDateTime = ({ v, dialog }) => {

        // let dateTimeSelected    = location || v.basket?.dateTimeSelected ||
        let dateTimeSelected    = this.state.dateTimeSelected ||
            {
                date 	: new Date().getDate(),
                month 	: new Date().getMonth(),
                hours 	: new Date().getHours() + 1,
                minutes	: 0
            }
    
        return(
            <div style={Object.assign({}, Style.card, Style.div, {  })}>
                
                {/* <div style={Object.assign({}, Style.flexRow, { justifyContent:'space-between', paddingBottom:0 })}> */}
                    {/* <div   style={Style.text} >{item.dateTime["name_"+language] || item.dateTime.name.toUpperCase()}</div> */}
                    {/* { item.dateTime.required? <div style={{ padding:1, paddingRight:10, paddingLeft:10, borderRadius:2, backgroundColor:'#D50000', fontSize:'0.9rem', color:'white' }}>{ getAppText(this.props,'Required') }</div> :null} */}
                {/* </div> */}
                
                {/* Date */}
                <div style={{display:'flex', flex:1, flexDirection:'row', alignItems:'center', justifyContent:'center' }}>                                                
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>  
                        <div style-={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <Button onClick={() => this.handleDateTime({ v, dateTimeSelected, dialog, type:'date', increment:1}) } style={{justifyContent:'center', alignItems:'center'}}>{getIcon2({icon:'caretUp'})}</Button>                                      
                            <div><div  style={{ fontSize:14, textAlign:'center' }}>{dateTimeSelected.date}</div></div>
                            <Button onClick={() => this.handleDateTime({ v, dateTimeSelected, dialog, type:'date', increment:-1}) } style={{justifyContent:'center', alignItems:'center'}}>{getIcon2({icon:'caretDown'})}</Button>                                      
                        </div>
                        <div  style={{ fontSize:14, textAlign:'center'}}>:</div>
                        <div style-={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <Button onClick={() => this.handleDateTime({ v, dateTimeSelected, dialog, type:'month', increment:1}) } style={{justifyContent:'center', alignItems:'center'}}>{getIcon2({icon:'caretUp'})}</Button>                                      
                            <div  style={{ fontSize:14, textAlign:'center' }}>{numToMON(dateTimeSelected.month)}</div>
                            <Button onClick={() => this.handleDateTime({ v, dateTimeSelected, dialog, type:'month', increment:-1}) } style={{justifyContent:'center', alignItems:'center'}}>{getIcon2({icon:'caretDown'})}</Button>                                      
                        </div>
                    </div>
                    <div style={{display:'flex'}}><div  style={{ fontSize:14, textAlign:'center', width:100}}>{this.handleDateTime({ v, dateTimeSelected, dialog, type:'getTodayTomorrow'})}</div></div>
                </div>
    
                {/* Time */}
                <div style={{display:'flex', flex:1, flexDirection:'row', alignItems:'center', justifyContent:'center', width:'100%', marginTop:20 }}>                                                
                    <div style-={{display:'flex', flexDirection:'column'}}>
                        <Button onClick={() => this.handleDateTime({ v, dateTimeSelected, dialog, type:'hours', increment:1}) } style={{justifyContent:'center', alignItems:'center'}}>{getIcon2({icon:'caretUp'})}</Button>                                      
                        <div  style={{ fontSize:40, textAlign:'center', width:60}}>{dateTimeSelected.hours}</div>
                        <Button onClick={() => this.handleDateTime({ v, dateTimeSelected, dialog, type:'hours', increment:-1}) } style={{justifyContent:'center', alignItems:'center'}}>{getIcon2({icon:'caretDown'})}</Button>                                      
                    </div>
                    <div  style={{ fontSize:40, textAlign:'center'}}>:</div>
                    <div style-={{display:'flex', flexDirection:'column'}}>
                        <Button onClick={() => this.handleDateTime({ v, dateTimeSelected, dialog, type:'minutes', increment:15}) } style={{justifyContent:'center', alignItems:'center'}}>{getIcon2({icon:'caretUp'})}</Button>                                      
                        <div  style={{ fontSize:40, textAlign:'center', width:60}}>{dateTimeSelected.minutes<10?"0"+dateTimeSelected.minutes:dateTimeSelected.minutes}</div>
                        <Button onClick={() => this.handleDateTime({ v, dateTimeSelected, dialog, type:'minutes', increment:-15}) } style={{justifyContent:'center', alignItems:'center'}}>{getIcon2({icon:'caretDown'})}</Button>                                      
                    </div>
                    <div  style={{ fontSize:40, textAlign:'center', marginLeft:5}}>{dateTimeSelected.hours>11?"PM":"AM"}</div>
                </div>
            </div>
        )
    }

    handleDateTime = ({ v, dialog, increment, type, dateTimeSelected }) => {

        console.log(v, dialog, increment, type, dateTimeSelected, dateTimeSelected.month)

        if (type==='date') {
            dateTimeSelected.date += increment
            if (dateTimeSelected.date<1)     {dateTimeSelected.date=31}
            if (dateTimeSelected.date>31)    {dateTimeSelected.date=1}
            this.setState({ dateTimeSelected, dateTimeSelectedString:formatDateTime({ dateTimeSelected }) })
            // v.updateBasket({ ...v.basket, dateTimeSelected })
        }
        else if (type==='month') {
            dateTimeSelected.month += increment
            if (dateTimeSelected.month<0)    {dateTimeSelected.month=11}
            if (dateTimeSelected.month>11)   {dateTimeSelected.month=0}
            this.setState({ dateTimeSelected, dateTimeSelectedString:formatDateTime({ dateTimeSelected }) })
            // v.updateBasket({ ...v.basket, dateTimeSelected })
        }
        else if (type==='hours') {
            dateTimeSelected.hours += increment
            if (dateTimeSelected.hours<0)    {dateTimeSelected.hours=23}
            if (dateTimeSelected.hours>23)   {dateTimeSelected.hours=0}
            this.setState({ dateTimeSelected, dateTimeSelectedString:formatDateTime({ dateTimeSelected }) })
            // v.updateBasket({ ...v.basket, dateTimeSelected })
        }
        else if (type==='minutes') {
            dateTimeSelected.minutes += increment
            if (dateTimeSelected.minutes<0)  {dateTimeSelected.minutes=45}
            if (dateTimeSelected.minutes>59) {dateTimeSelected.minutes=0}
            this.setState({ dateTimeSelected, dateTimeSelectedString:formatDateTime({ dateTimeSelected }) })
            // v.updateBasket({ ...v.basket, dateTimeSelected })
        }

        else if (type==='getTodayTomorrow') {

            if ( 
                    dateTimeSelected.date   === new Date().getDate() &&
                    dateTimeSelected.month  === new Date().getMonth()
                ) {
                    return "TODAY"
            }

            else if ( 
                dateTimeSelected.date   === new Date().getDate()+1 &&
                dateTimeSelected.month  === new Date().getMonth()
            ) {
                return "TOMORROW"
            }
            
            else return ""
        }
        else return null
    }


    startTimer = ({ stop }) => {

        let v           = this.props.v
        let seconds     = v.dialog.restartIn

        let timer = setInterval(() => {

            seconds = seconds-1
            this.setState({ timerSeconds:seconds })
            if (seconds==0) { clearInterval(timer); v.updateDialog(""); window.location.reload() } // don't know how to clear timer
        }, 1000)
    }

    render() {
        let v                   =   this.props.v
        let orderlinaStore 		= 	{...this.props.orderlinaStore}
        let dialog              =   v && v.dialog
    
        console.log('dialog', dialog, this.props)
        if ( dialog ) {
    
            let wlc                 =   v.wlc
            let whiteLabelConfig    =   wlc
            let info                =   v.info
            let color               =   v.bt||'white'
            let language            =   v.language
            let selected            =   dialog.selected || []

            if     (dialog.confirmSendOrder) v={ ...v, ...dialog.confirmSendOrder }
            return (
                <div style={Object.assign({},  { display:'flex', minWidth:'100%', minHeight:'100%', width:`${100/v.browserConfigGlobal?.zoom||1}vw`, height:`${100/v.browserConfigGlobal?.zoom||1}vh`, justifyContent:'center', position:'fixed', top:0, left:0, backgroundColor:'#000000b8', zIndex:999999999999999, maxWidth:'100%' })} onClick={() => dialog.closeClickAnywhere? this.props.updateDialog(""): null } >
                    <div style={Object.assign({}, { padding:'1rem', backgroundColor:'transparent', maxHeight:'100vh', overflow:'scroll', alignSelf:'flex-start', paddingBottom:'4rem', justifyContent:'center', width:'100%', maxWidth:700, ...dialog.styleCardCont })}>
                        <Card id='dialog' style={Object.assign({}, Style.card, v.div, v.style.br, { maxHeight:'80vh', alignSelf:'center', padding:0, display:'flex', flexDirection:'column', overflow:'scroll', marginTop:0, ...dialog.styleCard })}>
                            
                            <div style={Object.assign({}, Style.flexRow, v.div4, {justifyContent:'space-between', padding:'0.5rem' })}>
                                <Button onClick={() => console.log(this.props) } style={Object.assign({}, {     padding:'0.75rem', borderRadius:99, margin:0, justifyContent:'flex-start', marginLeft:5 })}>{getIcon2({icon:dialog.icon||'alert',fontSize:'1.8rem', color })}</Button>
                                <Button onClick={() => this.props.updateDialog("") } style={Object.assign({}, { padding:'0.75rem', borderRadius:99, margin:0, justifyContent:'flex-end' })}>{getIcon2({icon:'close', fontSize:'2rem', color })}</Button>
                            </div>
    
                            <div style={Object.assign({}, { padding:'1.5rem 1.5rem', paddingBottom:(dialog.button2||!dialog.hideOkButton)?0:'1.5rem', ...dialog.styleBody })}> 
                                {dialog.loadingBackground?  <Loading v={v} cover light style={{zIndex:-1}} /> :null }
                                {dialog.text?       <div style={Object.assign({}, Style.flexRow, Style.textSSB, Style.wrap, v.div, { textAlign:'center', textTransform:'uppercase', marginBottom:5 })}>{getAppText2({v, text:dialog.text})}</div>:null}
                                {dialog.description?<div style={Object.assign({}, Style.flexRow, Style.textS,   Style.wrap, v.div, { textAlign:'center', marginBottom:10, lineHeight:2, fontWeight:'normal' })}>{getAppText2({ v, text:dialog.description})}</div>:null}

                                {/* {dialog.textArea?   <Input2 autoFocus v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} instructions={getAppText2({v, text:dialog.textAreaInstructions})} 					placeholder={getAppText2({v, text:dialog.textAreaPlaceholder})} 	 	value={dialog.textAreaValue||""} onChange={(e) => this.props.updateDialog({ ...dialog, textAreaValue:e.target.value }) } styleCont={ Object.assign({}, { marginTop:15 })} />:null} */}
                                {/* {dialog.fields && dialog.fields.map((item, index) => { 
                                    return <Input2 key={index} v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} instructions={getAppText2({v, text:item})} 					placeholder={getAppText2({v, text:item})} 	 	value={dialog[item]||""} onChange={(e) => this.props.updateDialog({ ...dialog, [item]:e.target.value }) } styleCont={ Object.assign({}, { marginTop:15 })} />
                                })} */}
                                {dialog.fields?.map((item, index) => {
                                    return <Input2 rows={dialog.fieldsRows} key={index} v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} instructions={item.instructions||item.placeholder} 					placeholder={item.placeholder} 	 	value={dialog[item.placeholder]||""} onChange={(e) => this.props.updateDialog({ ...dialog, [item.placeholder]:e.target.value }) } styleCont={ Object.assign({}, { marginTop:15 })} />
                                })}
                                {dialog.iconSelect? <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap' }}>{iconNameArray.map(item => { return <Button onClick={() => { dialog.iconSelect(item); this.props.updateDialog("") }} style={Style.button}>{getIcon2({ icon:item, fontSize:'1.5rem' })}</Button>})}</div>:null}
                                {dialog.pageUrl? <Page {...this.props} pageUrl={dialog.pageUrl.split('?')[0]} />:null}
                                {dialog.pageKey? <Page {...this.props} pageKey={dialog.pageKey} />:null}
                                { dialog.multiSelect && Object.entries(dialog.multiSelect).map((item,index) => { item = { ...item[1], key:item[0], index  } 
                                    
                                    return (
                                        <Button key={item.key} style={Object.assign({}, { color, justifyContent:'flex-start' })} onClick={() => { 
                                            
                                            let indx = selected.indexOf(item.key)
                                            if (indx>-1) selected.splice(indx, 1)
                                            else selected.push(item.key) 

                                            this.props.updateDialog({ ...dialog, selected })
                                            dialog.handleChange(selected)
                                        }}>
                                            { getIcon2({ icon:!selected.length || selected.includes(item.key)?'CheckBoxSelected':'CheckBox' }) }
                                            <div style={{marginLeft:10}}>{item[dialog.multiSelectName]}</div>
                                        </Button>                                    
                                    )
                                })}
                                {dialog.languageSelect? languageButtons({props:this.props, v}) :null}
                                {dialog.orderDateTime?  this.renderDateTime({props:this.props, v, dialog}) :null}
                                {dialog.setAvailable?   this.renderSetAvailable({ v, dialog }) :null}
                                {dialog.sort?           this.sort({ v, dialog }) :null }
                                {dialog.allLang?<div style={{maxHeight:'70vh', overflow:'scroll'}}>{ languageButtons({props:this.props, v, allLang:true }) }</div>:null}
                                {info && dialog.settings?
                                    <div style={Style.flexColOnly}>
                                        { languageButtons({props:this.props, v}) }
                                        {v.info.chatActive? <Button3 styleText={{textAlign:'left', ...Style.textSSB }} style={v.div} twenty v={v} text="Chat"               icon="chat"     onClick={()=>{this.props.history.push(`/${v.venueUrl}/chat`);   this.props.updateDialog("") }} varEnd={v.client.newChatsC?  navButtonMini({ v, style:v.div4, icon:'notify' }) :null} /> :null}
                                        {v.ordering==true?  <Button3 styleText={{textAlign:'left', ...Style.textSSB }} style={v.div} twenty v={v} text="View Orders"        icon="orders"   onClick={()=>{this.props.history.push(`/${v.venueUrl}/orders`); this.props.updateDialog("") }} varEnd={v.client.newOrdersC? navButtonMini({ v, style:v.div4, text:v.client.newOrdersC }) :null} /> :null}
                                                            <Button3 styleText={{textAlign:'left', ...Style.textSSB }} style={v.div} twenty v={v} text="Submit Feedback"    icon="feedback" onClick={()=> customerFeedback({ props:this.props }) } />
                                                            <Button3 styleText={{textAlign:'left', ...Style.textSSB }} style={v.div} twenty v={v} text="View Basket"        icon="basket"   onClick={()=>{this.props.updateDialog({basket:true, icon:'basket', hideOkButton:true}) }} hide={v.basket?.itemsNew?.length?false:true}/>
                                    </div> 
                                :null}
                                {dialog.dietTags?
                                    <div style={Object.assign({}, Style.flexRow, { flexWrap:'wrap', padding:'1rem' })}>
                                        { v.info.dietTags && Object.entries(v.info.dietTags).map((item,index) => { item = { ...item[1], key: item[0], index  } 
                                            return <Button key={item.key} style={Object.assign({}, dialog.selectedDietTags&&dialog.selectedDietTags.includes(item.key)?v.button:v.div, Style.buttonBadge, { })} onClick={() => {dialog.updateSelectedDietTags&&dialog.updateSelectedDietTags(item.key); v.updateDialog("") } }>{item["name_"+language] || item.name}</Button>
                                        })}
                                    </div>
                                :null}
                                {dialog.confirmSendOrder?       <Total      v={v} style={{ padding:2, paddingBottom:0 }} />: null}
                                {dialog.basket?                 <Basket     v={v} {...this.props} />: null}
                                {dialog.verify?                 <Verify     v={v} {...this.props} />: null}
                                {dialog.pic?                    RenderImage2({ v, img:dialog.pic, clickToOpenInNewTab:dialog.clickToOpenInNewTab, defaultPic:dialog.defaultPic }): null}
                                {dialog.select?                 dialog.select.map((item,index) => {
                                    return <button key={index} style={Object.assign({}, { width:'100%', fontSize:'2rem', padding:'1rem', fontFamily:item[1] })} onClick={() => { dialog.onChange && dialog.onChange(item[0]); v.updateDialog("") }}>{item[0]}</button>                                    
                                }):null}
                                {dialog.iframeMobile?   <iframe src={dialog.iframeMobile} title="IFrame" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{height:'85vh', width:'100vw' }}></iframe> : null}
                            </div>
    
                            {dialog.button2 || !dialog.hideOkButton?
                                <div style={Object.assign({}, Style.flexRowOnly, { padding:'1.5rem', paddingTop:15, minHeight:'5rem' })}>
                                    {dialog.button3? <Button style={Object.assign({}, v.button, { backgroundColor:v.pc, marginRight:5 })} onClick={() => { dialog.button3(); this.props.updateDialog("") }}>{getIcon2({ icon:dialog.button3Icon, fontSize:'2rem' })}{getAppText2({v, text:dialog.button3Text, fontSize:'2rem' })}</Button> :null}
                                    {dialog.button2? <Button style={Object.assign({}, v.button, { backgroundColor:v.pc, marginRight:5 })} onClick={() => { dialog.button2(); this.props.updateDialog("") }}>{getIcon2({ icon:dialog.button2Icon, fontSize:'2rem' })}{getAppText2({v, text:dialog.button2Text, fontSize:'2rem' })}</Button> :null}
                                    {dialog.hideOkButton?null:
                                        <Button3 v={v} text={getAppText2({v, text:dialog.buttonText})} icon={dialog.iconOk} style={Object.assign({}, { flex:1 })} onClick={() => { 
                                            console.log(dialog)
                                            if      (dialog.verify) { buttonAction3({ v, item:dialog.item }); this.props.updateDialog("") }
                                            else if (dialog.link==='managerFeedbackEmail') {
                                                sendEmail2( { ...whiteLabelConfig, businessName:info.title||info.name||"UNKNOWN" , supportEmail:v.userInfo?.email||"" }, 
                                                            { recipientEmail:whiteLabelConfig.supportEmail, recipientBCC:'support@orderlina.com' }, 
                                                            { subject:`Manager Feedback`, title:`Feedback from ${info.title||info.name||"UNKNOWN"} on the ${whiteLabelConfig.businessName} Platform`, text: [ dialog.feedback ] })
                                                this.props.updateDialog({text:'Thank You', icon:'feedback'})
                                            }
                                            else if (dialog.link==='managerBugReportEmail') {
                                                sendEmail2( { ...whiteLabelConfig, businessName:info.title||info.name||"UNKNOWN" , supportEmail:v.userInfo?.email||"" }, 
                                                            { recipientEmail:whiteLabelConfig.supportEmail, recipientBCC:'support@orderlina.com' }, 
                                                            { subject:`Manager Bug Report`, title:`Bug Report from ${info.title||info.name||"UNKNOWN"} on the ${whiteLabelConfig.businessName} Platform`, text: [ dialog['bug details'] ] })
                                                this.props.updateDialog({text:'Thank You', icon:'bug'})
                                            }
                                            else if (dialog.link==='customerFeedbackEmail') {
                                                sendEmail2( whiteLabelConfig, 
                                                            { recipientEmail:info.email, recipientBCC:'support@orderlina.com' }, 
                                                            { subject:`${info.title||info.name} Customer Feedback Received`, title:`Feedback: ${v.userInfo?.email||""}`, text: [ dialog[dialog.fields[0].placeholder]||"Love your menu!", dialog['email optional']||"" ] })
                                                this.props.updateDialog({text:'Thank You', icon:'feedback'})
                                            }
                                            else if (dialog.link==='customerReferral') {
                                                sendEmail2( whiteLabelConfig, 
                                                    { recipientEmail:dialog[dialog.fields[2].placeholder], recipientBCC:whiteLabelConfig.supportEmail }, 
                                                    { subject:`${dialog[dialog.fields[0].placeholder]||"A Friend"} Referred You To Us`, title:`${dialog[dialog.fields[0].placeholder]||"A Friend"} Referred You To Us`, text: [ `Hi ${dialog[dialog.fields[1].placeholder]||""}`, `We'll keep it short. We do Beautiful Contactless Menus. If this might interest you please check us out here:`,  whiteLabelConfig.homepageLink ] })
                                                this.props.updateDialog({text:'Email Sent',  icon:'email'})
                                            }
                                            else if (dialog.link==='reservation') {
                                                placeOrder({ props:this.props, v, then:()=>{ console.log('doing nothing') } }) 
                                                sendEmail2( { ...whiteLabelConfig, businessName:v.venueTitle||"Reservations" , supportEmail:v.info.email||"" }, 
                                                            { recipientEmail:dialog[dialog.fields[1]], recipientBCC:v.info.email||"" }, 
                                                            { subject:`Reservation Received`, title:`Reservation Received`,text: [ `Your Reservation is being processed and you will receive a Reservation Confirmation shortly. If you would like to make changes to your reservation please call ${v.info.phone}.`, `${dialog.fields[0]}: ${dialog[dialog.fields[0]]||""}`, `${dialog.fields[1]}: ${dialog[dialog.fields[1]]||""}`, `${dialog.fields[2]}: ${dialog[dialog.fields[2]]||""}`, `${dialog.fields[3]}: ${dialog[dialog.fields[3]]||""}`, `${dialog.fields[4]}: ${dialog[dialog.fields[4]]||""}`, 'Time: ' +formatDateTime({ dateTimeSelected:v.basket.dateTimeSelected }), `Location: ${v.venueTitle}, ${v.info.address}.` ] })
                                                setTimeout(() => { this.props.updateDialog({ text:'Thank You', description:`Thanks For Reserving a Table at ${v.venueTitle}. Please Check Your Email for a Reservation Confirmation`,  icon:'clock'}) }, 50);
                                            }
                                            else if (dialog.checkInBeforeEntry)     { recordCheckIn(v, dialog); setGuestType3({ props:this.props, guestTypeKey:dialog.guestTypeKey, v, navTo:`/${ v.venueUrl }/menu` }); this.props.updateDialog(""); }
                                            else if (dialog.link==='updateApp')     { this.props.updateDialog(""); updateApp(this.props) }
                                            else if (dialog.link==='reload')        { this.props.updateDialog(""); window.location.reload() }
                                            else if (dialog.link==='recordCheckIn') { recordCheckIn(v, dialog); this.props.updateDialog({text:'Thank You', icon:'shield'}); }
                                            else if (dialog.setAvailable)           { dialog.onClick && dialog.onClick(dialog.available||""); v.updateDialog("") }
                                            else if (dialog.link)                   { dialog.link(this.state); this.props.updateDialog("") }
                                            else if (dialog.confirmSendOrder)       { 
                                                v.dialog=""
                                                dialog.tempOrder
                                                    ?   placeOrder({ props:this.props, v, doNothing:true })
                                                    :   placeOrder({ props:this.props, v, then:()=>handleLink3({ v, link:'back' }) })
                                                v.updateDialog({ text:'Order Sent', description:'To Track Your Order, Please select ORDERS in the Menu', hideOkButton:true, icon:'tick'})
                                            }
                                            else v.updateDialog("")
                                        }} />
                                    }
                                </div>
                            :null}
                        </Card>
    
                    </div>
                </div>
            )
        }
        else {
            console.log('langg noo dialog', dialog , v)
            return null
        }
    }
}
export default Dialog
