import React, { Component, Picker } from 'react';
import PushId from 'pushid';

import Firebase from '../Firebase';
import Style from '../styles';
import StripeElement from './StripeElement';


import { trimAmount, RenderHelper7, Text2, scrollTo2, trim_keepSpaces, Button3, Input2, isGod2, getDefaultLanguage2, handleError, readyPage, languageEditWarning, getDefaultLanguage, getVenueNameTrim, domain, supportEmail, verifyPlanCode, getWhiteLabelConfig, getAppText, getIsProCust, getWhiteLabelConfigEdit, Month, getVisitsCount, hint2, YYYY, MM, notifications, renderHelper5, isGod, sendEmail2, trim, uploadToFBStorage2, getIcon2, getUserEmail, randomNumber, getLocationsFlat, countInstances, Card2, handleLink2, log2, Select2, Select3, download3, uploadButton3, handleLink3, manageSubscription, createSubscription, searchParams, getSticker, renderCustomerSubscription, renderSubscription, getIsProCust2, isAdmin, getSub, updateSub, toArray, renderPlan, containsVowel, cloudFunction, Loading, alert2, updateData, renderLogo3, Button5, Text3 } from '../Mini-components/Functions'

import Button2 from '../Mini-components/Button2';
import Subscribe from './Subscribe';



let height = window.innerHeight

const orderlinaStripeClientId = "ca_EFUcxZKPm28U3pwpQTkydBd0ALN2KPEg"; //prouction key


class MSubscription extends Component {

	constructor(props) {
		super(props);
        let v = this.props.v
		this.state = {
            email:		v.userInfo.email,
			customer:	{ id:v.userConfig?.stripeCustomerId },
			prices:		[],
			coupon:		this.props.coupon
        };
	}

    componentDidMount() {

        let v = this.props.v

        // this.getStripeCustomer({v})
        this.validateSub()
		// this.handleChange3({ type: 'getPlans' })
	}



	// FLOWS
	// 1. GETPLANS
	// 2. CREATE CUSTOMER
	// 3. CREATE SUB (FOR TRIALS THIS SUB DISPLAYS DISCOUNTS APPLIED)

	// ADDITIONAL STEPS FOR TRIALS
	// 4. CREATE SETUPINTENT (INTENT TO PAY LATER)
	// 5. UPDATE DEFAULT PAY METHOD
	// 6. CREATE SUB (FOR TRIALS THIS IS THE SUB THAT WILL BE ATTACHED)


	handleChange3 = async ({ type, defaultPayMethod, defaultPayMethodUpdated, price }) => {

		let { prices, email, customer, subSelected, clientSecret, subs, name, error, coupon, enterCoupon} = this.state
		let { v, then } 						= this.props
		    price								= price || this.state.price
        let subscription                        = v.info?.subscription

        console.log('vvv', price, this.state.price)

		if (type == 'getPlans') {

            let prices          = v.wlc.stripePlans
            let priceKey        = prices && Object.keys(prices)?.[0]
            price               = subscription?.plan || prices?.[priceKey]

            console.log('ppp', price)
			this.setState({ prices, price })

            if (!v.info?.subscription) this.handleChange3({ type: 'createCustomer', price })
		}
		else if (type == 'createCustomer') {

			if (customer?.id) {
				console.log('v customer', customer)
				this.handleChange3({ type: 'createSub', price  })
			}
			else {
				const resp = await cloudFunction({ v, name: 'stripe', params: { type: 'createCustomer', email } })
				console.log('SUB customer: ', resp, )
				customer = resp?.data
				this.setState({ customer })
				this.handleChange3({ type: 'createSub', price  })
			}
		}	
		else if (type == 'createSub') {

			let priceId 	= price?.id
            this.setState({ clientSecret:"", lb:priceId })
            console.log('sub create sub 1', customer, this.state.price)

			// IF SAME SUB, AND NOT ADDING COUPON, DON'T DO ANYTHING
			if ( !customer || !priceId ) return null


			const resp = await cloudFunction({ v, name: 'stripe', params: { type: 'createSub', customerId:customer?.id, priceId, clientKeys:v.clientKey, coupon } })
			console.log('sub create sub 2', resp)
            this.setState({ subSelected:resp?.data, lb:'' })

			if (resp?.data?.error) { v.updateDialog({ v, text:resp?.data?.error?.raw?.message||"", hideOkButton:true }); this.setState({ coupon:"" }); return }

			this.setState({ clientSecret:resp?.data?.latest_invoice?.payment_intent?.client_secret })
		}

        else if (type == 'updateSub') {
			
            this.setState({ clientSecret:"", lb:price?.id })
            console.log('sub update start', v.info?.subscription?.id, price?.id)
			const resp = await cloudFunction({ v, name: 'stripe', params: { type: 'updateSub', subscriptionId:v.info?.subscription?.id, priceId:price?.id } })
			console.log('sub update end', resp)
            this.setState({lb:''})

			if (resp?.data?.error) { v.updateDialog({ v, text:resp?.data?.error?.raw?.message||"", hideOkButton:true }); this.setState({ coupon:"" }); return }
		}

		else if (type == 'createSetupIntent') {

			const resp = await cloudFunction({ v, name: 'stripe', params: { type, customerId:customer?.id } })
			console.log('SUB SETUP INTENT: resp', resp)
			this.setState({ clientSecret:resp?.data?.client_secret })
		}

		else if (type == 'updateDefaultPayMethod') {

            console.log('updateDefaultPayMethod', customer, defaultPayMethod)
			const resp = await cloudFunction({ v, name:'stripe', params: { type, customerId:customer?.id, defaultPayMethod } })
			console.log('SUB UPDATE DEFAULT PAY METHOD: ', resp)
            this.setState({clientSecret:""})
			then&&then()
		}

		else if (type == 'getSubs') {
			const resp = await cloudFunction({ v, name: 'stripe', params: { type: 'getSubs', customerId: customer.id } })
			console.log('SUB getSubs', resp?.data?.data)
			let subs = resp?.data?.data
			this.setState({ subs })
		}
	}


	AddSubManually = () => {

		let v = this.props.v
		return (
			this.state.addSubManually
				?   <Input2 twenty v={v} instructions='Add Subscription Id Manually' placeholder='Eg. sub_1LAOyVCjNf9kseHpc9rsfasdf' value={ this.state.subId||""} onChange={(e)=> this.setState({ subId:e.target.value })} button={()=>this.handleChange2({type:'addSubId'})} iconButton={this.state.lb?'loading':'save'} />
                :   <Text2 v={v} text='add subscription manually' style={{...Style.text0_75, alignSelf:'center', marginTop:20, color:'grey', display:'block', textAlign:'center' }} onClick={()=>this.setState({addSubManually:true})} />
        )
	}



    getStripeCustomer = async ({v}) => {

        // USED TO PROMPT WHETHER CUSTOMER HAS DEFAULT PAYMENT METHOD OR NOT 

        let customerId = v.info?.subscription?.customer || v.userConfig?.stripeCustomerId
        const resp = await cloudFunction({ v, name:'stripe', params: { type:'getCustomer', customerId } })
        console.log('CUSTOMER: resp', resp)
        this.setState({ stripeCustomer:resp.data })
    }

    validateSub = async () => { 

        let v                   = this.props.v
        let sub                 = v.info?.subscription

        if (sub && v.pathname.includes('manager/config/plan')) {

            // REMOVE $0 SUBS
            console.log('REMOVING $0 SUB', v.info?.subscription?.plan?.amount)
            if ( sub?.plan?.amount===0 ||
                 sub?.status=='canceled') { console.log('REMOVING SUB', v.info?.subscription?.plan?.amount);  updateData({ v, subscription:"" }) }


            console.log('checking sub active')
            let subLive = await getSub({v, id:sub.id })
            if (subLive) {
                let subLiveClientKeys  = toArray(subLive?.metadata?.clientKeys)

                // HANDLES WHEN SUB DOESN'T HAVE THE VENUE'S CLIENTKEY SO DOESNT GET UPDATES
                if      (!subLiveClientKeys?.includes?.(v.clientKey))   {console.log('XXX SUB DOES NOT INCLUDE KEY'); updateData({ v, subscription:"" }) }

                // WARN IF SUB VENUES EXCEED QUANTITY
                else if (subLiveClientKeys?.length>subLive.quantity)    handleError({v, message:'venues exceed subscription quantity, please click "manage" to revise your plan'})
                else                                                    console.log('XXX SUB INCLUDES KEY AND HAS SUFFICENT QUANTITY')
            }
        }
    }


    renderSubscription = ({ v, subscription }) => {

        if (subscription) {

            let clientKeysArray         = toArray(subscription?.metadata?.clientKeys)
            let amount                  = subscription.discount
                                            ?   `$${ ( (subscription.plan?.amount/100) * (1-(subscription.discount?.coupon?.percent_off_precise/100)) ).toFixed(2) } month (Includes ${subscription.discount?.coupon?.percent_off||""}% Discount)` 
                                            :   `$${( (subscription.plan?.amount||subscription.plan?.tiers?.[0]?.amount) / 100)||0}`
            let trial                   = subscription.plan?.trial_period_days ? `+${subscription.plan?.trial_period_days} Day Free Trial` : ""
            let subSelected             = this.state.subSelected

            if (!subSelected ||  subscription?.plan?.id==subSelected?.plan?.id) return (

                <Card2 v={v} icon='page' title='Your Plan Details'  style={{ ...v.div2, marginTop:15, ...Style.flexColOnly, overflow:'scroll' }} >

                    <Text2 icon='shield'    v={v} style={Object.assign({}, Style.styleText1UpperNormal, {  })}                               varStart2='PLAN'        varStart2Style={Style.varStart2Style}       text={`${subscription.plan?.nickname||"Premium"}`}                                                                 varEnd={isAdmin({v}) && !subscription.plan.nickname? "Edit plan name by adding a 'nickname' in stripe":"" } />
                    <Text2 icon='dollar'    v={v} style={Object.assign({}, Style.styleText1UpperNormal, {  })}                   twenty     varStart2='AMOUNT'      varStart2Style={Style.varStart2Style}       text={ `${amount} ${trial}` } />
                    <Text2 icon='page'      v={v} style={Object.assign({}, Style.styleText1UpperNormal, {textTransform:'none'})} twenty      varStart2='ID'          varStart2Style={Style.varStart2Style} varEnd={subscription.id} />
                    <Text2 icon='star'      v={v} style={Object.assign({}, Style.styleText1UpperNormal, {  })}                   twenty      varStart2='STATUS'      varStart2Style={Style.varStart2Style} >{subscription.pause_collection?'paused':subscription.status}</Text2>
                    {subscription.quantity>1?
                    <Text2 icon='settings'  v={v} style={Object.assign({}, Style.styleText1UpperNormal, {  })}                   twenty      varStart2='QUANTITY'    varStart2Style={Style.varStart2Style} >{subscription.quantity}</Text2> 
                    :null}
                    <Text2 icon='business'  v={v} style={Object.assign({}, Style.styleText1UpperNormal, {  })}                   twenty      varStart2='VENUES'      varStart2Style={Style.varStart2Style} >
                        <div style={Style.flexCol1}>
                            { clientKeysArray.map((item, index) => { 
                                return (
                                    <div style={{...Style.flexRowSP, ...Style.text1, marginTop:index==0?0:15 }}>
                                        <div onClick={()=> { Firebase.database().ref(`clients/${item}/info/name`).once('value').then(snap => { this.setState({ [item]:snap.val() }) }).catch((error)=> { handleError({v , error}) }) }}>{v.clientKey==item? v.venueTitle : this.state[item] || 'get name'}</div>
                                        { getIcon2({icon:'trash', onClick:()=> v.clientKey==item? this.handleChange2({ type:'removeClientKeyFromSub', key:item }) : alert(`To remove this from the subscription go to the manager plan page for ${this.state[item] || item}`), fontSize:'0.8rem' })}
                                    </div>
                                )
                            }) }
                        </div>
                    </Text2>

                    {/* MANAGE */}
                    <Button3 light v={v} twenty text={'Manage'} icon='settings' varEnd={getIcon2({icon:'next'})}  style={{...Style.flexRowSP, flex:1, padding:'0.5rem 1rem'}} contStyle={{margin:0, marginTop:30, marginBottom:20 }} onClick={() => manageSubscription({ v, stripeCustomerId: subscription?.customer || v.userConfig.stripeCustomerId, domainHttps:v.domainHttps } ) } />
                    {/* {clientKeysArray?.length>subscription.quantity && !this.state.dismiss? <Card2 v={v} icon="alert" title='venues exceed subscription quantity, please click "manage" to revise your plan' style={Object.assign({}, v.div4, Style.cover, {  })} onClick={()=>{this.setState({dismiss:true}); handleError({v, message:'Subscribed Venues Exceed Quantity'}) }} />:null} */}
                </Card2>
            )
        }
        else return null
    }

    Subscribe2 = () => {

		let { subSelected, clientSecret, subs, name, coupon, enterCoupon} = this.state
		let { v, then } 						= this.props
		let stripePlans 						= v.wlc.stripePlans
        let subscription                        = v.info?.subscription

        return (
            <Card2 v={v} icon='shield' title='Subscribe' onClick={() => console.log('sss', this.state)} styleChildren={{ padding:0, paddingBottom:'1.5rem' }}>

                {/* PLANS */}
                {stripePlans?
                    <div style={{ ...Style.flexRowOnly, overflow:Object.keys(stripePlans).length>1?'scroll':'none', paddingBottom:5, paddingTop:'1.5rem' }}>
                        <div style={{marginLeft:'0.5rem'}} />
                        { Object.entries(stripePlans).map((price,index) => { price = { ...price[1], key:price[0], index  }
                                
                                let isPriceSelected 	= this.state?.price?.amount == price.amount
                                let subHighlight 		= isPriceSelected && Object.keys(stripePlans).length>1? v.div4: v.div2
                                let subDiscount 		= isPriceSelected && subSelected?.discount? `$${ trimAmount( (subSelected?.latest_invoice?.amount_due/100) ) }/month (INCLUDES ${subSelected?.discount.coupon?.percent_off||""}% DISCOUNT CODE '${subSelected?.discount?.coupon?.id}')` :""
                                
                                console.log('ggg', price, subSelected)
                                return (
                                    <RenderHelper7 props={{ 
                                        v, key: price.id, noIcon: true,
                                        aboveTitle: renderLogo3({ img:v.wlc.logo, size:'2rem', style:{margin:0, alignItems:'flex-start', marginBottom:10 }, styleCont:{alignItems:'flex-start'}  }),
                                        title: price.nickname || "Plan",
                                        message: subDiscount || `$${price.amount / 100} month` || !price.unit_amount&&'FREE' || `$${price.unit_amount / 100} month`,
                                        message2: price.recurring?.trial_period_days ? `${price.recurring?.trial_period_days} Day Free Trial. Cancel anytime during the trial` : "",
                                        onClick: () => {console.log('ppp', price); this.setState({ price }); this.handleChange3({ type: subscription.default_payment_method?'updateSub':'createSub', price }) },
                                        style: { ...subHighlight, minWidth:200, marginLeft:'1rem', flex:1 },
                                        styleMessage: {textTransform:'none'},
                                        icon2:this.state.lb==price.id?'loading':''
                                    }} />
                                )
                        })}
                        <div style={{marginRight:'3rem'}} />
                    </div>
                :null}

                <div style={{ ...Style.flexColOnly, paddingLeft:'1.5rem', paddingRight:'1.5rem' }}>

                    {/* COUPON */}
                    {clientSecret && !subSelected?.discount?.coupon
                        ?	enterCoupon
                                ?	<Input2 twenty  v={v}  id='coupon' icon='discount' placeholder='Promotion Code'  value={coupon} onChange={(e) =>{ this.setState({ coupon:e.target.value }) }}  iconButton={this.state.coupon?'tick':'close'} styleCont={{ paddingtop:20 }}  button={()=>{ this.state.coupon&&this.handleChange3({type:'createSub'}); this.setState({enterCoupon:false}) }} />
                                :	<Text2  twenty  v={v}  icon='discount' text='Add Promotion Code' onClick={(e)=>{ this.setState({ enterCoupon:true }) }} style={{ marginLeft:5 }}  />
                        :   null
                    }

                    { this.renderSubscription({ v, subscription }) }

                    {/* STRIPE ELEMENT */}
                    {clientSecret? <StripeElement subs={subs} updateDefaultPayMethod={(pm)=>{ console.log('subscribe paymethod:', pm); this.handleChange3({ type:'updateDefaultPayMethod', defaultPayMethod:pm }) }} then={() => { this.handleChange3({ type: 'getSubsxxx' }); then&&then()  }} clientSecret={clientSecret} name={name} v={v} style={{marginTop:25}} /> : null }

                </div>
                                    

                <div style={Style.flexRowOnly}>
                    {this.state?.price? <Text2 v={v} text='Buy with External Gateway' style={{...Style.text0_75, alignSelf:'center', marginTop:20, color:'grey', display:'block', textAlign:'center' }} onClick={()=>createSubscription({ v, plan:this.state?.price, stripeCustomerId:v.userConfig.stripeCustomerId, domainHttps:v.domainHttps })} />:null}
                    {this.AddSubManually()}
                </div>


                {/* CREATE CUSTOMER */}
                    {/* <div style={Style.flexColOnly}>
                    <Input2 v={v} id='email' icon='email' placeholder='Email' type="email" value={email} onChange={(e) => this.setState({ email:e.target.value })} />
                    <Button3 v={v} onClick={() => this.handleChange3({ type: 'createCustomer' })} text="Next" icon='next' style={{ marginTop: 5 }} />
                </div> */}

            </Card2>
        )
	}


	Subscribe = () => {

		let { email, customer, prices, subSelected, clientSecret, subs, name, coupon, enterCoupon} = this.state
		let { v, then } 						= this.props
		let stripePlanIds 						= v.wlc.stripePlanIds || []
		let onFreePlan 							= !subSelected?.plan?.amount
        let subscription                        = v.info?.subscription

        console.log('ssss', subscription)

        return (
            <Card2 v={v} loading={!subscription} icon='shield' title='Subscribe' onClick={() => console.log('sss', this.state)} styleChildren={{ padding:0, paddingBottom:'1.5rem', minHeight:400 }}>

                {/* PLANS */}
                <div style={{ ...Style.flexRowOnly, overflow:stripePlanIds.length>1?'scroll':'none', paddingBottom:5, paddingTop:'1.5rem' }}>
                    <div style={{marginLeft:'0.5rem'}} />
                    { prices.map((price) => {
                            
                            let isPriceSelected 	= subSelected? (subSelected.plan?.amount==price.unit_amount||subSelected.plan?.unit_amount==price.unit_amount) : subscription?.plan?.amount==price.unit_amount
                            let subHighlight 		= isPriceSelected && stripePlanIds.length>1? v.div4: v.div2
                            let subDiscount 		= isPriceSelected && subSelected?.discount? `$${ trimAmount( (price.unit_amount/100) * (1-(subSelected?.discount?.coupon?.percent_off_precise/100)) ) }/month (INCLUDES ${subSelected?.discount.coupon?.percent_off||""}% DISCOUNT CODE '${subSelected?.discount?.coupon?.id}')` :""
                            
                            return (
                                <RenderHelper7 props={{ 
                                    v, key: price.id, noIcon: true,
                                    aboveTitle: renderLogo3({ img:v.wlc.logo, size:'2rem', style:{margin:0, alignItems:'flex-start', marginBottom:10 }, styleCont:{alignItems:'flex-start'}  }),
                                    title: price.nickname || "Plan",
                                    message: subDiscount || !price.unit_amount&&'FREE' || `$${price.unit_amount / 100} month`,
                                    message2: price.recurring?.trial_period_days ? `${price.recurring?.trial_period_days} Day Free Trial. Cancel anytime during the trial` : "",
                                    onClick: () => {console.log('ppp', price); this.setState({price }); this.handleChange3({ type: subscription.default_payment_method?'updateSub':'createSub', newPrice:price }) },
                                    style: { ...subHighlight, minWidth:200, marginLeft:'1rem', flex:1 },
                                    styleMessage: {textTransform:'none'},
                                    icon2:this.state.lb==price.id?'loading':''
                                }} />
                            )
                    })}
                    <div style={{marginRight:'3rem'}} />
                </div>

                <div style={{ ...Style.flexColOnly, paddingLeft:'1.5rem', paddingRight:'1.5rem' }}>

                    {/* COUPON */}
                    {clientSecret && !subSelected?.discount?.coupon
                        ?	enterCoupon
                                ?	<Input2 twenty  v={v}  id='coupon' icon='discount' placeholder='Promotion Code'  value={coupon} onChange={(e) =>{ this.setState({ coupon:e.target.value }) }}  iconButton={this.state.coupon?'tick':'close'} styleCont={{ paddingtop:20 }}  button={()=>{ this.state.coupon&&this.handleChange3({type:'createSub'}); this.setState({enterCoupon:false}) }} />
                                :	<Text2  twenty  v={v}  icon='discount' text='Add Promotion Code' onClick={(e)=>{ this.setState({ enterCoupon:true }) }} style={{ marginLeft:5 }}  />
                        :   null
                    }

                    { this.renderSubscription({ v, subscription }) }

                    {/* STRIPE ELEMENT */}
                    {clientSecret? <StripeElement subs={subs} updateDefaultPayMethod={(pm)=>{ console.log('subscribe paymethod:', pm); this.handleChange3({ type:'updateDefaultPayMethod', defaultPayMethod:pm }) }} then={() => { this.handleChange3({ type: 'getSubsxxx' }); then&&then()  }} clientSecret={clientSecret} name={name} v={v} style={{marginTop:25}} /> : null }

                    {this.AddSubManually()}
                </div>


                {/* CREATE CUSTOMER */}
                    {/* <div style={Style.flexColOnly}>
                    <Input2 v={v} id='email' icon='email' placeholder='Email' type="email" value={email} onChange={(e) => this.setState({ email:e.target.value })} />
                    <Button3 v={v} onClick={() => this.handleChange3({ type: 'createCustomer' })} text="Next" icon='next' style={{ marginTop: 5 }} />
                </div> */}

            </Card2>
        )
	}


	render() {

        let v                   = this.props.v
        let subscription        = v.info?.subscription
        let wlc                 = v.wlc
        let upgradeTitle        = 'subscription' // visitCount>200?'monthly limit exceeded': visitCount>100? 'nearing monthly limit' : 'subscription'
        let upgradePrompt       = wlc.upgradePrompt || 'upgrade your subscription'
        let styleCont           = { paddingLeft:0, paddingRight:0, marginTop:20 }

        console.log('sub state', subscription, this.state)

        // RESELLER
        if (wlc.platformType=='reseller' && !v.isAdmin) return <RenderHelper7 props={{ v, style:{ },   title:`${wlc.businessName} Subscription`,   message2:`Please contact ${wlc.supportEmail || wlc.email ||""} for subscription support`,   icon:'shield'      }} />

        // MANUAL KEY ENTERED - LEGACY
        else if (v.isPro && !subscription) return <RenderHelper7 props={{ v, style:{ },   title:`${wlc.businessName} Subscription`, message:'Thank you for upgrading to a subscription',  message2:`Please contact ${wlc.supportEmail || wlc.email ||""} for subscription support`,   icon:'shield'      }} />


        // SUBSCRIPTION
        else if (subscription) return this.renderSubscription({ v, subscription })

        // UPGRADE IN APP
        // else if (wlc.stripePlanIds)  return this.Subscribe()

        // UPGRADE IN APP
        // else if (subscription || wlc.stripePlans) return this.Subscribe2()

        // UPGRADE VIA PORTAL
        else if (wlc.stripePlans) return (

            <Card2 onClick={()=>log2(v)} v={v} title={upgradeTitle} icon='alert'  style={{}} >

                {upgradePrompt?     <Text3 h3 style={{...Style.flexRow}}>{upgradePrompt}</Text3> :null}
                {v.wlc.pricingUrl?  <Text3 style={{...Style.flexRow, marginTop:10}} onClick={()=>handleLink3({v, link:v.wlc.pricingUrl})}>View full pricing details {getIcon2({icon:'openInNewTab', marginLeft:10})}</Text3> :null}

                {/* SHOW LOADING IF RETURNING FROM SUBSCRIPTION http://localhost:3000/manager/config/plan?session_id=cs_live_a1zjydy3RA9IJIMSM7myehAjUQanKc1Wc4KMZjRX4AKuHGs3zzeQyqcQj3 */}
                {v.searchParams.get('session_id')? <div style={{...Style.flexCol, marginTop:20 }}>{getIcon2({ icon:'loading', color:'inherit', fontSize:'1rem' })}</div> :null}

                <div style={{...Style.flexRow, flexWrap:'wrap', marginTop:20 }}>
                    { v.wlc.stripePlans && Object.entries(v.wlc.stripePlans).map((item,index) => { item = { ...item[1], key:item[0], index  }
                        console.log('ppp', item)
                        return (
                            <Button5 v={v} light={index} text={item.metadata?.buttonText||item.nickname||'Plan'} style={{marginLeft:index?10:null}} loading={this.state.lb==item.key} onClick={()=> { this.setState({lb:item.key}); createSubscription({ v, plan:item, stripeCustomerId:v.userConfig.stripeCustomerId, domainHttps:v.domainHttps }) }} />
                        )
                    })}
                </div>

                {this.AddSubManually()}
            </Card2>
        )

        // OLD - UPGRADE VIA SIMPLE LINK
        else if (wlc?.upgradeLink) return (

            <Card2 v={v} title={upgradeTitle} message={upgradePrompt} icon='alert' style={styleCont} >                
                
                <Button2   v={v} style={{...Style.flexRowSP, flex:1, padding:'0.5rem 1rem'}}  contStyle={{margin:0}} onClick={() => window.open(wlc?.upgradeLink) } icon={'user'}>
                    { getSticker({ stickerSize:2 }) }
                    <div style={{textAlign:'center'}}>{'upgrade'}</div>
                    <div style={{...Style.flexRowOnly, justifyContent:'center', textAlign:'center', width:60}}>{getIcon2({ icon:'next', fontSize:'2rem' })}</div>
                </Button2>

                {this.AddSubManually()}
            </Card2>
        )
        else return (
            <Card2 v={v} title={upgradeTitle} icon='shield' style={styleCont}  >
                <div style={Object.assign({}, Style.textSB, {})}>No Upgrade Options For This Service</div>
                {this.AddSubManually()}
            </Card2>
        )
    }

    handleChange2 = async ({ type, queue, key, value }) => {
        
        let v                       = this.props.v
        let info                    = v.info
        let subscription            = v.info.subscription
        let language                = v?.language
		let defaultLanguage         = getDefaultLanguage2({ v })
        let whiteLabelConfig        = v.wlc
        let wlcKey                  = v.searchParams.get("wlcKey")
        let pages                   = wlcKey? whiteLabelConfig?.pages||{} : info?.pages||{}

        if (type=='addSubId')  {

            this.setState({lb:true})
            let sub = await getSub({v, id:this.state.subId })

            if (sub.id) {
                // CLIENTKEYS STRING TO ARRAY
                let clientKeys = toArray(sub.metadata?.clientKeys)

                // ALERT IF NEW CLIENTKEY EXISTS
                if (clientKeys.includes(v.clientKey)) { v.updateDialog({ v, text:'already exists' }); this.setState({lb:false}); return }
                // ALERT IF INSUFFICIENT QUANTITY ON SUBSCRIPTION
                if (sub.quantity<=clientKeys.length)  { v.updateDialog({ icon:'alert', v, text:'to add another venue, please add "quantity" or remove a venue from this subscription' }); this.setState({lb:false}); return }

                // ELSE ADD IT
                clientKeys.push(v.clientKey)

                // BACK TO STRING
                clientKeys = clientKeys.toString()

                // UPDATE
                let subNew = await updateSub({v, id:sub.id, update:{metadata: { clientKeys }} })

                if      (subNew?.metadata?.clientKeys?.includes(v.clientKey))   { v.updateDialog({ v, text:'subscription added' }); this.setState({lb:false}) }
                else if (subNew?.type=='StripeInvalidRequestError')             { v.updateDialog({ v, text:subNew.raw?.message || 'see error' }); console.log('sub adding error', subNew); this.setState({lb:false}) }
            }
            else if (sub?.raw?.message) handleError({ v, error:sub.raw.message, stopStateLoading:()=>this.setState({lb:false}) })
            else {this.setState({lb:false}); return null}
        }  
        else if (type=='removeClientKeyFromSub')  {
            
            let sub         = subscription
            let clientKeys  = toArray(sub.metadata?.clientKeys)            
            let index       = clientKeys.indexOf(key)

            if (index>-1 && window.confirm(`Remove ${v.venueTitle} From Subscription?`)) {

                clientKeys.splice(index, 1)
                clientKeys = clientKeys.toString()

                // REMOVE FROM SUB
                let subNew = await updateSub({v, id:sub.id, update:{metadata: { clientKeys }} })
                if (!subNew?.metadata?.clientKeys?.includes(v.clientKey))  {
                    
                    updateData({ v, subscription:"" }) // REMOVE FROM VENUE - HAS TO BE DONE MANUALLY AS VENUE NO LONGER GETTING SUB UPDATES
                    v.updateDialog({ v, text:'venue removed from subscription', hideOkButton:true })
                }
                else console.log('Sub Still includes clientKey')
            }  
        }
    }

    authorizeCode = async (v) => {

        let authorizationCode = this.state.authorizationCode
    
        if (authorizationCode) {

            console.log("authorizing code now...");
            this.props.updateLoading(true);

            let authCode = Firebase.functions().httpsCallable('authCode')
		
            authCode({authorizationCode})
                .then((authorizeCodeResponse) => {

                    console.log('authorizeCodeResponse', authorizeCodeResponse);

                    let stripe_user_id = authorizeCodeResponse && authorizeCodeResponse.data && authorizeCodeResponse.data.stripe_user_id

                    console.log('authorizing code resp', stripe_user_id);
                    
                    if ( stripe_user_id ) {
                        console.log('SUCCESS connectedStripeAccountId', stripe_user_id )
                        this.handleChange('updateStripeAuthCode', stripe_user_id )
                        setTimeout(()=>{ this.save() }, 1000)
                        this.props.updateLoading(false)
                    }
                    else handleError({ v,  message:"It didn't work..", error:authorizeCodeResponse, props:this.props })
                })
                .catch((error) => handleError({ v,  message:"It didn't work..", error, props:this.props }) )
        }
        else handleError({ v,  error:"Please Enter Authorization Code Above", props:this.props })
    }

}


export default MSubscription

