import React, { Component } from 'react';
import Firebase, { auth, provider } from '../Firebase';
import PushId from 'pushid';

import Button2 from './../Mini-components/Button2'
import {Card, Button} from '@material-ui/core';
import Style from '../styles';

import { clearBasket, handleError, searchParams, renderTipButtons, closeButton, image2, getSticker, RenderHelper7, renderHelper6, sendEmailTemplate, formatDateTime, getOrderStatusSticker, stickerAwaitingAck, stickerAcked, stickerPreparing, stickerDelivering, stickerDone, stickerArchived, logo2, getAppText2, getPriceWithOptions, getVariables, print, dialog, getIcon2, Loading, social, trimAmount, iconPage, getIcon, readyPage, getClientKey, isManager, time, elapsedTime, numToMON, getFormattedDate, getTime, getCustomerOrderCount, venueName, getUser, footer, getUserEmail, val, containsVowel, isOrderDelivery, getLanguage, renderCustomerPhone, renderDriverHereNotifyButton, renderHelper, getLocationManualEntry, getLocationAddress, getLocationCoords, ifOrderViewLoginWithUrlEmail, renderTukButton, getAppText, olred, getLocalStorageSize, notifications, renderHelper5, handleDateTime, renderPayQr, seperator2, Card2, triggerSync, isGod2, DownloadButton, isMobile, getLocationAddressFull, guestTypeHasNoLocations, Button3, handleLink3, cloudFunction, log2, getFormattedLocalTime2, printOrders } from '../Mini-components/Functions'
import Basket from '../client/Basket';
// import { askForPermissioToReceiveNotifications } from '../push-notification';




let width = window.innerWidth
let height = window.innerHeight


class MOrders extends Component {

	constructor(props) {
		super(props);
		this.state = {
            filterDate:window.location.pathname.includes('manager')?1:false
        }
	}

    componentDidMount() {
        let v           = this.props.v
        readyPage({ props:this.props, v })
        // this.ifEmailExistsInOrderUpdateUserEmail()
        
        this.getOrderType({})

        // window.addEventListener("focus", payload => { console.log('change, focus'); this.setState({pageFocus:true}) })
        // window.addEventListener("blur", payload => { console.log('change, blur'); this.setState({pageFocus:false}) })

        this.orderPaidWebHook({v})
    }

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.v.client.orders) != JSON.stringify(this.props.v.client.orders)) {
            log2('nnn M ORDERS')
            this.getOrders()
        }
	}

    componentWillUnmount() {
    }

    getOrderType = ({ switchView }) => {

        let pathArchive = window.location.pathname.includes('archive')

        if (switchView) { this.props.history.push(`/manager/${pathArchive?'manageorders':'archive'}`); pathArchive= !pathArchive; if (pathArchive) this.setState({filterDate:1}) }
        
        pathArchive? this.getArchivedOrders() :this.getOrders()
    }

    orderPaidWebHook = async ({v}) => {

        let newPaidOrder        = searchParams('paid')
        if (newPaidOrder) {

            this.setState({ newPaidOrder })
            let newPaidOrderExists  = this.state.orders?.[newPaidOrder]

            if   (newPaidOrderExists) clearBasket({ v })
            else {
                cloudFunction({ v, name:'moveAwaitToPaidOrders', params:{ orderKey:newPaidOrder, clientKey:v.clientKey} })
                setTimeout(() => {this.orderPaidWebHook({v})}, 5000);
            }                     
        }
    }

    getArchivedOrders = async () => {

        Firebase.database().ref("archive/" + this.props.orderlinaStore.lastManaged + "/orders_archived" )
            .once("value")
            .then((snap) => this.setState({ archive:JSON.parse(JSON.stringify( snap.val() )) }) )
            .catch((error) => console.log(error))
    }
    
    getOrders = async () => {
        
        let v           = this.props.v
        let clientKey   = v.clientKey

        if (clientKey) {

            let orders          = v.client.orders || {}
            let newPaidOrder    = this.state.newPaidOrder

            if (newPaidOrder && !orders[newPaidOrder]) orders[newPaidOrder+'_AP'] = this.props.orderlinaStore.awaitingPayment
            
            this.setState({ orders })
        }
    }

	redNotifyCover(p) {

        let { v, title }    =   p
        let manager         =   v.manager

		if ( manager && title.status==1 && !v.pathname.includes('archive')) {
            let name        = title.custName
            let location    = title.guestType.delivery? getAppText2({ v, text:'Delivery' }):title.tableName || title.guestType.name

			return (
				<div onClick={() => this.handleOrderStatus({customer:v.customer, title}) } style={{backgroundColor:'#D50000', position:'absolute', width:'100%', height:'100%', top:0, left:0, display:'flex', justifyContent:'space-evenly', alignItems:'center', flexDirection:'column', color:'white', zIndex:9999 }}>
                    { getSticker({ stickerSize:5, v, img:stickerAwaitingAck }) }
                    {location   && <div style={Object.assign({}, Style.textL, Style.wrap, {textTransform:'uppercase', textAlign:'center'})}>{location}</div>}
                    {name       && <div style={Object.assign({}, Style.textL, Style.wrap, {textTransform:'uppercase', textAlign:'center'})}>{name}</div>}
				</div>
			)
        }
        else if ( this.state.addNote && this.state.addNote===title.key ) {
            return (
                <div  style={{ backgroundColor:'white', position:'absolute', width:'100%', height:'100%', top:0, left:0, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', zIndex:9999 }}>
                    <Button style={Style.navButton} onClick={() => this.setState({addNote:false}) }>{getIcon2({icon:'close', fontSize:Style.navIconSize})}</Button>
                    <input autoFocus style={ Object.assign({}, Style.text, Style.inputBasic, { fontWeight:'bold', color:'black', flex:'none', textAlign:'center', marginTop:20 })} className="form-control input" placeholder="Note"  value={ this.state.newNote } onChange={(e) => this.setState({newNote:e.target.value})  } />
                    <Button onClick={() => this.handleChangeOrder('addNote', title ) } style={Object.assign({}, Style.navButton, Style.textM, { fontWeight:'bold', marginTop:20 })}>{getIcon2({icon:'done', fontSize:Style.navIconSize })}</Button>
                </div>
            )
        }
		else return null
	}

    isPaid = (order) => {

        if ( order?.payment?.isPaid || order.paymentFlag || order.isPaid || order.payment?.charge?.paid ) return true
        else return false
    }

    orderInfo = ({item, manager, v}) => {
        return (
            <div id='orderInfo' style={Object.assign({}, Style.textXXS, { textTransform:'uppercase', marginTop:20 })}>
                { "ORDERID " + item.key }
                { item.guestType&&item.guestType.name? " | " + item.guestType.name:"" }
                { this.isPaid(item)?` | ${getAppText2({v, text:'Paid'})} ${item.payment?.amount||""} ${item.payment?.type||""}` : " | "+getAppText2({v, text:'Unpaid'})}
                { item.guestType&&item.guestType.name? " | " + item.guestType.name:"" }
                { item.customerEmail? "  |  " + item.customerEmail : null }
                { item.customerName!=="" ? "  |  " + item.customerName :null}
                { "  |  " + elapsedTime( item.orderTime ) + "mins" }
                { manager? "  | ACK IN  " + `${item.acknowledgedIn||""}` + "mins" :null}
                { item.completedIn===undefined ? null : "  | COMP IN  " + item.completedIn + "mins" }
                { "  |  " + getFormattedLocalTime2(item.orderTime2) }
            </div>
        )
    }

    bulkProcessing = ({ type, confirm, ordersFiltered, keys }) => {
        
        let v                   = this.props.v
        let clientKey           = v?.clientKey
            keys                = keys || ordersFiltered?.map(item => item[0]) || []
            
        if (confirm && keys.length>150) { window.alert('Selecting batches of less than 100 orders is recommended.') }
        let confirmed           = confirm? window.confirm(`${type} ${keys.length} orders? This could take a few minutes.`) : true
        
        if (confirmed && keys.length && !this.state.cancelProcessing) {

            this.setState({ processing:type })
            let orderKey = keys.splice(-1)

            if (type=='Archive') {

                // COPY
                Firebase.database().ref("clients/" + clientKey + "/orders/" + orderKey)
                    .once("value")
                    .then((snap) => { let order   = snap.val()
            
                        // ADD
                        order && Firebase.database().ref("archive/" + clientKey + "/orders_archived/" + orderKey)
                            .set(order)
                            .then(() => { 
            
                                // DELETE
                                Firebase.database().ref("clients/" + clientKey + "/orders/" + orderKey)
                                    .remove()
                                    .then(() => this.bulkProcessing({ type, keys }))
                                    .catch((error) => handleError({ v, error, noDialog:true }) ) 
                            }) 
                            .catch((error) => handleError({ v, error, noDialog:true }) ) 
                    })
                    .catch((error) => handleError({ v, error, noDialog:true }) )
            }
            else if (type=='Mark Done') {

                Firebase.database().ref("clients/" + clientKey + "/orders/" + orderKey + '/status' )
                    .set(5)
                    .then(() => setTimeout(() => this.bulkProcessing({ type, keys }), 1000))
                    .catch((error) => handleError({ v, error, noDialog:true }) )
            }
            else if (type=='Mark Paid') {

                Firebase.database().ref("clients/" + clientKey + "/orders/" + orderKey + '/paymentFlag' )
                    .set(true)
                    .then(() => setTimeout(() => this.bulkProcessing({ type, keys }), 500))
                    .catch((error) => handleError({ v, error, noDialog:true }) )
            }
            else return null
        }
        else this.setState({ processing:false, cancelProcessing:false })
    }

    handleChangeOrder = (type, var0, var1, var2, var3, var4) => {
        
		let orderlinaStore 	= {...this.props.orderlinaStore}
        let orders          = this.state.orders
        let title           = var0
        let item            = var1

        console.log('handleChangeOrder')

        if (type==='addCustomNote') {

            let indexCN     = var2
            let customNote  = var3


            if (!orders[title.key].items[item.key].itemObject.customNotesAdded) {  
                orders[title.key].items[item.key].itemObject.customNotesAdded=[]

                for (var i = 0; i < item.itemObject.quantitySelected; i++) { 
                    orders[title.key].items[item.key].itemObject.customNotesAdded.push({i}) 
                }
            }

            if (!orders[title.key].items[item.key].itemObject.customNotesAdded[indexCN].date) {
                orders[title.key].items[item.key].itemObject.customNotesAdded[indexCN].date=getFormattedDate()
            }

            if (!orders[title.key].items[item.key].itemObject.customNotesAdded[indexCN].customNotes) {
                orders[title.key].items[item.key].itemObject.customNotesAdded[indexCN].customNotes={}
            }

            orders[title.key].items[item.key].itemObject.customNotesAdded[indexCN].customNotes[PushId()] = {
                time:       getTime(),
                customNote: customNote
            }

            setTimeout(()=>{ this.setState({showCustomNotes:false})    }, 300)
        }

        else if (type==='deleteCustomNotesAll') {

            let indexCN     = var2
            let customNote  = var3
            orders[title.key].items[item.key].itemObject.customNotesAdded[indexCN] = { i:indexCN }
            setTimeout(()=>{ this.setState({showCustomNotes:false})    }, 300)
        }

        else if (type==='deleteCustomNote') {

            let indexCN     = var2
            let customNote  = var3
            delete orders[title.key].items[item.key].itemObject.customNotesAdded[indexCN].customNotes[customNote]
            setTimeout(()=>{ this.setState({showCustomNotes:false})    }, 300)
        }

        else if (type==='addNote') {

            orders[title.key].notesAdded = this.state.newNote||""
            setTimeout(()=>{ this.setState({addNote:false})    }, 300)
        }

        setTimeout(()=>{ this.setState({orders})    }, 300)
        setTimeout(()=>{ this.updateFB(title.key)   }, 300)
    }

    renderCustomNotes = (title, item) => {

        let v = this.props.v
        
        if (item.itemObject && item.itemObject.customNotes) {

            let orderlinaStore 	    = {...this.props.orderlinaStore}
            let clientKey 		    = v.clientKey
            let manager			    = v.manager
            let info			    = v.info 
                                        
            var customNotesAdded    = item.itemObject.customNotesAdded || []
    

            // IF empty, Create Array

            if ( !customNotesAdded.length ) {  for (var i = 0; i < item.itemObject.quantitySelected; i++) {  customNotesAdded.push({i}) }}
    

            // Custom Note OPTIONS

            if ( this.state.showCustomNotes && this.state.showCustomNotes[0].key===title.key ) {
                
                let titleCN                 = this.state.showCustomNotes[0]
                let itemCN                  = this.state.showCustomNotes[1]
                let indexCN                 = this.state.showCustomNotes[2]
                // console.log('itemCN',itemCN,)
                // console.log('indexCN',indexCN)
                // console.log('itemCN.itemObject.customNotesAdded[indexCN].date',itemCN.itemObject.customNotesAdded[indexCN].date)
                
                let customNotesAddedIndex   =   itemCN.itemObject.customNotesAdded && itemCN.itemObject.customNotesAdded[indexCN]

                return (
                    <div  style={{ backgroundColor:'white', position:'absolute', width:'100%', height:'100%', top:0, left:0, display:'flex', alignItems:'center', flexDirection:'column', zIndex:99, padding:height/30 }}>
                        
                        <Button onClick={() => this.setState({showCustomNotes:false}) }>{getIcon2({ icon:'back', fontSize:Style.navIconSize })}</Button>

                        {/* Existing Custom Notes */}

                        { customNotesAddedIndex && customNotesAddedIndex.date &&
                            <div style={{display:'flex', width:'100%', flexDirection:'column'}}>
                                <div style={Object.assign({}, Style.flexRow, { justifyContent:'center', width:'100%', flex:0 })}>
                                    { <div style={Object.assign({}, Style.textM, { fontWeight:'bold' })}>{customNotesAddedIndex.date}</div> }
                                    <Button onClick={() => this.handleChangeOrder('deleteCustomNotesAll', titleCN, itemCN, indexCN ) }>{getIcon2({ icon:'delete', fontSize:Style.navIconSize })}</Button>
                                </div>

                                <div style={Object.assign({}, {marginTop:15 })}>
                                    { customNotesAddedIndex.customNotes && Object.entries(customNotesAddedIndex.customNotes).map((item3,index) => { item3 = { ...item3[1], key:item3[0], index:index  }
                                        return( 
                                            <div style={Style.flexRow}>
                                                <div style={Object.assign({}, Style.textM, { fontWeight:'bold', textAlign:'center' })}>{item3.customNote} {item3.time}</div>
                                                <Button onClick={() => this.handleChangeOrder('deleteCustomNote', titleCN, itemCN, indexCN, item3.key ) }>{getIcon2({ icon:'delete', fontSize:Style.navIconSize })}</Button>
                                            </div>
                                    )})}
                                </div>
                            </div>
                        }

                        {/* Add CN */}

                        <div style={Object.assign({}, Style.flexRow, {flexWrap:'wrap', flex:0, marginTop:10 })}>
                            { (itemCN.itemObject.customNotes).map((item,index) => {  return( <Button onClick={() => this.handleChangeOrder('addCustomNote', titleCN, itemCN, indexCN, item ) } style={Object.assign({}, Style.textS, { fontWeight:'bold', backgroundColor:'black', borderRadius:99, color:'white', margin:10, padding:10, paddingLeft:20, paddingRight:20 })}>{getIcon2({ icon:'addNoCircle' })} {item}</Button> )})}
                        </div>

                    </div>
                )
            }


            // Custom Notes Boxes

            else {
                return (
                    <div style={Object.assign({}, Style.flexRow, { marginTop:15, flexWrap:'wrap', justifyContent:'left' })}>
                        { customNotesAdded.map((item2,index) => {
                            console.log('item2',item2)  
                            return( 
                                <div onClick={() => manager? ( console.log(item2), this.setState({showCustomNotes:[title,item,index]}) ):null } style={Object.assign({}, Style.coWorkSquare, { backgroundImage:item2.date?"url(" + info.logo + ")":undefined })} key={index}>
                                    { item2.date && <div style={Style.coWorkMiniIcon}>{item2.date.slice(0, -7)}</div> }
                                    { item2.customNotes && Object.entries(item2.customNotes).map((item3,index) => { item3 = { ...item3[1], key:item3[0], index:index  }
                                        return( <div style={Style.coWorkMiniIcon}>{item3.customNote.charAt(0)}</div>
                                    )})}
                                </div> 
                        )})}
                    </div>
                ) 
            }
        }

        else return null
    }

    search = ({v}) => {

        return(
            <div style={Object.assign({}, Style.flexRowOnly, { flexWrap:'wrap' })}>
                {this.state.search?<input style={Object.assign({}, Style.input, { marginTop:0, marginRight:10 })} type="text" autoComplete="none" autoCorrect="off" spellCheck="off" placeholder={getAppText2({ v, text:'Search' })+"..."} value={this.state.searchTerm} onChange={(e) => this.setState({searchTerm:e.target.value}) } />:<div />}
                {this.state.search?
                    <Button style={Object.assign({}, Style.navButton, {marginTop:5, marginBottom:5})} onClick={() => this.setState({search:false,searchTerm:""}) } >{getIcon2({icon:'close', fontSize:'2rem'})}</Button>
                    :<Button style={Object.assign({}, Style.navButton, {marginTop:5, marginBottom:5})} onClick={() => this.setState({search:true}) } >{getIcon2({icon:'search', fontSize:'2rem'})}</Button>
                }
                <Button style={Object.assign({}, Style.navButton, {marginTop:5, marginBottom:5})} onClick={() => window.location.reload() }>{getIcon2({icon:'refresh', fontSize:'2rem' })}</Button>
                <Button style={Object.assign({}, Style.navButton, {marginTop:5, marginBottom:5})} onClick={() => this.getOrderType({ switchView:true }) }>{getIcon2({icon:v.pathname.includes('archive')?'orders':'archive', fontSize:'2rem'})}</Button>
            </div>
        )
    }

    renderFilters = ({ordersFiltered, manager, v, customerOrderCount}) => {
        
        let queueDefault    = v.info.queueDefault
        let filterQueue     = v.browserConfig.filterQueue || []
        let filterTable     = this.state.filterTable || []
        let filterName      = this.state.filterName || []
        let filterDate      = this.state.filterDate
        let filterDateStart = this.state.filterDateStart
        let filterStatus    = this.state.filterStatus

        let names           = []
        let tables          = []

        // Tables
        ordersFiltered && ordersFiltered.forEach((item) => { 
            tables.push( item[1].tableName )
        })
        tables  = [ ...tables, ...filterTable ]
        tables  = [ ...new Set(tables) ].filter(i=>i).sort() // remove duplicates, removes empty vals, sort in alpha order

        // Names
        ordersFiltered && ordersFiltered.forEach((item) => { 
            if (!filterName.length || filterName.includes(item[1].custName)) names.push( item[1].custName )
        })
        names   = [ ...names, ...filterName ]
        names   = [ ...new Set(names) ].filter(i=>i).sort() // remove duplicates, removes empty vals, sort in alpha order


        
        if (!manager) return null
        else return (
            <div style={Object.assign({}, Style.flexColOnly, { })}>

                {this.search({v})}
                { notifications({props:this.props, v}) }
                {v.info.queues?
                    <div style={Object.assign({}, Style.flexRow0, {flexWrap:'wrap', maxWidth:'100%', marginTop:20 })}>
                        { Object.entries(v.info.queues).map((item,index) => {
                            return <Button key={index} style={Object.assign({}, Style.buttonBadge, Style.ellipsis, filterQueue.includes(item[0])?v.div4:null, { fontSize:'1rem', justifyContent:'flex-start' })} onClick={ () => { filterQueue.includes(item[0])? filterQueue.splice(filterQueue.indexOf(item[0]), 1):filterQueue.length<3&&filterQueue.push(item[0]); this.props.updateClient({ browserConfig:{ ...v.browserConfig, filterQueue }}); triggerSync({v}) }}>{item[1].name}{item[0]==queueDefault?" *":""}</Button>
                        })}
                    </div>
                :null}
                <div style={Object.assign({}, Style.flexRow, {flexWrap:'wrap', marginTop:10})}>{ [1,2,3,4,5,6].map((item, index) => { return <Button key={index} onClick={() => { filterStatus==item? this.setState({ filterStatus:"" }): this.setState({ filterStatus:item }) }} style={Object.assign({}, Style.smallBlackIcon, filterStatus==item?v.div4:null, {  })}>{logo2({ img:getOrderStatusSticker(item), size:'1.5rem', style:{ borderRadius:0, boxShadow:'unset' } })}</Button>})}</div>
                <div style={Object.assign({}, Style.flexRow0, {flexWrap:'wrap', maxWidth:'100%', marginTop:10 })}>
                    {/* { [ {name:'12hrs', value:0.5}, {name:'7days', value:7}, {name:'30days', value:30} ].map((item,index) => {
                        return <Button key={index} style={Object.assign({}, Style.buttonBadge, Style.ellipsis, filterDate==item.value?v.div4:null, { fontSize:'1rem', justifyContent:'flex-start' })} onClick={ () => { this.setState({ filterDate:filterDate==item.value?"":item.value }) }}>{item.name}</Button>
                    })} */}

                    <select style={Object.assign({}, Style.selectDropdown, filterDate?v.div4:null, { borderRadius:99, marginTop:0 })} value={filterDate} onChange={(e) =>{ this.setState({ filterDate:parseFloat(e.target.value) }) }}>
                    { [ ...Array(100).keys() ].map(item => { return <option style={Object.assign({}, { })} key={item} value={item}>{item?'last ' +item+ ' days':'select'}</option> })}
                    </select>                      
                </div>
                <div style={Object.assign({}, Style.flexRow0, {flexWrap:'wrap', maxWidth:'100%', marginTop:10 })}>
                    {/* { [ {name:'older than 48hrs', value:2}, {name:'older than 7days', value:7}, {name:'older than 14days', value:14}, {name:'older than 30days', value:30}, {name:'older than 50days', value:50}, {name:'older than 60days', value:60}].map((item,index) => {
                        return <Button key={index} style={Object.assign({}, Style.buttonBadge, Style.ellipsis, filterDateStart==item.value?v.div4:null, { fontSize:'1rem', justifyContent:'flex-start' })} onClick={ () => { this.setState({ filterDateStart:filterDateStart==item.value?"":item.value }) }}>{item.name}</Button>
                    })} */}

                    <select style={Object.assign({}, Style.selectDropdown, filterDateStart?v.div4:null, { borderRadius:99, marginTop:0 })} value={filterDateStart}  onChange={(e) => this.setState({ filterDateStart:parseFloat(e.target.value) }) }>
                    { [ ...Array(100).keys() ].map(item => { return <option style={Object.assign({}, { })} key={item} value={item}>{item?'older than ' +item+ ' days':'select'}</option> })}
                    </select>                      
                </div>

                <div style={Object.assign({}, Style.flexRow, {flexWrap:'wrap', maxWidth:'100%', marginTop:10 })}>
                    { tables.length>20?
                        <select style={Object.assign({}, Style.selectDropdown, { borderRadius:99, marginTop:0 })} onChange={(e) => this.setState({ filterTable:[e.target.value] }) }>
                        { [ "20+ Locations", ...tables].map(item => { return <option style={Object.assign({}, { })} key={item} value={item}>{item}</option> })}
                        </select>                      
                        :tables.map((item,index) => { return <Button key={index} style={Object.assign({}, Style.buttonBadge, Style.ellipsis, filterTable.includes(item)?v.div4:{backgroundColor:'lightgrey'}, { fontSize:'1rem', justifyContent:'flex-start' })} onClick={ () => { filterTable.includes(item)? filterTable.splice(filterTable.indexOf(item), 1):filterTable.length<3&&filterTable.push(item); this.setState({ filterTable }) }}>{item}</Button>})
                    }
                </div>
                <div style={Object.assign({}, Style.flexRow, {flexWrap:'wrap', maxWidth:'100%', marginTop:10 })}>
                    { names.length>20?
                        <select style={Object.assign({}, Style.selectDropdown, { borderRadius:99, marginTop:0 })} onChange={(e) => this.setState({ filterName:[e.target.value] }) }>
                            { [ "20+ Names", ...names].map(item => { return <option style={Object.assign({}, { })} key={item} value={item}>{item}</option> })}
                        </select>  
                        :names.map((item,index) => { return <Button key={index} style={Object.assign({}, Style.buttonBadge, Style.ellipsis, filterName.includes(item)?v.div4:{backgroundColor:'lightgrey'}, { fontSize:'1rem', justifyContent:'flex-start' })} onClick={ () => { filterName.includes(item)? filterName.splice(filterName.indexOf(item), 1):filterName.length<3&&filterName.push(item); this.setState({ filterName }) }}>{item}</Button>})
                    }
                </div>

                { isGod2({v}) ? DownloadButton({ v, data:this.state.orders, style:{ alignSelf:'center', marginTop:20} }) :null}

                {this.state.loading||filterTable.length||filterName.length||filterDate||filterStatus||filterDateStart? <Button style={Object.assign({}, Style.navButton, v.div4, { alignSelf:'center', marginTop:10 })} onClick={ () =>{ this.setState({ filterTable:[], filterName:[], filterDate:"", filterStatus:"", filterDateStart:"" }) }}>{getIcon2({icon:this.state.loading?'loading':'filterOff', fontSize:'1.6rem' })}</Button> :null}
            </div>
        )
    }

    renderFilterSummary = ({v, ordersFiltered, manager, queueTotal, queuePaid, customerOrderCount, currencySymbol, filterTable, filterName, filterDate}) => {

        let guestType               = v.guestType || {}
        let payPayPal               = guestType.payPayPal
        let payStripe               = guestType.payStripe
        let tipping                 = guestType.tipping && !payStripe && !payPayPal
        let payQr                   = guestType.payQr && queueTotal
        let customerActionButtons   = v.customer && (tipping||payQr) && (queueTotal||queuePaid)
        let summaryView             = this.state.summaryView


        if (queueTotal!==undefined && (manager || customerOrderCount>1)) return (

            <Card2 v={v} title='order summary' icon='orders' styleChildren={{padding:'1.5rem', paddingTop:0}} style={{ ...v.div, backgroundImage:'unset', ...Style.text1_2B, marginTop:20 }} >
                
                <div style={Object.assign({}, Style.flexRowOnly, { alignItems:'center', marginTop:20 })}>{getIcon2({icon:'orders', marginRight:10})}{customerOrderCount}{" "}{getAppText2({v, text:'Orders'})}</div>
                {filterTable.length?            <div style={Object.assign({}, Style.flexRowOnly, { alignItems:'center', marginTop:10, textTransform:'uppercase' })}>{getIcon2({icon:'marker', marginRight:10})}{" "}{filterTable[0]}{" "}{filterTable[1]}{" "}{filterTable[2]}</div> :null}
                {filterName.length?             <div style={Object.assign({}, Style.flexRowOnly, { alignItems:'center', marginTop:10, textTransform:'uppercase' })}>{getIcon2({icon:'user', marginRight:10})}{" "}{filterName[0]}{" "}{filterName[1]}{" "}{filterName[2]}</div> :null}
                {filterDate && manager?         <div style={Object.assign({}, Style.flexRowOnly, { alignItems:'center', marginTop:10, textTransform:'uppercase' })}>{getIcon2({icon:'calendar', marginRight:10})}{" "}{filterDate}{" "}{getAppText2({v, text:'Days'})}</div> :null}
                {v.manager||queueTotal?         <div style={Object.assign({}, Style.flexRowOnly, { alignItems:'center', marginTop:10, textTransform:'uppercase' })}>{getIcon2({icon:'unpaid', marginRight:10})}{getAppText2({v, text:'Unpaid'})}{" "}{currencySymbol}{queueTotal}</div> :null}
                {v.manager||queuePaid?          <div style={Object.assign({}, Style.flexRowOnly, { alignItems:'center', marginTop:10, textTransform:'uppercase' })}>{getIcon2({icon:'dollar', marginRight:10})}{getAppText2({v, text:'Paid'})}{" "}{currencySymbol}{queuePaid}</div> :null}

                { v.pathname2?.includes('manageorders')?
                    <div style={Object.assign({}, Style.flexRowOnly, { marginTop:15, width:'100%', color:'transparent'})}>
                        <Button2 v={v} style={{ margin:0, flex:1, padding:10, textTransform:'uppercase' }}                                                  onClick={()=> this.state.processing?this.setState({cancelProcessing:true}): this.bulkProcessing({ type:'Mark Done',  ordersFiltered, confirm:true })} loading={this.state.processing=='Mark Done'}>{getIcon2({icon:'tick'})}</Button2>
                        <Button2 v={v} style={{ margin:0, flex:1, padding:10, textTransform:'uppercase' }} contStyle={{ marginRight:10, marginLeft:10 }}    onClick={()=> this.state.processing?this.setState({cancelProcessing:true}): this.bulkProcessing({ type:'Mark Paid',  ordersFiltered, confirm:true })} loading={this.state.processing=='Mark Paid'}>{getAppText2({v, text:'Paid'})}</Button2>
                        <Button2 v={v} style={{ margin:0, flex:1, padding:10, textTransform:'uppercase' }}                                                  onClick={()=> this.state.processing?this.setState({cancelProcessing:true}): this.bulkProcessing({ type:'Archive',    ordersFiltered, confirm:true })} loading={this.state.processing=='Archive'}>{getIcon2({icon:'archive'})}</Button2>
                    </div>
                :null}

                {/* { customerActionButtons?
                    <div style={Object.assign({}, Style.flexColOnly, { marginTop:15, width:'100%', position:'relative' })}>
                        { summaryView? 
                            <div style={Style.flexColOnly}>
                                { closeButton({ v, onClick:()=>this.setState({summaryView:""}), styleCont:{position:'relative', top:'unset', right:'unset'}, icon:'back' })}
                                { summaryView=='payQr'?   
                                    renderPayQr({ v, props:this.props, showSendReceiptButton:true, uploading:this.state.rpqu, uploadingChange:(e)=>this.setState({rpqu:e}), afterPlaceOrder:()=>{this.setState({summaryView:""}); this.props.updateDialog({icon:'done', text:'Payment Receipt Sent', description:'Please wait a few minutes for acknowledgement', hideOkButton:true }) } })
                                    :renderTipButtons({ props:this.props, v, queueTotalAndPaid:queueTotal||0+queuePaid||0, showSendTipButton:true, afterPlaceOrder:()=>{this.setState({summaryView:""}); this.props.updateDialog({icon:'done', text:'Thank You', icon:'heart', description:'Your Tip Has Been Added To The Bill', hideOkButton:true }) } })}
                            </div>
                            :
                            <div style={{display:'flex', flexDirection:'row'}}>
                                {tipping?                   <Button2 v={v} style={{ margin:0, flex:1, padding:'1rem', textTransform:'uppercase', justifyContent:'space-between' }} onClick={()=> this.setState({summaryView:"tipping"}) } contStyle={{marginRight:10}}>+{getAppText2({v, text:'Tip'})}{getIcon2({icon:'smileyFace', fontSize:'1.5rem'})}</Button2> :null}
                                {payQr?                     <Button2 v={v} style={{ margin:0, flex:1, padding:'1rem', textTransform:'uppercase', justifyContent:'space-between' }} onClick={()=> this.setState({summaryView:"payQr"}) }>{getAppText2({v, text:'Pay'})}<div>{getIcon2({icon:'qr', fontSize:'1.5rem'})}</div></Button2> :null}
                            </div>
                        }
                    </div>
                :null} */}
                
            </Card2>
        )
    }

    calculateQueueTotal = (ordersFiltered) => {
        let unpaid  = 0
        let paid    = 0

        ordersFiltered.forEach((item) => {
            if ( !this.isPaid(item[1]) ) { unpaid += Number(item[1].total) }
            else                         { paid   += Number(item[1].total) }
        })
        return { unpaid:trimAmount(unpaid), paid:trimAmount(paid) } 
    }

    getTitle = (title) => {

        let credentials = title.credentials
        if      (title.name)                                return title.name
        else if (credentials && credentials["First Name"])  return credentials["First Name"]
        else if (credentials && credentials["Name"])        return credentials["Name"]
        else if (credentials && credentials["Nombre"])      return credentials["Nombre"]
        else return ""
    }

    getAddress = ({ title, v }) => {

        let location                = title.location
        let locationAddress         = location.address || ""
        let locationCoords          = location.customerLocation || ""

        return (
            <div style={Object.assign({}, { marginTop:15})}>

                { getLocationAddressFull({v, location}) }

                {v.manager?
                    <div style={Object.assign({}, Style.flexRow,{ marginTop:10, justifyContent:'flex-start', flexWrap:'wrap' })}>
                        <Button onClick={() => window.open( `https://maps.google.com/?q=${locationAddress}`)} style={Object.assign({}, Style.smallBlackIcon, { marginLeft:0 })}>{getIcon2({icon:'map', fontSize:'1rem'})}</Button>
                        { locationCoords && locationCoords.lat?
                            <Button onClick={() => window.open( `https://maps.google.com/?q=${locationCoords.lat},${locationCoords.lng}`)} style={Object.assign({}, Style.smallBlackIcon, {  })}>{getIcon2({icon:'locateGps', fontSize:'1rem'})}</Button>
                            :<div style={Object.assign({}, Style.navButtonWithText, {fontSize:'0.6rem', color:'#D50000', minHeight:'2.8rem', marginTop:0, textAlign:'center', padding:undefined })}>Customer coordinates not available. Default delivery cost may have been applied</div>
                        }
                        { renderDriverHereNotifyButton(this.props, title, ()=>this.setState({sentSMS:title.key}), this.state.sentSMS && this.state.sentSMS.includes(title.key)) }
                        { renderTukButton(title, v.info)}
                    </div>
                :null}
            </div>
        )
    }


    getOrderLocation = ({ v, order }) => {
        
        if      (!order?.guestType)                                     return ""
        else if (guestTypeHasNoLocations({ guestType:order.guestType }))    return <div style={Style.textMB}>{order.guestType.name}</div>
        else if (order.guestType?.delivery)                             return this.getAddress({v, title:order})
        else                                                            return <div style={Style.textMB}>{order.location["name_"+v.language] || order.location.name || order.tableName || order.guestType.name || "" }</div>
    }

    orderNotifications = ({ v, customerOrderCount, manager }) => {

        if (!customerOrderCount && !manager)   return renderHelper6({ v, shouldRender:true, title:this.state.newPaidOrder?'Order Received. Please Wait, Getting Order Status.':"No Orders Here", message:'If you have placed an order recently, your order will appear here shortly', link:'reload', style:{marginTop:15} })
        else return null
    }

    tooManyOrdersWarning = ({ v, orders }) => {

        let coverStyle = this.state.dismissedOW?{}:{ position:'fixed', left:0, right:0, height:'30vh', margin:'2rem', zIndex:9999999999 }

        if (v?.pathname2=='manager/manageorders' && orders && (Object.keys(orders).length>200)) return RenderHelper7({ props:{ v, icon:this.state.dismissedOW?'alert':'close', style:{backgroundColor:'#D50000', backgroundImage:'unset', ...coverStyle }, onClick:()=>{this.setState({dismissedOW:true}); handleError({ v, error:'Large Orders Node Warning', noDialog:true }) }, title:'over 200 orders detected. please archive old orders to ensure a smooth customer experience' }})
        else return null
    }


    renderOrder = ({ v, order, orderKey }) => {

        console.log('vvv 1', order)

            // order                   = { ...order?.[1], key:order?.[0]  }
        let item                    = order
        let pathArchive             = window.location.pathname.includes('archive')
        let manager                 = v.manager
        let title                   = item
        let basket                  = item.basket || {}
        let dateTimeSelected        = basket.dateTimeSelected
        let dialog                  = order.dialog
            title.guestType         = title.guestType || {}
            order.guestType         = order.guestType || {}
            order.location          = order.location || {}

            console.log('vvv 2', order)
        return (
            <Card2 v={v} id={item.key} key={item.key} hideHeader style={Object.assign({}, v.style.card, manager&&item.status==1?{ backgroundColor:'#D50000' }:null, { padding:0, marginBottom:0, marginTop:15, position:'relative', margin:'0.5rem', minWidth:300, maxWidth:500, flex:1 })} styleChildren={{padding:0}}>
                { this.redNotifyCover({v, title}) }

                {/* STATUS */}
                <div id='orderHeader' className='hidePrint' style={Object.assign({}, Style.flexCol0, v.div3, { borderBottom:v.style.bo.border, margin:0, padding:'1rem', minHeight:'8rem', borderBottomLeftRadius:0, borderBottomRightRadius:0, boxShadow:'unset' })} onClick={()=>{ log2('sss order', order) }}>
                    {this.handleIcon({v, title, size:'3.5rem', style:{ minWidth:'6rem', minHeight:'6rem', height:'6rem', } })}
                    <div style={Style.orderStatusName}>{ this.getOrderStatusText(v, title.status) }</div>
                </div>

                {item.status==0?
                    <div style={Object.assign({}, Style.flexCol, {padding:'3rem'})}>{getIcon2({ icon:'loading', color:v.tc||'black' })}</div>
                    :
                    <div id='orderBody' style={{ padding:20, ...Style.flexColOnly }}>
                        
                        {/* ORDER KEY LAST 5 */}
                        <div style={{...Style.text1_5B, textAlign:'right'}}>#{order?.key?.slice(-5)}</div>

                        {/* CUSTOMER NAME */}
                        <div style={Style.textMB}>{title.custName}</div>

                        {/* ADDRESS OR TABLENAME OR GUESTTYPE */}
                        { this.getOrderLocation({ v, order })}

                        <div style={{ marginTop:10 }} /> 

                        {/* Order Date Time */}
                        { dateTimeSelected? <div style={Object.assign({}, Style.orderItem, Style.textSSB, { })}>{getAppText2({ v, text:'Order For' })}{": "}{ formatDateTime({ dateTimeSelected:title.basket.dateTimeSelected })}</div> :null}

                        {/* Dialog */}
                        { dialog?  
                            <div style={Object.assign({}, Style.orderItem, Style.textSB, { flexDirection:'column', alignItems:'unset' })}>
                                <div key={item.index} style={Object.assign({}, Style.textSSB, {marginBottom:10})}>{dialog.text}</div>

                                { dialog.fields && dialog.fields.map((item,index) => {
                                    return <div key={index}>{item?.placeholder}: {dialog[item?.placeholder]}</div>
                                })}

                                { manager && JSON.stringify(dialog).toLowerCase().includes('reservation')?
                                    <div style={Object.assign({}, Style.flexRow, {marginRight:5, marginTop:20 })}>
                                        {/* <Button onClick={() => this.handleOrderStatus({customer:v.customer, title, changeTo:}) } style={Object.assign({}, v.button, Style.buttonTrans, { marginRight:5 })}>{getAppText2({v, text:'Reject'})}</Button> */}
                                        <Button onClick={() => { this.handleOrderStatus({ customer:v.customer, title, changeTo:5 }); sendEmailTemplate({v, template:'reservationAcceptance', dialog, order })  }} style={Object.assign({}, v.button, title.status==5?Style.buttonTrans:null, { flex:1 })}>{getAppText2({v, text:title.status==5?'Accepted':'Accept'})}</Button>
                                    </div>
                                :null}                     
                            </div> 
                        :null}

                        <Basket v={v} order={{...order, handlePaidStatus:()=>this.handlePaidStatus(title), isPaid:()=>this.isPaid(title), handleItemStatusNew:(item)=>this.handleItemStatusNew({v, title, item }) }} />

                        {v.customer? <Button3 v={v} twenty text='Reorder' icon='basket' onClick={()=> { let currentBasket=v.basket?.itemsNew||[]; let orderBasket=order.basket?.itemsNew||[];  v.updateBasket({ ...v.basket, itemsNew:[ ...currentBasket, ...orderBasket ] }); handleLink3({ v, link:'/total' }) }} style={{minHeight:'none', fontSize:'1.25rem', ...v.style.card}} /> :null}

                        {/* Notes Added By Venue */}
                        {title.notesAdded && <div style={{marginTop:10, fontStyle: 'italic', color:'#D50000', fontWeight:'bold', flex: 1, textAlign:'left'}}>Notes: {item.notesAdded}</div>}

                        {/* CUSTOMER PAYMENT RECEIPT */}
                        {title.basket?.custPayReceipt?
                            <div style={Object.assign({}, Style.flexRow, {marginTop:5})}>
                                <div style={Object.assign({}, Style.textSSB, { flex:1, textAlign:'right', marginRight:10 })}>{getAppText2({ v, text:'Customer Pay Receipt'})}</div>
                                { image2({ image:title.basket.custPayReceipt, style:{...Style.navButton, padding:0}, onClick:()=>this.props.updateDialog({ pic:title.basket.custPayReceipt }) })}
                            </div>
                        :null}

                        {/* Order Information */}
                        {this.orderInfo({item, manager, v})}

                        {/* Chat, Archive, Etc Buttons */}
                        {manager?
                            <div className='hidePrint' style={{marginTop:10}}>
                                {pathArchive?
                                    <div style={{display:'flex', flexDirection:'row'}}>
                                        <Button onClick={() => this.unArchiveOrder(title)} style={Style.smallBlackIcon}>{getIcon2({ icon:'unArchive', fontSize:'1.5rem'})}</Button>
                                        {v.role.includes('manager')? <Button onClick={() => this.deleteOrder(title) } style={Style.smallBlackIcon}>{getIcon2({ icon:'trash', fontSize:'1.5rem'})}</Button> :null}                                         
                                    </div>
                                    :
                                    <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                        <Button onClick={() => printOrders({ v, newOrdersToPrint:[order], force:true }) } style={Style.smallBlackIcon}>{getIcon2({ icon:'print', fontSize:'1.5rem'})}</Button>
                                        <Button onClick={() => this.setState({addNote:title.key}) } style={Style.smallBlackIcon}>{getIcon2({ icon:'note', fontSize:'1.5rem'})}</Button>
                                        { v.info.chatActive?<Button onClick={() => this.props.history.push(`/manager/chat?newChatCustomer=${title.customerId}`)} style={Style.smallBlackIcon}>{getIcon2({ icon:'chat', fontSize:'1.5rem'})}</Button> :null}
                                        {title.status===5? <Button onClick={() => this.handleOrderStatus({customer:v.customer, title, changeTo:1 }) }   style={Style.smallBlackIcon}>{getIcon2({ icon:'refresh', fontSize:'1.5rem'})}</Button>:null}
                                        <Button onClick={() => this.archiveOrder(title) } style={Style.smallBlackIcon}>{getIcon2({ icon:'archive', fontSize:'1.5rem'})}</Button>
                                        { renderCustomerPhone(title) }
                                    </div>
                                }
                            </div>
                            :
                            <div style={{marginTop:10}}>
                                {v.info.chatActive?<Button onClick={() => this.props.history.push(`/${v.venueUrl}/chat`)} style={Object.assign({}, Style.smallBlackIcon, v.div, {})}>{getIcon2({icon:'chat', fontSize:'1.5rem'})}</Button>:null}
                            </div>
                        }
                    </div>
                }
            </Card2>
        )
    }

	render() {

        let v                   = this.props.v
		let orderlinaStore 	    = {...this.props.orderlinaStore}
        let manager			    = v && v.manager
        let pathArchive         = window.location.pathname.includes('archive')
        let orders              = pathArchive? this.state.archive: this.state.orders
        let searchTerm          = this.state.searchTerm && this.state.searchTerm.toLowerCase()
        let queuesKeys          = v.info.queues && Object.entries(v.info.queues).map(item=> item[0])
        let queueDefault        = v.info.queueDefault||""
        let filterQueue         = v.browserConfig.filterQueue || []  
        let filterDefaultQueueSelected      = filterQueue.includes(queueDefault||"")
        let filterTable         = this.state.filterTable || []  
        let filterName          = this.state.filterName || []  
        let filterDate          = this.state.filterDate
        let filterDateStart     = this.state.filterDateStart
        let filterStatus        = this.state.filterStatus
        let ordersFiltered      = v && orders && Object.entries(orders).concat().reverse()
                                    .filter(item =>
                                        (item[1].newPaidOrder) ||
                                        (   
                                            ( !searchTerm || (item[1].custName).toLowerCase().includes(searchTerm) || (item[1].tableName).toLowerCase().includes(searchTerm)  ) &&
                                            ( 
                                                ( 
                                                    !filterQueue.length || 
                                                    (
                                                        ( JSON.stringify(item[1]).includes(filterQueue[0]) ) ||
                                                        ( JSON.stringify(item[1]).includes(filterQueue[1]) ) ||
                                                        ( JSON.stringify(item[1]).includes(filterQueue[2]) ) ||
                                                        ( filterDefaultQueueSelected && ( !item[1].basket?.itemsNew || Object.entries(item[1].basket?.itemsNew || "").map(item=> { if (!queuesKeys.includes(item[1]?.queue)) return true })[0] )) // no basket or an item has no queue
                                                    )
                                                )
                                                &&
                                                ( 
                                                    !filterTable.length || 
                                                    (
                                                        ( (item[1].tableName)==filterTable[0] ) ||
                                                        ( (item[1].tableName)==filterTable[1] ) ||
                                                        ( (item[1].tableName)==filterTable[2] )
                                                    )
                                                )
                                                &&
                                                ( 
                                                    !filterName.length || 
                                                    (
                                                        (item[1].custName)==filterName[0] ||
                                                        (item[1].custName)==filterName[1] ||
                                                        (item[1].custName)==filterName[2]
                                                    )
                                                )
                                            ) &&
                                            ( !filterStatus || item[1].status==filterStatus )
                                            &&
                                            ( !filterDate || elapsedTime(item[1].orderTime) < (filterDate*24*60) )
                                            &&
                                            ( !filterDateStart || elapsedTime(item[1].orderTime) > (filterDateStart*24*60) )
                                        )
                                        &&
                                        ( manager || (v.uids.includes(item[1].customerId)) ) 
                                    )

        let customerOrderCount  = ordersFiltered && Object.keys(ordersFiltered).length
        let queueTotalAndPaid   = ordersFiltered && this.calculateQueueTotal(ordersFiltered)
        let queueTotal          = ordersFiltered && queueTotalAndPaid && queueTotalAndPaid.unpaid
        let queuePaid           = ordersFiltered && queueTotalAndPaid && queueTotalAndPaid.paid
        
		if ( !v ||  orders===undefined ) return <Loading v={v} />
		else {
            let currencySymbol  = v.info.currencySymbol


			return (
            <div style={Style.flexRowOnly}>

                <div style={Object.assign({}, { display:'flex', flex:1, flexWrap:'wrap', boxSizing:'border-box' })}>
                    
                    {/* Admin Panel */}
                    <div style={Object.assign({}, v.customer||v.isMobile?{minWidth:'100%', maxWidth:'100%'}:{minWidth:350, maxWidth:350, ...Style.boxShadowRight, backgroundColor:Style.olgrey}, { padding:'1rem', flex:1, boxSizing:'border-box' })}>
                        { this.tooManyOrdersWarning({ v, orders }) }
                        { this.renderFilters({ordersFiltered, manager, v}) }
                        { this.renderFilterSummary({v, ordersFiltered, manager, queueTotal, queuePaid, customerOrderCount, currencySymbol, filterTable, filterName, filterDate}) }
                        { this.orderNotifications({v, customerOrderCount, manager})}
                    </div>

                    <div style={Object.assign({}, Style.flexRowOnly, { flex:1, flexWrap:'wrap', padding:'0.5rem', boxSizing:'border-box' })}>
                        { ordersFiltered && ordersFiltered.map((item,index) => { return this.renderOrder({ v, order:item[1] }) })}
                    </div>
				</div>
            </div>
            )
		}
	}

    // STATUS: 
    // 1.ORDERED        = Awaiting Acknowledgment (no ETA) 
    // 2.ACKNOWLEDGED   = 20m minus minutes since ordering  
    // 3.PREPARING      = 15m minus minutes since ordering
    // 4.DELIVERING     = 5m minus minutes since ordering
    // 5.DONE           = 0m
    // 6.ARCHIVED       = 0m

    archiveOrder = (title) => {
        
        let clientKey = this.props.orderlinaStore.lastManaged;

        // Remove from State

        let orders = {...this.state.orders}
        let orderToArchive = orders[title.key] // create a copy to use later
        delete orders[title.key];
        this.setState({orders})
        
        if (orderToArchive.completedIn===undefined) { orderToArchive.completedIn = elapsedTime( orderToArchive.orderTime ) }

        // Add to Archive

        Firebase.database().ref("archive/" + clientKey + "/orders_archived/" + title.key)
            .set(orderToArchive)
            .then(() => {

                // Removed from Orders
                Firebase.database().ref("clients/" + clientKey + "/orders/" + title.key)
                    .remove()
                    .then(() => {})
                    .catch((error) => {console.log(error)})
            })
            .catch((error) => {console.log(error)})
    }

    handlePaidStatus = (item, status) => {

        let orders = {...this.state.orders}

        if      ( !orders[item.key] )           return null
        else if (status)                        orders[item.key].paymentFlag=status
        else if (orders[item.key].paymentFlag)  orders[item.key].paymentFlag=false
        else                                    orders[item.key].paymentFlag=true

        this.setState({orders})
        this.updateFB(item.key)
    }

    handleOrderStatus = ({ customer, title, changeTo }) => {

        let     orders              = {...this.state.orders}

        if   (customer || window.location.pathname.includes('archive'))  return null

        else {
            // Set acknowledged Status
            if (orders[title.key].status===1 && orders[title.key].acknowledgedIn===undefined ) {
                orders[title.key].acknowledgedIn = elapsedTime( orders[title.key].orderTime )
            }

            // Set completedIn Time
            if (orders[title.key].status===4 && orders[title.key].completedIn===undefined ) { 
                orders[title.key].completedIn = elapsedTime( orders[title.key].orderTime )
            }

            // order status
            if (orders[title.key].status < 5) orders[title.key].status += 1

            // Item Status
            if (orders[title.key].basket?.itemsNew) {
                // NEW
                Object.entries(orders[title.key].basket.itemsNew).map( (item) => { 
                    // If ItemStatus is less than orderStatus, then make them same
                    if (item[1].status < orders[title.key].status) {
                        item[1].status = orders[title.key].status
                    }
                    //If OrderStatus has been reset to 1.Ordered, then do the same for itemStatus
                    if (orders[title.key].status === 1) {
                        item[1].status = 1
                    }
                })
            }
            else {
                // OLD
                orders[title.key].items && Object.entries(orders[title.key].items).map( (item) => { 
                    // If ItemStatus is less than orderStatus, then make them same
                    if (item[1].status < orders[title.key].status) {
                        item[1].status = orders[title.key].status
                    }
                    //If OrderStatus has been reset to 1.Ordered, then do the same for itemStatus
                    if (orders[title.key].status === 1) {
                        item[1].status = 1
                    }
                })
            }

            // Direct Status change Reservation Acceptance
            if (changeTo) orders[title.key].status = changeTo

            this.setState({orders})
            this.updateFB(title.key)
        }
    }

    updateFB = async (orderKey) => {

        let clientKey = this.props.orderlinaStore.lastManaged;

        Firebase.database().ref("clients/" + clientKey + "/orders/" + orderKey )
            .update( this.state.orders[orderKey] )
            .then(() => { })
            .catch((error) => { console.log(error) })
    }
    
    
    handleItemStatusNew = ({ v, title, item }) => {

        let     orders              = { ...this.state.orders }

        if      (v.customer || window.location.pathname.includes('archive'))      return null

        //Handle Item Status
        orders[title.key].basket.itemsNew[item.key].status >= 5 ? 
            orders[title.key].basket.itemsNew[item.key].status = 1
            : 
            orders[title.key].basket.itemsNew[item.key].status += 1

        //Raise Order Status to minimum Item status in the order
        
        let minItemStatus = 6

        Object.entries(orders[title.key].basket.itemsNew).map( (item) => { 
            // If ItemStatus is less than minItemStatus, then lower minItemStatus to item Status
            if (item[1].status < minItemStatus) {
                minItemStatus = item[1].status 
			}
        })

        if (orders[title.key].status < minItemStatus) {
            orders[title.key].status = minItemStatus
		}

        this.setState({orders})
        this.updateFB(title.key)
    }

    orderETA = (title) => {

        if (title.status===1) {
            let eta = 25-elapsedTime(title.orderTime)
            return eta>0 ? eta : 0 
        }
        else if (title.status===2) {
            let eta = 20-elapsedTime(title.orderTime)
            return eta>0 ? eta : 0 
        }
        else if (title.status===3) {
            let eta = 15-elapsedTime(title.orderTime)
            return eta>0 ? eta : 0 
        }
        else if (title.status===4) {
            let eta = 5-elapsedTime(title.orderTime)
            return eta>0 ? eta : 0 
        }
        else if (title.status>4) {
            return 0
        }
    }

    handleIcon = (p) => {
        let { v, item, size, style, title } = p

        if (item && item.itemObject && item.itemObject.customNotes) return null
        else {
            if      (item&&item.status) return <Button onClick={() => this.handleItemStatusNew({v, title, item})  } style={Object.assign({}, Style.navButton, Style.orderIcon, { ...style })}>{logo2({ img:getOrderStatusSticker(item.status),   size:size||'2rem', style:{ borderRadius:0, boxShadow:'unset' } })}</Button>
            else                        return <Button onClick={() => this.handleOrderStatus({customer:v.customer, title})          } style={Object.assign({}, Style.navButton, Style.orderIcon, { ...style })}>{logo2({ img:getOrderStatusSticker(title.status),  size:size||'2rem', style:{ borderRadius:0, boxShadow:'unset' } })}</Button>
        }
    }
    
    getOrderStatusText = (v, titleStatus) => {
       
        switch (titleStatus) {
            case (0): return getAppText2({ v, text:'Getting Order Status' })
            case (1): return getAppText2({ v, text:'Order Sent Awaiting Acknowledgement' }) 
            case (2): return getAppText2({ v, text:'Acknowledged' }) 
            case (3): return getAppText2({ v, text:'Preparing' }) 
            case (4): return getAppText2({ v, text:'Delivering' }) 
            case (5): return getAppText2({ v, text:'Done' }) 
            case (6): return getAppText2({ v, text:'Archived' }) 
            case (7): return getAppText2({ v, text:'Deleted' }) 
            default:  return getAppText2({ v, text:'No Status' }) 
        }
    }    

    deleteOrder = (title) => {

        let clientKey = this.props.orderlinaStore.lastManaged;

        // Remove from State

        let archive = {...this.state.archive}
        delete archive[title.key];
        this.setState({archive})

        // Removed from firebase

        Firebase.database().ref("archive/" + clientKey + "/orders_archived/" + title.key)
            .remove() 
            .then(() => {})
            .catch((error) => {console.log(error)})

    }

    unArchiveOrder = (title) => {
        
        let clientKey = this.props.orderlinaStore.lastManaged;

        // Remove from State
        let archive = {...this.state.archive}
        let orderToActivate = archive[title.key] // create a copy to use later
        delete archive[title.key];
        this.setState({archive})

        // Add to Orders Node
        Firebase.database().ref("clients/" + clientKey + "/orders/" + title.key)
            .set(orderToActivate)
            .then(() => {

                // Remove from firebase
                Firebase.database().ref("archive/" + clientKey + "/orders_archived/" + title.key)
                    .remove() 
                    .then(() => {console.log('archived ok deleted', this.state)})
                    .catch((error) => {console.log('archived not deleted', error, this.state)})
            })
            .catch((error) => {console.log(error)})

    }
	
}


export default MOrders

