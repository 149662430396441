import React, { Component } from 'react';

import PushId from 'pushid';
import Firebase, { auth } from '../Firebase';
import StripeFetch from '../Mini-components/StripeFetch';
import { Route, BrowserRouter as Router } from 'react-router-dom';


import Style from '../styles';
import { Button } from '@material-ui/core/';
import { ArrowForward, ArrowBack } from '@material-ui/icons/';
import { FiHome, FiSave } from 'react-icons/fi';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { MdFavorite, MdAdd } from 'react-icons/md';

import { scrollTo2, verify, getIcon2, ButtonNav, getAppText2, getVariables, isGuestTypeDelivery, checkIfVenueOpen, getCustomerDistance, placeOrder, countInstances, validCard, getAppText, getPayMethods, getVenueNameTrim, acceptPayment2, verify2, Stripe5, Button3, StripeV5, setLocation, isOrderingOn, guestTypeHasNoLocations, isGuestTypeDelivery2, oPayStripeId } from '../Mini-components/Functions'




let width = window.innerWidth
let height = window.innerHeight

class NavBar extends Component {

	constructor() {
		super();
		this.state = {
		};
	}

    componentDidMount() {
	}

    getDeliveryNavButtons(props, v) {

        let delivery				= 	v.info.delivery

        if ( v.guestType?.delivery ) { 
            let deliveryMinimumCost =   delivery?.deliveryMinimumCost
            let deliveryMaxRadius   =   delivery?.deliveryMaxRadius
            let deliveryEnfAccLoc   =   delivery?.enforceAccurateLocation
            let address             =   v.location?.address
            let customerCoords      =   v.location?.customerLocation
            let basketTotal         =   v.basket?.totalBasket
            let customerDistance    =   getCustomerDistance({ v })

            if      ( basketTotal<deliveryMinimumCost )         return <Button style={Object.assign({}, Style.navBarCont,  v.div4, { })}>{ getAppText2({v, text:'Minimum'}) } {v.info?.currencySymbol}{ deliveryMinimumCost }</Button>
            else if ( customerDistance>deliveryMaxRadius )      return <Button style={Object.assign({}, Style.navBarCont,  v.div4, { })}>{ getAppText2({v, text:'Sorry Too Far'}) }</Button>
            else if ( !address )                                return <Button style={Object.assign({}, Style.navBarCont,  v.div4, { })}>{ getAppText2({v, text:'Enter Address'}) }</Button>
            else if ( deliveryEnfAccLoc && !customerCoords )    return <Button style={Object.assign({}, Style.navBarCont,  v.div4, { })}>{ getAppText2({v, text:'Please Search For Address'}) }</Button>
        }
        else return null
    }

    render() {
        let v                   =   this.props.v

        if (!v) return null
        return(
            <div style={Object.assign({}, Style.center, { position:'fixed', bottom:0, zIndex:9999999 })}>
                { this.renderNavBar(v) }
            </div>
        )
    }

	renderNavBar(v) {

		let orderlinaStore 	    =   {...this.props.orderlinaStore}
		let clientKey 		    =   v.clientKey
        let language 		    =   v.language
        let venueName           =   v.venueUrl
        let info                =   v.info
        let pathname 		    =   v.pathname
        let basketItems 		=   v.basket && v.basket.itemsNew && v.basket.itemsNew.length 
        let tableName			=   v.tableName
        let ordering            =   v.ordering
        let appText             =   v.appText
        let delivery            =   isGuestTypeDelivery2({ v })
        let open                =   checkIfVenueOpen({ v })
        let stripePay           =   orderlinaStore.stripePay||{}
        let payments            =   info?.payments && { ...info.payments }

        // console.log('navbar', this.props, pathname,appText, orderlinaStore.loading, !orderlinaStore, !orderlinaStore[clientKey], !info )
        if      ( orderlinaStore.loading || !v || !pathname || !appText || v.manager )  return null
        
        // Welcome
        else if ( countInstances("/", pathname)===1  ) return null

        else {

            let color       =   v.bt||'white'
            let basketTotal =   v.basket?.total
            let verified    =   verify2({ v })

            if ( !open )                                                            return <Button3   v={v} style={Object.assign({}, Style.navBarCont2, {  })} styleText={{ textTransform:'uppercase' }} noIcon text='Currently Closed' />

            else if (ordering=='readOnly')                       return null
            else if ( pathname==`/${v.venueUrl}/menu` ) {
                if  ( basketItems ) {
                    if      (!ordering)                                             return <ButtonNav v={v} onClick={ () => this.props.history.push(`/${ venueName }/saved`) }    >{v.basket.itemsNew.length}<MdFavorite style={Object.assign({}, Style.navBarIcon, {color})} /></ButtonNav>
                    else if (delivery)                                              return <ButtonNav v={v} onClick={ () => this.props.history.push(`/${ venueName }/location`) } >{ getAppText2({ v, text:'Checkout' }) } {v.basket.itemsNew.length}<ArrowForward style={Object.assign({}, Style.navBarIcon, {color})} /></ButtonNav>
                    else if (guestTypeHasNoLocations({ guestType:v.guestType }))    return <ButtonNav v={v} onClick={ () => this.props.history.push(`/${ venueName }/total`) }    >{ getAppText2({ v, text:'Checkout' }) } {v.basket.itemsNew.length}<ArrowForward style={Object.assign({}, Style.navBarIcon, {color})} /></ButtonNav>
                    else                                                            return <ButtonNav v={v} onClick={ () => this.props.history.push(`/${ venueName }/location`) }><div style={{ fontSize:'1.4rem', textTransform:'uppercase'}}>{ getAppText2({v, text:'Checkout'}) } {v.basket.itemsNew.length}</div>{getIcon2({icon:'next', fontSize:'2rem', marginLeft:'3rem'})}</ButtonNav>
                }
                else return null
            }

            // else if ( !user )           return <Button style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})} onClick={() => props.history.push(`/${ venueName }/customerregister`) }>Register OR Login</Button>

            else if ( pathname==`/${v.venueUrl}/item` ) {
                return null
                // if  (ordering)          return <Button onClick={ () => this.props.var1() } style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})}><MdAdd style={Object.assign({}, Style.navBarIcon, { fontSize:'3rem' })} /></Button>
                // else                    return <Button onClick={ () => this.props.var1() } style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})}><MdFavorite style={Object.assign({}, Style.navBarIcon, { color:'white' })} /></Button>
            }

            else if ( pathname==`/${v.venueUrl}/orders` )             return <ButtonNav v={v} style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})} onClick={() => this.props.history.push(`/${ venueName }/menu`) }><MdAdd style={Object.assign({}, { fontSize:'2rem', color})} />{getAppText(this.props, 'New Order')}</ButtonNav>

            else if     ( pathname==`/${v.venueUrl}/location` ) {
                if      ( this.getDeliveryNavButtons(this.props, v) )   return this.getDeliveryNavButtons(this.props, v)
                else if ( delivery )                                    return <ButtonNav v={v} style={Object.assign({}, Style.navBarCont, { backgroundImage:v.lg, color })} onClick={() => this.props.history.push(`/${ venueName }/total`)} >{ getAppText(this.props, "Next") }<ArrowForward style={Object.assign({}, Style.navBarIcon, {color})} /></ButtonNav>
                else if ( !tableName )                                  return <Button3   v={v} style={Object.assign({}, Style.navBarCont2, { })} noIcon                     onClick={() => setLocation({ props:this.props, item:"", v })} text='Select Location' />
                else                                                    return <ButtonNav v={v} style={Object.assign({}, Style.navBarCont, { backgroundImage:v.lg, color })} onClick={() => this.props.history.push(`/${ venueName }/total`)} >{ getAppText(this.props, "Next") }<ArrowForward style={Object.assign({}, Style.navBarIcon, {color})} /></ButtonNav>
            }

            else if ( pathname==`/${v.venueUrl}/saved` ) return null
            else if ( pathname==`/${v.venueUrl}/basket` ) {
                if      ( !basketItems )                                return null
                else if ( v.basket.total < 0 )                          return <ButtonNav v={v}  style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})} onClick={() => this.props.history.push(`/${ venueName }/menu`) }><ArrowBack style={Object.assign({}, Style.navBarIcon, {color})} />{orderlinaStore.config.appText.l87[language] || orderlinaStore.config.appText.l87.english }  {v.info?.currencySymbol||""}1</ButtonNav>
                else                                                    return <ButtonNav v={v}  style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})} onClick={() => this.props.history.push(`/${ venueName }/total`)}>{ getAppText(this.props, "Next") }<ArrowForward style={Object.assign({}, Style.navBarIcon, { })} /></ButtonNav>
            }

            else if ( pathname==`/${v.venueUrl}/total` ) {

                let payMethods  =   getPayMethods({v}) 
                let payMethod   =   v.payMethod
                console.log('payMethods', payMethods, !payMethods )

                // No Items
                if      (!basketItems) return null

                // !ORDERING
                else if ( !ordering ) return <Button style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})}>{ getAppText2({ v, text:'Ordering Disabled' }) }</Button>

                // UNVERIFIED
                else if ( !verified ) return <Button style={Object.assign({}, Style.navBarCont, Style.navBarText, { backgroundImage:v.lg, color })} onClick={() => { scrollTo2('Verify', -200) }}>{getAppText2({v, text:"Enter Details"})}</Button>

                // SUBMIT - PAY BILL or CASH or CARDwith0amount 
                else if ( !payMethods || payMethod==='payAddToBill' || payMethod==='payCash'|| payMethod==='payQr' || ( payMethod==='payStripe' && basketTotal===0 ) || ( payMethod==='oPay' && basketTotal===0 ) || ( payMethod==='payPayPal' && basketTotal===0 )) return <ButtonNav v={v}  style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})} onClick={() => placeOrder({ v, props:this.props })}>{getAppText2({v, text:"Send Order"})}{getIcon2({icon:'next', fontSize:'2rem'})}</ButtonNav>
                
                // SUBMIT - PAY CARD 
                // else if (payMethod==='payStripe' || payMethod==='oPay') {
                //     if      ( payments.stripeV2 ){ return (
                //                 <div id='navBar' style={Object.assign({}, Style.navBarCont2, v.div1, { minHeight:'unset', display:'flex' })}>
                //                     {/* <AppleGooglePay {...this.props} /> */}
                //                     {/* <Button3 v={v} text="Pay" icon="openInNewTab" style={{ flex:1, margin:'0.5rem', borderRadius:4, boxShadow:'unset', height:60, minHeight:'unset' }}   onClick={()=> placeOrder({ v, props:this.props, awaitingPayment:true, payment:v.payment }) } /> */}
                //                 </div>
                //     )}
                //     else if ( (stripePay.section==="newCard" || !stripePay.section)  && !validCard(this.props) )    return <Button            style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})}>{getAppText2({ v, text:"Enter Card Details" })}</Button>
                //     else if ( stripePay.section==="savedCard" && !stripePay.selectedCard )                          return <ButtonNav v={v}   style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})} onClick={() => { }}>{getAppText2({v, text:"Select Card"})}</ButtonNav>
                //     else    return <ButtonNav v={v} style={Object.assign({}, Style.navBarCont, {backgroundImage:v.lg, color})} onClick={() => acceptPayment2({ v, props:this.props }) }>{getAppText(this.props, "Pay and Send Order")}</ButtonNav>
                // }
                else return null
            }

            else return null
        }
    }

}
export default NavBar
