import React, { Component } from 'react';
import Firebase from '../Firebase';
import Style from '../styles';



import { Button } from '@material-ui/core/';


import { getNewOrderKey, lb, toNonDecimal, trimAmount, image2, Download2, uploadButton, formatDateTime, getIcon2, getAppText2, handleError, renderHelper6, isAdvanceOrders, iconPage2, placeOrder, updatePayPalOrder, notifications, getTotal, trim, readyPage, social, iconPage, renderNotes, basketItems, locationSelected, Loading, saveUserConfig, getLanguage, getGuestType, footer, renderTotalSummary, getVenueName, getIcon, getGuestTypes, getAppText, setPayMethod, getPayMethods, seperator, getVariables, renderPayQr, oPayStripeId, Text2, log2 } from '../Mini-components/Functions'
import Stripe from './Stripe';
import Paypal from './Paypal';





let v, orderKey, paymentInfo

class PayMethods extends Component {

	constructor(props) {
        super(props);
		this.state = {
		};
	}

    componentDidMount() {

        let v               = this.props.v
        orderKey 	        = getNewOrderKey(v)	

        this.mapStateToProps()
    }

    componentDidUpdate() {

		if   (this.state.payment != JSON.stringify( this.props.v.payment)) this.mapStateToProps()
	}
    
    mapStateToProps = () => {

        v                   = this.props.v
        paymentInfo         = {
            orderKey,        
            amount          : v.basket?.total,
            amountNd        : v.basket?.totalNonDecimal,
            currency	    : v.currency?.toLowerCase() || 'usd',
            metadata	    : { clientKey:v.clientKey, orderKey },
            payment		    : v.payment || ""
        }

        // USED TO DETERMINE WHETHER COMPONENT SHOULD UPDATE 
        this.setState({ payment:JSON.stringify(v.payment) }) // USED TO COM

    }

    render() {
        let v                           =   this.props.v
        let guestType                   =   v.guestType
        let payMethods                  =   getPayMethods({v}) 
        let payMethod                   =   v.payMethod
        let payments                    =   v.info?.payments || {}
        let stylePaySelect              =   { ...Style.card, ...Style.navButtonWithTextSmall, textTransform:'uppercase', minHeight:'2rem' }

        
        if      (!payMethods)                   return <div style={Object.assign({}, Style.card, Style.flexColOnly, v.div, { padding:0 }) }><div style={Style.textSB}>{guestType?.["paymentInstructions_"+v.language] || guestType?.paymentInstructions || ""}</div></div>
        else    {
            return (
                <div style={Object.assign({}, { })}>
                    <div style={Object.assign({}, Style.flexRow, { justifyContent:'flex-start', minHeight:undefined, backgroundColor:'transparent' })}>
                        {guestType.payAddToBill && <Button onClick={()=> setPayMethod({v, props:this.props, method:'payAddToBill'})}    style={Object.assign({}, stylePaySelect, payMethod==='payAddToBill'?v.div4:v.div, {  })}>+{getAppText2({v, text:'Bill'})}</Button>}
                        {guestType.payCash      && <Button onClick={()=> setPayMethod({v, props:this.props, method:'payCash'})}         style={Object.assign({}, stylePaySelect, payMethod==='payCash'?v.div4:v.div,      {  })}>{getAppText2({v, text:'Cash'})}</Button>}
                        {guestType.oPay         && !payments?.connectedStripeAccountId &&
                                                   <Button onClick={()=> setPayMethod({v, props:this.props, method:'oPay'})}            style={Object.assign({}, stylePaySelect, payMethod==='oPay'?v.div4:v.div,         {  })}>{getIcon2({icon:v.wlc.icon||'orderlina', fontSize:'0.7rem', style:{marginRight:1} })} {getAppText2({v, text:`Pay`})}</Button>}
                        {guestType.payStripe    && payments?.connectedStripeAccountId &&
                                                   <Button onClick={()=> setPayMethod({v, props:this.props, method:'payStripe'})}       style={Object.assign({}, stylePaySelect, payMethod==='payStripe'?v.div4:v.div,    {  })}>{getIcon2({ icon:'stripe', fontSize:'1.5rem', color:payMethod==='payStripe'?'white':'rgb(99, 91, 255)' })}</Button>}
                        {guestType.payPayPal    && 
                         payments.payPalClientId&& <Button onClick={()=> setPayMethod({v, props:this.props, method:'payPayPal'})}       style={Object.assign({}, stylePaySelect, payMethod==='payPayPal'?v.div4:v.div,    {  })}>{getIcon2({ icon:'paypal', fontSize:'1.5rem', color:payMethod==='payPayPal'?'white':'rgb(0, 48, 135)' })}</Button>}
                        {guestType.payQr        && <Button onClick={()=> setPayMethod({v, props:this.props, method:'payQr'})}           style={Object.assign({}, stylePaySelect, payMethod==='payQr'?v.div4:v.div,        {  })}>{getAppText2({v, text:'QR'})}</Button>}
                    </div>
                    {this.renderPaymentOption({ v })}
                </div>
            )
        }
    }

    renderPaymentOption({v}) {

        let language                    =   v.language
        let guestType                   =   v.guestType
        let payMethod                   =   v.payMethod

        if      ( payMethod==='payAddToBill' ) return <div style={Object.assign({}, Style.textXSB, {marginTop:20, textAlign:'center'})}>{guestType["payAddToBillInstructions_"+language] || guestType.payAddToBillInstructions}</div>
        else if ( payMethod==='payCash'      ) return <div style={Object.assign({}, Style.textXSB, {marginTop:20, textAlign:'center'})}>{guestType["payCashInstructions_"+language] || guestType.payCashInstructions}</div>
        else if ( payMethod==='payQr'        ) return renderPayQr({ v, uploading:this.state.rpqu, uploadingChange:(e)=>this.setState({rpqu:e}) })
        else if ( payMethod==='payStripe'    ) return this.renderPayStripe({v})
        else if ( payMethod==='oPay'         ) return this.renderPayStripe({v})
        else if ( payMethod==='payPayPal'    ) return this.renderPayPayPal({v})
        else return null
    }

    renderPayStripe({v}) {

        let language            =   v.language
        let payments            =   v.info.payments

        if (v.connectedStripeAccountId) {
            return (
                <div style={{...Style.flexColOnly, marginTop:20}}>
                    {payments.instructions && <div style={Object.assign({}, Style.textXSB, {textAlign:'center'})}>{payments["instructions_"+language] || payments.instructions}</div>}

                    <div style={{marginTop:20}} />
                    <Stripe {...this.props} {...paymentInfo} />
                </div>
            )
        }
        else return <div style={Object.assign({}, Style.textXSB, {marginTop:20, textAlign:'center'})}>Please Configure Card Payments under Manager &gt; Payments</div>
    }


    renderPayPayPal({v}) {

        // let localProps          =   this.props
        let language            =   v.language
        let guestType           =   v.guestType
        let payments            =   v.info.payments
        // let initialOptions      =   {
        //                                 "client-id": payments.payPalClientId,
        //                                 "currency": v.info.currency,
        //                                 "intent": "capture"
        //                             }
    
        if (guestType.payPayPal) {
            return (
                <div style={Object.assign({}, Style.flexColOnly, {marginTop:20})}>
                    
                    { payments.payPalClientId
                        ?   <div style={Style.flexColOnly}>
                                <Text2 v={v} style={{...Style.textXSB, alignSelf:'center', marginBottom:10, textAlign:'center' }} text={payments["payPalInstructions_"+language] || payments.payPalInstructions} /> 
                                <Paypal {...this.props} {...paymentInfo} />
                            </div>
                        :   <Text2 v={v} style={{...Style.textXSB, alignSelf:'center' }} text="Please configure Paypal Payments under PAYMENTS, PAYPAL" /> }
    
                    {/* <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons  style={{ color: "blue", shape: "pill", label: "pay", height: 40, width:'100%' }}
                            createOrder= {(data, actions) => {
                                return placeOrder({ v, props: localProps, awaitingPayment:true, payment:v.payment  })
                                    .then((order) => {
                                        return order.id
                                    })                        
                            }}
    
                            onApprove={(data, actions) => {
                                return actions.order.capture().then(function(orderData) {
                                    // Successful capture! For demo purposes:
                                    console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                                    var transaction = orderData.purchase_units[0].payments.captures[0];
                                    // alert('Transaction '+ transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');
                                    
                                    updatePayPalOrder({ v, props: localProps, paymentResponse: orderData})
                                    // Replace the above to show a success message within this page, e.g.
                                    // const element = document.getElementById('paypal-button-container');
                                    // element.innerHTML = '';
                                    // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                                    // Or go to another URL:  actions.redirect('thank_you.html');
                                })
                            }}
                        />
                    </PayPalScriptProvider> */}
                </div>
            )
        }
        else return null
    }



}
export default PayMethods
