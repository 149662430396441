
import Firebase, { auth, provider } from '../Firebase';
import React, { Component } from 'react';

import { Card, Button } from '@material-ui/core/';
import { KeyboardArrowDown, Menu, FileCopy, Delete, ArrowBack, RestaurantMenu } from '@material-ui/icons/';
import { FiSettings } from 'react-icons/fi';
import { TiThMenuOutline } from 'react-icons/ti';

import Style from '../styles';
import { syncUserConfig, handleLink3, Text2, seperator2, Button3, Input2, handleError, headerHome, appTitle, PromoBanner, getVariables, dialog2, getLinearGradient, headerPattern, getPrimaryColor, getIcon2, smallWhiteLogo, Loading, orderlinaLogo, logo, footer, isManager, guestUser, readyPage, updateUserFirebase, getIcon, sendEmail, renderBackgroundBubbles, getAppText, supportEmail, domain, domainShort, getLanguage, getWhiteLabelConfig, sendEmail2, dialog, getUser, getUserEmail, getAppText2, trim2, trimSpaces, randomNumber } from '../Mini-components/Functions'
import Button2 from './../Mini-components/Button2'
import LoginRegisterBox from './LoginRegisterBox'
import Page from '../client';
import { SignInTW } from '../components/'

import { CheckCircleIcon } from '@heroicons/react/20/solid'


class MLogin extends Component {

	constructor() {
		super();
		this.state = {
		};
	}

	componentDidMount() {
		// let v = this.props.v
		// readyPage({ props:this.props, v })	
		this.mapStateToProps()

		// setTimeout(() => { this.setState({ lb:"" }) }, 5000); 
	}


	componentDidUpdate(prevProps) {

		if (JSON.stringify(prevProps.v.pathname) != JSON.stringify(this.props.v.pathname)) {
			this.mapStateToProps()
        }
	}

	mapStateToProps = () => {
		console.log('vv updated 2')
		let v = this.props.v

		let fullUrl = window.location.href

		if 		( fullUrl.includes("login")) 		 	 { this.setState({ pageFocus:'login' }) }		
		else if ( fullUrl.includes("register")) 		 { this.setState({ pageFocus:'register' }) }		
		else if ( fullUrl==="/") 			    		 { this.setState({ pageFocus:'register' }) }		

		if (v.isDev) { let rn = randomNumber(5); this.setState({ email:`test${rn}@u.com`, name:`Test ${rn}`, password:`0000000`, passwordRepeat:`0000000` }) }
	}

	// tryingToResolveLoginIssue = () => {
	//   if (getUser(this.props)) this.props.updateDialog({text:'continue as:', description:`${getUserEmail(this.props)}`, link:()=>this.props.history.push("/manager/venues"), icon:'user', onlyOnPage:'login' })
	// }

	render() {

		let v						= this.props.v
		let styleProps				= this.props.style||{}
		let orderlinaStore			= this.props.orderlinaStore||{}
		let pc 						= v.pc


		if 			( this.state.loading || orderlinaStore.loading || !v ) 	return <Loading />
		else if 	( v.wlc.loginRegisterPageKey ) 							return <Page {...this.props} pageKey={v.wlc.loginRegisterPageKey} />
		else {
			let wlc					= v.wlc
			let manager 			= v.manager
			let pageFocus			= this.state.pageFocus
	
			return (
				<div style={{}}>

					<PromoBanner v={ v } />

					<div className="flex min-h-full flex-1">
						<div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
						<div className="mx-auto w-full max-w-sm lg:w-96">
							<div>
							<img
								className="h-10 w-auto"
								src={v.wlc.logo}
								alt={v.wlc.businessName}
							/>
							<h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900" >
								{ pageFocus=='register'?`${v.wlc.businessName} Sign Up`:pageFocus=='forgot'?'Forgot':'Sign in to your account' }
							</h2>
							{pageFocus=='login' || !pageFocus?
								<p className="mt-2 text-sm leading-6 text-gray-500">
									Not a member?{'  '}
									<a onClick={()=> this.setState({pageFocus:'register'})} style={{color:pc}} className="font-semibold text-indigo-600 hover:text-indigo-500">
									Sign Up
									</a>
								</p>
							:null}

							</div>

							<div className="mt-10">
							<div>


								{this.socialLogins({ v })}



								<form action="#" method="POST" className="space-y-6">

								{pageFocus=='register'?
									<div>
										<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
										Name
										</label>
										<div className="mt-2">
										<input
											onChange={e=> this.setState({name:e.target.value})}
											id="name"
											name="name"
											type="text"
											autoComplete="name"
											value={this.state.name}
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										/>
										</div>
									</div>
								:null}


								<div>
									<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
									Email address
									</label>
									<div className="mt-2">
									<input
										onChange={e=> this.setState({email:e.target.value})}
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										value={this.state.email}
										required
										className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
									</div>
								</div>

								

								{pageFocus=='register' || pageFocus=='login' || !pageFocus ?
									<div>
										<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
										Password
										</label>
										<div className="mt-2">
										<input
											onChange={e=> this.setState({password:e.target.value})}
											id="password"
											name="password"
											type="password"
											autoComplete="current-password"
											required
											value={this.state.password}
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										/>
										</div>
									</div>
								:null}
								
								{pageFocus=='register'?
									<div>
										<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
										Password Repeat
										</label>
										<div className="mt-2">
										<input
											onChange={e=> this.setState({passwordRepeat:e.target.value})}
											id="password"
											name="password"
											type="password"
											autoComplete="current-password"
											required
											value={this.state.passwordRepeat}
											className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
										/>
										</div>
									</div>
								:null}


								<div>
									<button
										onClick={()=> { this.handleChange({ type:pageFocus=='register'?'register':pageFocus=='forgot'?'forgot':'login' })} }
										type="button"
										className="w-full justify-center inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
										style={{backgroundColor:pc}}
									>
										{pageFocus=='register'?'Register':pageFocus=='forgot'?'Recover Password':'Sign in'}

										{getIcon2({icon:this.state.lb?'loading':'', fontSize:'1rem', color:v.div4.color||'white' })}
										{/* <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" /> */}
									</button>
								</div>

      
	  

								</form>

								<div className="flex items-center justify-between" style={{ marginTop:20 }}>
									<div className="text-sm leading-6">
										<a onClick={()=> this.setState({ pageFocus:'' })} style={{ color:pc }} className="font-semibold text-indigo-600 hover:text-indigo-500">
											Login
										</a>
									</div>
									<div className="text-sm leading-6">
										<a onClick={()=> this.setState({ pageFocus:'register' })} style={{ color:pc }} className="font-semibold text-indigo-600 hover:text-indigo-500">
											Register
										</a>
									</div>

									<div className="text-sm leading-6">
										<a onClick={()=> this.setState({ pageFocus:'forgot' })} style={{ color:pc }} className="font-semibold text-indigo-600 hover:text-indigo-500">
											Forgot password?
										</a>
									</div>
								</div>



							</div>


							</div>
						</div>
						</div>
						<div className="relative hidden w-0 flex-1 lg:block">
						<img
							className="absolute inset-0 h-full w-full object-cover"
							src="https://firebasestorage.googleapis.com/v0/b/olhf-multi-region/o/manualUploads%2Folsmilingbarista2.png?alt=media&token=b1c45c68-e41f-4682-9843-bf3b01ec5f84"
							alt=""
						/>
						</div>
					</div>

				</div>
			);
		}
	}

	updateEmailAndPassword =() => {
		
		let email 		= this.state.email
		let password 	= this.state.password
		let user 		= Firebase.auth().currentUser;
		let credential 	= Firebase.auth.EmailAuthProvider.credential( user.email, 'pass@123')
		
		user.reauthenticateWithCredential(credential)
			.then((user) => { 
				user.user.updateEmail(email)
					.then(() => { console.log('Updated - Email'); this.updatePassword() })
					.catch((error) => { console.log('Error - Email not updated', error); this.setState({ loading:false, error:error.message }) })					
			})
			.catch((error) => {console.log('User NOT Reauthentiated', error); this.setState({ loading:false, error:error.message }) })
	}

	updatePassword =() => {
		
		let email 		= this.state.email
		let password 	= this.state.password
		let user 		= Firebase.auth().currentUser;
		let credential 	= Firebase.auth.EmailAuthProvider.credential( user.email, 'pass@123')

		user.reauthenticateWithCredential(credential)
			.then((user) => { 
				user.user.updatePassword(password)
					.then(() => { console.log('Updated - Password'); this.props.history.goBack() })
					.catch((error) => { console.log('Error - Password not updated', error); this.setState({ loading:false, error:error.message }) })	
			})
			.catch((error) => {console.log('User NOT Reauthentiated', error); this.setState({ loading:false, error:error.message }) })
	}



	updateEmailAndPassword =() => {
		
		let email 		= this.state.email
		let password 	= this.state.password
		let user 		= Firebase.auth().currentUser;
		let credential 	= Firebase.auth.EmailAuthProvider.credential( user.email, 'pass@123')
		
		user.reauthenticateWithCredential(credential)
			.then((user) => { 
				user.user.updateEmail(email)
					.then(() => { console.log('Updated - Email'); this.updatePassword() })
					.catch((error) => { console.log('Error - Email not updated', error); this.setState({ loading:false, error:error.message }) })					
			})
			.catch((error) => {console.log('User NOT Reauthentiated', error); this.setState({ loading:false, error:error.message }) })
	}

	updatePassword =() => {
		
		let email 		= this.state.email
		let password 	= this.state.password
		let user 		= Firebase.auth().currentUser;
		let credential 	= Firebase.auth.EmailAuthProvider.credential( user.email, 'pass@123')

		user.reauthenticateWithCredential(credential)
			.then((user) => { 
				user.user.updatePassword(password)
					.then(() => { console.log('Updated - Password'); this.props.history.goBack() })
					.catch((error) => { console.log('Error - Password not updated', error); this.setState({ loading:false, error:error.message }) })	
			})
			.catch((error) => {console.log('User NOT Reauthentiated', error); this.setState({ loading:false, error:error.message }) })
	}

    handleChange = ({ type }) => {
		
		let v				= this.props.v
		let name 			= this.state.name
		let email 			= trimSpaces(this.state.email) || this.state.email
		let password 		= this.state.password
		let passwordRepeat 	= this.state.passwordRepeat
		let whiteLabelConfig= v?.wlc

		this.setState({ lb:type })

		if (type=='register') {

			if (name && email && password && (password===passwordRepeat)) {

				let createUser = Firebase.functions().httpsCallable('createUserNEP')
				createUser({displayName:name, email, password})
					.then((result) => { 
						console.log(result.data)
						if 		(result.data.success) 	{ 
							this.setState({newSignUp:true})
							this.handleChange({ type:'login' }); 
							sendEmail2( 
								whiteLabelConfig, 
								{ recipientEmail:email, recipientBCC:[ whiteLabelConfig.supportEmail ] }, 
								{ subject:`Welcome to ${whiteLabelConfig.businessName}`, title:`Thanks For Registering With ${whiteLabelConfig.businessName}`, 
									text:   [	
												`Congratulations on Joining the World's Fastest Growing Digital Hospitality Solution! Here is a link to the Manager portal where you can manage your account and much more.`, 
												{button:true, link:`https://${domain}/manager`, title:'Manager'},
												`At ${whiteLabelConfig.businessName}, customer service is our first priority so please don't hesitate to message us for support. You can contact us through our website, the Manager portal, or simply by replying to this email.`, 
											] 
								}
							)
						}
						else {
							if (result.data?.error=='The email address is already in use by another account.') {
								handleError({ v, noDialog:true, error:result.data, message:result.data.error, stopStateLoading:()=>this.setState({ lb:false }) }) 
								v.updateDialog({ v, description:'The email address is already registered. Do you want to login?', buttonText:'Login', link:()=>{ this.setState({pageFocus:'login'}); this.handleChange({ type:'login' }) } })
							}
							else handleError({ v,  props:this.props, error:result.data, message:result.data.error, stopStateLoading:()=>this.setState({ lb:false }) }) 
						}
					})
					.catch((error) =>  handleError({ v, props:this.props, error, message:error.message, stopStateLoading:()=>this.setState({ lb:false }) }) )
			}
			else {  handleError({ v,  props:this.props, message:"Please Check Your Details", stopStateLoading:()=>this.setState({ lb:false }) }) }
		}
		else if (type=='forgot') {
			if (email)	{
				Firebase.auth().sendPasswordResetEmail(email)
					.then((user) => {
						v.updateDialog({text:'Sent', description:'Please check you email inbox for password reset instructions', icon:'user', hideOkButton:true })
						this.setState({ lb:false })
					})
					.catch((error)=> { handleError({ v,  error, props:this.props }); this.setState({ lb:false }) })
			}
			else { handleError({ v,  props:this.props, message:"Please Enter the Email you Registered With", stopStateLoading:()=>this.setState({ lb:false }) }) }
		}
	
		else if (type=='login') {

			if (email && password) {
				Firebase.auth().signInWithEmailAndPassword(email, password)
					.then((resp) => 	{ syncUserConfig({ v, uid:resp.user.uid }); setTimeout(()=>{ this.props.history.push(`/manager/venues${this.state.newSignUp?'newsignup':''}`) },3000) })
					.catch((error)=> 	handleError({ v,  props:this.props, error, message:error.message, stopStateLoading:()=>this.setState({ lb:false }) }) )
			}
			else handleError({ v,  props:this.props, message:"Please Enter Email and Password", stopStateLoading:()=>this.setState({ lb:false }) })
		}
		else this.setState({ lb:false })
	}
	socialLoginFunction = ({v, provider, redirect}) => {
				

		let signInType = 	redirect
								?	Firebase.auth().signInWithRedirect(provider)
				  				:	Firebase.auth().signInWithPopup(provider)

		signInType.then((result) => {
			console.log('Login Result', result, v)
			var credential = result.credential;
			// This gives you a Google Access Token. You can use it to access the Google API.
			var token = credential.accessToken;
			// The signed-in user info.
			var user = result.user;
			if (user) handleLink3({ v, link:'/manager/venues' })
			// IdP data available in result.additionalUserInfo.profile.
			// ...
		})
		.catch((error) =>{
			if (error?.message?.includes('Unable to establish a connection with the popup')) this.socialLoginFunction({v, provider, redirect:true})
			else	handleError({ v, message:error.message, error }) 
		})
	} 


	
	socialLogins = ({v}) => {

		if (v.wlc.socialLogins && this.state.pageFocus!='forgot') return (


			<div className="mt-10">

				<div className="relative flex justify-center text-sm font-medium leading-6">
					<span className="px-6 text-gray-900">Continue with</span>
				</div>

				{this.googleLogin({ v })}
				{this.facebookLogin({ v })}

				<div className="relative" style={{ marginTop:40, marginBottom:20 }}>
					<div className="absolute inset-0 flex items-center" aria-hidden="true">
						<div className="w-full border-t border-gray-200" />
					</div>
					<div className="relative flex justify-center text-sm font-medium leading-6">
						<span className="px-6 text-gray-900">{`Or With Email:`}</span>
					</div>
				</div>
			</div>
		)
	}

	googleLogin = ({ v }) => {

		return (
			<button
				type="button"
				onClick={()=>{ this.socialLoginFunction({ v, provider }) }}
				className="w-full justify-center inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
				style={{backgroundColor:'white', border:'1px solid lightgrey', color:'black', marginTop:10 }}
			>
				{getIcon2({ icon:'google'  })}
				Google
			</button>
		)
	}

	facebookLogin = ({ v }) => {

		var provider = new Firebase.auth.FacebookAuthProvider();
		return (
			<button
				type="button"
				onClick={()=>{ this.socialLoginFunction({ v, provider }) }}
				className="w-full justify-center inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
				style={{backgroundColor:'rgb(33, 107, 229)', color:'white', marginTop:10 }}
			>
				{getIcon2({ icon:'facebook', color:'white' })}
				Facebook
			</button>
		)
	}


	renderLoginLogout = ({ v }) => {

		let loginInputStyle			=	{ }
		let buttonStyle				= 	{ margin:0, flex:1, fontWeight:'bold', padding:'1.5rem', fontSize:'1.5rem', justifyContent:'space-between' }
		let userInfo				= 	v.userInfo.email || v.userInfo.name
		let pageFocus				= 	this.state.pageFocus
		let focusButtonStyle		=	Object.assign({}, Style.textSB, { color:v.pc, fontFamily:'inherit' })
		let contStyle 				= 	{ marginTop:15, borderRadius:10 }

		let forgot =
			<div style={Object.assign({}, Style.flexColOnly, { })} >
				<Input2 v={v} setState={(e)=>this.setState(e)} icon='email'  inputFocus={this.state.inputFocus} 	type="email" 		instructions={'Email'} 						placeholder={'Email'} 						value={this.state.email} 			onChange={(e) => this.setState({email: e.target.value, error:""}) }  		styleCont={ Object.assign({}, { marginTop:15 })} styleInput={Object.assign({}, loginInputStyle, { })}  />
				<Button2 v={v} style={buttonStyle} contStyle={contStyle}  onClick={() => this.handleChange({ type:'forgot' }) }>{getAppText2({v, text:'Get Password'})}{getIcon2({ icon:'send' })}</Button2>
			</div>

		let register = 
			<div style={Object.assign({}, Style.flexColOnly, { })} >
				{this.socialLogins({v, buttonStyle, contStyle})}
				<Input2 v={v} setState={(e)=>this.setState(e)} icon='user' 	 inputFocus={this.state.inputFocus} 	type="name" 		instructions={'Your Name'} 					placeholder={'Your Name'} 					value={this.state.name} 			onChange={(e) => this.setState({name: 			e.target.value, error:""}) } styleCont={ Object.assign({}, { marginTop:15 })} styleInput={Object.assign({}, loginInputStyle, { })}  />
				<Input2 v={v} setState={(e)=>this.setState(e)} icon='email'  inputFocus={this.state.inputFocus} 	type="email" 		instructions={'Email'} 						placeholder={'Email'}  						value={this.state.email} 			onChange={(e) => this.setState({email: 			e.target.value, error:""}) } styleCont={ Object.assign({}, { marginTop:15 })} styleInput={Object.assign({}, loginInputStyle, { })}  />
				<Input2 v={v} setState={(e)=>this.setState(e)} icon='shield' inputFocus={this.state.inputFocus} 	type="password" 	instructions={'Password'} 					placeholder={'Password'} 					value={this.state.password} 		onChange={(e) => this.setState({password: 	 	e.target.value, error:""}) } styleCont={ Object.assign({}, { marginTop:15 })} styleInput={Object.assign({}, loginInputStyle, { })}  InputType='input' />
				<Input2 v={v} setState={(e)=>this.setState(e)} icon='shield' inputFocus={this.state.inputFocus} 	type="password" 	instructions={'Enter Your Password Again'} 	placeholder={'Repeat Password'}  			value={this.state.passwordRepeat} 	onChange={(e) => this.setState({passwordRepeat: e.target.value, error:""}) } styleCont={ Object.assign({}, { marginTop:15 })} styleInput={Object.assign({}, loginInputStyle, { })}  InputType='input'   onKeyDown={(e) => { if (e.key=='Enter') this.handleChange({ type:'register' }) }}/>
				<Button2 v={v} style={buttonStyle} contStyle={contStyle} onClick={()=> this.handleChange({ type:'register' })} loading={this.state.lb}>{ getAppText2({v, text:'Register'})} {getIcon2({ icon:'send' })}</Button2>
			</div>

		let login = 
			<div style={Object.assign({}, Style.flexColOnly, { })} >
				{this.socialLogins({v, buttonStyle, contStyle})}
				<Input2 v={v} setState={(e)=>this.setState(e)} icon='email'  inputFocus={this.state.inputFocus} 	type="email"  		instructions={'Email'} 						placeholder={'Email'}						value={this.state.email} 			onChange={(e) => this.setState({email: e.target.value, error:""}) }    		styleCont={ Object.assign({}, { marginTop:15 })} styleInput={Object.assign({}, loginInputStyle, { })}  />
				<Input2 v={v} setState={(e)=>this.setState(e)} icon='shield' inputFocus={this.state.inputFocus} 	type="password" 	instructions={'Password'} 					placeholder={'Password'} 	 				value={this.state.password} 		onChange={(e) => this.setState({password: e.target.value, error:""}) } 		styleCont={ Object.assign({}, { marginTop:15 })} styleInput={Object.assign({}, loginInputStyle, { })}  onKeyDown={(e) => { if (e.key=='Enter') this.handleChange({ type:'login' }) }} InputType='input' />
				<Button2 v={v} style={buttonStyle} contStyle={contStyle} loading={this.state.lb=='login'} onClick={() => this.handleChange({ type:'login' }) }>{ getAppText2({v, text:'login'})} {getIcon2({ icon:'send' })}</Button2>
			</div>

		return (
			<div style={Object.assign({}, Style.flexColOnly, Style.borderRadius, Style.boxShadow, v.div, { alignSelf:'center', zIndex:9, marginTop:20, maxWidth:400, width:'100%', backgroundColor:'#eae7e7' })} >

				<div style={Object.assign({}, Style.flexColOnly, v.div4, { padding:'1rem', borderTopRightRadius:'inherit', borderTopLeftRadius:'inherit' })}>
					{userInfo? <Button3 noIcon sticker={'done'} stickerSize={2} v={v} style={{ ...Style.text1B, margin:'0.5rem' }} onClick={()=> this.props.history.push("/manager/venues") } text={ getAppText2({v, text:'Continue As'})+" "+userInfo+ " →"} /> :null}
					<h1 style={Object.assign({}, Style.flexRow0, Style.h1, { color:'white', fontSize:'2rem', padding:'1rem', textTransform:'capitalize' })}>{getAppText2({v, text:this.state.pageFocus||"login" })} {getIcon2({ icon:this.state.lb?'loading':'user', color:v?.bt||'white', fontSize:'1.8rem', marginLeft:10 })}</h1>
				</div>

				<div style={Object.assign({}, Style.flexColOnly, { padding:'1.5rem', paddingTop:'1rem' })}>
					{ pageFocus=='register'?	register:
					  pageFocus=='forgot'?   	forgot:
												login }
				</div>

				<div style={Object.assign({}, Style.flexRowSP, { padding:'1.5rem', paddingTop:0, flexWrap:'wrap' })}>
					<Button onClick={() => this.setState({ pageFocus:'', 			error:"" }) }  	style={focusButtonStyle}>{getAppText2({ v, text:'Login' })}</Button>
					<Button onClick={() => this.setState({ pageFocus:'register', 	error:"" }) } 	style={focusButtonStyle}>{getAppText2({ v, text:'Register'})}</Button>
					<Button onClick={() => this.setState({ pageFocus:'forgot', 		error:"" }) } 	style={focusButtonStyle}>{getAppText2({ v, text:'Forgot' })}</Button>
				</div>
			</div>
		)
	}



}
export default MLogin
