
import Firebase from '../Firebase';
import React, { Component } from 'react';

import Dialog from '../Mini-components/Dialog'
import { Button } from '@material-ui/core/';
import Style from '../styles';

import { AppBar, LinearProgress, Snackbar } from '@material-ui/core/';
import { ArrowForward, Close, ArrowBack, MenuOpen } from '@material-ui/icons/';
import { MdFavorite } from 'react-icons/md';
import { handleError, correctClient, Loading, goBack, pixelStart, pixelPageView, getIcon2, languageButtons, getVariables, logo2, headerPattern, trim, firstLetterUpperCase, getClient, nav, getClientKey, footer, isManager, getIcon, updateVersion, getAppText, domain, getVenueTitle, getWhiteLabelConfig, setLocation, getAppText2, isManagerNewOrder, searchParams, renderNavButton, log2, RenderBadgeL, zoomButtons, timer, timer2, setZoom, setStyle, renderLogo3, Text2, setGuestType3, trimLastSlash, getLocalStorageSize, getConnectedVenue, Toast2, getLocalStorageDetails, navButtonMini, renderHamburger, syncUserConfig, printOrders, cloudFunction, updateData, orders2Alerts } from '../Mini-components/Functions'


import whoosh from '../../src/assets/whoosh.mp3';
import {Howl, Howler} from 'howler';
import { HeaderTW } from '.';
import Advizir from '../advizir/Advizir';

class Header extends Component {

	constructor() {
		super();
		this.state = {
		};
	}

	componentDidMount() {
		let v = this.props.v
		setStyle({ v })
		this.saveTmpUid()
		this.isSoundUnlocked()
		this.getUrlSearchParams()
		this.checkInternet()
		this.startFacebookPixel()
		getConnectedVenue({ v })
		syncUserConfig({ v })
		orders2Alerts({ v }) // V2 ORDERS, CHATS, TASKS, OTHER

		window.onerror = function (errorMsg, url, lineNumber, column, errorObj) { handleError({ v, error:errorMsg, noDialog:true, errorMsg, url, lineNumber, column, errorObj }) } // EMAIL IF ANY ERROR OCCURS ANYWHERE
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.v) != JSON.stringify(this.props.v)) {
			// let v = this.props.v
			console.log('gg HEADER NOTIFY')
			this.checkIfNotifyNeeded()
		}
	}


	getUrlSearchParams = () => {

		let v				= this.props.v
		let sp				= v.searchParams
		let demo			= v.href.includes('//demo') || v.href.includes('localhost') || v.href.includes('lantara') || v.href.includes('marcelas')
		let loc   			= sp.get("loc")
		let room   			= sp.get("room")
		// let table   		= sp.get("table")
		let guestTypeKey 	= sp.get("guetyp") || (!v.guestTypeKey && v.info?.guestTypeDefault)
		let dialog   		= sp.get("dialog")
		let language   		= sp.get("language") || sp.get("lang")
		let currency   		= sp.get("currency")
		let credentialsSP   = sp.get("credentials")
			credentialsSP   = credentialsSP && JSON.parse(credentialsSP) || {}
		let credentials 	= v.userConfigVenue?.credentials || {}
		let type			= sp.get("type")
		let code 			= sp.get("code")
		
		console.log('xx', guestTypeKey, sp.get("guetyp") || !v.guestType && v.info?.guestTypeDefault)



		// CREDENTIALS
		if (demo) {		if (!credentials.name) 		credentials.name 			= "Judith"
						if (!credentials.room) 		credentials.room 			= "202"
						if (!credentials.location) 	credentials.location 		= v.href.includes('marcelas')?"Table 2":"202" 	}
		if (room) {									credentials.room			= room
													credentials.location		= room		}
		if (loc) {									credentials.location		= loc		}
		credentials = { ...credentials, ...credentialsSP }

		// !! this needs to be more specific update
		setTimeout(() => { v.updateUserConfigVenue({ credentials }) }, 1000);


		// LOCATION
		if (loc) 				setLocation({ v, locationKey:loc, guestTypeKey })


		// GUESTTYPEKEY
		if (guestTypeKey)		setGuestType3({ v, guestTypeKey })


		// LANGUAGE
		if (language) this.props.updateSimple({ language:language||"" } )


		// DIALOG Format should be: https://hotefy.com/manager/login?dialog={"icon":"success","text":"thank%20you%20for%20subscribing","description":"we%20have%20received%20your%20payment%20and%20your%20venue%20will%20be%20upgraded%20to%20the%20new%20plan%20features%20within%2024%20hours.%20if%20you%20have%20any%20questions%20please%20contact%20support@hotefy.com",%20"hideOkButton":true}
		if (dialog) v.updateDialog(JSON.parse(dialog))

		// LOYVERSE
		if (v.pathname.toLowerCase().includes('loyverse')) v.updateSimple({ browserConfigGlobal:{ ...v.browserConfigGlobal, loyverseUser:true, afterCreateVenueNavLink:'/manager/integrations?type=loyverse' }} )
		if (type=='loyverse' && code) 						getConnectedVenue({ v, type:'loyverseGetToken' }) // USE OAUTH CODE TO GET A TOKEN

		// USER CONFIG - CURRENCY DEMO
		if (currency) v.updateUserConfigVenue({ currency:currency.toUpperCase() } )

		// CLOUDBEDS - INITIAL SETUP TO GET REFRESH_TOKEN
		if (sp.get("code")) {	
			cloudFunction({ name:'cloudbeds', 
				params:{ type:'getRefreshToken', getRefreshToken:{ redirectUri:`${v.domainHttps.replace('http://', 'https://')}/manager/integrations?type=cloudbeds`, code:v.searchParams.get('code')}  },
				then:(data)=>{ 
					let refresh_token = data?.data?.data?.refresh_token; 
					console.log('cloudbedsRefreshToken', refresh_token); 
					if (refresh_token) {
						updateData({ v, cloudbeds:{refreshToken:refresh_token} });
						cloudFunction({ name:'cloudbeds',  params:{ type:'saveCbClientSearch', refresh_token, clientSearchData:{ clientKey:v.clientKey, venueTitle:v.venueTitle, email:v.info.email, venueUrl:v.venueUrl, logo:v.info.logo, domainHttps:v.domainHttps } } })
					}
				}
			})
		}
	}

	startFacebookPixel = () => {

		let v               = this.props.v

		if (v.customer && v) {
			let pixel_wlc  		= v.wlc  && v.wlc.facebookPixel
			let pixel_client  	= v.info && v.info.facebookPixel
		
			if (pixel_wlc) {
				pixelStart({ pixel:pixel_wlc })
				pixelPageView()
			}
			if (pixel_client) {
				pixelStart({ pixel:pixel_client })
				pixelPageView()
			}
		}
		else setTimeout(()=>{ this.startFacebookPixel() }, 3000)
	}

	isSoundUnlocked = () => {
		let sound 	= new Howl({ src: [whoosh], volume:0 })
		sound.play()
		sound.on('end',() => { this.setState({ soundUnlocked:true }); console.log('sok') })
	}

	checkIfNotifyNeeded() {
		let v 				= this.props.v
		let newOrdersM		= v.client?.newOrdersM 	&& v.browserConfig?.alertsOrdersSoundM!==false && v.pathname3!=='/manager/login' && v.pathname3!=='/manager/register'
		let newChatsM 		= v.client?.newChatsM 	&& v.browserConfig?.alertsChatSoundM!==false
		let newOrdersToPrint 	= v.manager && v.browserConfig?.autoPrint && v.client?.newOrdersMFull
		
		// NEW CHAT WLC
		let newChatsWlcW 		= v.state.newChatsWlcW && v.chatInfo.chatAdmin && v.browserConfig?.alertsChatSoundM!==false

		console.log('gg newChatsWlcW', newChatsWlcW, v)
		if (newOrdersToPrint) printOrders({ v, newOrdersToPrint })

		if 	( newOrdersM || newChatsM || newChatsWlcW ) { 

			if (!this.state.notify) { this.setState({notify:true}); setTimeout(() => this.notifySound(), 1000) }
		}
		else if (this.state.notify) this.setState({notify:false})
		else 	return null
    }

	notifySound = async () => {

		let v					= 	this.props.v


		if ( v.manager && this.state.notify ) {

			let sound 	= new Howl({ src: [whoosh] });
			// sound.play();
			// sound.on('end',() => { setTimeout(()=>this.notifySound(),1000) })

			try { 
				await sound.play();
				sound.on('end',			() 		=> { console.log('sound played'); setTimeout(()=>this.notifySound(),1000) });

				// Trying to catch play errors
				sound.on('loaderror',	(error) => { console.log('sound loaderror', error); handleError({ v, error, noDialog:true }) });
				sound.on('playerror',	(error) => { console.log('sound playerror', error); handleError({ v, error, noDialog:true }) })
			} 
			catch (error) { 
				console.log('sound error', error);
				handleError({ v, error, noDialog:true })		
			}
		}
	}

	saveTmpUid = () => {
        let customerId   	= searchParams('customerId')
        if (customerId) 	{
			let uids 	= this.props.v.uids || []
			if (!uids.includes(customerId)) this.props.updateSimple({ uids:[ ...uids, customerId ] })
		}
	}

	


	getLocationTitle = ({ v }) => {

		let pathname 		= v.pathname || ""
		let devMode  		= 0 // Click 5 times to change to PRO mode and LOGGING
		let icon, text, logo			

		if      (pathname.includes(`/chat`))        		icon='chat'
		else if (pathname.includes(`/basket`))              icon='basket'
		else if (pathname.includes(`/location`))            icon='marker'
		else if (pathname.includes(`/total`))               icon='send'
		else if (pathname.includes(`/manager/config/pos`))  icon='pos'
		else if (pathname.includes(`/manager/home`))        icon='home'
		else if (pathname.includes(`/manager/venues`))      icon='list'
		else if (pathname.includes(`/manager/archive`)) 	{ icon='archive'; 	text='archive' }
		else if (pathname.includes(`orders`))       		{ icon='orders'; 	text='orders' }
		else if (pathname.includes(`/manager`))     		icon='settings'
		else 												{ v.info.logo? logo=v.info.logo: icon='menu' }
	
		return 	(
			<div style={Object.assign({}, Style.flexRow, {justifyContent:v.manager?'flex-start':'center'})} onClick={()=> { devMode+=1; if (devMode>4) { console.log(devMode); v.setState({ proForced:true, devForced:true }) } }} >
				

				{/* Platform Name */}
				{ RenderBadgeL({ v, resize:true, logo:v.wlc?.logo, hide:v.customer, text:v.wlc?.businessName, textStyle:{color:'black'} }) }
				
				{/* Page Icon Or Name */}
				{ RenderBadgeL({ v, resize:true, icon, hide:false, text, style:v.div4, logo }) }

				{/* Language */}
				{ RenderBadgeL({ v, icon:'edit', hide:v.customer||v.language==v.languageDefault, text:v.language, style:{backgroundColor:'#D50000', color:'white'} }) }
				
				{/* Notify Buttons Eg. New Chat/Order */}
				{ this.renderNotifyNavButtons({v}) }
			</div>	
		)
	}
	

	renderHeader = ({v}) => {

		let orderlinaStore 	= 	{...this.props.orderlinaStore}
		let pathname 		= 	v.pathname3
		let clientKey 		= 	v.clientKey
		let wlc 			= 	v.wlc
		let manager			= 	v.manager
		let color			= 	manager?'black':(v&&v.tc||'black')
		let styleHeader		=	{ ...Style.header, borderBottom:v.customer?v.style.bo.border:"" }

		// console.log('header', pathname, this.props.notify )

		if 		( orderlinaStore.keyboardOpen) 			return <Button style={Object.assign({}, Style.navButton, { right:10, top:10, position:'fixed', zIndex:999999 })} onClick={() => this.props.updateKeyboardOpen(false) }>{getIcon2({icon:'keyboardHide', fontSize:Style.navIconSize, color})}</Button>
		else if ( pathname==="/" || 
				  pathname.includes("/manager/login") ||
				  pathname.includes("/manager/register"))		return <div />
		else if ( pathname.includes('manager/venues') ) return <div style={styleHeader}>{ this.getLocationTitle({ v }) }{zoomButtons({v})}{ renderNavButton({ v, icon:'close', onClick:()=>Firebase.auth().signOut() })}</div>
		else if ( pathname==="/manager" || 
				  pathname==="/manager/home") 			return <div style={styleHeader}>{ renderNavButton({ v, icon:'back', onClick:()=>this.props.history.push('/manager/venues') })}{ this.getLocationTitle({ v }) }{ languageButtons({props:this.props, v}) }{zoomButtons({v})}</div>
		else if ( pathname==="/manager/managemenu") 	return <div style={styleHeader}>{ renderNavButton({ v, icon:'home', onClick:()=>this.props.history.push('/manager') })}{ this.getLocationTitle({ v }) }{ languageButtons({props:this.props, v}) }{zoomButtons({v})}</div>
		else if ( pathname==="/manager/manageitem") 	return <div style={styleHeader}>{ renderNavButton({ v, icon:'back', onClick:()=>goBack({ v, history:this.props.history }) })}{ this.getLocationTitle({ v }) }{ languageButtons({props:this.props, v}) }{zoomButtons({v})}</div>
		else if ( pathname.includes("/manager")) 		return <div style={styleHeader}>{ renderNavButton({ v, icon:'home', onClick:()=>this.props.history.push('/manager') })}{ this.getLocationTitle({ v }) }{ languageButtons({props:this.props, v}) }{zoomButtons({v})}</div>

		// HOME - TITLE - SETTINGS

		else if ( pathname==`/${v.venueUrl}/menu` ) {
			return (
				<div style={styleHeader}>
					{ renderNavButton({ v, icon:'back', onClick:()=>this.props.history.push(`/${v.venueUrl}`) })}
					{ this.getLocationTitle({ v }) }
					{ renderHamburger({ v })}
				</div>
			)
		}

		// BACK

		else if ( pathname==`/${v.venueUrl}/item` || pathname.includes("/settings") || pathname.includes("customerlogin") ) {
			return (
				<div style={Object.assign({}, styleHeader, {backgroundColor:'transparent', top:0, position:'fixed', zIndex:999, borderBottom:'none' })}>
					{ renderNavButton({ v, icon:'back', onClick:()=>this.props.history.goBack(), style:v.div })}
				</div>
			)
		}

		// BACK - TITLE - HEART

		else if ( pathname==`/${v.venueUrl}/saved` ) {
			return (

				<div style={styleHeader}>
					{ renderNavButton({ v, icon:'back', onClick:()=>this.props.history.goBack() })}
					{ this.getLocationTitle({ v }) }
					{ renderHamburger({ v })}
				</div>
			)
		}
				
		// MENU - TITLE - SETTINGS
		
		else if ( pathname.includes(`/${v.venueUrl}/orders`) ) {
			return (
				<div style={styleHeader}>
					 { renderNavButton({ v, icon:'back', onClick:()=>v.history.push(`/${v.venueUrl}/menu`) }) }
					{ this.getLocationTitle({ v }) }
					{ renderHamburger({ v })}
				</div>
			)
		}

		// BACK - TITLE - SETTINGS
		
		else if ( pathname==`/${v.venueUrl}/basket` || pathname==`/${v.venueUrl}/location` || pathname==`/${v.venueUrl}/total` || pathname==`/${v.venueUrl}/chat` ) {
			return (
				<div style={styleHeader}>
					{ renderNavButton({ v, icon:'back', onClick:()=>goBack({ v, history:v.history }) })}
					{ this.getLocationTitle({ v }) }
					{ renderHamburger({ v })}
				</div>
			)
		}
		else return null
	}

	renderNotifyNavButtons = ({v}) => {

		let manager			= v.manager
		let customer 		= v.customer
		let client 			= v.client
		let browserConfig	= v.browserConfig

		console.log('nnn', v )

		return (
			<div style={Style.flexRowOnly}>
				{ manager	&&	client?.newOrdersM  &&  browserConfig.alertsOrdersBadgeM!==false?  	renderNavButton({ v, icon:'orders', 				onClick:()=>this.props.history.push('/manager/manageorders'), 	style:{backgroundColor:'#D50000', color:'white'} }) :null}
				{ manager	&&	client?.newChatsM	&&	browserConfig.alertsChatBadgeM!==false? 	renderNavButton({ v, icon:'chat',   				onClick:()=>this.props.history.push('/manager/chat'), 			style:{backgroundColor:'#D50000', color:'white'} }) :null}
				
				{/* CHAT NEW */}
				{ manager	&&	v.state.newChatsWlcW&&	browserConfig.alertsChatBadgeM!==false? 	renderNavButton({ v, icon:'chat',   				onClick:()=>this.props.history.push('/manager/chat2?type=wlc'), style:{backgroundColor:'#D50000', color:'white'} }) :null}
				{/* THIS IS HANDLED IN RENDERHAMBURGER */}
				{/* { customer	&&	client?.newChatsC? 	renderNavButton({ v, icon:'chat',   				onClick:()=>this.props.history.push(`/${v.venueUrl}/chat`), 	style:{backgroundColor:'#D50000', color:'white'} }) :null} */}
				{/* { customer  &&  client?.newOrdersC? renderNavButton({ v, icon:'orders', 				onClick:()=>this.props.history.push(`/${v.venueUrl}/orders`), 	style:{backgroundColor:'#D50000', color:'white'} }) :null} */}
			</div>
		)
	}

	renderUnlockSoundPage = ({v}) => {
		if ( v.manager &&
			 !v.isHomepage &&
			 !this.state.soundUnlocked && 
			 !v.pathname3.includes('/pricing') &&
			 !v.pathname3.includes('/register') &&
			 !v.pathname3.includes('/login') ) {
			return (
				<div onClick={() => this.setState({ soundUnlocked:true }) } style={Object.assign({}, Style.header, v.div4, {height:`${100/v.browserConfigGlobal?.zoom||100}vh`, position:'absolute', width:`${100/v.browserConfigGlobal?.zoom||100}vw`, backgroundColor:'white', zIndex:99999999, flexDirection:'column', justifyContent:'space-between'})}>
					<div style={Style.flexCol}>{ v.info?.logo? renderLogo3({ img:v.info.logo, size:'3rem' }):null}</div>
					<Button style={{borderRadius:9999}}>{getIcon2({icon:'playCircle', fontSize:'10rem', color:v.bt })}</Button>
					<div style={Style.flexCol} />
				</div>
			)
		}
		else return null			
	}

	checkInternet = () => {

		// let v	= this.props.v
		// if (v.pathname3.includes('/pricing')) return null

        Firebase.database().ref(".info/connected")
            .on('value', (snap) => {
                if (snap.val()) { console.log('listen CONNECTED'); this.setState({error_internet:undefined});  } 
                else {
					console.log('listen DISCONNECTED'); this.setState({error_internet:true});
					// setTimeout(()=>{ if (this.state.error_internet) { window.location.reload() } }, 10000) 
                }
            })
	}

	render() {

		let v						= 	this.props.v
		let orderlinaStore			= 	this.props.orderlinaStore
		let dialog          		=   orderlinaStore?.dialog
		let DivType 				= 	v.newStylePage? HeaderTW : AppBar
		let headerStyle 			=	v.newStylePage? { width:'100%', zIndex:99999}: Object.assign({}, v.div, v.style.bs, { display:'flex', flexDirection:'row', zIndex:999999999 })
		
		return (
			<DivType v={v}  onClick={() => log2(v, this.state, this.props)} position="sticky" style={headerStyle}  >
				{ v.isDev? getLocalStorageDetails() :null}
				{ dialog? <Dialog {...this.props} v={v} /> :null}
				{ updateVersion(this.props) }
				{ this.renderHeader({v}) }
				{ this.renderUnlockSoundPage({v}) }
				<Toast2 v={v} toast={orderlinaStore.toast} />

				{v.wlc.chat && v.manager && v.pathname.includes('manager/register')? <Advizir v={v} /> :null}
				
				{this.state.error_internet? <Text2 text='offline' style={Object.assign({}, Style.boxShadow, { position:'fixed', width:'100%', top:0, left:0, color:'white', backgroundColor:'#D50000', display:'unset', textTransform:'uppercase', fontWeight:'bold', textAlign:'center', padding:'0.2rem', fontSize:'0.6rem', zIndex:9999999 })} /> :null }
			</DivType>
		)
	}
}
export default Header
