import React, { Component, Picker } from 'react';
import PushId from 'pushid';

import Firebase from '../Firebase';
import {Card, Button, Switch} from '@material-ui/core';
import Style from '../styles';
import Item from '../client/Item'
import Page from '../client'
import Location from '../client'
import Total from '../client'
import Verify from '../client/Verify';
import PdfViewer from '../client/PdfViewer';


import { Chat, PowerSettingsNew, GpsFixed } from '@material-ui/icons/';
import { FiHome, FiMapPin, FiMail, FiPhone, FiLink, FiDollarSign, FiImage } from 'react-icons/fi';
import { FaMoneyBillAlt, FaFacebookSquare, FaInstagram, FaRegStar } from 'react-icons/fa';
import { MdSecurity, MdTitle, MdDescription } from 'react-icons/md';


import { defaultPicBlank, RenderImage2, Switch2, Text2, scrollTo2, trim_keepSpaces, Button3, Input2, isGod2, getDefaultLanguage2, getQrImageUrl, DownloadButton, numToDAY, defaultPic, RenderHelper7, addItem, trimAmount, renderHelper6, placeOrder, ButtonNav, hint3, logo2, SaveCancelButtons2, renderGuestTypeButtonsMini, getBasketTotal, getAppText2, getBasketItemCount, renderNotes, handleError, monthPicker, yearPicker, defaultSrcLogo, numToMON, isLoading, getVariables, defaultSrcBlank, SaveCancelButtons, convertToNumber, Loading, getLanguage, getIcon, footer, renderHelper, uploadToFBStorage, placeholderImage, hint, seperator, readyPage, languageEditWarning, getDefaultLanguage, getVenueNameTrim, domain, supportEmail, verifyPlanCode, getWhiteLabelConfig, getAppText, getIsProCust, getWhiteLabelConfigEdit, Month, getVisitsCount, hint2, YYYY, MM, notifications, renderHelper5, isGod, sendEmail2, trim, uploadToFBStorage2, getIcon2, getUserEmail, randomNumber, countInstances, Card2, handleLink2, log2, Select2, Select3, download3, uploadButton3, handleLink3, manageSubscription, createSubscription, searchParams, getSticker, renderCustomerSubscription, renderSubscription, getIsProCust2, isAdmin, getSub, updateSub, toArray, SaveCancelButtons3, splitCamelCase, trimSpaces, trimSpaces2, GetQR, seperator2, getData, trimAmount2, Form2, Switch3, backup, getTime2, getFormattedLocalTime2, updateData2 } from '../Mini-components/Functions'

import languagesCodes from './../Mini-components/languagesCodes.json'
import Button2 from '../Mini-components/Button2';
import MSubscription from './MSubscription';



let height = window.innerHeight

const orderlinaStripeClientId = "ca_EFUcxZKPm28U3pwpQTkydBd0ALN2KPEg"; //prouction key


class MMulti extends Component {

	constructor(props) {
		super(props);
		this.state = {
        };
	}

    componentDidMount() {
        this.mapStoreToState()
        let v = this.props.v
        readyPage({ props:this.props, v })
        this.getData({v})
        
        // this.subscriptionPaidWebhook({v}) // now done via cloud webhook
	}

    componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.v.info) != JSON.stringify(this.props.v.info)) {
            this.mapStoreToState(this.props.v) 
        }
	}
    
    mapStoreToState() {
        let v               = this.props.v

        this.setState({ info:               v.info      && JSON.parse(JSON.stringify(v.info)),
                        appText:            v.appText   && JSON.parse(JSON.stringify(v.appText))
        })
    }

    getData = ({v}) => {

        let pathname    = window.location.pathname

        if (v) { 
            if      (pathname.includes('/manager/config/form/')) this.getFormData({ v })
            else if (pathname.includes('/manager/config/booking'))    this.setState({ bookingConfig:v.info.bookingConfig })
            else if (pathname.includes('/manager/config/pages')) { this.setPageTemplates(); this.setWhiteLabelConfig() }
            else if (pathname.includes('/manager/config/backup'))   this.getBackups({v})
        }
        else return null
    }

    getBackups = async ({ v }) => {

        let backups = await backup({v, type:'get' })
        this.setState({ backups:backups||"", lb:false })
    }

    setWhiteLabelConfig = () => {

        let v           = this.props.v
        let wlcKey      = v.searchParams.get("wlcKey")

        if (wlcKey) {
            if      (wlcKey==v.wlc.key && v.userInfo.email==v.wlc.email)                            this.setState({ whiteLabelConfig:v.wlc })
            else if (this.props.orderlinaStore?.config?.whiteLabel?.[wlcKey] && isGod2({v}))    this.setState({ whiteLabelConfig:this.props.orderlinaStore.config?.whiteLabel?.[wlcKey] })
            else    handleLink3({ v, link:'/' })
        }

        // If Editing Pages For Wlc
        // if (wlcKey) {
        //     Firebase.database().ref(`config/whiteLabel/${wlcKey}`)
        //         .once('value') 
        //         .then(snap => this.setState({ whiteLabelConfig:{...snap.val(), key:wlcKey}  }) )
        //         .catch((error)=> { console.error(error) })
        // }
    }

    setPageTemplates = () => {
        // Gets Page Templates From Lantara
        Firebase.database().ref("clients/-MTd-98WVV7H3ygptDQ5/info/pages")
            .once('value')
            .then(snap => { this.setState({ pageTemplates:snap.val() }) })
            .catch((error)=> { console.error(error) })
    }



    getFormData = () => {

        let v           = this.props.v
        let uprops      = new URLSearchParams(window.location.search);
        let formKey     = uprops.get("form")
        let formTitle   = uprops.get("formTitle")
        let year        = this.state.year||YYYY
        let month       = this.state.month||MM

        this.setState({ gettingData:true })

        Firebase.database().ref(`forms/${v.clientKey}/${formKey}/${year}/${month}`)
            .once('value') 
            .then(snapshot => { this.setState({ formData:snapshot.val(), year, month, gettingData:false, formTitle }) })
            .catch((error)=> { console.error(error); this.setState({ gettingData:false }) })
    }

    deleteFormRow = ({ formRow }) => {

        let v           = this.props.v
        let uprops      = new URLSearchParams(window.location.search);
        let formKey     = uprops.get("form")
        let year        = this.state.year||YYYY
        let month       = this.state.month||MM

        if (formRow?.key && window.confirm(`Delete Row?`)) {

            Firebase.database().ref(`forms/${v.clientKey}/${formKey}/${year}/${month}/${formRow.key}`)
                .remove()
                .then(()=>{ this.getFormData() })
                .catch((error)=> { handleError({v, error}) })
        }
    }

    // now done via cloud webhook
    // subscriptionPaidWebhook = async ({ v }) => {
    //     let subscriptionSessionId = searchParams('session_id')
    //     if (subscriptionSessionId) {
    //         Firebase.functions().httpsCallable('updateSubscription')({ subscriptionSessionId, clientKey: v.clientKey })
    //             .then(() => { setTimeout(() => this.props.history.push(`/manager/config/plan`), 3000) })
    //             .catch((error)=>handleError({ v, error, props: this.props }))
    //         setTimeout(() => { this.subscriptionPaidWebhook({ v }) }, 5000);
    //     }
    // }


    renderSocial = ({v}) => {

        let info            = this.state.info
        let language        = v.language

        let social          = info && info.social

        return (
            <Card2 v={v} title='Social' icon='instagram'  message='Direct Customers to Your Social Media' >

                <Switch3 button icon='power' v={v} text={social.active?'on':'off'} checked={social.active||false} onChange={()=> this.handleChange('handleActivation') } />

                { social?.active?
                    <div>
                        <Input2 icon='page'         twenty  v={v}       placeholder='Social Heading'                        value={social["title_"+language] || social.title}               name="title"            onChange={ (e) => this.handleChange('socialTitle', e.target.value) } />
                        <Input2 icon='page'         twenty  v={v}       placeholder='Description'                           value={social["description_"+language] || social.description}   name="description"      onChange={ (e) => this.handleChange('socialDescription', e.target.value) } />
                        <Input2 icon='facebook'     twenty  v={v}       placeholder='Link to Your Facebook Page'            value={social.facebookLink}                                     name="facebookLink"     onChange={ (e) => this.handleChange('facebookLink', e.target.value) } />
                        <Input2 icon='instagram'    twenty  v={v}       placeholder='Link to Your Instagram'                value={social.instagramLink}                                    name="instagramLink"    onChange={ (e) => this.handleChange('instagramLink', e.target.value) } />
                        <Input2 icon='tiktok'       twenty  v={v}       placeholder='Link to Your Tiktok'                   value={social.tiktokLink}                                       name="tiktokLink"       onChange={ (e) => this.handleChange('tiktokLink', e.target.value) } />
                        <Input2 icon='telegram'     twenty  v={v}       placeholder='Link to Your Telegram'                 value={social.telegramLink}                                     name="telegramLink"     onChange={ (e) => this.handleChange('telegramLink', e.target.value) } />
                    </div>
                :null}

                { SaveCancelButtons( this.state.toggleEdit, this.mapStoreToState, this.save ) }
            </Card2>
        )
    }

    renderFooter = ({v}) => {

        let info                    = this.state.info
        let language                = v.language
        let social                  = v.info?.social
        let footer                  = v.info?.footer
        let pageTemplates           = v.info?.pages?             Object.entries(v.info.pages).map(item => ({  key:item[0], ...item[1]  })) : []

        return (
            <Card style={Object.assign({}, Style.card, { padding:0 })}>

                { RenderHelper7({ props:{ v, icon:'page', title:'footer', message:'Select or Create a Custom Footer', style:{ boxShadow:'unset', borderRadius:'unset' } }}) }

                <div style={Object.assign({}, Style.flexColOnly, {padding:'1.5rem'})}>

                    <Select3 v={v} icon='page' twenty placeholder={'select footer'} array={[ {title:'default', key:'' }, {title:'none', key:'none' }, ...pageTemplates ]} value={info.footerPageKey||""} optionValue='key' optionName='title' onChange={(value) => this.handleChange2({ type:'footerPageKey_Venue', value }) } />
                    { !footer && <Button3 twenty v={v} onClick={() => handleLink3({v, link:'/manager/social' })} text='edit social links' /> }

                    { hint3({ v, text:'Go To Manager > Pages to create a custom footer', style:{marginTop:20} })}
                </div>

                { SaveCancelButtons( this.state.toggleEdit, this.mapStoreToState, this.save ) }
            </Card>
        )
    }

    renderUsers = ({v}) => {

        let info            = this.state.info
        let users           = info.users


        return (
            <div style={{}}>

                <Card2 v={v} icon="user" h1='Users'>
                    { users && Object.entries(users).map((item,index) => { item = { ...item[1], uid:item[0], key:item[0], index:index  } 
                        return (
                            <div key={item.key} style={Object.assign({},Style.flexRow, { minHeight:'3rem' })}>
                                <Button onClick={() => this.handleChange('removeUserByUid', item) } style={Object.assign({}, {  minWidth:'4rem'})}>{getIcon2({icon:'trash', fontSize:'1.25rem'})}</Button>
                                <div onClick={() => console.log(item)} style={Object.assign({},Style.textS, Style.ellipsis,{ flex:1 })} >{item.email || item.uid}</div>
                                <div style={Object.assign({}, Style.buttonBadge, v.div4, { textTransform:'uppercase' })} >{item.role}</div>
                            </div>                           
                        )
                    })}
                </Card2>


                <Card2 v={v} icon="shield" title='add user'>

                    <Switch2 v={v} text='add existing user' checked={this.state.addExistingUser} onChange={() => this.setState({ addExistingUser:!this.state.addExistingUser })} />

                    <input style={Object.assign({}, Style.input, Style.textS, { marginTop:20 })} placeholder='Email'                      value={this.state.email}           onChange={ (e) => this.setState({ email:            e.target.value })} />
                    {this.state.addExistingUser? null: 
                    <input style={Object.assign({}, Style.input, Style.textS, { marginTop:20 })} placeholder='Password'                   value={this.state.password}        onChange={ (e) => this.setState({ password:         e.target.value })} /> }

                    <select style={Object.assign({}, Style.selectDropdown, {  })} value={this.state.role||"SELECT"} name="platformType" onChange={(e) => this.setState({role:e.target.value}) }>
                        {   ([{ role:'SELECT'}, {role:'manager'}, {role:'orders' } ]).map((item) => {
                                return( <option style={Object.assign({}, Style.mInfoText, { textTransform:'uppercase' })} key={item.key} value={item.role}>{item.role}</option>)
                        })}
                    </select>

                    <Button3 v={v} onClick={() => this.handleChange(this.state.addExistingUser?'addUserByEmail':'createAndAddUserByEmail') } twenty text={'add user'} icon='add' loading={this.state.blcaaue} />
                </Card2>

                {/* <Card style={Object.assign({}, Style.card, { marginTop:15, textAlign:'left' })}>
                    <div style={Style.flexRow}>
                        <MdSecurity style={Object.assign({}, Style.mInfoIcon, {})} />
                        <div className="flex ellipsis" style={Style.textS} >Add Email</div>
                    </div>
                    <input style={Object.assign({}, Style.textS, { marginLeft:50, marginTop:15 })} placeholder='Email'                      value={this.state.email}           onChange={ (e) => this.setState({ email:            e.target.value })} />
                    <input style={Object.assign({}, Style.textS, { marginLeft:50, marginTop:15 })} placeholder='Password'                   value={this.state.password}        onChange={ (e) => this.setState({ password:         e.target.value })} />
                    <input style={Object.assign({}, Style.textS, { marginLeft:50, marginTop:15 })} placeholder='Password Repeat'            value={this.state.passwordRepeat}  onChange={ (e) => this.setState({ passwordRepeat:   e.target.value })} />
                    <div className="flex ellipsis" style={Object.assign({}, Style.textS, { marginLeft:50, marginTop:15, color:'red' })}>After saving, you will be auto logged-in with new credentials. Your existing and new credentials will both work.</div>

                    <Button style={{ marginTop:15, marginLeft:30 }} onClick={ () => this.handleChange('userEmailSave') } >{getIcon('save', '1.5rem')}</Button>
                </Card> */}

                {/* { SaveCancelButtons( this.state.toggleEdit, this.mapStoreToState, this.save ) } */}

            </div>
        )
    }

    renderPayment = (v) => {

        let info                        = this.state.info
        let payments                    = info.payments || {}
        let connectedStripeAccountId    = payments?.connectedStripeAccountId
        let whiteLabelConfig            = v.wlc
        let oPayNameFull                = `${v.wlc.businessName} Pay`
        let oPayBalance                 = 0
        let withdrawCountries           = ('Albania,Algeria,Andorra,Angola,Anguilla,Antigua and Barbuda,Argentina,Armenia,Aruba,Australia,Austria,Azerbaijan,Bahrain,Bangladesh,Belgium,Belize,Bolivia,Bosnia and Herzegovina,Brazil,Bulgaria,Cambodia,Canada,Canary Islands,Chile,China,Colombia,Costa Rica,Croatia,Curaçao,Cyprus,Czech,Republic,Denmark,Dominica,Dominican Republic,Ecuador,Egypt,El Salvador,Equatorial Guinea,Estonia,Faroe Islands,Fiji,Finland,France,French Guiana,French Polynesia,French Southern Territories,Georgia,Germany,Gibraltar,Greece,Greenland,Grenada,Guatemala,Guernsey,Guyana,Honduras,Hong Kong (China),Hungary,Iceland,India,Indonesia,Ireland,Isle of Man,Israel,Italy,Jamaica,Japan,Jersey,Jordan,Kazakhstan,Kenya,Korea (Republic,of),Kosovo,Kuwait,Kyrgyzstan,Latvia,Liechtenstein,Lithuania,Luxembourg,Macao (China),Malaysia,Maldives,Malta,Martinique,Mauritania,Mauritius,Mayotte,Mexico,Moldova,Monaco,Montenegro,Morocco,Namibia,Nepal,Netherlands,New Caledonia,New Zealand,Nigeria,North Macedonia,Norway,Oman,Pakistan,Palestine,Panama,Paraguay,Peru,Philippines,Poland,Portugal,Puerto,Rico,Qatar,Réunion,Romania,Saint Barthélemy,Saint Lucia,Saint Martin,Saint Pierre and Miquelon,San Marino,Saudi Arabia,Serbia,Seychelles,Singapore,Slovakia,Slovenia,South Africa,Spain,Sri Lanka,Sweden,Switzerland,Taiwan (China),Tajikistan,Tanzania,Thailand,Timor-Leste,Tunisia,Turkey,Uganda,Ukraine,United Arab Emirates,United Kingdom,United States of America,Uruguay,Uzbekistan,Vanuatu,Vietnam,Virgin Islands (British),Wallis and Futuna,Zambia').split(',').map(item=> item={ key:item, name:item })

        return (
            <div>
                { RenderHelper7({ props:{ v, title:'Payment Options', message:`Add a Payment Option to a Guest Type in 'TYPES > TYPE > PAYMENT OPTIONS'`, style:{marginTop:20, ...v.div4 } }}) }


                <Card2 v={v} icon={v.wlc.icon||'orderlina'} title={oPayNameFull} message={`${v.wlc.businessName} Payment Processing`} hide={!this.state.viewOpay} onClick={()=>this.setState({viewOpay:!this.state.viewOpay})} dropDown >

                    <Text2 v={v} style={Style.textB} text='Balance:' varEnd={this.state.oPayBalance? ` ${v.info.currency||'$'}${v.info.currencySymbol||''}${this.state.oPayBalance}` : ""} />
                    <Button3 v={v} twenty icon={this.state.lb=='gettingoPayBalance'?'loading':'refresh'} onClick={ async()=> {

                        this.setState({lb:'gettingoPayBalance'})
                        let unpaidOrders = await getData({v, collection:'oPayments', query:['clientKey', "==", v.clientKey], query2:['paidOut', "==", false] })

                        this.setState({unpaidOrders })
                        console.log('sss', unpaidOrders)
                        unpaidOrders && unpaidOrders.forEach(order => { oPayBalance += order.total })

                        oPayBalance = trimAmount2({ v, amount:oPayBalance*0.955 })

                        this.setState({ oPayBalance, lb:false })
                        sendEmail2( v.wlc, { recipientEmail:'shaun@orderlina.com' }, { subject:`OPay Balance Checked`, title:`OPay Balance Checked`, text: [ v.venueTitle, this.state.oPayBalance||"", v.clientKey ] })

                    }} text='Get Current Balance' />

                    {/* WITHDRAW BUTTON */}
                    {this.state.oPayBalance>10?
                        <Button3 text='Withdraw Balance' v={v} inactive twenty icon={this.state.lb=='gettingoPayBalance'?'loading':'refresh'} onClick={ async()=> { 
                            sendEmail2( v.wlc, { recipientEmail:'shaun@orderlina.com' }, { subject:`OPay Withdrawal Requested`, title:`OPay Withdrawal Requested`, text: [ v.venueTitle, this.state.oPayBalance||"", v.clientKey ] })
                            v.updateDialog({ v, text:'Withdraw Request Submitted', description:`Funds will appear in your nominated bank account within 10 working days but normally happens much sooner. If you do not receive funds in 10 days please contact ${v.wlc.supportEmail||"Support"}`, hideOkButton:true })
                        }} />
                    :null}

                    {/* TRANSACTIONS ADMIN */}
                    { v.isGod?
                        this.state.unpaidOrders && this.state.unpaidOrders.map(item=> { 
                            console.log('ss', item)
                            return(
                                <div style={{...Style.flexRowSP, marginTop:20}}>
                                    <div>{item.time}</div>
                                    <div>{item.currency}{item.total}</div>
                                    <Button3 v={v} onClick={()=> updateData2({ v, col:'oPayments', doc:item.key, update:{paidOut:true} }) } text='mark paidOut' />
                                </div>
                        )})
                    :null }

                    <Text2 v={v} icon='alert' twenty text={`${oPayNameFull} processing fee is a flat 4.5%. ${oPayNameFull} funds will be deposited to your nominated account within 10 days of initiating withrawal.`} />
                    <Text2 v={v} icon='alert' twenty text={`Withdrawals can be initiated manually by selecting the button above but will occur automatically when balance exceeds USD$500 equivalent. Minimum withdrawal amount is USD$10 or equivalent.`} />
                    <Text2 v={v} icon='alert' twenty text={`Withdrawal Method: Direct Bank Transfer`} />
                    <Text2 v={v} icon='alert' twenty text={`Eligibility: ${oPayNameFull} supports payments to over 150 countries. Please see WITHDRAW ACCOUNT SETTINGS for country eligibility`} />
                    
                    <Card2 v={v} twenty dropDown icon='page' title='Withdrawal Account Settings' hide={!this.state.editOpayWithdrawAccount} onClick={()=>this.setState({ editOpayWithdrawAccount:!this.state.editOpayWithdrawAccount })} >

                        <Select3 v={v} icon='alert'       placeholder={'Select Country'}                value={payments?.oPayWithdrawAccount?.Country||""}       array={withdrawCountries} optionValue='key' optionName='name' onChange={(value) => { console.log('xx', value); let formData=payments?.oPayWithdrawAccount||{}; this.handleChange2({ v, type:'oPayWithdrawAccount', value:{...formData, Country:value} }) }}  />
                        <Form2 v={v} 
                            show={payments.oPayWithdrawAccount?true:false} 
                            buttonText  = 'Save'
                            onChange    = {(formData)=> { console.log('xxx', formData); this.handleChange2({ v, type:'oPayWithdrawAccount', value:formData }) }}
                            formData    = {payments.oPayWithdrawAccount}
                            fields      = {[
                                { name:'Bank SWIFT or BIC Code' }, 
                                { name:'Optional Sort Code' },
                                { name:'Optional Chips Number' },
                                { name:'Optional ABA Fed Wire Routing Number' },
                                { name:'Optional Bank Name and Address if No SWIFT or BIC Code' },
                                { name:'Account Number or IBAN' },
                                { name:'Country' },
                                { name:'Account Holder Name' },
                                { name:'Account Holder Address' },
                            ]}
                        />
                        <Text2 v={v} twenty icon='alert' twenty text={`Contact ${v.wlc.supportEmail} if you wish to use ${oPayNameFull} and your country is not listed.`} />
                    
                    </Card2>

                </Card2>

                <Card2 v={v} icon='stripe' title='Stripe' message='Card Payments, Google Pay, Apple Pay +More' hide={!this.state.viewStripe} onClick={()=>this.setState({viewStripe:!this.state.viewStripe})} dropDown >

                    {connectedStripeAccountId?
                        <div style={Style.flexColOnly}>
                            <div style={Object.assign({}, Style.textSB, {  })}>STRIPE CONNECTED</div>
                            <div style={Object.assign({}, Style.textS, { marginTop:15 })}>Congratulations your account is configured to accept credit card payments via Stripe</div>

                            {/* Current connectedStripeAccountId */}
                            <div style={Object.assign({}, Style.textSB, { marginTop:15 })}>Payments will directed to this account account:</div>
                            <div style={Object.assign({}, Style.textS, { marginTop:15 })}>{payments.connectedStripeAccountId}</div>

                            {/* Payments Instructions */}
                            <div style={Object.assign({}, Style.textSB, { marginTop:15 })}>Additional Payment Instructions to Customers paying with Stripe (Optional):</div>
                            <input style={Object.assign({}, Style.input, { marginTop:15 })} placeholder='Instructions' autoCapitalize = 'none'   value={payments.instructions}  onChange={(e) => this.handleChange('updateStripeInstructions', e.target.value) } />
                        
                            {/* Use Stripe V2 - can remove this option after a while */}
                            {/* <div style={Object.assign({}, Style.flexRow, { justifyContent:'space-between', marginTop:15 })}>
                                <div style={Object.assign({}, Style.textSB, {})}>Stripe V2 - SCA COMPLIANT</div>
                                <Switch checked={payments.stripeV2} color="secondary" name="stripeV2" onChange={(e) => this.handleChange('updateStripeV2', e.target.value)} />
                            </div> */}

                            <Button style={Object.assign({}, Style.button, { backgroundColor: 'darkgrey', marginTop:15 })} onClick={() => this.handleChange('clearStripeConfig') }>Clear Stripe Config</Button>
                        </div>
                        :
                        <div style={Style.flexColOnly}>

                            <Text2 v={v} style={Object.assign({}, Style.textS, { textTransform:'none' })}>Stripe is a Global Internet Payments provider Card payments processed by Stripe are deposited into your Stripe account Immediately</Text2>
                            <Text2 v={v} style={Object.assign({}, Style.textS, { marginTop:15, color:v.pc, textTransform:'none', fontWeight:'bold' })} onClick={()=>window.open( `https://stripe.com/pricing`)}>Stripe Fees vary globally so please check their Pricing page or CLICK HERE for more information</Text2>
                            <Text2 v={v} style={Object.assign({}, Style.textS, { marginTop:15, color:v.pc, textTransform:'none', fontWeight:'bold' })} onClick={()=>window.open( `https://stripe.com/global`)}>To receive payments via stripe you will need a business number from a Stripe Eligible Country CLICK HERE to check eligible countries</Text2>
                            <Text2 v={v} style={Object.assign({}, Style.textS, { marginTop:15, textTransform:'none' })} varStart={whiteLabelConfig.businessName+" "}>is a NO FEE NO COMMISSION Platform and does not charge additional fees</Text2>
                            {/* <div style={Object.assign({}, Style.textS, { marginTop:15 })}>{"Payments processed through Stripe will incur a 3.5%  +  " + currency + " " + fee + " Fee*"}</div> */}

                            <Text2 v={v} style={Object.assign({}, Style.textS, { marginTop:15 })}>Click the button below to link an existing Stripe account or create a new one</Text2>
                            <Button3 v={v} style={{ marginTop:15 }} onClick={() => {  window.open( `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${orderlinaStripeClientId}&scope=read_write`); }} >Connect with Stripe</Button3>
                            
                            <Text2 v={v} style={Object.assign({}, Style.textS, { marginTop:15 })}>Enter the code generated from the above step and click SAVE</Text2>
                            <div style={Object.assign({}, Style.flexRow, { marginTop:15 })}>
                                <Input2 v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} placeholder='Authorization Code'           value={this.state.authorizationCode} onChange={(e) => this.setState({ authorizationCode:e.target.value })} />
                                <Button3 v={v} onClick ={()=> this.authorizeCode(v) } style={{ marginLeft:10 }}>Save</Button3>
                            </div>
                        </div>
                    }   

                    { hint3({ v, text:"Stripe is currently available in over 30 countries, If your country is not listed the Stripe ATLAS service may provide an effective solution", style:{ marginTop:20 } })}
                </Card2>

                <Card2 v={v} icon='paypal' title='Paypal' message='Paypal Payments, Card Payments and More'  hide={!this.state.viewPaypal} onClick={()=>this.setState({viewPaypal:!this.state.viewPaypal})} dropDown >

                    <Text2 v={v} style={Object.assign({}, Style.textS, { textTransform:'none' })}>PayPal is a familiar and easy way for customers to pay. Follow the below steps to setup paypal on your venue.</Text2>
                    <Text2 v={v} style={Object.assign({}, Style.textS, { marginTop:15, textTransform:'none' })} varStart={whiteLabelConfig.businessName+" "} onClick={()=>window.open( `https://www.paypal.com/au/webapps/mpp/merchant-fees`)}>is a NO FEE NO COMMISSION Platform and does not charge additional fees. However PayPal Fees vary globally so please CLICK HERE to view fees in your locality.</Text2>
                    {/* <Text2 v={v} style={Object.assign({}, Style.textS, { marginTop:15, color:v.pc, textTransform:'none', fontWeight:'bold' })} onClick={()=>window.open( `https://www.paypal.com/au/webapps/mpp/account-selection`)}>To receive payments via PayPal you will need a API credentials from a PayPal Account, CLICK HERE to check eligible countries and create merchant account</Text2> */}

                    <Text2 v={v} twenty style={Object.assign({}, Style.textB, {  })}>Paypal Setup Instructions</Text2>

                    <Text2 v={v} twenty varStart2='Step 1.' style={Object.assign({}, Style.textS, {  })}>Click the below button to create a Paypal ClientId and ClientSecret as a Paypal Merchant</Text2>
                    <Button3 v={v} style={{ marginTop:15 }} onClick={() => {  window.open( `https://developer.paypal.com/developer/applications/`); }} >Go To Paypal</Button3>
                    
                    <Text2 v={v} twenty varStart2='Step 2.' style={Object.assign({}, Style.textS, {  })}>Under ‘My apps and Credentials’, click ‘Live’</Text2>
                    <Text2 v={v} twenty varStart2='Step 3.' style={Object.assign({}, Style.textS, {  })}>Then click ‘Create App’</Text2>
                    <Text2 v={v} twenty varStart2='Step 4.' style={Object.assign({}, Style.textS, {  })}>Enter an App name eg. '{whiteLabelConfig.businessName}' and click 'Create App'</Text2>
                    
                    <Text2 v={v} twenty varStart2='Step 5.' style={Object.assign({}, Style.textS, {  })}>On the next page, copy the CLIENT ID and SECRET and paste them below</Text2>
                    <Input2 v={v} twenty  placeholder='Client ID'           value={payments.payPalClientId}         onChange={(e) => this.handleChange('updatePayPalClientId', e.target.value) } />
                    <Input2 v={v} twenty  placeholder='Client Secret'       value={payments.payPalClientSecret}     onChange={(e) => this.handleChange('updatePayPalClientSecret', e.target.value) } />

                    <Text2 v={v} twenty varStart2='Step 7.' style={Object.assign({}, Style.textS, {  })}>Click the SAVE button below</Text2>
                    <Text2 v={v} twenty varStart2='Step 8.' style={Object.assign({}, Style.textS, {  })}>Done, your Paypal is now configured. If you don’t see the paypal option during the payment process you will have to enable it for the specific Guest Type via MANAGER > GUEST TYPE > PAYMENTS > PAYPAL</Text2>
                
                    {/* Instructions */}
                    <Text2 v={v} twenty style={Object.assign({}, Style.textSB, { })}>Optional Payment Instructions to Customers paying with PayPal</Text2>
                    <Input2 v={v} twenty placeholder='Instructions'          value={payments.payPalInstructions}     onChange={(e) => this.handleChange('updatePayPalInstructions', e.target.value) } />
            
                    {/* Clear Config */}
                    <Button style={Object.assign({}, Style.button, { backgroundColor: 'darkgrey', marginTop:20 })} onClick={() => this.handleChange('clearPayPalConfig') }>Clear PayPal Config</Button>
                    { hint3({ v, text:"PayPal is currently available in over 200 countries, If your country is not listed please check with our team for alternate options", style:{ marginTop:20 } })}
                </Card2>

                { SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, cancel:()=>this.mapStoreToState, save:()=>this.save }) }
            </div>
        )
    }


    renderMenuImage = (v) => {

        let info                = this.state.info
        let menuPDFActive       = info && info.menuPDFActive // DEPRECATED
        let menuImageActive     = info && info.menuImageActive
        let menuImages          = info && info.menuImages
        let off                 = !menuImageActive && !menuPDFActive
        let language            = v.language

        return (
                <Card2 v={v} onClick={()=>log2(menuImages)} icon='image' title='Image and PDF Menu' style={Object.assign({}, {  })} >

                    {/* <div style={Object.assign({}, Style.flexRow, { })}>
                        <Button3 v={v} onClick={()=> this.handleChange('handleDynamicMenu') }           inactive={!off}              text='off'     icon='close'    style={{flex:1}}  />
                        <Button3 v={v} onClick={()=> this.handleChange('handleImageMenuUseImages') }    inactive={!menuImageActive}  text='images'  icon='image'    style={{flex:1, marginLeft:10, marginRight:10}} />
                        <Button3 v={v} onClick={()=> this.handleChange('handleImageMenuUsePDF') }       inactive={!menuPDFActive}    text='pdf'     icon='pdf'      style={{flex:1}} />
                    </div> */}

                    <Switch2 v={v} button icon='image' text='Image and PDF Menu' description='enable to use as your default menu. Upload one or multiple images or pdfs. Provide an optional title for each file to create handy links for your customers.' checked={menuImageActive} onChange={()=> menuImageActive? this.handleChange('handleDynamicMenu'):this.handleChange('handleImageMenuUseImages') } style={{paddingTop:'1rem', paddingBottom:'1rem'}}  />

                    {/* DEPRECATED - REMOVE JAN 1ST */}
                    { menuPDFActive? RenderHelper7({ props:{ icon:'alert', style:{marginTop:20}, v, message:'you are using an older version of pdf menu. please enable the above switch to start using the new version.' }}) :null}

                    <div style={{marginTop:20}}>
                        { menuImages && Object.entries(menuImages).map((item,index) => { item = { ...item[1], key: item[0], index: index  }
                            return(
                                    <div key={item.key} style={{ display:'flex', alignItems:'flex-start', marginTop:15 }} >                                      
                                        <div key={item.key} style={{ display:'flex', flex:1, marginRight:15, flexDirection:'column' }}>
                                            <Input2 id={item.key} instructions='optional title' v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} style={Object.assign({}, Style.textS, Style.inputBasic, { fontWeight:'bold' })} placeholder="Title"  value={item["title_"+language] || item.title} onChange={(e) => this.handleChange('handleImageMenuTitleEdit', e.target.value, item.key) } />
                                            {/* <input style={Object.assign({}, Style.textS, Style.inputBasic, { color:'#A9A9A9' })} className="form-control input" placeholder={'IMG Url'}  value={item.image} onChange={(e) => this.handleChange('handleImageMenuImageUrlEdit', item.key, undefined, e.target.value)} /> */}
                                        </div>

                                        <Card style={{ display:'flex', flex: 1 }}>
                                            <div style={{ position:'absolute', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                                                <Button style={Object.assign({}, Style.smallBlackIcon, v.div4, {  })} onClick={() => this.handleChange('handleImageMenuDelete', item.key)}>{getIcon('trash','1.5rem')}</Button>
                                                <Button style={Object.assign({}, Style.smallBlackIcon, v.div4, {  })} onClick={() => this.handleChange('handleMoveImageDown', item.key)}>{getIcon2({icon:'arrowDownLong', fontSize:'1.5rem'})}</Button>
                                                <label style={Object.assign({}, Style.smallBlackIcon, v.div4, {  })}>{this.state['uploadingImage'+item.key]?getIcon2({icon:'loading',fontSize:'1rem',color:v.bt}):getIcon2({icon:'upload', fontSize:'1.3rem'})}<input size="60" type="file" onChange={(e)=> { this.handleChange('uploadMenuImage', item.key, e) }} /></label>
                                            </div>
                                            { item.image?.includes?.('.pdf')?
                                                <PdfViewer v={v} file={item.image} />
                                                :<img src={ item.image } onError={(e)=>defaultSrcBlank(e)} alt="PDF or Image" style={Style.image} />}
                                        </Card>
                                    </div>    
                        )})}
                        <Button onClick={() => this.handleChange('handleImageMenuAdd') } style={{ marginTop:30, width:'100%' }}>{getIcon('add','3rem')}</Button>
                    </div>

                    { hint3({v, style:{marginTop:20}, text:`Maximum Recommended Image filesize = 300KB. Large files impact customer experience.`}) }

                    { SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, cancel:()=>this.mapStoreToState, save:()=>this.save }) }
            </Card2>
        )
    }




    renderLanguages = ({v}) => {

        let info                = this.state.info
        let language            = v.language
        let appText             = this.state.appText
        let languageSelect      = this.state.languageSelect

        console.log('render', this.state )

        return (
            <div>
                <Card style={Object.assign({}, Style.card, {textAlign:'left', padding:'1.5rem'})} >

                    {getIcon('languages', '3rem')}

                    <div style={{marginTop:10, fontWeight:'normal'}}>

                    <div onClick ={()=> console.log(this.state.appText)} style={Object.assign({}, Style.textB, { })}>{getAppText2({v, text:'languages'})}</div>

                        { RenderHelper7({ props:{ v, icon:'alert', style:{marginTop:20}, title:'Select a language to add translation',  message:'NB. This page has multiple simultaneous editors. To avoid data loss, we recommend copy pasting values from another source and saving regularly.' }}) }

                        <div style={Object.assign({}, Style.flexRowOnly, { marginTop:20})}>
                            <div style={Object.assign({}, Style.textXSB, {flex:1, padding:'0.5rem'})}>ENGLISH</div>
                            <select value={languageSelect} onChange={(e) => this.setState({languageSelect:e.target.value}) } style={Object.assign({}, Style.textXSB, {flex:1, padding:'0.5rem', textTransform:'uppercase', backgroundColor:Style.ollightgrey, borderRadius:5 })}>
                                {   Object.entries(languagesCodes).map((item,index) => { item = { ...item[1], key: item[0], index:index  }
                                        return( <option style={Style.mInfoText} key={item.key} value={item.key}>{item.key}</option>)
                                })}
                            </select>
                        </div>

                        { languageSelect?
                            <div style={{marginTop:10}}>
                                {  appText && Object.entries(appText)
                                    .filter(item => !this.state.newEnglishText || item[0].toLowerCase().includes(this.state.newEnglishText.toLowerCase()) )
                                    .map((item,index) => { item = { ...item[1], key: item[0], index: index  }
                                    return(
                                        <div key={item.key} style={Object.assign({}, Style.flexRow, { justifyContent:'flex-start', alignItems:'flex-start', marginTop:10})}>
                                            <div style={Object.assign({}, Style.textXSB, {flex:1, padding:'0.5rem'})}>{item['english'] || item.key}</div>
                                            <input style={Object.assign({}, Style.textXS, { flex:1, backgroundColor:Style.ollightgrey, padding:'0.5rem', borderRadius:5 })} placeholder={""}     value={item[languageSelect]||""} onChange={(e) => this.handleChange2({ type:'languageEdit', key:item.key, value:e.target.value}) } />
                                        </div>
                                )})}

                                {this.state.enterEnglishText?
                                    <Input2 iconButton='add' id={'newEnglishTextInput'} v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} button={()=> this.handleChange2({ type:'addNewEnglishText' }) }  styleCont={Object.assign({}, { marginTop:20 })} instructions='Add Missing English Text' placeholder={'English Text'}   value={this.state.newEnglishText} onChange={(e) => this.setState({ newEnglishText:e.target.value }) } />
                                    :
                                    <div onClick={()=>this.setState({enterEnglishText:true})} style={Object.assign({}, Style.flexRow, { justifyContent:'flex-start', alignItems:'flex-start', marginTop:10})}>
                                        <div style={Object.assign({}, Style.textXSB, {flex:1, padding:'0.5rem', color:'#D50000'})}>Cannot find the English Text in this list? Click Here To Add It</div>
                                        <div style={Object.assign({}, Style.textXSB, {flex:1, padding:'0.5rem'})} />
                                    </div>
                                }

                                { hint3({  v, text:`Remember to Save Regularly`, style:{ marginTop:20 } })}
                            </div>
                        :null}

                        {this.state.toggelEditLanguage? <Button3 v={v} fixedBar icon='save' style={{ justifyContent:'center', zIndex:9999999 }} onClick ={()=> this.handleChange('languageSave')} /> :null}
                    </div>
                </Card>

                {this.backupAndRestoreAppText({v})}
            </div>
        )
    }

    renderMenuUpload = (v) => {

        let whiteLabelConfig    = v.wlc  

        return (
            <Card2 v={v} title='Menu Upload' icon='menu' >
                <div style={Object.assign({}, Style.text1,  Style.textTranformNone, {  })}>{ getAppText2({ v, text:'If you would like help creating your menu please email it to us and we will do it for you' })}</div>
                <div style={Object.assign({}, Style.text1B, Style.textTranformNone, { marginTop:20 })}>{whiteLabelConfig && whiteLabelConfig.supportEmail}</div>
            </Card2>
        )
    }

    renderRequestFeature = (v) => {

        let whiteLabelConfig    = v.wlc  

        return (
            <div style={Style.div1} >

                { getIsProCust(this.props)?
                    <Card style={Object.assign({}, Style.card, {textAlign:'left', padding:'1.5rem'})} >

                        {getIcon('star', '3rem')}

                        <div style={{marginTop:10, fontWeight:'normal'}}>
                            <div style={Object.assign({}, Style.textB, { textTransform:'uppercase' })}>{ getAppText(this.props, 'Request Feature')}</div>
                            <div style={Object.assign({}, Style.textS, { marginTop:15 })}>{ getAppText(this.props, 'If you would like a special feature on your menu please email us at the below address')}</div>
                            <div style={Object.assign({}, Style.textSB, { marginTop:15 })}>{whiteLabelConfig && whiteLabelConfig.supportEmail}</div>
                        </div>
                    </Card>
                    :<MSubscription {...this.props} />
                }

            </div>
        )
    }

    renderUsage = (v) => {

        let orderlinaStore 	    = {...this.props.orderlinaStore}
        let visitCount          = orderlinaStore.visitCount || 0
        let isProCust           = getIsProCust2({v})

        return (

            <div>
                <Card style={Object.assign({}, Style.card, { padding:0 })} >

                    { RenderHelper7({ props:{ v, icon:'speedometer', title:'Usage', style:{ boxShadow:'unset', borderRadius:'unset' } }}) }
                    <div style={{ padding:'1.5rem' }}>
                        <div style={Object.assign({}, Style.textMB, { textTransform:'uppercase' })}>{ getAppText2({v, text:Month})}</div>
                        <div style={Object.assign({}, Style.textSSB, { marginTop:15, textTransform:'uppercase' })}>{ getAppText2({v, text:'Visits'})}: {visitCount}</div>
                        {/* <div style={Object.assign({}, Style.textSSB, { marginTop:15, textTransform:'uppercase' })}>{ getAppText2({v, text:'Remaining'})}: {isProCust? "Unlimited":(200-visitCount)}</div> */}
                        {/* { hint2({ props:this.props, text:'Visits Reset Monthly', marginTop:20, fontSize:'0.9rem'}) } */}
                    </div>
                </Card>

                { isProCust? null: <MSubscription {...this.props} /> }
            </div>
        )
    }

    renderCheckIns = (v) => {

        let checkIns    = this.state.checkIns && Object.entries(this.state.checkIns)
                            .map(item => ({...item[1], key:item[0] }))
                            .sort(function(a, b) { return b.length - a.length })

        return (
            <div>
                <Card style={Object.assign({}, Style.card, {textAlign:'left', padding:'1.5rem'})} >
                    {getIcon('speedometer', '3rem')}
                    <div onClick={() => console.log(this.state) } style={Object.assign({}, Style.textMB, { marginTop:15, textTransform:'uppercase' })}>Covid Check In</div>

                    <div style={Object.assign({}, Style.flexRow, { justifyContent:'space-between', marginTop:10 })}>
                        <div onClick={() => console.log(this.state) } style={Object.assign({}, {  })}>{ getAppText2({ v, text:'Show Covid Check In Button' })}</div>
                        <Switch checked={this.state.info.showCheckInButton} color="secondary" onChange={() => this.handleChange('toggleShowCheckInButton')} />
                    </div>

                    {/* Year Month Selector */}
                    <div style={Object.assign({}, Style.flexRow, { })}>
                        { yearPicker({ year:this.state.year, change:(year)=> this.setState({ year }), style:{marginRight:5} }) }
                        { monthPicker({ month:this.state.month, change:(month)=> this.setState({ month }), style:{marginLeft:5} }) }
                    </div>

                    <Button style={Object.assign({}, Style.button100, {backgroundImage:v.lg})} onClick={() => { this.getCheckInData(v) } }>{this.state.gettingVisitData?getIcon('loading', '1rem', 'white'):"View"}</Button>

                    <div style={{marginTop:20}} />
                    { checkIns? 
                        <div>
                            { checkIns.map((item) => { 
                                return(
                                    <div key={item.key} style={Object.assign({}, Style.flexRowOnly, Style.textXSB, {justifyContent:'space-between', alignItems:'flex-start', margin:'0.5rem'})}>
                                        <div style={{width:30, display:'flex'}}>{getIcon2({icon:'checkin', fontSize:'1rem'})}</div>
                                        <div style={Style.flexColOnly}>
                                            <div style={Object.assign({}, Style.textXSB, { minWidth:90 })}>{item.DAYNUMBER}-{numToMON(item.MM)}-{item.YYYY}</div>
                                            <div style={Object.assign({}, Style.textXSB, { minWidth:90 })}>{item.HH}:{item.MIN}</div>
                                        </div>
                                        <div style={Object.assign({}, Style.flexRowOnly, Style.textXS, {flex:3})}>{item.name}, {item.email}, {item.phone}</div>
                                    </div>
                            )})}
                            <Button style={Object.assign({}, Style.button100, {backgroundImage:v.lg})} href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(checkIns))}`} download="Checkins.json">{ getAppText(this.props, 'Download Raw Data')}</Button>
                        </div>
                    :null}

                </Card>
                { SaveCancelButtons( this.state.toggleEdit, this.mapStoreToState, this.save ) }
            </div>
        )
    }

    renderForm = ({v}) => {

        // let checkIns    = this.state.checkIns && Object.entries(this.state.checkIns)
        //                     .map(item => ({...item[1], key:item[0] }))
        //                     .sort(function(a, b) { return b.length - a.length })

        let formData    = this.state.formData

        return (
            <div style={Style.flexColOnly}>

                <Card style={Object.assign({},Style.card, {textAlign:'left', fontWeight:'normal', padding:0})} >

                    { RenderHelper7({ props:{ v, icon:'page', h1:this.state.formTitle||'Form', style:{ borderRadius:'unset', boxShadow:'unset' } }}) }

                    <div style={Object.assign({}, Style.flexColOnly, {padding:'1.5rem'})}>
                        
                        <div style={Object.assign({}, Style.flexRow, { })}>
                            { yearPicker({ year:this.state.year, change:(year)=> this.setState({ year }), style:{marginRight:5, marginTop:0} }) }
                            { monthPicker({ month:this.state.month, change:(month)=> this.setState({ month }), style:{marginLeft:5, marginTop:0} }) }
                        </div>
                        <Button3 v={v} twenty onClick={() => { this.getFormData({v}) } } loading={this.state.gettingData} text='view' />

                        <div style={{marginTop:20, ...v.style.card, backgroundColor:'whitesmoke', overflow:'hidden' }}>
                        { formData && Object.entries(formData)
                            .map((item,index) => ({...item[1], key:item[0], index }))
                            .sort(function(a, b) { return b.index - a.index })
                            .map((item) => {

                            return(
                                <div key={item.key} style={Object.assign({}, Style.flexRowOnly, Style.textXSB, { overflow:'scroll' , border:'1px solid white' })}>
                                    
                                    <div style={{  ...Style.flexRowOnly }}>
                                        <div onClick={()=>this.deleteFormRow({ formRow:item })} style={{padding:'1rem'}}>{getIcon2({icon:'delete', fontSize:'1rem' })}</div>
                                        <div  title='Submission Date' style={Object.assign({}, Style.textXSB, { padding:'1rem', paddingLeft:0 })}>{numToDAY(item.DD)}-{item.DAYNUMBER}-{numToMON(item.MM)}-{item.HH}:{item.MIN}</div>
                                    </div>

                                    { Object.entries(item.fields).map((item,index) => { 

                                        let field   = item[1].field || item[0] || ""
                                        let value   = item[1].value || item[1] || ""
                                        let link    = value?.startsWith?.('http')
                                        return (
                                            link?
                                                <a   key={index} title={field} style={Object.assign({}, Style.flexRowOnly, Style.textXS, {flex:1, padding:'1rem', textTransform:'none', color:v.pc })} onClick={() => this.props.updateDialog({ pic:value, hideOkButton:true, closeClickAnywhere:true }) }>{field}</a>
                                                :
                                                <div key={index} title={field} style={Object.assign({}, Style.flexRowOnly, Style.textXS, {flex:1, padding:'1rem', textTransform:'none'                                                                                                                      })}>{value}</div>
                                        )
                                    })}
                                </div>
                        )})}
                        </div>

                        { DownloadButton({ v, data:formData, style:{alignSelf:'flex-end', marginTop:20} }) }
                    </div>
                </Card>

                { hint3({ v, link:()=>this.props.history.push("/manager/config/pages"), text:'To Create a New Form go to PAGES or Click Here', style:{ marginTop:20 } })}
            </div>
        )
    }

    renderAdminUsage = (v) => {

        let clients              = this.state.clients
        let allVisitsObj         = this.state.allVisits
        let allVisits            = allVisitsObj && Object.entries(allVisitsObj)
                                        .map(item => ({...item[1], key:item[0], length:Object.keys(item[1]).length, pro:item[1].pro, name:clients[item[0]] && clients[item[0]].name, title:clients[item[0]] && (clients[item[0]].title || clients[item[0]].name) || "DELETED", email:clients[item[0]] && clients[item[0]].email, domain:clients[item[0]] && clients[item[0]].domain }))
                                        .sort(function(a, b) { return b.length - a.length })
        let allVisitsCountriesOnly = allVisits && allVisits.map(item=> item = item.country||'UNSPECIFIED' )
        let clientCount         = allVisits && Object.keys(allVisits).length
        let clientProCount      = allVisits && allVisits.filter(item => item.pro===true ).length
        let customerUsage       = 0
        allVisitsObj && Object.entries(allVisitsObj).map(item => { customerUsage += Object.keys(item[1]).length })

        return (
            <div>
                <Card style={Object.assign({}, Style.card, {textAlign:'left', padding:'1.5rem'})} >
                    {getIcon('speedometer', '3rem')}
                    <div onClick={() => console.log(this.state) } style={Object.assign({}, Style.textMB, { marginTop:15, textTransform:'uppercase' })}>Admin Usage</div>

                    {/* Year Month Selector */}
                    <div style={Object.assign({}, Style.flexRow, { })}>
                        { yearPicker({ year:this.state.year, change:(year)=> this.setState({ year }), style:{marginRight:5} }) }
                        { monthPicker({ month:this.state.month, change:(month)=> this.setState({ month }), style:{marginLeft:5} }) }
                    </div>

                    <Button3 v={v} twenty onClick={() => { this.getVisitsData(); this.handleChange('getWhiteLabelConfigAll') } }>{this.state.gettingVisitData?getIcon('loading', '1rem', 'white'):"Run Report"}</Button3>


                    { allVisits && clients ?
                        <div>
                            <Button style={Style.button100} onClick={() => { this.getIsProCustData(v) } }>
                                <div style={Style.flexCol}>
                                    {this.state.gettingIsProCustData?getIcon('loading', '1rem', 'white'):"Check Pro"}
                                    <div style={Object.assign({}, Style.textXS, { textAlign:'center' })}>Use Sparingly, will make {clientCount} server calls</div>
                                </div>
                            </Button>

                            <Button style={Style.button100} onClick={() => { this.getHasSpecificValue(v) } }>
                                <div style={Style.flexCol}>
                                    {this.state.gettingIsProCustData?getIcon('loading', '1rem', 'white'):"Check Specific Value"}
                                    <div style={Object.assign({}, Style.textXS, { textAlign:'center' })}>Use Sparingly, will make {clientCount} server calls</div>
                                </div>
                            </Button>

                            <div style={{marginTop:20}} />
                            <div style={Style.flexRow}>
                                <div style={Object.assign({}, Style.badge, {})}>Clients: {clientCount}</div>
                                <div style={Object.assign({}, Style.badge, {})}>Customer Usage: {customerUsage}</div>
                                {clientProCount? <div style={Object.assign({}, Style.badge, {})}>Pro: {clientProCount}</div>:null}
                            </div>

                            <div style={{marginTop:20}} />
                            { allVisits.map((item,index) => { 
                                return(
                                    <div  key={item.key} style={Object.assign({}, Style.flexRow, {justifyContent:'space-between'})}>
                                        <div style={Object.assign({}, Style.flexRow, {justifyContent:'flex-start'})}>
                                            <div style={Object.assign({}, Style.flexRow0, {width:'2rem', minWidth:'2rem'})}>{item.customValue?getIcon2({icon:'tickCircleSolid', color:'red'}):null}</div>
                                            <div onClick={()=>console.log( 'sub:',item.subscription, 'plan:', item.plan)}   style={Object.assign({}, Style.flexRow0, {width:'2rem', minWidth:'2rem'})}>{getIcon2({icon:item?.subscription?.status=='active'?'tickCircleSolid':item?.subscription||item.plan?'RadioButtonSelected':'RadioButton'})}</div>
                                            <div style={Object.assign({}, {width:'2rem', minWidth:'7rem', fontSize:'0.5rem'})}>{item.domain}</div>
                                            <div style={Object.assign({}, {width:'2rem', minWidth:'7rem', fontSize:'0.5rem'})}>{item.country||'UNSPECIFIED'}</div>

                                            <div onClick={() => this.handleChange('sendExceededEmail', item) } style={Object.assign({}, Style.flexRow0, {width:'2rem', minWidth:'2rem'})}>{getIcon('email', undefined, item.email?'black':'white')}</div>
                                            <div onClick={() => {  this.props.clientSync({ clientKey:item.key }); setTimeout(()=>{ handleLink3({ v, newTab:'/manager' })}, 2000) }}>{item.title}</div>
                                            <div style={Object.assign({}, { minWidth:'2rem'})}>{getIcon2({ icon:'openInNewTab', marginLeft:'2rem', onClick:()=>{  window.open( '/'+trim(item.name) ) } })}</div>
                                            <div onClick={() =>{ Firebase.database().ref("clients/" + item.key + "/info/subscription" ).set("") }}  style={Object.assign({}, { padding:'0.5rem', marginLeft:10, fontSize:'0.8rem', color:'white', backgroundColor:'red', borderRadius:99})}>Clear Sub</div>
                                            <div onClick={() =>{ Firebase.database().ref("clients/" + item.key + "/info/plan" ).set("") }}          style={Object.assign({}, { padding:'0.5rem', marginLeft:10, fontSize:'0.8rem', color:'white', backgroundColor:'red', borderRadius:99})}>Clear Plan</div>
                                        </div>
                                        <div onClick={() => { console.log(item)}}>{Object.keys(item).length}</div>
                                    </div>
                            )})}
                            { DownloadButton({ v, data:allVisitsCountriesOnly, style:{alignSelf:'flex-end', marginTop:20} }) }
                        </div>
                    :
                    null}

                    {/* <Button3 v={v} onClick={() => { this.customScript({ v }) } } text={`run custom script 1 on ${v.venueTitle}`} /> */}

                </Card>
            </div>
        )
    }

    getVisitsData = () => {

        this.setState({ gettingVisitData:true })
        Firebase.database().ref("client_idx/")
            .once('value') 
            .then( snapshot => {
                console.log('got clients');
                this.setState({ clients:snapshot.val() })
                // this.props.v.updateSimple({ clients:snapshot.val() } )

                let year    = this.state.year || YYYY
                let month   = this.state.month || MM

                Firebase.database().ref("reports/visits/" + year + "/" + month )
                    .once('value') 
                    .then(snapshot => { console.log('got visits'); 
                        // this.props.v.updateSimple({ allVisits:snapshot.val() } ); 
                        this.setState({ allVisits:snapshot.val(), gettingVisitData:false }) 
                    })
                    .catch((error)=> { console.log(error) })
            })
            .catch((error)=> console.log(error))
    }

    getCheckInData = (v) => {

        this.setState({ gettingVisitData:true })

        Firebase.database().ref("reports/checkins/" + v.clientKey + "/" + this.state.year + "/" + this.state.month )
            .once('value') 
            .then(snapshot => { console.log('got checkins'); this.setState({ checkIns:snapshot.val(), gettingVisitData:false }) })
            .catch((error)=> { console.log(error) })
    }

    getIsProCustData = (v) => {

        this.setState({ gettingIsProCustData:true })
        let allVisits       = {...this.state.allVisits}

        allVisits && Object.entries(allVisits).map(item => { 

            let visits = Object.keys(item[1]).length
            if (visits>0) {
                let clientKey = item[0]
                setTimeout(()=>{  
                    Firebase.database().ref("clients/" + clientKey + "/info" )
                        .once("value")
                        .then((snap) => { 
                            allVisits[clientKey].subscription   = snap.val()?.subscription || ""
                            allVisits[clientKey].plan           = snap.val()?.plan || ""
                            allVisits[clientKey].country        = snap.val()?.country || ""
                            this.setState({ allVisits })
                        })
                        .catch((error) => { console.log('sub no 2'); handleError({ v,  error, props:this.props }); this.setState({ modal1: [false, ''], loading: false }) })
                }, 300)
            }
        })

        this.setState({ gettingIsProCustData:false })
    }

    getHasSpecificValue = (v) => {

        this.setState({ gettingIsProCustData:true })
        let allVisits       = {...this.state.allVisits}

        allVisits && Object.entries(allVisits).map(item => { 

            let visits = Object.keys(item[1]).length
            if (visits>0) {
                let clientKey = item[0]
                setTimeout(()=>{  
                    Firebase.database().ref("clients/" + clientKey + "/info" )
                        .once("value")
                        .then((snap) => { 
                            let clientNode              = snap?.val() || ""
                            let clientNodeJsonString    = JSON.stringify(clientNode)
                            let string                  = 'firebasestorage.googleapis.com/v0/b/orderlina-single-region'
                            let string2                 = 'firebasestorage.googleapis.com/v0/b/orderlina-dev'
                            let venueUrl                = trim(clientNode.name)

                            // if (clientNodeJsonString.includes(string2)) {
                            //     let instances = countInstances(string2, clientNodeJsonString)
                            //     console.log('Custom value:', clientNode.name, clientNode.title, instances, clientKey, `http://localhost:3000/${trim(clientNode.name)}` )  
                            //     allVisits[clientKey].customValue = true
                            //     this.setState({ allVisits })
                            // }

                            // if (clientNode?.payments?.connectedStripeAccountId && 
                            //     !clientNode?.countryCodeAlpha2) {
                            //     console.log('Custom value:', clientNode.name, clientNode.title, clientKey)  
                            //     allVisits[clientKey].customValue = true
                            //     this.setState({ allVisits })
                            // }

                            // Check if new ClientSearch Node exists
                            Firebase.database().ref("clientSearch/" + venueUrl + "/clientKey")
                                .once('value') 
                                .then(snap => { if (!snap.val()) console.log('NO CLIENT SEARCH:', clientNode.name, clientNode.title, clientKey, `http://localhost:3000/${trim(clientNode.name)}` ) })
                                .catch((error)=> { console.error(error) })                    

                        })
                        .catch((error) => { handleError({ v,  error, props:this.props }); this.setState({ modal1: [false, ''], loading: false }) })
                }, 2000)
            }
        })

        this.setState({ gettingIsProCustData:false })
    }

    customScript = ({ v }) => {

        return 
        let loc = Firebase.database().ref("clients/" + v.clientKey + "/info/menuItemGroupsNew" )

        
        loc.once("value")
            .then((snap) => { 

                let menu = snap?.val()

                console.log('xx', menu)

                menu && Object.entries(menu).map(item => { item = { ...item[1], key: item[0]  }

                    menu[item.key].menuTitle_thai = ""

                    let menuItems = item.menuItems
                    menuItems && Object.entries(menuItems).map(item2 => { item2 = { ...item2[1], key: item2[0]  }
                        console.log('xx', menu[item.key].menuItems[item2.key].title_thai)
                        console.log('xx', menu[item.key].menuItems[item2.key].description_thai)

                        menu[item.key].menuItems[item2.key].title_thai          = ""
                        menu[item.key].menuItems[item2.key].description_thai    = ""
                    })


                })
                console.log('xx', menu)


                if (menu) {
                    loc.update(menu)
                       .then(() => {  console.log('updated') })
                       .catch((error) => { handleError({ v,  error, props:this.props }) })    
                }             

            })
            .catch((error) => { handleError({ v,  error, props:this.props }); this.setState({ modal1: [false, ''], loading: false }) })
    }

    renderPos = (v) => {

        let posSection          = this.state.posSection||""
        let info                = v.info
        let menuItemGroupsNew   = info.menuItemGroupsNew
        let basketItemCount     = getBasketItemCount({v})
        let basketTotal         = v.basket?.total || 0

        return (
            <div style={Style.flexColOnly}>

                {/* Header */}
                <div style={Object.assign({}, Style.flexRow, { justifyContent:'flex-start', alignItems:'center' })}>
                    <Button style={Object.assign({}, Style.posLinks, !posSection||posSection.includes('menu')?v.div4:null, {})} onClick={() => this.setState({posSection:'menu'}) }>{getIcon2({icon:'menu'})}</Button>
                    <Button style={Object.assign({}, Style.posLinks, posSection.includes('location')?v.div4:null, {})} onClick={() => this.setState({posSection:'location'}) }>{getIcon2({icon:'marker'})}</Button>
                    <Button style={Object.assign({}, Style.posLinks, posSection.includes('verify')?v.div4:null, {})} onClick={() => this.setState({posSection:'verify'}) }>{getIcon2({icon:'user'})}</Button>
                    <Button style={Object.assign({}, Style.posLinks, posSection.includes('sendOrder')?v.div4:null, {})} onClick={() => this.setState({posSection:'sendOrder'}) }>{getIcon2({icon:'send'})}</Button>
                </div>

                { this.renderPosSection({v, basketItemCount, basketTotal}) }

                { this.renderPosNavButton({ v, posSection, basketItemCount, basketTotal }) } 
            </div>
        )
    }

    renderPosNavButton = ({ v, posSection, basketItemCount, basketTotal }) => {

            if      (posSection==='menuCategoriesItem')             return null
            else if (!posSection || posSection.includes('menu'))    return <ButtonNav v={v} delay={0} onClick={ () => this.setState({posSection:'location'}) }><div style={Style.flexRow0}>{ getIcon2({ icon:'basket', marginRight:'0.3rem' }) }{basketItemCount}</div><div>{v.info.currencySymbol}{" "}{basketTotal}</div>{getIcon2({icon:'next', fontSize:'2rem' })}</ButtonNav>
            else if (posSection.includes('location'))               return <ButtonNav v={v} delay={0} onClick={ () => this.setState({posSection:'verify'}) }><div style={Style.flexRow0}>{ getIcon2({ icon:'basket', marginRight:'0.3rem' }) }{basketItemCount}</div><div>{v.info.currencySymbol}{" "}{basketTotal}</div>{getIcon2({icon:'next', fontSize:'2rem' })}</ButtonNav> 
            else if (posSection.includes('verify'))                 return <ButtonNav v={v} delay={0} onClick={ () => this.setState({posSection:'sendOrder'}) }>{getAppText2({v, text:'Next'})}{getIcon2({icon:'next', fontSize:'2rem' })}</ButtonNav> 
            else if (posSection.includes('sendOrder'))              return <ButtonNav v={v} delay={0} onClick={ () => placeOrder({ props:this.props, v, then:()=>{ this.setState({posSection:'orderSubmitted'}) } }) }>{getAppText2({v, text:'Send Order'})}{getIcon2({icon:'next', fontSize:'2rem' })}</ButtonNav>
            else if (posSection.includes('orderSubmitted'))         return <ButtonNav v={v} delay={0} onClick={ () => this.setState({posSection:'menu'}) }>{getIcon2({icon:'back', fontSize:'2rem' })}{getAppText2({v, text:'New Order'})}</ButtonNav> 
            else return null
    }

    renderPosSection = ({ v, basketItemCount, basketTotal }) => {

        let posSection          = this.state.posSection
        let language            = v.language
        let item                = this.state.item
        let info                = v.info
        let menuItemGroupsNew   = info.menuItemGroupsNew

        if (posSection==="menuCategories") {
            return (
                <div style={{flex:7}}>
                    { Object.entries(menuItemGroupsNew[this.state.menuCategory].menuItems).map((item,index) => ({...item[1], key:item[0], index }))
                        .filter(item => item.active)
                        .sort(function(a, b) { return a.index - b.index })
                        .map((item) => {
                            return <Button key={item.key} onClick={() => item.variance?this.setState({posSection:'menuCategoriesItem', item}):addItem({ v, item, props:this.props, afterAddItem:()=>this.setState({posSection:"location"}) }) } style={Style.posLinks}>{item["title_"+language] || item.title}</Button>
                    })}
                </div>
            )
        }
        else if (posSection==="menuCategoriesItem" && item)     return <Item item={this.state.item} v={v} pos={true} {...this.props} backToPos={()=>this.setState({posSection:"location"})} />
        else if (posSection==='location')                       return <Location v={v} pos={true} {...this.props} backToPos={()=>this.setState({posSection:'verify'})} />
        else if (posSection==="verify")                         return <Verify v={v} pos={true} {...this.props} backToPos={()=>this.setState({posSection:'sendOrder'})} />
        else if (posSection==="sendOrder")                      return <Total v={v} pos={true} {...this.props} />
        // (
        //     <div style={Style.flexColOnly}>

        //         { renderNotes({ props:this.props, v, pos:true }) }
        //     </div>
        // )
        else if (posSection==='orderSubmitted')                 return renderHelper6({ v, shouldRender:true, title:"Order Submitted", buttonStyle:{ margin:4 } })

        // Menu Categories
        else {
            return (
                <div style={{flex:1}}>
                    { renderGuestTypeButtonsMini({ props:this.props, v, buttonStyle:{ fontSize:'0.75rem', flex:1 }, divStyle:{ overflow:'scroll' } }) }
                    { Object.entries(menuItemGroupsNew).map((item,index) => ({...item[1], key:item[0], index }))
                        .filter(item => item.active)
                        .sort(function(a, b) { return a.index - b.index })
                        .map((item) => {
                            return <Button key={item.key} onClick={() => this.setState({posSection:"menuCategories", menuCategory:item.key}) } style={Style.posLinks}>{item["menuTitle_"+language] || item.menuTitle}</Button>
                    })}
                </div>
            )
        }
        
        // else {
        //     return (
        //         <div style={Object.assign({}, Style.flexCol, {alignItems:'unset'})}>
        //             { renderGuestTypeButtonsMini({ props:this.props, v, buttonStyle:{ fontSize:'0.75rem', flex:1 } }) }
        //             { v.guestType?
        //                 <div style={Style.flexColOnly}>
        //                     <Button onClick={() => this.setState({posSection:'menu'}) }                        style={Style.posLinks}>{getAppText2({ v, text:'Menu' })}</Button>
        //                     <Button onClick={() => this.setState({posSection:'location'}) }                    style={Style.posLinks}>{getAppText2({ v, text:'Location' })}</Button>
        //                     <Button onClick={() => this.setState({posSection:'sendOrder' }) }            style={Style.posLinks}>{getAppText2({ v, text:'Notes' })}</Button>
        //                     { basketItemCount? 
        //                         <div style={Object.assign({}, Style.flexColOnly, Style.borderRadius, { boxShadow:Style.boxShadow, margin:4  })}>
        //                             <Button onClick={() => this.setState({posSection: posSection?null:'viewBasket' }) }   style={Object.assign({}, Style.posLinks, { boxShadow:'unset' })}>{info.currencySymbol}{basketTotal||0}{getIcon2({ icon:'basket', marginLeft:'1rem', marginRight:'0.2rem' })}{basketItemCount||0}</Button>
                                    
        //                             {posSection==='viewBasket' ?
        //                                 <div style={Style.flexColOnly}>
        //                                     {/* Basket */}
        //                                     { posSection==='viewBasket'? <Total v={v} pos={true} {...this.props} /> :null }
                                            
        //                                     {/* Submit Buttons */}
        //                                     <div style={Object.assign({}, Style.flexRow, {padding:'1rem'})}>
        //                                         <Button onClick={() => { }} style={Object.assign({}, Style.posLinks, { })}>{getIcon2({ icon:'trash' })}</Button>
        //                                         <Button onClick={() =>  basketItemCount?{}: null } style={Object.assign({}, Style.posLinks, basketItemCount?v.button:null, { flex:1 })}>{getAppText2({ v, text:'Submit' })}{getIcon2({ icon:'send', marginLeft:'1rem' })}</Button>
        //                                     </div>
        //                                 </div>
        //                             :null}
        //                         </div>
        //                     :null}
        //                 </div>
        //             :null}
        //         </div>
        //     )
        // }
    }

    renderDietTags = (v) => {

        let info                = this.state.info
        let dietTags            = info && info.dietTags
        let language            = v.language

        return (
            <div style={Object.assign({}, Style.card, Style.textSSB, Style.flexColOnly, { padding:'2rem' })}>

                <div style={Object.assign({}, Style.flexRow, Style.textMB, { justifyContent:'space-between' })}>
                    {getAppText2({ v, text:'Diet Tags' })}
                    {!dietTags? <Switch checked={dietTags} color="secondary" onChange={() => this.handleChange('handleActivationDietTags')} /> :null}
                    {logo2({ img:'stickerPreparing',  size:'2rem', style:{ borderRadius:0, boxShadow:'unset' } })}
                </div>

                { dietTags?
                    <div style={Object.assign({}, Style.flexColOnly, {marginTop:20})}>
                        { Object.entries(dietTags).map((item,index) => { item = { ...item[1], key: item[0], index  }
                            return (
                                <div key={item.key} style={Object.assign({}, Style.flexRow, {justifyContent:'space-between', marginTop:10})}>
                                    <input key={item.key}  style={Object.assign({}, Style.textS, Style.input, v.input, { marginRight:5 })} placeholder="Tag"  value={item["name_"+language] || item.name} onChange={(e) => this.handleChange('handleDietTagEdit', e.target.value, item.key) } />
                                    <Button onClick={() => this.handleChange('handleDietTagDelete', item.key) } style={v.button}>{getIcon2({ icon:'delete' })}</Button>
                                </div>
                            )
                        })}
                        <Button onClick={() => this.handleChange('handleDietTagAdd') } style={{ marginTop:30, width:'100%' }}>{getIcon('add','3rem')}</Button>
                    </div>
                :null}

                { SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, cancel:()=>this.mapStoreToState, save:()=>this.save }) }
            </div>
        )
    }

    renderMarketing = (v) => {

        let info            = this.state.info
        let language        = v.language

        return (
                <Card style={Object.assign({}, Style.card, Style.flexColOnly, { padding:0 })}>

                    { RenderHelper7({ props:{ v, icon:'facebook', title:'Facebook Pixel', style:{ boxShadow:'unset', borderRadius:'unset' } }}) }

                    <div style={{padding:'1.5rem'}}>
                        <Text2 v={v} style={Object.assign({}, Style.textS, { fontWeight:'normal' })}>Enter your venue's Facebook Pixel to start collecting your customer data</Text2>
                        <Input2 v={v} setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus} twenty placeholder='Enter Facebook Pixel'            value={info.facebookPixel} name="facebookPixel" onChange={ (e) => this.handleChange('facebookPixel', e.target.value) } />
                        { hint3({ v, text:'Click Here to find out more about Facebook Pixel', link:'https://www.facebook.com/business/learn/facebook-ads-pixel', style:{ marginTop:20, fontSize:'0.9rem' } })}
                    </div>

                    { SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, cancel:()=>this.mapStoreToState, save:()=>this.save }) }
                </Card>
        )

    }

    backupAndRestore = ({ v }) => {

        let backups = this.state.backups

        console.log('bb', backups)
        if (backups==undefined) return <Loading v={v} />
        else {
            return (
                <Card2 v={v} icon="save" title='backup and restore' message='Create and Restore Previous Versions of Your Venue' >
                    
                    { typeof backups==='object' && Object.entries(backups).map((item) => { item = { ...item[1], key:item[0]  } 
                        return (  
                            <div style={{...Style.flexRowOnly }}>
                                <Text2 v={v} icon='archive' text={getFormattedLocalTime2(item.time)} />
                                <Text2 v={v} text='Restore' style={{color:v.pc, flex:0}}  onClick={async ()=>{ await backup({ v, type:'restore', info:item.info }) }}  />
                                <div style={{padding:'1rem'}}>{getIcon2({ icon:'delete', onClick:async ()=>{ await backup({ v, type:'delete', key:item.key }); this.getBackups({v}) }})}</div>
                            </div>
                        )
                    })}

                    <Button3 v={v} icon={this.state.lb=='crbu'?'loading':'add'} twenty onClick={async()=>{ this.setState({lb:'crbu'}); await backup({ v, type:'create' }); this.getBackups({v}) }} text='Create Backup' />                    


                    {isGod2({v})?
                        <div style={Style.flexColOnly}>
                            <Button3 v={v} twenty text={'download config for this venue'} icon='download' onClick={() => download3({ data:v.client.info, filename:v.venueUrl }) }  />
                            
                            <Button3 v={v} twenty text={'upload config for this venue'}   icon='upload' upload={(value)=> {

                                let info         = JSON.parse(value)   
                                backup({ v, type:'restore', info })
                            }} />
                        </div>
                    :null}

                </Card2>
            )
        }
    }


    renderBookingConfig = ({ v }) => {

        let bookingConfig = this.state.bookingConfig
        let spaces        = bookingConfig?.spaces || []

        return (
            <div>
                <Card2 v={v} icon="settings" title='spaces' >
                    

                    <div style={{display:'flex', flexDirection:'row', overflow:'scroll', padding:5 }}>

                        { Object.entries(spaces).map(item=>{ item = { ...item[1], key: item[0] } 

                            let style   = {borderRadius:0}
                            let fields  = [
                                { name:'active',                                switchShow:true, },
                                { name:'name'                                   },
                                { name:'description'                            },
                                { name:'price'                                  },
                                { name:'occupancy'                              },
                                { name:'quantity'                               },
                                { name:'quantityAvailable'                      },
                                { name:'breakfast',                             switchShow:true,    },
                                { name:'childAllowed',                          switchShow:true,    },
                                { name:'childStaysFree',                        switchShow:true,    },
                                { name:'childMaxAgeMonths'                      },
                                { name:'childCotAvailable',                     switchShow:true,   },
                                { name:'payAtHotel',                            switchShow:true,    },
                                { name:'wifi',                                  switchShow:true,   },
                                { name:'windows'                                },
                                { name:'bathrooms'                              },
                                { name:'areaSquareMeter'                        },
                                { name:'freeCancellation',                      switchShow:true, },
                                { name:'freeCancellationHoursFromBooking'       },
                                { name:'freeCancellationHoursPriorToArrival'    }
                            ]

                            return (
                                <div style={{minWidth:300}}>

                                    { fields.map(field=>{
                                        let {name, switchShow} = field

                                        return <Input2 v={v} noIcon value={switchShow? item[name]?'YES':'NO' : item[name]||"" }                placeholder={splitCamelCase(field.name)}        styleInput={style}  onChange={(e)=>this.handleChange3({ type:'spaceValue', key:item.key, key2:field.name, value:e.target.value })}    switchShow={field.switchShow} checked={item[field.name]} switchOnChange={(value)=>{ this.handleChange3({ type:'spaceValue', key:item.key, key2:field.name, value }) }}     />
                                    })}
                                    {/* COPY/DELETE */}
                                    <div style={{...Style.flexRow, marginTop:5 }}>
                                        <Button3 v={v} icon='copy'  onClick={()=> this.handleChange3({ type:'spaceCopy',    key:item.key }) } style={{...v.div, boxShadow:'none' }} />
                                        <Button3 v={v} icon='trash' onClick={()=> this.handleChange3({ type:'spaceDelete',  key:item.key }) } style={{...v.div, boxShadow:'none', color:'red' }} />
                                    </div>
                                </div>
                            )
                        })}
                        <Button3 v={v} icon='add' style={{...Style.buttonIconCircle, margin:'0.75rem'}} onClick={()=>this.setState({ bookingConfig: { ...bookingConfig, spaces:{ ...spaces, [PushId()]:{name:'New Room'} }}, toggleEdit:'bookingConfig' })} />
                    </div>   
                
                </Card2>

                <Card2 v={v} icon="settings" title='booking config'>
                    
                    
                
                </Card2>


                { SaveCancelButtons3({ v, show:this.state.toggleEdit, lb:this.state.lb, save:()=>this.save2({ data:this.state.bookingConfig, location:'bookingConfig' }) }) }
            </div>
        )
    }

    backupAndRestoreAppText = ({ v }) => {

        if (isGod2({v})) return (
            <Card2 v={v} icon="download" title='backup and restore'>
                
                <Button3 v={v} twenty text={'download AppText for App'} icon='download' onClick={() => download3({ data:this.state.appText, filename:'Apptext' }) }  />
                <Button3 v={v} twenty text={'upload AppText for App'}   icon='upload'   upload={(value)=> {

                    if (window.confirm(`Have you taken a backup?`)) this.setState({ appText:JSON.parse(value), toggelEditLanguage:true })
                }} />
            </Card2>
        )
        else return null
    }
       
    renderProfitAndLoss = ({v}) => {

        let info            = this.state.info
        let language        = v.language

        return (
                <Card style={Object.assign({}, Style.card, Style.flexColOnly, { padding:0 })}>

                    { RenderHelper7({ props:{ v, icon:'page', title:'Proft and Loss', style:{ boxShadow:'unset', borderRadius:'unset' } }}) }

                    <div style={{padding:'1.5rem'}}>
                        <div style={Object.assign({}, Style.textS, { fontWeight:'normal' })}>Enter non-app revenue, expenses, then select a date range to calculate profit</div>
                    </div>

                </Card>
        )

    }


    renderPages = ({v}) => {

        let info            = this.state.info
        let wlcKey          = v.searchParams.get("wlcKey")
        let pages           = wlcKey? this.state.whiteLabelConfig?.pages: info.pages
        let pageTemplates   = this.state.pageTemplates?  Object.entries(this.state.pageTemplates).map(item => ({  ...item[1], title:'template '+item[1].title, key:item[0] })) : []

        return (
            <div style={Style.flexColOnly}>
                { RenderHelper7({ props:{ v, icon:'page', h1:'Pages', style:{marginTop:20, ...v.div4 }, onClick:() => log2(this.state, this.props)  }}) }

                { pages && Object.entries(pages).map((item,index) => { item = { ...item[1], uid:item[0], key:item[0], index  } 
                    return (  
                        <RenderHelper7 props={{ v, key:item.key, style:{marginTop:15} }}>
                            <div style={Object.assign({}, Style.flexRow, { })}>
                                <div style={Object.assign({}, Style.textSSB, { flex:1, paddingLeft:10 })}>{item.title}</div>
                                <Button style={Object.assign({}, Style.buttonIconCircle, v.div4, {})} onClick={() => this.handleChange2({type:'pageCopy', key:item.key}) }>{getIcon2({icon:'copy', fontSize:'1.5rem' })}</Button>
                                <Button style={Object.assign({}, Style.buttonIconCircle, v.div4, {})} onClick={() => this.props.history.push(`/manager/config/editpage?pageKey=${item.key}${wlcKey?`&wlcKey=${wlcKey}`:""}`) }>{getIcon2({icon:'edit', fontSize:'1.5rem' })}</Button>
                                <Button style={Object.assign({}, Style.buttonIconCircle, v.div4, {})} onClick={() => this.handleChange2({type:'pageDownload', key:item.key}) }>{getIcon2({icon:'download', fontSize:'1.5rem' })}</Button>
                                <Button3 style={Object.assign({}, Style.buttonIconCircle, {})} v={v} icon='upload' upload={(value)=> this.handleChange2({type:'pageUpload', key:item.key, value}) } />
                                <Button style={Object.assign({}, Style.buttonIconCircle, v.div4, {})} onClick={() => this.handleChange2({ type:'pageDelete', key:item.key}) }>{getIcon2({icon:'trash', fontSize:'1.25rem' })}</Button>
                            </div>
                        </RenderHelper7>
                )})}

                <Select3 v={v} icon='add' twenty placeholder={'add'} array={[ {title:'blank page', key:'blankPage' }, ...pageTemplates ]} optionValue='key' optionName='title' onChange={(value) => this.handleChange2({type:'pageAdd', key:value}) } styleSelect={{fontSize:'unset'}} />

                { SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, cancel:()=>this.mapStoreToState, save:()=>this.save }) }
            </div>
        )
    }

    renderPage = ({v}) => {
        return <Page style={{marginTop:20}} v={v} {...this.props} />
    }

    renderQr = ({v}) => {
        return (
            <div>
                <Card2 v={v} title='QR' icon='qr'>
                    <GetQR v={v} />
                </Card2>

                {this.renderFlyers({v})}
            </div>
        )
    }


    renderFlyers = ({v}) => {

        let FlyerTemplates  = 'https://docs.google.com/presentation/d/1WY06DNcLqixRCaQnJ6iLCXDyZcOH6qfPWFYaprA9nOQ/edit#slide=id.g6b9904d91f_0_0'
        
        return (
            <Card2 v={v} title='Flyer' icon='page'>

                <Text2 v={v} style={Object.assign({}, { })}>Paste the above QR into these templates to give your customers easy and contactless access to your venue</Text2>
                <Button3 icon='page' twenty v={v} onClick={()=>window.open(FlyerTemplates)} text='VIEW FLYER TEMPLATES' />
            </Card2>
        )
    }

    renderQueues = ({v}) => {

        let info              = this.state.info
        let queueDefault      = info && info.queueDefault
        let queues            = info && info.queues || {}
        let language          = v.language

        console.log('qq', queues)
        return (
            <div style={Style.flexColOnly}>

                { RenderHelper7({ props:{ v, icon:'queue', message:`Create and Edit Order Queues Here, To view orders for a queue, go to ORDERS and filter by Order Queue`, title:'Queues', style:{marginTop:20} }}) }

                { queues && Object.entries(queues).map((item,index) => { item = { ...item[1], key: item[0], index  } 
                            
                    let queue = item || {}
                    let show  = this.state[item.key]
                    return (
                        <Card2 v={v} icon='page' title={queue["name_"+language] || queue.name} key={item.key} dropDown helperStyle={show?v.div4:v.div} onClick={() => this.setState({ [item.key]:!show })} >
                            
                            {show?
                                <div style={Style.flexColOnly}>
                                    <Input2     v={v} placeholder='Queue Name' value={queue["name_"+language] || queue.name}     onChange={(e) => this.handleChange2({ v, type:'editQueueName', queue, value:e.target.value }) } />
                                    <Switch3    v={v} text='Default' button twenty checked={queue.key==queueDefault}     onChange={() => this.handleChange2({ v, type:'setDefaultQueue', queue })} />
                                    <Button      onClick={() => this.handleChange2({ v, type:'deleteQueue', queue }) } style={Object.assign({}, Style.buttonIconCircle, { marginTop:20 })}>{getIcon2({ icon:'delete', fontSize:'1rem' })}</Button>
                                </div>
                            :null}
                        </Card2>
                    )
                })}

                <Button onClick={() => this.handleChange2({ v, type:'addQueue' })} style={{ marginTop:30, width:'100%' }}>{getIcon2({ icon:'addCircle', fontSize:'3rem' })}</Button>

                { SaveCancelButtons2({ v, show:this.state.toggleEdit, lb:this.state.lb, cancel:()=>this.mapStoreToState, save:()=>this.save }) }
            </div>
        )
    }

	render() {

        let v                   = this.props.v
        let pathname            = this.props.location.pathname

        if   ( isLoading({ props:this.props, state:this.state }) || !v || !pathname || !this.state.info ) return <Loading />
        else {
            return (
                <div style={Style.div1} >
                    { notifications({props:this.props, v}) }
                    { this.route(v) }
                </div>
            )
        }
	}

    route = (v) => {
        
        let pathname            = window.location.pathname.toString().toLowerCase()

        if      ( pathname==="/manager/config/usage" )          return this.renderUsage(v)
        else if ( pathname==="/manager/config/checkins" )       return this.renderCheckIns(v)
        else if ( pathname==="/manager/config/adminusage" )     return this.renderAdminUsage(v)
        else if ( pathname==="/manager/users" )                 return this.renderUsers({v})
        else if ( pathname==="/manager/config/plan" )           return <MSubscription 	{...this.props} />
        else if ( pathname==="/manager/social" )                return this.renderSocial({v})
        else if ( pathname==="/manager/config/footer" )         return this.renderFooter({v})
        else if ( pathname==="/manager/payment" )               return this.renderPayment(v)
        else if ( pathname==="/manager/managemenuimage" )       return this.renderMenuImage(v)
        else if ( pathname==="/manager/config/languages" )      return this.renderLanguages({v})
        else if ( pathname==="/manager/config/upload" )         return this.renderMenuUpload(v)
        else if ( pathname==="/manager/config/feature" )        return this.renderRequestFeature(v)
        else if ( pathname==="/manager/config/pos" )            return this.renderPos(v)
        else if ( pathname==="/manager/config/diet" )           return this.renderDietTags(v)
        else if ( pathname==="/manager/config/marketing" )      return this.renderMarketing(v)
        else if ( pathname==="/manager/config/pandl" )          return this.renderProfitAndLoss({v})
        else if ( pathname==="/manager/config/pages" )          return this.renderPages({v})
        else if ( pathname==="/manager/config/qr" )             return this.renderQr({v})
        else if ( pathname==="/manager/config/editqueues" )     return this.renderQueues({v})
        else if ( pathname==="/manager/config/backup" )         return this.backupAndRestore({v})
        else if ( pathname==="/manager/config/booking" )        return this.renderBookingConfig({v})
        else if ( pathname.includes("/config/editpage" ))       return this.renderPage({v})
        else if ( pathname.includes("/manager/config/form"))    return this.renderForm({v})

        else return null
    }

    handleChange3 = async ({ type, key, key2, value }) => {

        let v                       = this.props.v

        if (type=='spaceValue') {
            
            let bookingConfig   = this.state.bookingConfig
            bookingConfig.spaces[key][key2] = value  
            this.setState({ bookingConfig, toggleEdit:true })
        }
        else if (type=='spaceDelete') {
            
            let bookingConfig   = this.state.bookingConfig

            if (window.confirm(`Delete ${bookingConfig.spaces[key].name}?`)) {
                delete bookingConfig.spaces[key]
                this.setState({ bookingConfig, toggleEdit:true })
            }
        }
        else if (type=='spaceCopy') {
            
            let bookingConfig   = this.state.bookingConfig

            let copy = bookingConfig.spaces[key]
            bookingConfig.spaces[PushId()] = copy
            this.setState({ bookingConfig, toggleEdit:true })
        }

    }

    handleChange2 = async ({ type, queue, key, value }) => {
        
        let v                       = this.props.v
        let info                    = this.state.info
        let language                = v?.language
		let defaultLanguage         = getDefaultLanguage2({ v })
        let whiteLabelConfig        = this.state.whiteLabelConfig||{}
        let wlcKey                  = v.searchParams.get("wlcKey")
        let pages                   = wlcKey? whiteLabelConfig?.pages||{} : info?.pages||{}


        // REMOVING PAYPAL CONFIG LEFT ACCIDENTALLY
        if (info.payments?.payPalClientId?.includes('ASCqeg08'))          info.payments.payPalClientId       = ""
        if (info.payments?.payPalClientSecret?.includes('EJ-lXYub_H'))    info.payments.payPalClientSecret   = ""


        if      (type=='addQueue' ) {
            if (!info.queues) info.queues={}
            let newKey     = PushId()
            info.queues[newKey] = { key:newKey, name:'New Queue '+randomNumber(), default:false } 
        }
        else if (type=='addSubId')  {

            let sub = await getSub({v, id:this.state.subId })

            if (sub.id) {
                // CLIENTKEYS STRING TO ARRAY
                let clientKeys = toArray(sub.metadata?.clientKeys)

                // ALERT IF NEW CLIENTKEY EXISTS
                if (clientKeys.includes(v.clientKey)) { v.updateDialog({ v, text:'already exists' }); return }

                // ELSE ADD IT
                clientKeys.push(v.clientKey)

                // BACK TO STRING
                clientKeys = clientKeys.toString()

                // UPDATE
                let subNew = await updateSub({v, id:sub.id, update:{metadata: { clientKeys }} })
                if (subNew?.metadata?.clientKeys?.includes(v.clientKey))  v.updateDialog({ v, text:'client added to subscription' })
            }
            else if (sub?.raw?.message) handleError({ v, error:sub.raw.message })
            else return null
        }  
        else if (type=='footerPageKey_Venue') info.footerPageKey = value
        else if (type=='editQueueName' ) {
            if ( !language || language===defaultLanguage) {
                 info.queues[queue.key]["name_" + defaultLanguage] = value
                 info.queues[queue.key].name                       = value            
            }
            else info.queues[queue.key]["name_" + language]        = value
        }
        else if (type=='deleteQueue')           delete info.queues[queue.key]
        else if (type=='setDefaultQueue')       info.queueDefault   = info.queueDefault==queue.key? "" : queue.key

        else if (type=='addNewEnglishText') {
            let newEnglishText          = this.state.newEnglishText
            let newEnglishTextTrim      = trim_keepSpaces(newEnglishText)
            let appText                 = this.state.appText

            if (appText[newEnglishTextTrim]) { this.props.updateDialog({ icon:'alert', hideOkButton:true, text:'Already Exists' }); return null }
            
            appText[newEnglishTextTrim] = { english:newEnglishText }            
            this.setState({ appText, newEnglishText:'', toggelEditLanguage:true })
            setTimeout(() => { scrollTo2('newEnglishTextInput', -300) }, 50)
        }
        else if (type=='languageEdit') {
            let appText                     = this.state.appText
            let languageSelect              = this.state.languageSelect
            
            appText[key][languageSelect]    = value
            this.setState({ appText, toggelEditLanguage:true })
        }
        else if (type=='whiteLabelConfigEditFooter') {
            if      (value=='create')   { handleLink3({ link:`http${domain.includes('localhost')?"":'s'}://${domain}/manager/config/pages?wlcKey=${whiteLabelConfig.key}` }); return null }
            else if (value=='none')     whiteLabelConfig.footerPageKey  = 'none'
            else if (value=='')         whiteLabelConfig.footerPageKey  = ''
            else if (value)             whiteLabelConfig.footerPageKey  = value
            else return null
        }
        else if (type=='pageDelete') {
            delete pages[key]
            wlcKey? whiteLabelConfig.pages=pages : info.pages=pages
        }
        else if (type==='pageCopy') {

            let copy            = {...pages[key]}
            copy.title          = copy.title + ' COPY'
            copy.directUrl      = copy.directUrl + randomNumber()
            pages[PushId()]     = copy

            wlcKey? whiteLabelConfig.pages=pages : info.pages=pages
            this.setState({ info, whiteLabelConfig, toggleEdit:true }); this.save(); return null
        }
        else if (type==='pageDownload') {

            let  data = { ...pages[key] }

            download3({ data, filename:data.title })
            return null
        }
        else if (type==='pageUpload') {

            if (window.confirm(`This will overwrite existing page`)) {
                
                let uploadedPage            = JSON.parse(value)  
                let originalPage            = pages[key]

                uploadedPage.title          = originalPage.title
                uploadedPage.directUrl      = originalPage.directUrl

                pages[key]                  = uploadedPage

                wlcKey? whiteLabelConfig.pages=pages : info.pages=pages
                this.setState({ info, whiteLabelConfig, toggleEdit:true })
            }
        }

        else if (type=='pageAdd' && key=='blankPage') {

            let title = "New Page "+randomNumber()
            
            pages[PushId()]                 = { title, directUrl:trim(title) }

            wlcKey? whiteLabelConfig.pages=pages : info.pages=pages
            this.setState({ info, whiteLabelConfig, toggleEdit:true }); this.save(); return null
        }
        else if (type==='pageAdd') {

            let newPage             = this.state.pageTemplates?.[key]
            newPage.title           = newPage.title +" "+ randomNumber()
            newPage.directUrl       = trim(newPage.title)
            pages[PushId()]         = newPage

            wlcKey? whiteLabelConfig.pages=pages : info.pages=pages
            this.setState({ info, whiteLabelConfig, toggleEdit:true }); this.save(); return null
        }

        else if (type==='oPayWithdrawAccount') {

            info.payments                       = info.payments || {}
            info.payments.oPayWithdrawAccount   = value
        }

        this.setState({ info, whiteLabelConfig, toggleEdit:true })
    }

    handleChange = async (type, var0, var1, var2) => {
        
        let v                   = this.props.v
        let language 		    = v.language
        let defaultLanguage     = getDefaultLanguage(this.props)
        let info                = this.state.info
        let whiteLabelConfig    = this.state.whiteLabelConfig

        console.log('language', language, defaultLanguage)


        if (type==='languageSave') {
            this.props.updateLoading(true)
            let appText = this.state.appText

            Firebase.database().ref( "config/" )
                .update({ appText })
                .then(() => {  this.props.updateLoading(false); this.setState({ toggelEditLanguage:false }) })
                .catch((error) => { handleError({ v,  error, props:this.props }) })
        }

        else if (type==='whiteLabelConfigEdit') {

            console.log(var0, var1)
            whiteLabelConfig[var0]  = var1
        }
        // else if (type=='whiteLabelConfigEditFooter') {

        //     if      (var1=='none')  whiteLabelConfig.footerPageKey  = 'none'
        //     else if (var1=='')      whiteLabelConfig.footerPageKey  = ''
        //     else if (var1)          {

        //         let footerPageKey                                   = PushId()
        //         let footerPage                                      = v.info?.pages?.[var1]

        //         // Copy Page to wlc Pages
        //         whiteLabelConfig.pages                              = whiteLabelConfig.pages || {}
        //         whiteLabelConfig.pages[footerPageKey]               = footerPage
                
        //         // Set as footer
        //         whiteLabelConfig.footerPageKey                      = footerPageKey
        //     }
        //     else return null
        // }

        else if (type==='homepageMenuKey') {
            let venueName = var0
            if (!venueName) whiteLabelConfig.homepageMenuKey  = ""
            else {
                Firebase.database().ref("clientSearch/" + venueName + "/clientKey")
                    .once('value') 
                    .then(snapshot =>   { whiteLabelConfig.homepageMenuKey  = snapshot.val()||""; this.setState({refresh:'refresh'}); console.log('key:', snapshot.val()) })
                    .catch((error)=>    { whiteLabelConfig.homepageMenuKey  = ""; console.log(error) })
            }
        }

        else if (type==='getWhiteLabelConfigAll') {
            Firebase.database().ref("config/whiteLabel")
                .once("value")
                .then((snapshot) => { this.setState({ whiteLabelConfigAll:snapshot.val() }) })
                .catch((error) => { console.log(error) })
        }

        else if (type==='sendExceededEmail') {

            let venue                   = var0
            let whiteLabelConfig        = venue.domain!=="" && Object.entries(this.state.whiteLabelConfigAll).filter(item => JSON.stringify(item).includes(venue.domain) )[0][1]
            
            if (whiteLabelConfig && venue.email) {
                sendEmail2( 
                    whiteLabelConfig, 
                    { recipientEmail:venue.email, recipientBCC:'shaun@orderlina.com' },
                    { subject:`${Month} Usage Alert - ${venue.title}`, title:`${Month} Visits - ${venue.length}`, 
                        text: [	
                                `${venue.title}'s Menu has been very popular in ${Month} and has been visited ${venue.length} times so far.`, 
                                `We are writing to inform you that the menu's visits have exceeded the monthly free use quota of 200.`, 
                                `Please upgrade your account for continued use in ${Month}:`,
                                {button:true, link:whiteLabelConfig.upgradeLink, title:'Upgrade'}, 
                                `Please reply to this email if you believe this has been sent in error or if you have any questions about your account.`, 
                            ] 
                    }
                )
            }
            else console.log('Unable to send email:', whiteLabelConfig, venue.email)
        }

        else if (type==='handleActivation') {
            if (info.social.active) { info.social.active=false } else { info.social.active=true }
        }
        else if (type==='toggleShowCheckInButton') {
            if      (!info.showCheckInButton) info.showCheckInButton=true
            else    info.showCheckInButton=false
        }
        else if (type==='socialTitle') {
            if ( !language || language===defaultLanguage) {
                info.social["title_" + defaultLanguage]  = var0
                info.social.title                        = var0
            }
            else { info.social["title_" + language]  = var0 }
        }
        else if (type==='socialDescription') {
            if ( !language || language===defaultLanguage) {
                info.social["description_" + defaultLanguage]  = var0
                info.social.description                        = var0
            }
            else { info.social["description_" + language]  = var0 }
        }
        else if (type==='facebookLink') {
            info.social.facebookLink                 = var0
        }
        else if (type==='facebookPixel') {
            info.facebookPixel                       = var0
        }
        else if (type==='instagramLink') {
            info.social.instagramLink                 = var0
        }
        else if (type==='tiktokLink')       info.social.tiktokLink      = var0
        else if (type==='telegramLink')     info.social.telegramLink    = var0


        // else if (type==='userSave' && this.state.uid) {

        //     this.setState({ loading:true });

        //     let venueId     = v.clientKey
        //     let uid         = this.state.uid
        //     let addClient   = { [venueId]: { "role":"manager"} }

        //     Firebase.database().ref("users/" + uid + "/clients")
        //         .update( addClient )
        //         .then(() => { 
        //             console.log('saved user')
        //             // Save to current profile
        //             if (!info.users) { info.users={} }
        //             info.users[PushId()] = { "uid": this.state.uid, "role": "manager" }
        //             this.save()
        //             this.setState({ loading:false, notify:"User Added" });
        //         })
        //         .catch((error) => {  console.log(error); this.setState({ loading:false }) })
        // }


        else if (type=='createAndAddUserByEmail' ) {

            this.setState({blcaaue:true})

		    Firebase.functions().httpsCallable('createUserNEP')({displayName:'name', email:this.state.email, password:this.state.password })
				.then((result) => { 
                    if   (result?.data?.error) handleError({ v, error:result.data.error, stopStateLoading:()=>this.setState({blcaaue:false}) })
                    else this.handleChange('addUserByEmail')
				})
				.catch((error) =>  handleError({ v, error, message:error.message, stopStateLoading:()=>this.setState({blcaaue:false}) }) )
            }

        else if (type==='addUserByEmail') {

                this.setState({ blcaaue:true })
                let getUserByEmail = Firebase.functions().httpsCallable('getUserByEmail')
                getUserByEmail({ email:this.state.email })
                    .then((result) => { 
                        console.log(result.data)

                        let uid         = result.data && result.data.success && result.data.success.uid
                        let venueId     = v.clientKey
                        let role        = this.state.role || "manager"
                        let addClient   = { [venueId]: { "role":[ role ] } }

                        if (uid) 	{ 
                            Firebase.database().ref("users/" + uid + "/clients")
                                .update( addClient )
                                .then(() => { 
                                    // Save to current profile
                                    if (!info.users) { info.users={} }
                                    info.users[uid] = { "email": this.state.email, "role":[role] }
                                    this.save()
                                    this.setState({ blcaaue:false, email:"", password:"" })
                                    console.log('saved user')
                                })
                                .catch((error) => {  handleError({ v,  error, props:this.props }); this.setState({ blcaaue:false }) })
                        }

                        else { this.setState({ blcaaue:false }); handleError({ v, message:result.data&&result.data.error, error:result, props:this.props }) }
                    })
                    .catch((error) => { this.setState({ blcaaue:false }); handleError({ v,  error, props:this.props }) })
        }

        else if (type=='removeUserByUid') {

            let uid         = var0.uid
            let venueId     = v.clientKey

            if      (Object.keys(v.info.users).length==1) this.props.updateDialog({ icon:'alert', text:'cannot delete only user' })
            else if (window.confirm('Delete User?') && uid && venueId) { 
                
                this.setState({ loading:true })

                // Remove from Users Info
                Firebase.database().ref("users/" + uid + "/clients/" + venueId)
                    .remove( )
                    .then(() => { 
                        // Remove to from Venue Info
                        if (info.users[uid]) delete info.users[uid]
                        this.save()
                        this.setState({ loading:false })
                    })
                    .catch((error) => {  handleError({ v,  error, props:this.props }); this.setState({ loading:false }) })
            }
            else return null
        }

        // else if (type==='userEmailSave' && this.state.email && this.state.password ) {

        //     if ( this.state.password!==this.state.passwordRepeat ) {
        //         this.setState({error:"Please Check That Passwords Match"})
        //         return null
        //     }

        //     this.setState({ loading:true });

        //     let venueId         = v.clientKey
        //     let email           = this.state.email
        //     let password        = this.state.password
        //     let addClient   = { [venueId]: { "role":"manager"} }

        //     Firebase.auth().createUserWithEmailAndPassword(email, password)
        //         .then((user) => { 
        //             console.log('CREATED USER', user.user.uid) 
        //             Firebase.database().ref("users/" + user.user.uid + "/clients")
        //                 .update( addClient )
        //                 .then(() => { 
        //                     console.log('saved user')
        //                     // Save to current profile
        //                     if (!info.users) { info.users={} }
        //                     info.users[PushId()] = { "email": this.state.email, "role": "manager" }
        //                     this.save()
        //                     this.setState({ loading:false, notify:"User Added" });
        //                 })
        //                 .catch((error) => {  console.log(error); this.setState({ loading:false }) })
        //             })
        //         .catch((error)=> { console.log('error: ', error); this.setState({error:error.message}) })
        // }
        
        // else if (type==='userRemove') {

        //     let uid   = var0.key
        //     delete info.users[uid]
        // }

        // Image Menu

        else if (type==='handleDynamicMenu') { info.menuImageActive  = false; info.menuPDFActive=false }
        else if (type==='handleImageMenuUseImages')  { info.menuImageActive  = true;  info.menuPDFActive=false }
        else if (type==='handleImageMenuUsePDF')     { info.menuImageActive  = false; info.menuPDFActive=true  }

        else if (type==='handleImageMenuAdd') {
            if (!info.menuImages) info.menuImages = { }
            info.menuImages[PushId()] = { title:"Drinks", title_spanish: "Bebidas", image:'https://firebasestorage.googleapis.com/v0/b/orderlina-single-region/o/ab%2FTeds%20Montana%20Grill%20Bottles.jpg?alt=media&token=4e329e71-34ca-4259-a3db-3a1535bacc1f' }
        }
        else if (type==='handleMoveImageDown') {
            // Image to be copied
            let imageKey = var0

            // Copy image
            let itemCopy = {...info.menuImages[imageKey]};

            // Push into Menu
            info.menuImages[PushId()] = itemCopy        

            // Delete original Image
            delete info.menuImages[imageKey]
        }
        else if (type==='handleImageMenuDelete') {
            let imageKey = var0
            delete info.menuImages[imageKey]
        }
        else if (type==='handleImageMenuTitleEdit') {
            let text     = var0
            let imageKey = var1

            if ( !language || language===defaultLanguage) {
                info.menuImages[imageKey]["title_" + defaultLanguage] = text
                info.menuImages[imageKey].title = text 
            }
            else { info.menuImages[imageKey]["title_" + language]        = text }
        }
        else if (type==='handleImageMenuImageUrlEdit') {
            // handleImageMenuImageUrlEdit', item.key, undefined, e.target.value
            let imageKey = var0
            let text     = var2
            info.menuImages[imageKey].image = text
        }
        else if (type==='uploadMenuImage') {
            let imageKey = var0
            let e       = var1

            this.setState({ ['uploadingImage'+imageKey]:true })
            let uploadedUrl = await uploadToFBStorage2(this.props, e)
            if (uploadedUrl) { console.log(uploadedUrl); info.menuImages[imageKey].image = uploadedUrl; this.setState({ ['uploadingImage'+imageKey]:false }) }
        }
        else if (type==='uploadPDF') {
            this.setState({ uploadingPDF:true })
            let uploadedUrl = await uploadToFBStorage2(this.props, var0)
            if (uploadedUrl) { console.log(uploadedUrl); info.menuPDF = uploadedUrl; this.setState({ uploadingPDF:false }) }
        }
        // PAYPAL
        else if (type==='updatePayPalInstructions') {
            info.payments.payPalInstructions = var0
        }  
        else if (type==='updatePayPalClientId') {
            info.payments.payPalClientId = var0
        } 
        else if (type==='updatePayPalClientSecret')  {
            info.payments.payPalClientSecret = var0
        }       
        else if (type==='clearPayPalConfig')  {
            info.payments.payPalClientId = ""
            info.payments.payPalClientSecret = ""
            info.payments.payPalInstructions = ""
        }
        //STRIPE
        else if (type==='updateStripeInstructions') {
            info.payments.instructions = var0
        }  
        else if (type==='updateStripeV2') {
            info.payments.stripeV2 = info.payments.stripeV2?false:true
        } 
        else if (type==='updateStripeAuthCode')  {
            info.payments.connectedStripeAccountId = var0
        }       
        else if (type==='clearStripeConfig')  {
            info.payments.connectedStripeAccountId = ""
            info.payments.instructions = ""
        }

        else if (type==='planUpgrade')  {
            if ( this.state.planCode && verifyPlanCode(this.props, this.state.planCode) ) {
                info.plan = 'venue'
                this.setState({ info, toggleEdit:true })
                this.save()
                window.scrollTo(0,1)
            }
            else handleError({ v,  message:'Incorrect Plan Code, Please Try Again', props:this.props })
        }  
        else if (type==='planClearCode')  {
            info.plan = ''
            this.setState({ info, toggleEdit:true })
            this.save()
        }  

        // DIET TAGS
        else if (type==='handleActivationDietTags') {
            if (!info.dietTags) info.dietTags= { [PushId()]:{ name:'vegetarian' } }
            else return null
        }
        else if (type==='handleDietTagEdit') {
            let text     = var0
            let tagKey   = var1

            console.log(this.state, info.dietTags, tagKey)

            info.dietTags[tagKey]["name_" + language] = text
            if (language===defaultLanguage) info.dietTags[tagKey].name = text 
        }
        else if (type==='handleDietTagDelete') delete info.dietTags[var0]
        else if (type==='handleDietTagAdd') {
            info.dietTags[PushId()] = { name:'new' }
        }

        // Form
        else if (type==='formAdd') {
            if (!info.forms) info.forms = { }
            let title = "New Form "+randomNumber()
            info.forms[PushId()] = { title, directUrl:trim(title) }
            this.setState({ info, whiteLabelConfig, toggleEdit:true })
            this.save()
            return null
        }
        else if (type==='formCopy') {
            let copy        = {...info.forms[var0]}
            copy.title      = copy.title + ' COPY'
            copy.directUrl  = copy.directUrl + randomNumber()
            info.forms[PushId()] = copy
            this.setState({ info, whiteLabelConfig, toggleEdit:true })
            this.save()
            return null
        }
        else if (type==='formDelete') {
            delete info.forms[var0]
        }
        
        this.setState({ info, whiteLabelConfig, toggleEdit:true })
    }


    save = async () => {

        let v           = this.props.v
        let wlcKey      = v.searchParams.get("wlcKey")
        this.setState({ lb:true })

        if (wlcKey) {
            let wlc         = this.state.whiteLabelConfig
            if (wlcKey) {
                Firebase.database().ref( "config/whiteLabel/" )
                    .update({ [wlcKey]:wlc })
                    .then(() => {  this.setState({ lb:false, toggleEdit:false })  })
                    .catch((error) => { handleError({ v,  error, props:this.props }) })
            }
        }
        else {
            Firebase.database().ref("clients/" + v.clientKey )
                .update({ info: this.state.info })
                .then(() => {  this.setState({ loading:false, lb:false, toggleEdit:false }) })
                .catch((error) => { handleError({ error, props:this.props }); this.setState({ loading:false, lb:false, toggleEdit:true }) })
        }
    }


    save2 = async ({ data, location }) => {

        this.setState({ lb:true })
        let v           = this.props.v

        if (v.clientKey && data && location) {
            Firebase.database().ref("clients/" + v.clientKey + "/info/" + location ) // location eg. 'bookingConfig'
                .update(data)
                .then(() => {  this.setState({ loading:false, lb:false, toggleEdit:false }) })
                .catch((error) => { handleError({ error, props:this.props }); this.setState({ loading:false, lb:false, toggleEdit:true }) })
        }
    }

    authorizeCode = async (v) => {

        let authorizationCode = this.state.authorizationCode
    
        if (authorizationCode) {

            console.log("authorizing code now...");
            this.props.updateLoading(true);

            let authCode = Firebase.functions().httpsCallable('authCode')
		
            authCode({authorizationCode})
                .then((authorizeCodeResponse) => {

                    console.log('authorizeCodeResponse', authorizeCodeResponse);

                    let stripe_user_id = authorizeCodeResponse && authorizeCodeResponse.data && authorizeCodeResponse.data.stripe_user_id

                    console.log('authorizing code resp', stripe_user_id);
                    
                    if ( stripe_user_id ) {
                        console.log('SUCCESS connectedStripeAccountId', stripe_user_id )
                        this.handleChange('updateStripeAuthCode', stripe_user_id )
                        setTimeout(()=>{ this.save() }, 1000)
                        this.props.updateLoading(false)
                    }
                    else handleError({ v,  message:"It didn't work..", error:authorizeCodeResponse, props:this.props })
                })
                .catch((error) => handleError({ v,  message:"It didn't work..", error, props:this.props }) )
        }
        else handleError({ v,  error:"Please Enter Authorization Code Above", props:this.props })
    }

}


export default MMulti

