import firebase from 'firebase';
import { firebase_config } from './Config'

firebase.initializeApp(firebase_config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const storage = firebase.storage()

export default firebase;


