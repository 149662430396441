import React, { Component } from 'react';
import Firebase, { auth, provider } from './Firebase';
import Welcome 		from './client'
import MLogin 		from './manager/MLogin'

import Style from './styles';
import { RestaurantMenu } from '@material-ui/icons/';
import { Button, Card } from '@material-ui/core/';
import { FiSettings } from 'react-icons/fi';

import { handleError, appTitle, headerHome, defaultPic, logo2, headerPattern, renderBackgroundBubbles, trim, getClient, Loading, orderlinaLogo, logo, footer, getLanguage, getAppText, getWhiteLabelConfig, getVariables } from './Mini-components/Functions'
import Page from './client';
import { HeroTW2 } from './components';




class Homepage extends Component {

	constructor() {
		super();
		this.state = {
		};
	}

    componentDidMount() {
		this.setupHomepageMenuKey()
    }


    filterArray = (text) => {
        let search = text.toLowerCase();
        this.setState({
            searchTerm : search,
            filter: this.state.clients && this.state.clients.filter(obj => (obj.title+obj.domain+obj.email).toLowerCase().includes(search))
        });
    }

	setupHomepageMenuKey = () => {

		let homepageMenuKey		= this.props.v.wlc?.homepageMenuKey
		if (homepageMenuKey) 	this.props?.clientSync && this.props.clientSync({ clientKey:homepageMenuKey })
	}

	render() {

		let v					= this.props.v
		let wlc					= v?.wlc

		if 	 			(wlc?.homepageKey) 			return <Page {...this.props} pageKey={wlc.homepageKey} /> // wlc page key -ADSGTSDFA24353gadfasas
		else if 	 	(wlc?.homepageMenuLink) 	return <Welcome v={v} {...this.props} /> // lantara
        else 										return <HeroTW2 	v={v} />

	}


}
export default Homepage

