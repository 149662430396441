import React, { Component } from 'react';
import Style from '../styles';
import { lb } from '../Mini-components/Functions'



class Button2 extends Component {

	constructor() {
		super();
		this.state = {
            lb:false
		};
	}

    componentDidMount() {
    }
    
    componentWillUnmount = () => {
        this.setState({lb:false})
    };

	buttonAction({onClick, delay}) {

        if (delay===0) onClick()
        else {
            let timeout = delay||500

            this.setState({lb:true})
            setTimeout(() => { onClick() }, timeout)
            setTimeout(() => { this.setState({lb:false}) }, timeout+1000) 
        }
    }

    render() {
        let { v, onClick, children, style, delay, styleBR, contStyle, styleLB, loading, className } = this.props
        let noButtonShadow = v.info?.noButtonShadow? { boxShadow: '0px 3px rgb(128 128 128), 0px 4px rgb(118 118 118), 0px 5px rgb(108 108 108), 0px 6px rgb(98 98 98), 0px 7px rgb(88 88 88), 0px 8px rgb(78 78 78)' } :{}

        return (
            <div className={className||"button2"} style={Object.assign({}, noButtonShadow, { display:'flex', flex:style?.flex, margin:style&&style.margin, borderRadius:5, ...styleBR,  ...contStyle })} >
                <div  onClick={()=> onClick?this.buttonAction({onClick, delay}):null } className={className||"inner"} style={Object.assign({}, v.div4, Style.flexRow, { textTransform:'uppercase', borderRadius:style&&style.borderRadius||10, position:'relative', ...styleBR, ...style, borderRadius:'inherit', overflow:'hidden' })}>
                    { this.state.lb||loading? <div style={Object.assign({}, Style.flexRow0, v.div4, style.backgroundImage?{ ...Style.imageBackground, backgroundImage:style?.backgroundImage }:null, {borderRadius:style&&style.borderRadius||10, justifyContent:'center', left:0, position:'absolute', width:'100%', height:'100%', backgroundImage:v.lg, zIndex:10, ...styleBR, ...styleLB, borderRadius:'inherit', overflow:'hidden' })}>{lb({ v })}</div>: null}
                    { children }
                </div>
            </div>
        )
    }


}
export default Button2
