import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import PushId from 'pushid';
import Firebase, { auth } from './Firebase';
import { AppBar, Button, LinearProgress, Snackbar } from '@material-ui/core/';


// App
import Homepage from './Homepage';
import Header from './components/Header';
// import Slider from './Slider'
import Footer from './Footer';
import NavBar from './Mini-components/NavBar';
// MANAGER
import MLogin 		from './manager/MLogin'
import MLanding 	from './manager/MLanding'
import MHome 		from './manager/MHome'
import MInfo 		from './manager'
import MOrders 		from './manager/MOrders'
import Advizir		from './advizir/Advizir'
import MChat		from './manager'
import MPlatform	from './manager'
import MTypes		from './manager'
import MVerify		from './manager/MVerify'
import MAlerts		from './manager/MAlerts'
import MMulti		from './manager/MMulti'
import Integrations	from './manager'
// MENU
import Page 		from './client'
import Welcome 		from './client'
import Menu 		from './client'
import Item 		from './client/Item'
import Basket 		from './client/Basket'
import Location 	from './client'
import Total 		from './client'
import Bookings 	from './client/Bookings'


import Style from './styles';
import { PlayArrow, ArrowForward, Chat, Check, Home, ExitToApp, Close, ArrowBack, MenuOpen } from '@material-ui/icons/';
import { FiHome } from 'react-icons/fi';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { MdFavorite, MdDonutLarge, MdPlayArrow } from 'react-icons/md';



import { correctClient, Loading, goBack, pixelStart, pixelPageView, getIcon2, languageButtons, getVariables, logo2, headerPattern, trim, firstLetterUpperCase, getClient, nav, getClientKey, footer, isManager, getIcon, updateVersion, getAppText, domain, getVenueTitle, getWhiteLabelConfig, setLocation, getAppText2, isManagerNewOrder, picChef, sizeObj, handleError } from './Mini-components/Functions'


////////////     REDUX      ///////////////
import { connect } from 'react-redux';
import { updateAwaitingPayment, updateStripePay, updateVerifiedStatus, updateVisitCount, updateSimple, updateGuestTypeKey2, updateToast, updateOrderlinaStore, updateConfig, updateClient, updateLoading, updateUserConfig, updateFbsync, updateWhiteLabelConfig, updateManagerNewOrder, updateCustomerExistingOrder, updateDialog, updateBasket, updateKeyboardOpen, updateLocation, updateLocation2, updateUserConfigVenue, updateUserConfigVenueGuestType } from './redux';
const mapStateToProps = (state) => { return {  orderlinaStore: {...state.orderlinaStore} } }
const mapDispatchToProps = (dispatch) => ({    
	updateOrderlinaStore			: (data) => { dispatch(updateOrderlinaStore(data)) }, 
	updateSimple					: (data) => { dispatch(updateSimple(data)) },
	updateConfig					: (data) => { dispatch(updateConfig(data)) },
	updateWhiteLabelConfig			: (data) => { dispatch(updateWhiteLabelConfig(data)) },
	updateClient					: (data) => { dispatch(updateClient(data)) },
	updateUserConfig				: (data) => { dispatch(updateUserConfig(data)) },
	updateUserConfigVenue		    : (data) => { dispatch(updateUserConfigVenue(data)) },
	updateUserConfigVenueGuestType	: (data) => { dispatch(updateUserConfigVenueGuestType(data)) },
	updateLoading					: (data) => { dispatch(updateLoading(data)) },
	updateFbsync					: (data) => { dispatch(updateFbsync(data)) },
	updateManagerNewOrder			: (data) => { dispatch(updateManagerNewOrder(data)) },
    updateCustomerExistingOrder		: (data) => { dispatch(updateCustomerExistingOrder(data)) },
    updateDialog					: (data) => { dispatch(updateDialog(data)) },
    updateBasket					: (data) => { dispatch(updateBasket(data)) },
    updateToast						: (data) => { dispatch(updateToast(data)) },
    updateKeyboardOpen				: (data) => { dispatch(updateKeyboardOpen(data)) },
    updateLocation2					: (data) => { dispatch(updateLocation2(data)) },
    updateGuestTypeKey2					: (data) => { dispatch(updateGuestTypeKey2(data)) },
	updateVisitCount				: (data) => { dispatch(updateVisitCount(data)) }, 
	updateVerifiedStatus			: (data) => { dispatch(updateVerifiedStatus(data)) },
	updateStripePay					: (data) => { dispatch(updateStripePay(data)) }, 
	updateAwaitingPayment			: (data) => { dispatch(updateAwaitingPayment(data)) }
})


class App extends Component {

	constructor(props) {
		super(props);
		this.state = { 
		};
	}

	componentDidMount() {
		
		console.log('cm header')
		// window.addEventListener("focus", payload => { 
		// 	console.log('WOOO: ', this.props )
		// })
        this.getDeviceAlertToken()
		this.wlcSYNC()
		this.authUser()
		this.getClientKey()
		this.detectKeyboardOpen()
		this.configSYNC()
	}

	configSYNC = () => {
		Firebase.database().ref("config").on('value', (snap) => {
			console.log('FB Config SYNC triggered', snap.val())
			this.props.updateConfig( snap.val() )
		})
	}

	detectKeyboardOpen = () => {

		// Set Original Height
		this.setState({ originalHeight:window.innerHeight })

		window.addEventListener("resize", () => {
			let newHeight = window.innerHeight
			if (newHeight < (0.6*this.state.originalHeight) ) 	{ this.props.updateKeyboardOpen(true) }
			else 												{ this.props.updateKeyboardOpen(false)  }
		})
	}




    getDeviceAlertToken = async () => {
    
        try {
                const token = await Firebase.messaging().getToken();
    
                if (token) { this.props.updateSimple({ alertToken:token }) }
                else { console.log('no Token:') }
    
            } catch (error) {
                console.log('Error - Alerts Inactive:', error);
            }
    }


	// config = () => {

    //     Firebase.database().ref("config/")
    //         .once("value")
    //         .then((snapshot) => {
	// 			this.props.updateConfig(snapshot.val())
	// 			console.log('configGet', snapshot.val())

	// 			this.wlcSYNC(snapshot.val()?.whiteLabel)
	// 			this.configSYNC()
    //         })
    //         .catch((error) => { console.log(error) })
	// }
	
	wlcSYNC = () => {

        Firebase.database().ref("config/")
            .once("value")
            .then((snap) => {

				// HANDLE NGROK
				let domain2 = domain
				if (domain2.includes('ngrok')) domain2 = 'ngrokOrderlina' // 'ngrokHotefy'

				let whiteLabel			= snap?.val()?.whiteLabel
				let wlc 				= whiteLabel && Object.entries(whiteLabel).filter(item => item?.[1]?.domain?.includes(domain2) ).map((item,index) => ({ ...item[1], key: item[0] }))
				wlc 					= wlc && wlc[0]
		
				if ( wlc?.key ) {
					Firebase.database().ref(`config/whiteLabel/${wlc.key}`).on('value', (snap) => {
						this.props.updateWhiteLabelConfig({ ...snap.val(), key:wlc.key })
					})
				}
            })
            .catch((error) => { console.log(error) })
	}

	getClientKey = () => {

		let pathname = window.location.pathname.toString().toLowerCase()

		if 		( pathname=='/') 					return null
		else if ( pathname.includes("/manager") ) 	this.clientSync({ clientKey:this.props.orderlinaStore.lastManaged })
		else 	{
			let venueName = this.getVenueName()
			venueName && Firebase.database().ref("clientSearch/" + venueName + "/clientKey")
				.once('value') 
				.then(snapshot => { let clientKey = snapshot.val(); if (clientKey) this.clientSync({ clientKey }) })
				.catch((error)=> { console.error(error) })
		}
	}

	clientSync = ({ clientKey }) => {

		let os 						= this.props.orderlinaStore || {}
		let manager                 = window.location.pathname.includes('/manager')
		let uid 					= os.user?.uid || os.tmpuid || 'none'
		let filterQueue				= os.userConfig?.venues?.[clientKey]?.browserConfig?.filterQueue || []

		if (clientKey) {
	

			// UPDATE CLIENT KEY
			manager?	 this.props.updateSimple({ lastManaged:clientKey } )
						:this.props.updateSimple({ lastVenue:clientKey, clientKey } )
			this.setState({ clientKey })
	


			// INFO
			let clientNodeInfo = Firebase.database().ref("clients/" + clientKey + '/info'  )
			clientNodeInfo.off('value')
			clientNodeInfo.on('value', (snap) => { 
				
				console.log('nnn INFO')

				this.setState({ info:snap.val()||{} })

				// IF OVER 5MB SEND ALERT
				if (sizeObj(snap.val())>5) handleError({ message:`Large Size ${sizeObj(snap.val())}, ${window.location.pathname}, ${clientKey}`, noDialog:true })

				// DEPRECATED - OLD - SAVES IN REDUX - LARGE SIZE ISSUE
				let orderlinaStore = {...this.props.orderlinaStore}
				if ( !orderlinaStore[clientKey]) { orderlinaStore[clientKey] = {} }
				orderlinaStore[clientKey].info         		= ""
				orderlinaStore[clientKey].newOrdersM    	= ""
				orderlinaStore[clientKey].newOrdersC    	= ""
				orderlinaStore[clientKey].newChatsM    		= ""
				orderlinaStore[clientKey].newChatsC    		= ""
				this.props.updateFbsync( [ clientKey, orderlinaStore[clientKey] ] )
			})



			// ORDERS
			let clientNodeOrders = Firebase.database().ref("clients/" + clientKey + '/orders' )
			clientNodeOrders.off('value')
			clientNodeOrders.on('value', (snap) => { 

				
				let orders 							= snap.val()||{}
				let newOrdersMFull    				= Object.entries(orders).map(item =>item={...item[1], key:item[0]}).filter(item => (item.status==1) && (!filterQueue.length || (( JSON.stringify(item).includes(filterQueue[0]) ) || ( JSON.stringify(item).includes(filterQueue[1]) ) || ( JSON.stringify(item).includes(filterQueue[2]) ))))
				let newOrdersM    					= newOrdersMFull?.length
				let newOrdersCFull    				= Object.entries(orders).filter(item => item[1].customerId==uid )
				let newOrdersC    					= newOrdersCFull?.length


				this.setState({ orders, newOrdersM, newOrdersC, newOrdersMFull:newOrdersMFull?.length&&newOrdersMFull })
			})



			// CHAT
			let clientNodeChat = Firebase.database().ref("clients/" + clientKey + '/chat' )
			clientNodeChat.off('value')
			clientNodeChat.on('value', (snap) => { 
			
				console.log('nnn CHAT')

				let chat 							= snap.val()||{}
				let newChatsM    					= Object.entries(chat).filter(item => item[1].newMessageFromCustomer).length
				let newChatsC    					= chat?.[uid]?.newMessageFromVenue
				this.setState({ chat, newChatsM, newChatsC })
			})


		}
		else console.log('LISTENER NO CLIENT KEY')
	}


	getVenueName = () => {

		let str = window.location.pathname.toLowerCase()
	
		if (str) {
			// remove starting and trailing '/'s
			let len = str.length;
			if ( str.charAt(len-1)=='/' ) { str = str.slice(0, -1) }
			if ( str.charAt(0)=='/' )     { str = str.slice(1, len) }
	
			// remove '_record'
			let ind = str.indexOf("_record");
			if (ind>-1) { str = str.slice(0, ind)}
	
			// remove '/menu' or '/orders'
			let indx = str.indexOf("/");
			if (indx>-1) { str = str.slice(0, indx)}
	
			return str
		}
		else return ""
	}
	
	authUser = () => {

		auth.onAuthStateChanged((user) => {

			this.props.updateSimple({ user:user||"" })
			this.nav({ user }) 
		})
	}


	nav = ({ user }) => {

		let orderlinaStore  =   this.props.orderlinaStore
		let lastManaged     =   orderlinaStore.lastManaged
		let pathname        =   window.location.pathname.toString().toLowerCase()
		let manager			=	pathname.includes("/manager")

		if 		( !manager )											return null // venue, do nothing
		else if ( pathname.includes("/manager/register") ||
				  pathname.includes("/manager/login") )              	{ if (user) this.props.history.push("/manager/venues"); else return null }
		else if ( !user)                                                this.props.history.push("/manager/login")
		else if ( !lastManaged )  										this.props.history.push("/manager/venues")
		else 															return null
	}


	renderBody = ({ props }) => {

		let v 				= props.v
		let path			= v.pathname2 || ""
		let pathSlashes		= path?.match(/\//g || [])?.length
		
		// console.log('xx path', v, path, pathSlashes )

		if 		( path=="" ) 											return <Homepage 	{...props} clientSync={this.clientSync} />
		else if ( path=='manager/login' || 
				  path.includes('manager/register') ) 					return <MLogin 		{...props} />
		else if ( path.includes('manager/venues')) 						return <MLanding 	{...props} clientSync={this.clientSync} />
		else if ( path=='manager' || path=='manager/home') 				return <MHome 		{...props} />
		else if ( path=='pricing') 										return <Page 		{...props} pageKey={v.wlc?.pricingPageKey} />
		
		else if ( v?.info) {

			if 	( path.startsWith('manager/')) {
				if 		(path=='manager/info') 							return <MInfo 		{...props} />
				else if (path=='manager/manageorders') 					return <MOrders 	{...props} />
				else if (path=='manager/managemenu') 					return <Menu 		{...props} />
				else if (path=='manager/manageitem') 					return <Item 		{...props} />
				else if (path=='manager/archive') 						return <MOrders 	{...props} />
				else if (path=='manager/managelocations' || 
						 path=='manager/managedelivery')				return <Location 	{...props} />
				else if (path=='manager/types') 						return <MTypes 		{...props} />
				else if (path=='manager/verify') 						return <MVerify 	{...props} />
				else if (path=='manager/alerts') 						return <MAlerts 	{...props} />
				else if (path=='manager/chat2') 						return <Advizir 	{...props} />
				else if (path=='manager/chat') 							return <MChat 		{...props} />
				else if (path=='manager/platform') 						return <MPlatform 	{...props} />
				else if (path.includes('manager/integrations')) 		return <Integrations {...props} />
				else if (path=='manager/local' || 
						path=='manager/social' ||
						path=='manager/users' ||
						path=='manager/payment' ||
						path=='manager/managemenuimage' ||
						path.includes('manager/config') ) 				return <MMulti 		{...props} clientSync={this.clientSync} />
				else 													return <MHome 		{...props} />
			}
			else if 	(!pathSlashes) 									return <Welcome 	{...props} />
			else if 	(pathSlashes>0) {
				if 		(path.endsWith('/menu')) 						return <Menu 		{...props} />
				else if (path.endsWith('/item')) 						return <Item 		{...props} />
				else if (path.endsWith('/location')) 					return <Location 	{...props} />
				else if (path.endsWith('/basket')) 						return <Basket 		{...props} />
				else if (path.endsWith('/saved')) 						return <Basket 		{...props} />
				else if (path.endsWith('/total')) 						return <Total 		{...props} />
				else if (path.endsWith('/chat')) 						return <MChat 		{...props} />
				else if (path.includes('/orders'))						return <MOrders 	{...props} />
				else if (path.includes('/bookings'))						return <Bookings 	{...props} />
				else													return <Page 		{...props} />
			}
			else return null
		}	
		else 															return <Loading 	{...props} />
	}

	backgroundImage = ({ v, center }) => {

		if (center) return(
			<div style={Object.assign({}, Style.flexCol1, v.div, { backgroundImage:v.manager?'unset':"url(" + (v.info?.pic || picChef) + ")", position:'fixed', top:0, left:0, width:'100%', height:'100%', zIndex:-999 })}>
				<div style={Object.assign({}, Style.flexCol1, { backgroundColor:'rgba(244, 244, 244, 0.5)' })}>

				</div>
			</div>
		)
	}


	// UPDATES KEYS INSIDE COMPONENT EG info:{currency:AUD}  
	setState2 = ({ info }) => {
		console.log('RUNNING SETSTATE 2', info)

		if (info) this.setState({ info:{ ...this.state.info, ...info }}) 
	}


	render() {

		let v               =   getVariables({ props:this.props, noInfo:true, state:this.state, setState:(x)=>this.setState(x), setState2:(x)=>this.setState2(x) })

		if 	 ( !v || !correctClient({ v }) ) return <Loading v={v} props={this.props} component={this.constructor?.name} />
		else {

			// DISABLE CONSOLE.LOG
			if (!(v.isDev||v.isDemo)) console.log = function() {}

			let center 	=	v.customer && !v.isMobile
			let props	= { v, ...this.props, ...this.state }
			let oldStyle			= Object.assign({}, Style.flexCol1, Style.boxShadow, v.div, center?{ width:700, maxWidth:700, alignSelf:'center'}:{}, {  })


			return (
				<div id='app' style={v.newStylePage? {}: oldStyle}>

					<Header 	{...props} notify={this.state.notify} managerNewOrders={this.state.managerNewOrders} />
					{/* <Slider v={v}  {...this.props} /> */}
					{ this.renderBody({ props })}
					<NavBar 	{...props} />
					<Footer 	{...props} />
					{this.backgroundImage({v, center})}
				</div>
			)
		}
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))

