import React, { Component } from 'react';

import Firebase from '../Firebase';

import Style from '../styles';
import { Card, Button } from '@material-ui/core/';
import { KeyboardArrowDown } from '@material-ui/icons/';
import { IoIosCloseCircle } from 'react-icons/io';


import { renderTipButtons, renderHelper5, trim, readyPage, calculateBasketTotals, social, iconPage, renderNotes, basketItems, Loading, getSymbolTotal, getLanguage, trimAmount, footer, getVenueName, getPathname, getAppText, getVariables, getIcon2, getAppText2, RenderHelper7, isOrderingOn, trimAmount2, getPriceWithOptions, logo2, getOrderStatusSticker, renderImage, itemView, RenderAttachment, fileAttachmentPic } from '../Mini-components/Functions'
import { navBar } from '../Mini-components/NavBar'
import Item from './Item';





class Basket extends Component {

	constructor() {
		super();
		this.state = {
		};
	}

    componentDidMount() {
		let v = this.props.v

		if (!this.props.order) { 	// ORDERS PAGE ORDER
			readyPage({ props:this.props, v })
		}
	}

	render() {

		let v				= this.props.v

		if ( !v ) return <Loading v={v} />

		else {
			let order			= this.props.order // BASKET FROM ORDERS PAGE
			let basket          = order? order.basket: v.basket
			let pathname 		= v.pathname
			let savedPage 		= pathname && pathname.includes("saved")
			let dialog   		= v.dialog
			let language 		= v.language
			let info            = v.info
			let currencySymbol	= info.currencySymbol
			let queues          = v.info?.queues || {}
			let isPaid			= order?.isPaid()

			if (basket) return (
				<div style={Object.assign({}, Style.flexColOnly, v.div1, {padding:savedPage?'1rem':undefined})}>

					{/* { isOrderingOn({v})? null: RenderHelper7({ props:{ v, icon:'basket', message:'app ordering off', style:{ marginBottom:20 } }}) } */}

					{ basket?.itemsNew?.length? 
						Object.entries(basket.itemsNew).map((item,index) => { item = { ...item[1], key: item[0], index:index  } 
							let price		= item.price2?.price || getPriceWithOptions({ item }) || 0
							let editItem	= order?null:()=>itemView({ props:this.props, v, item, update:true }) 
							return ( 
								<div className='orderItem' key={item.key} style={Object.assign({}, Style.flexColOnly, v.manager?v.style.card:{}, { flex:1, flexDirection:'row', backgroundColor:'transparent', marginTop:index?10:0, padding:'8px 5px 12px 12px' })} >
										
										{renderImage({ v, img:item.image, onClick:editItem, styleCont:{width:'unset', marginRight:15, marginTop:4}, style:{ maxWidth:75, maxHeight:75, borderRadius:10 } })}

										<div onClick={editItem} style={{ display:'flex', flex: 1, flexDirection:'column', justifyContent: 'space-between'}}>
											<div style={Style.textSB}>{ item["title_"+language] || item.title}{item.quantitySelected>1?" X "+item.quantitySelected:null}</div>
											{ item.variance && Object.entries(item.variance).map(tit => (
												!tit[1].readOnly && tit[1].options && 
												Object.entries(tit[1].options).map((opt,index) => { opt = { ...opt[1], key:item[0], index  }
													if (opt.selected) {
														if 		(opt.selected?.includes?.('firebasestorage')) 	opt.selected = RenderAttachment({ v, contStyle:{marginTop:10}, img:opt.selected, defaultPic:fileAttachmentPic, enlarge:true, clickToOpenInNewTab:true, style:{maxHeight:'10rem', objectFit:'contain'} })
														else if (opt.input) 									opt.selected = "- "+opt.selected||""
														else 													opt.selected = ""

														return  <div key={index} style={Object.assign({}, Style.textXS, {textTransform:'capitalize', fontWeight:'normal'})}>{ tit[1]["name_"+language] || tit[1].name}: {opt["option_"+language] || opt.option} {opt.selected}</div>
													}

												})
											))}

											{price===0||price===undefined||v.info.hideBasketTotals ?null:
											<div style={Object.assign({}, Style.flexRowOnly, Style.textSB, {marginTop:5})}>
											{item.price2?.discount? <div style={Object.assign({}, Style.strikethrough, { marginRight:10 })}>{currencySymbol}{item.price2?.pricePreDiscount}</div> :null}
												<div style={{}}>{currencySymbol}{price}</div>
											</div>}

											{ item.notes ? <div style={{ marginTop:5, fontStyle: 'italic', color:'#D50000', fontSize:16, fontWeight:'bold', flex: 1, textAlign:'left' }}>{item.notes}</div>:null}
											{v.manager && queues?.[item.queue]? <div style={Object.assign({}, Style.buttonBadge, Style.textXXSB, { margin:0, marginTop:10, backgroundColor:'#e4e3e3', boxShadow:'unset', alignSelf:'flex-start' })}>{queues[item.queue].name}</div>:null}
										</div>

										{ order? 
											<Button onClick={()=>order.handleItemStatusNew(item)  } className='hidePrint' style={Object.assign({}, Style.navButton, Style.orderIcon, { marginTop:0, marginBottom:0 })}>{logo2({ img:getOrderStatusSticker(item.status),   size:'2rem', style:{ borderRadius:0, boxShadow:'unset' } })}</Button> 
											:
											getIcon2({icon:'minusCircle', style:{padding:'0.25rem'}, fontSize:'0.8rem', onClick:()=>this.removeItem({ v, itemKey:item.key })})}
								</div>
							)
						})
						:getIcon2({icon:'emptyBasket', fontSize:'3rem', style:{alignSelf:'center'} })
					}

					{/* BASKET NOTES */}
					{ basket?.basketNotes?        				<div style={Object.assign({}, Style.textSB, {color:'#D50000', margin:5, marginTop:20 })}>{basket.basketNotes}</div> :null}

					{ basket.total?
						<div style={Object.assign({}, Style.flexColOnly, v.div1, {})}>
							{ order? null:renderTipButtons({props:this.props, v, dialog}) }

							<div style={Object.assign({}, Style.flexColOnly, Style.textSB, { textAlign:'right', marginRight:10, alignItems:'flex-end', textTransform:'uppercase', marginTop:10 })}>
								{ basket.totalDiscount ? <div style={{...Style.inherit, color:v.pc  }}>{getAppText2({v, text:"Discount"})} -{currencySymbol}{trimAmount(basket.totalDiscount)}</div>:null }
								{ basket.deliveryCost  ? <div style={{...Style.inherit, marginTop:5 }}>{getAppText2({v, text:"Delivery"})} {currencySymbol}{trimAmount(basket.deliveryCost)}</div>:null }
								{ basket.totalTip  	   ? <div style={{...Style.inherit, marginTop:5 }}>{getAppText2({v, text:"Tip"})} {currencySymbol}{trimAmount(basket.totalTip)}</div>:null }
								{ basket.totalTaxInc   ? <div style={{...Style.inherit, marginTop:5 }}>{`(`}{basket.totalTaxIncName?.map(i=>{return i+" "})||"Tax"} {getAppText2({v, text:"Inc"})} {currencySymbol}{trimAmount(basket.totalTaxInc)}{`)`}</div>:null }
								{ basket.totalTaxExc   ? <div style={{...Style.inherit, marginTop:5 }}>{basket.totalTaxExcName?.map(i=>{return i+" "})||"Tax"} {currencySymbol}{trimAmount(basket.totalTaxExc)}</div>:null }
							
								<div style={Object.assign({}, Style.flexRow, {marginTop:5, marginBottom:10})}>
									{ v.info.hideBasketTotals&&v.customer? null: 
										<div style={Object.assign({}, Style.textB, Style.flexRowOnlyAic, { textAlign:'right' })}>
											{v.manager||isPaid? <div  style={{...Style.badge3, color:'white', backgroundColor:isPaid?'green':'#D50000', marginRight:10, textTransform:'uppercase' }}>{getAppText2({v, text:isPaid?'Paid':'Unpaid' })}</div> :null}
											{getAppText2({v, text:"Total"})} {currencySymbol||info.currency||""}{trimAmount2({v, amount:basket.total})}
										</div>}
									{v.manager? <Button className='hidePrint' onClick={() => order.handlePaidStatus() } style={Object.assign({}, Style.navButton, { color:'unset', marginLeft:10, marginRight:0 })}>{getIcon2({ icon:isPaid?'done':'unpaid', fontSize:'2rem', color:isPaid?'green':'#d50000' })}</Button> :null}
								</div>
							</div>
						</div>
					:null}
				</div>
			)
			else return (
				<div style={Object.assign({}, Style.flexColOnly, v.div, {padding:savedPage?'1rem':undefined})}>
					{getIcon2({ icon:'emptyBasket', fontSize:'5rem', style:{alignSelf:'center'} }) }
				</div>
			)
		}

	}
	
    handleIcon = ({ v, item, size, style, title }) => {

        if 		(item?.itemObject?.customNotes) return null
        else if (item?.status) 					return <Button onClick={() => this.handleItemStatusNew({v, title, item})  } style={Object.assign({}, Style.navButton, Style.orderIcon, { ...style })}>{logo2({ img:getOrderStatusSticker(item.status),   size:size||'2rem', style:{ borderRadius:0, boxShadow:'unset' } })}</Button>
        else        	            			return null
    }

	removeItem = ({ v, itemKey }) => {

		// IF THIS IS A TEMP ORDER, EG. SERVICE REQUEST
		if (v.dialog?.tempOrder) return null 

        let basket = v.basket

        // Remove Item
        basket.itemsNew.splice(itemKey, 1);

        calculateBasketTotals({ props:this.props, v, newBasket:basket })
    }



}
export default Basket

