import 'whatwg-fetch'
import { stripe_public_key } from '../Config'

const REQM = ' is required';
const baseURL = "https://api.stripe.com/v1/";
const key = stripe_public_key;
const requestHeaders = {
    'Accept': 'application/json',
    "Authorization": "Bearer " + key,
};
export default {
    getNewCardToken: async function (newCard, connectedStripeAccountId) {
        if (!newCard) throw new Error(`newCard${REQM}`);
        if (!newCard.number) throw new Error(`cardNumber${REQM}`);
        if (!newCard.exp_month) throw new Error(`expMonth${REQM}`);
        if (!newCard.exp_year) throw new Error(`expYear${REQM}`);
        if (!newCard.cvc) throw new Error(`cvc${REQM}`);

        const card = Object.assign({}, newCard);

        return fetch(`${baseURL}tokens`, {
            method: 'POST',
            headers: {
                ...requestHeaders,
                'Content-Type': 'application/x-www-form-urlencoded',
                'Stripe-Account': connectedStripeAccountId
            },
            body: Object.entries(card)
                .map(([key, value]) => `card[${key}]=${value}`)
                .reduce((previous, current) => `${previous}&${current}`, '')
        })
        .then((response) => {
            return response.json();
        }).catch((reason) => {
            console.error(reason);
            return reason;
        });
    }
}