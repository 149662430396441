
import React, { Component } from 'react';


export const Integrations = ()=> { return (<div></div>) }
export const MChat          = Integrations
export const MInfo          = Integrations
export const MPlatform      = Integrations
export const MTypes         = Integrations
// export const Integrations = 1
// export const Integrations = 1
// export const Integrations = 1
// export const Integrations = 1
export default MChat