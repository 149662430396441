import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import {
	CardElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import Style from '../styles';

import { Button3, Input2 } from '../Mini-components/Functions';

const StripeCardElement = ({ v, clientSecret, then, updateDefaultPayMethod, style }) => {

	// Get the lookup key for the price from the previous page redirect.
	// const [clientSecret] = useState(location.state.clientSecret);
	// const [subscriptionId] = useState(location.state.subscriptionId);
	const [name, setName] = useState('');
	const [messages, _setMessages] = useState('');
	const [lb, setLb] = useState('');


	// helper for displaying status messages.
	const setMessage = (message) => {
		_setMessages(`${messages}\n\n${message}`);
	}

	// Initialize an instance of stripe.
	const stripe = useStripe();
	const elements = useElements();

	if (!stripe || !elements) {
		// Stripe.js has not loaded yet. Make sure to disable
		// form submission until Stripe.js has loaded.
		return '';
	}

	// When the subscribe-form is submitted we do a few things:
	//
	//   1. Tokenize the payment method
	//   2. Create the subscription
	//   3. Handle any next actions like 3D Secure that are required for SCA.
	const handleSubmit = async (e) => {

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		console.log('Card Details Submitted', clientSecret, name)

		setLb(true)
		const cardElement 	= elements.getElement(CardElement);
		const options = {
			payment_method: {
				card: cardElement,
				billing_details: { name }
			}
		}

		// Use card Element to tokenize payment details

		let trial 									= clientSecret.includes('seti')
		const stripeSubType 						= trial ? stripe.confirmCardSetup : stripe.confirmCardPayment
		let { error, setupIntent, paymentIntent } 	= await stripeSubType(clientSecret, options)


		console.log('Card Details Post Submit', paymentIntent, setupIntent)
		if (error) {
			// show error and collect new card details.

			console.log('Card Details Error', error)
			setMessage(error.message);
			setLb(false)
			return;
		}
		else if (setupIntent?.status && setupIntent?.status == 'succeeded') 	{ 
			
			console.log('Card Details Succeeded SETUP INTENT',setupIntent, setupIntent.payment_method); 
			updateDefaultPayMethod(setupIntent.payment_method); 
		}
		else if (paymentIntent?.status && paymentIntent.status == 'succeeded') 	{
			
			console.log('Card Details Succeeded PAYMENT INTENT',paymentIntent, paymentIntent.payment_method); 
			updateDefaultPayMethod(paymentIntent.payment_method); 
			then&&then(); setLb(false) 
		}
	}



	return (
		<div style={{...Style.flexColOnly, ...style}}>
			{/* <h1>Subscribe</h1>

      <p>
        Try the successful test card: <span>4242424242424242</span>.
      </p>

      <p>
        Try the test card that requires SCA: <span>4000002500003155</span>.
      </p>

      <p>
        Use any <i>future</i> expiry date, CVC,5 digit postal code
      </p>

      <hr /> */}



			<Input2 v={v} icon='user' instructions="First and Last Name" placeholder="Full Name" id="name" value={name} onChange={(e) => setName(e.target.value)} style={{ marginTop: 10 }} />

			<div style={{ ...Style.boxShadow, ...v.style.bs, ...v.style.br, display: 'flex', alignItems: 'center', marginTop: 20, minHeight: 61, color: 'pink' }}>
				<CardElement

					id="card-element"
					options={{
						style: {
							base: {
								color: "#32325d",
								fontFamily: 'Arial, sans-serif',
								fontSmoothing: "antialiased",
								fontSize: "16px",
								"::placeholder": {
									color: "#32325d"
								}
							},
							invalid: {
								fontFamily: 'Arial, sans-serif',
								color: "#fa755a",
								iconColor: "#fa755a"
							}
						}
					}}

				/>
			</div>


			<Button3 icon={lb ? 'loading' : ""} v={v} twenty text='Subscribe' onClick={() => handleSubmit()} />

			{messages && <div style={{ marginTop: 10, ...Style.text0_75B, color: 'red', alignSelf: 'center', textAlign: 'center' }}>{messages}</div>}
		</div>
	)
}

export default StripeCardElement;