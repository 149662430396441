

import React, { Component } from 'react';


export const Integrations = ()=> { return (<div></div>) }
export const MChat = Integrations
export const Location = Integrations
export const Menu = Integrations
export const Page = Integrations
export const Total = Integrations
export const Welcome = Integrations
// export const Integrations = 1
// export const Integrations = 1
// export const Integrations = 1
// export const Integrations = 1
export default MChat