
// import {  } from './Mini-components/Functions'
import { store } from './redux';
import { getVariables } from './Mini-components/Functions'

let width             = window.innerWidth
let height            = window.innerHeight
let borderRadius      = 10 // 'max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px'
let olred             = '#D50000'
let ollightgrey       = '#f1f2f5' //'#f0f0f0'
let ollightgrey2      = '#d3d3d33b'
let olgrey            = ollightgrey
let hotefyColor       = '#62142e' //'#84040d' // rgb(98, 20, 46)
let nYellow           = '#fde139'
let primaryColor      = '#D50000'
let secondaryColor    = '#E91E63'
let placeholderColor  = '#bfbfbf'
let boxShadow         = '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
let boxShadowLite     = '0px 1px 5px 0px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
let boxShadowTop      = 'rgb(0 0 0 / 20%) 0px -1px 3px 0px, rgb(0 0 0 / 14%) 0px -1px 1px 0px, rgb(0 0 0 / 12%) 0px -2px 1px -1px'
let boxShadowTopLite  = 'rgb(0 0 0 / 20%) 0px -1px 3px 0px'
let boxShadowRight    = 'rgb(0 0 0 / 20%) 1px 0px 3px 0px, rgb(0 0 0 / 14%) 1px 0px 1px 0px, rgb(0 0 0 / 12%) 2px 0px 1px -1px'

let wrap              = { overflowWrap:'break-word', overflow:'hidden', wordBreak:'break-word' }
let cardFlat          = { marginTop:15, padding:'1.5rem', textAlign:'center', fontSize:'1.5rem', fontWeight:'bold', borderRadius }
let card              = { ...cardFlat, boxShadow }
let linearGradient    = { backgroundImage:`linear-gradient(to bottom right, ${primaryColor}, ${secondaryColor})` }
let flexCol0          = { flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }
let minHeight         = 50
let input             = { fontWeight:'bold', border:'transparent', fontSize:'1.2rem', paddingLeft:'1rem', paddingRight:'1rem', minHeight:'3.5rem', borderRadius, boxShadow, backgroundColor:'transparent' }
let button            = { ...input, color:'black', paddingLeft:'1.5rem', paddingRight:'1.5rem' }
let text              = { fontSize:'1.5rem' }
let manager           = window.location.href.includes('manager')?true:false
let ellipsis          = { overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis' }
let navButton         = { fontWeight:'bold', lineHeight:1, minWidth:'4rem', minHeight:'4rem', height:'4rem', width:'4rem', padding:'0.65rem', margin:5, borderRadius:999, zIndex:999, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow, ...wrap, fontFamily:'inherit' }
let navButton2        = { ...navButton, padding:0 }
let buttonNoPadding   = { paddingLeft:0, paddingRight:0, paddingTop:0, paddingBottom:0, minHeight:'unset', minWidth:'unset', minHeight:'unset' }
let tileSize          = width>500? '6.4rem':'31vw'
let tile              = { height:tileSize, width:tileSize, maxWidth:tileSize, maxHeight:tileSize }
let fullWidth         = { paddingLeft:'1rem', paddingRight:'1rem' }




// function getOrderlinaStore() { return store.getState().orderlinaStore }

export default {

  backgroundLightgrey: { backgroundColor:ollightgrey },
  ollightgrey,
  ollightgrey2,
  primaryColor,
  secondaryColor,
  placeholderColor,
  boxShadowValue:boxShadow,
  boxShadow:    { boxShadow },
  boxShadowTop: { boxShadow:boxShadowTop },
  boxShadowLite: { boxShadow:boxShadowLite },
  boxShadowRight: { boxShadow:boxShadowRight },
  boxShadowTopLite: { boxShadow:boxShadowTopLite },
  linearGradient,
  card,
  cardFlat,
  ellipsis,
  nYellow,
  fullWidth,
  olgrey,

  bold:       { fontWeight:'bold' },
  h1:         { fontSize:'2.2rem', textAlign:'center', backgroundColor:'inherit' },
  h2:         { fontSize:'1.8rem', textAlign:'center', backgroundColor:'inherit' },

  textXXS:    { fontSize:'0.6rem' },
  textXXSB:   { fontSize:'0.6rem', fontWeight:'bold' },
  textXS:     { fontSize:'0.8rem' },
  textXSB:    { fontSize:'0.8rem', fontWeight:'bold' },
  textS:      { fontSize:'1.125rem', lineHeight:1.5 },
  textSB:     { fontSize:'1.125rem', fontWeight:'bold' },
  textSS:     { fontSize:'1.2rem' },
  textSSB:    { fontSize:'1.2rem', fontWeight:'bold' },
  text,
  textB:      { fontSize:'1.5rem', fontWeight:'bold' },
  textM:      { fontSize:'2rem' },
  textMB:     { fontSize:'2rem', fontWeight:'bold' },
  textML:     { fontSize:'2.5rem' },
  textL:      { fontSize:'3rem', fontWeight:'bold' },
  textH1:     { fontSize:'3rem', fontWeight:'bold' },
  text0_75:   { fontSize:'0.75rem', fontWeight:'normal' },
  text0_75B:  { fontSize:'0.75rem', fontWeight:'bold' },
  text1:      { fontSize:'1rem', fontWeight:'normal' },
  text1B:     { fontSize:'1rem', fontWeight:'bold' },
  text1_2:    { fontSize:'1.2rem', fontWeight:'normal' },
  text1_2B:   { fontSize:'1.2rem', fontWeight:'bold' },
  text1_5:    { fontSize:'1.5rem', fontWeight:'normal' },
  text1_5B:   { fontSize:'1.5rem', fontWeight:'bold' },
  text2:      { fontSize:'2rem', fontWeight:'normal' },
  text2B:     { fontSize:'2rem', fontWeight:'bold' },
  textTranformNone: { textTransform:'none' },
  olred,
  ollightgrey,
  borderRadius: {borderRadius:borderRadius},
  posLinks:   { ...button, ...ellipsis, backgroundColor:'transparent', margin:4, color:'black', flex:1 },

  noPadding: { paddingTop:0, paddingLeft:0, paddingRight:0, paddingBottom:0 },
  inherit:   { backgroundColor:'inherit', color:'inherit', backgroundImage:'inherit', fontSize:'inherit', fontWeight:'inherit', textTransform:'inherit', textAlign:'inherit' },

  orderItem: {
    borderRadius: 5, 
    padding: 12, paddingRight: 5, marginBottom: 3, 
    display:'flex', flexDirection:'row', justifyContent: 'space-between', alignItems:'center', 
    boxShadow,
  },

  strikethrough:{textDecoration:'line-through', color:'lightgrey'},
  
  whiteBorder:{
    color:'white',
    border:'2px solid white',
  },  
  
  buttonCont: {
    backgroundColor:ollightgrey,
    borderRadius,
    paddingLeft:'0.5rem'
  },

  button,
  button20: { ...button, marginTop:20 },
  button100: { ...button, width:'100%', marginTop:20 },
  buttonNoPadding,
  buttonSmall: {
    minWidth:'6rem', height:'2.8rem', 
    borderRadius, padding:'0.6rem', zIndex:999, 
    fontSize:'0.7rem', 
    fontWeight:'bold', overflow:'hidden',
    boxShadow,
    margin:3
  },
  buttonBadge: { 
    ...buttonNoPadding, height:'unset', minHeight:'unset', borderRadius:999, 
    paddingTop:'0.25rem', paddingBottom:'0.25rem', paddingRight:'1rem', paddingLeft:'1rem', 
    margin:3, fontSize:'0.75rem', fontWeight:'bold',
    boxShadow,
    fontFamily:'inherit'
  },
  buttonIcon: { ...buttonNoPadding, padding:'0.5rem', borderRadius:999, padding:'0.3rem' },
  buttonIconCircle: {
    borderRadius:99, backgroundColor:'transparent',
    width:'2.8rem', height:'2.8rem', minWidth:'2.8rem', minHeight:'2.8rem',
    fontSize:'0.9rem',
    display:'flex', alignItems:'center', justifyContent:'center',
    margin:2,
    zIndex:9,
    boxShadow,
    paddingLeft:'unset',
    paddingRight:'unset'
  },
  buttonTrans: { color:'unset', backgroundColor:'transparent', backgroundImage:'unset' },

  redButton: { ...button },
  redButton100: { ...button, width:'100%', marginTop:20 },


  editSection_A: {
    overflow:'scroll', 
    zIndex:99999999,
    fontWeight:'bold',
    position: 'fixed',
    left:'1rem',
    top:100,
    width:'20vw', maxHeight:'60vh',
    boxShadow,

    fontSize:'0.7rem', borderRadius:5, backgroundColor:'#3e3d3d', padding:'0.75rem', marginTop:10, flex:0, color:'white', display:'flex', flexDirection:'column'
  },

  editSection_SUB: {
    fontSize:'0.7rem', borderRadius:5, backgroundColor:'#3e3d3d', padding:'0.75rem', marginTop:10, flex:0, color:'white', display:'flex', flexDirection:'column'
  },

  editSection_B: {
    fontSize:'0.7rem', borderRadius:5, backgroundColor:'rgb(56, 53, 53)', padding:'0.75rem', margin:3, flex:0, color:'white', display:'flex', flexDirection:'row', flexWrap:'wrap', boxShadow
  },

  editSection_INPUTCONT:  { margin:3, marginTop:10 },
  editSection_INPUT:      { fontSize:'0.7rem', backgroundColor:'rgb(56, 53, 53)', color:'white', flex:1 },

  editSection_B_SWITCH: {
    fontSize:'0.7rem', borderRadius:5, backgroundColor:'rgb(56, 53, 53)', paddingLeft:'0.75rem', marginTop:10, flex:0, color:'white', display:'flex', flexDirection:'row', boxShadow
  },

  // OLD
  pageSectionEditRow: {
    display:'flex', flexDirection:'row', justifyContent:'flex-start', alignItems:'center',
    overflow:'scroll', 
    backgroundColor:'black', color:'white', zIndex:9999999999999,
    borderRadius:'3px',
    fontWeight:'bold',
    position: 'fixed',
    left:'1rem',
    top:100,
    width:'30vw',
    boxShadow,
    borderRadius,
    flexWrap:'wrap'
  },
  pageSectionEditRowItem: {
    display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', flexWrap:'wrap',
    height:'2.8rem', paddingLeft:5,
    fontSize:'0.7rem', 
    fontWeight:'bold',
    color:'white', textTransform:'uppercase', height:'unset'
  },
  pageSectionEditRowInput: {
    display:'flex', backgroundColor:'inherit', color:'inherit', fontSize:'inherit', padding:'1rem', fontWeight:'inherit'
  },

  pageSubSectionInput: {
    minHeight:35, minWidth:'3rem', marginLeft:5, fontSize:'0.7rem', backgroundColor:'#383535', color:'white', borderRadius:5, paddingLeft:10, margin:5 
  },

  mInfoHeading: {
    display:'flex', flex:1,
    fontSize:'1rem', fontWeight:'bold',
    textTransform:'uppercase'
  },

  typesDropArea: {
    marginTop:15, borderRadius:5, padding:10,
    overflow:'hidden', 
    display:'flex', flexDirection:'column',
    ...card,
    borderRadius
  },

  guestTypeDiscountBox: { backgroundColor:'transparent', marginTop:0, marginLeft:5, marginRight:5, textAlign:'center' },

  helpBox :{
    backgroundColor:'#D50000', 
    color:'white'
  },

  input,
  input100: {...input, width:'100%'},
  inputM:   {...input, fontSize:'1.5rem', minHeight:'5rem', textAlign:'center', },
  inputL:   {...input, fontSize:'2rem', minHeight:'5rem', textAlign:'center', },
  textArea: {...input, minHeight:'8rem', padding:'1rem' },

  inputSmallGrey: {
    ...input,
    fontSize:'1rem', fontWeight:'bold',
    minHeight:'2rem',
    // textAlign: 'center',
    // borderColor:'lightgrey', borderBottomWidth:0.5,
    backgroundColor:'whitesmoke',
    paddingLeft:'1rem', paddingRight:'1rem',
    paddingTop:'0.25rem', paddingBottom:'0.25rem',
    marginTop:3, 
    borderTop:'5px solid transparent',
    borderBottom:'5px solid transparent',
    fontFamily:'inherit'
},

  input2: {
    backgroundColor:'#f0f0f0', padding:'0.5rem', borderRadius:5, width:'100%',
    minHeight:'3rem'
  },

  header: {
    padding:'0.2rem 0.5rem', 
    display:'flex', flexDirection:'row', alignItems:'center', 
    flex:1,
    // justifyContent:'center',
    // minHeight:'5rem',
    overflow:'scroll',
    // zIndex:9999999999,
    // backgroundColor:manager?ollightgrey:'white'
  },

  homepageHeader: {
    position:'absolute', zIndex:9999999, paddingLeft:10, paddingTop:8, display:'flex', overflow:'scroll'
  },

  headerTransAbsolute: {
    backgroundColor:'transparent', top:0, position:'fixed', width:'100%', justifyContent:'space-between',
  },


  cardS :{
    marginTop:15, padding:height/40, borderRadius:5, width:'100%',
  },



  cardMenuItem: {
    marginTop:15, borderRadius:5, 
    width:'100%', maxWidth:'100%',
    flex:1, display:'flex', 
    minHeight: 70,
    alignItems:'flex-start',
    paddingLeft:10, paddingRight:10
  },

  cardMenuItem2: {
    marginTop:15, borderRadius:5, 
    paddingLeft:10, paddingRight:10
  },

  wrap,

  hoverHelper:{
    position:'fixed', top:'7rem', left:0, zIndex:9999,
    marginTop:0, borderRadius:0, padding:'0.8rem'
  },

  flexRow : {
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexRow0 : {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexRowOnly : {
    flexDirection: 'row',
    display: 'flex',
  },
  flexRowOnly1 : {
    flexDirection: 'row',
    display: 'flex',
    flex:1
  },
  flexRowOnlyAic : {
    flexDirection: 'row',
    display: 'flex',
    alignItems:'center'
  },
  flexRowSP : {
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  flexColOnly:{
    flexDirection: 'column',
    display: 'flex',
  },
  flexCol1:{
    flexDirection: 'column',
    display: 'flex',
    flex:1
  },
  flexCol : {
    flexDirection: 'column',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexCol0,

  flexQ : {
    width: '2rem',
    fontSize:'1.5rem',

    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  iconCaret : {
    fontSize:'1.5rem'
  },

  colorHeading: { marginTop:20 },

  mInfoCont: {
    display:'flex',
    flex:1,
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent:'flex-start',
    marginTop:20,
    marginBottom:20,
    textAlign:'left'
  }, 

  mInfoCont2: {
    display:'flex',
    flex:1,
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent:'flex-start',
    marginTop:20,
    textAlign:'left'
  }, 

  mInfoText: {
    paddingLeft:0, marginTop:0,
    fontSize:'1.3rem',
    color:'black',
    fontWeight:'bold',
    flex:1
  },

  mInfoIcon: {
    width:30,
    minWidth:30,
    fontSize:'2.5rem', color:'black', marginRight:'1.5rem',
    display:'flex'
  },

  mInfoIcon2:{
    display:'flex',
    width:'3rem',
    minWidth:'3rem'
  },

  mInfoTimeInput :{
    marginTop:0, textAlign:'center', fontSize:'0.75rem', padding:'0.25rem',
    backgroundColor:'transparent'
  },

  transparentBackgroundImage :{
    backgroundImage: 'linear-gradient(to right bottom, #ffffffe6, #ffffffe6)'
  },

  selectDropdown : {
    textTransform:'uppercase', 
    marginTop:20, paddingLeft:'1rem', paddingRight:'1rem',
    flex:1, maxWidth:'100%', fontSize:'1.2rem', fontWeight:'bold',
    boxShadow, borderRadius, minHeight,
    fontFamily:'inherit'
  },

  tileButton :{
    padding:0, margin:0
  },

  tileText: {
    fontWeight:'bold',
    fontSize:'1rem'
  },

  itemVariantTitleCont : {
    display:'flex', flexDirection: 'row', backgroundColor: 'transparent', 
    justifyContent : 'space-between', alignItems: 'center', marginTop:5, paddingBottom: 0
  },

  itemVariantTitle : {flex: 1, fontWeight:'bold', color: 'black', paddingLeft: 7, fontSize:height/30 },

  itemVariantReq : { 
    padding: 1, paddingRight:10, paddingLeft:10, borderRadius:2, 
    backgroundColor: '#D50000',
    fontSize:'0.9rem', color: 'white'
  },

  navTitle: {
    color:'black', fontSize:'2.75rem', fontWeight:'bold',
    width:'100%', textAlign:'center',
    overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis',
    display:'flex', alignItems:'center', justifyContent:'center'
  },

  navTitleLeft: {
    color:'black', fontSize:'2.75rem', fontWeight:'bold',
    width:'100%', textAlign:'left',
    overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis',
    // marginLeft:'1rem', marginRight:'1rem'
  },

  navButton,
  navButton2,
  navButtonText: {
    ...navButton,
    paddingRight:'1.5rem', paddingLeft:'1.5rem', width:'unset'
  },

  navButtonWithText: {
    color:'black', fontWeight:'bold',
    padding:'1rem', paddingRight:'1.5rem', paddingLeft:'1.5rem',
    borderRadius:999, zIndex:999,
    margin:5,
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  },

  navButtonWithTextMedium: {
    minWidth:'8rem', height:'2.8rem', 
    borderRadius:999, padding:'0.6rem', zIndex:999, 
    fontSize:'0.7rem', 
    fontWeight:'bold', overflow:'hidden',
    boxShadow
  },

  navButtonWithTextSmall: {
    ...card,
    color:'black', fontWeight:'bold',
    // backgroundColor:'rgba(0, 0, 0, 0.06)',
    padding:'0.3rem', 
    // paddingRight:'0.75rem', paddingLeft:'0.75rem',
    borderRadius:999, zIndex:999,
    margin:3,
    display: 'flex', flex:1, justifyContent: 'center', alignItems: 'center',
    fontSize:'0.75rem',
    minWidth:'unset',
    textTransform:'uppercase'
  },

  badge: {
    fontWeight:'bold',
    padding:'0.25rem', paddingRight:'0.75rem', paddingLeft:'0.75rem',
    borderRadius:999, zIndex:999,
    margin:3,
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    fontSize:'0.75rem',
    boxShadow
  },
  badge1: {
    fontWeight:'bold',
    padding:'0.25rem', paddingRight:'0.75rem', paddingLeft:'0.75rem',
    borderRadius:999, zIndex:999,
    margin:3,
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    fontSize:'0.75rem',
    boxShadow,
    alignSelf:'flex-start'
  },
  badge2: {
    color:'black', fontWeight:'bold',
    backgroundColor:'rgba(0, 0, 0, 0.06)',
    padding:'0.25rem', paddingRight:'0.75rem', paddingLeft:'0.75rem',
    borderRadius:5, zIndex:999,
    margin:3,
    display: 'flex', flex:1, justifyContent: 'center', alignItems: 'center', flexWrap:'wrap',
    fontSize:'0.75rem',
    boxShadow,
    minHeight:50
  },
  badge3: {
    fontWeight:'bold',
    padding:'0.25rem', paddingRight:'0.75rem', paddingLeft:'0.75rem',
    borderRadius:99,
    margin:3,
    fontSize:'0.6em',
    boxShadow,
  },
  badge4: {
    margin:3,
    fontSize:'0.75rem',
    minHeight:'unset'
  },

  fixedHoverCont: {
    position: 'fixed',
    zIndex: 99999999,
    fontFamily: 'inherit',
    transform:'translateX(-0.5rem) translateY(-1rem)',
    width:'100%',
    maxWidth:700
  },

  navBarCont: {
    display:'flex', 
    flex:1,
    backgroundColor:'#D50000', zIndex:999999999, 
    width:'100%', position:'fixed', bottom:0, right:0,
    borderRadius:0,
    minHeight:'5rem',

    // paddingLeft:10,
    // paddingRight:10,
    fontSize:'1.3rem', fontWeight: 'bold', color: 'white', textAlign:'center',
    overflow:'hidden', textOverflow:'ellipsis',
    boxShadow:boxShadowTopLite,
    fontFamily:'inherit'
  },
  navBarCont2: {
    zIndex:99999999, 
    width:'100%', position:'fixed', bottom:0,
    borderRadius:0,
    minHeight:'5rem',
    boxShadow:boxShadowTopLite,
    maxWidth:700,
    // margin:'1rem'
  },
  navBar: {
      display:'flex',
      flex:1,
      flexDirection: 'row',
      backgroundColor: '#D50000', 
      justifyContent: 'center', alignItems: 'center', 
      minHeight:height/9, 
      padding:5,
      paddingLeft:15, paddingRight:15,
      borderRadius:0,
      zIndex:999
  },
  navBarText: {
      fontSize:'1.5rem', 
      fontWeight: 'bold', 
      color: 'white', 
      textAlign:'center',
      overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'
  },

  navBarIcon:{
    color:'white',
    fontSize:'2rem',
    marginLeft:'0.5rem',
    marginRight:'0.5rem'
  },

  notesText: {
    fontSize:'2rem', 
    fontWeight: 'bold', 
    textAlign:'center'
},

  navIcon: {
    color:'black', fontWeight:'bold', fontSize:'2rem'
    // minWidth:height/10, minHeight:height/10,
    // padding:height/65, marginLeft:2, marginRight:2,
    // display: 'flex', justifyContent: 'center', alignItems: 'center'
  },

  navIconSize:'2rem',
  navIconTitleSize:'3rem',

  locationCard: {
    marginBottom:3,marginTop:2,
	  justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column'
  },

  locationButton: {
    alignSelf: 'center', 
    padding: 10, paddingRight: 20, paddingLeft: 20,
    // width: '60%', 
    minHeight:50, justifyContent:'space-between',
    width:'100%',
    display:'flex', alignItems:'center'
  },

  locationText: {
    flex: 1, textAlign:'center', 
    fontSize:'1.1rem', 
    fontWeight:'bold'
  },

  settingsText: {
    fontSize:'2rem', 
    fontWeight: 'bold', 
    textAlign:'center',
    marginTop:'2rem'
  },

  creditRow: {
    display:'flex', 
    flex: 1, flexDirection: 'row', 
    width:'100%',
    alignItems:'center',
    justifyContent:'space-between',
    marginTop:10
  },

  xxx: {
    backgroundColor: 'black',
    color:' blue'
  },
  disabled: {
    opacity: '.5'
  },

  heading: {
    color: '#D50000', textAlign: 'center', fontSize: 30, color: 'black', padding:30
  },

  errorCard:{  backgroundColor:'#D50000', color: 'white', textAlign: 'center', fontSize:'1.5rem', fontWeight:'bold', padding:'1.5rem', borderRadius:5, zIndex:99 },
  notifyCard:{  backgroundColor:'white', color: 'black', textAlign: 'center', fontSize:'1.5rem', fontWeight:'bold', padding:'1.5rem'  },
  tile,
  bgred: { backgroundColor: 'red'},
  bgwhite: { backgroundColor: 'white'},


  orderStatusCont: {
    padding:15, paddingTop:5, height:'8rem',
    ...flexCol0
    // width:110, 
    // height: 18, 
    // borderRadius:20, borderWidth:1, borderStyle: 'solid', borderColor:'lightgrey',
    // alignItems: 'center', justifyContent: 'center',
    // backgroundColor:'lightgrey',
    // position:'relative'
  },
    orderStatusName: {
        // color: 'black',
        fontSize:'1.2rem', 
        fontWeight:'bold',
        // textAlign: 'center',
        // zIndex:99999
        textAlign:'center',
        padding:'0.5rem',
        textTransform:'uppercase'
    },
    orderStatusBar: {
        height: 16, backgroundColor:'lightgrey', borderTopLeftRadius:9, borderBottomLeftRadius:9, position: 'absolute', left:0, top:0,
    },

  orderIcon: {
    // borderWidth: 2, borderStyle: 'solid', borderColor:'black',
    // display: 'flex', alignItems: 'center', justifyContent:'center', 
    // marginRight:5,
    // backgroundColor:'white'
  },

  iconCircle: {
    borderWidth: 2, borderStyle: 'solid', borderColor:'black',
    display: 'flex', alignItems: 'center', justifyContent:'center', 
    width:'5rem', height:'5rem', 
    borderRadius:999,
  },

  smallBlackIcon: {
    borderRadius:99, backgroundColor:'transparent',
    width:'2.8rem', height:'2.8rem', minWidth:'2.8rem', minHeight:'2.8rem',
    marginRight:4, marginLeft:4,
    fontWeight:'bold',
    fontSize:'0.9rem',
    display:'flex', alignItems:'center', justifyContent:'center',
    margin:2,
    zIndex:9,
    boxShadow
  },
  
  smallBlackIconWithText: {
    borderRadius:99, backgroundColor:'#F0F0F0',
    height:'2.8rem',
    marginRight:4, marginLeft:4,
    fontWeight:'bold',
    fontSize:'0.8rem',
    paddingLeft:'1rem', paddingRight:'1rem',
    margin:1,
    boxShadow,
    ...flexCol0
  },

  coWorkSquare: {
    // borderWidth:0.5, borderStyle: 'solid', borderColor:'lightgrey',
    display: 'flex', alignItems: 'flex-end', justifyContent:'flex-end', flexWrap:'wrap',
    width: 50, height: 50, 
    borderRadius:2,
    marginRight:5, marginTop:5,
    backgroundColor:'white',
    backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', overflow:'hidden'
  },

  coWorkMiniIcon: {
    display: 'flex', alignItems: 'center', justifyContent:'center', 
    width:20,height: 20, borderRadius:99,
    marginRight:1, marginTop:1,
    backgroundColor:'black',
    fontSize:10, fontWeight:'bold', color:'white'
  },


  Musername18: {
    // backgroundColor: 'green',
   fontSize: 18,color: 'black', flex: 1,
  },

  image: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      alignItems: 'center',
      alignSelf:'center',
      position: 'relative'
  },

  imageBannerText:{
    paddingLeft:30, paddingRight:40, 
    backgroundColor:'black', color:'white', textAlign: 'left', fontWeight:'bold'
  },
  
  imageBackground: {
    display:'flex',
    flexDirection:'column',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    justifyContent:'space-between',
    padding:0
  },

  backgroundNone :{
    backgroundImage:'none',
    backgroundColor:'none'
  },

  backgroundPic :{
    display:'flex',
    flexDirection:'column',
    backgroundPosition: 'center', 
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    flex:1
  },

  menuTitle: { 
    fontWeight:'bold', 
    fontSize:height/26 
  },

  inputBasic: {
    padding:0, 
    margin:0, 
    fontColor:'black',
    maxWidth: '100%',
    backgroundColor:'transparent',
    color:'black',
    fontFamily:'inherit'
  },
  

  // div2: {  
  //   maxWidth:400,
  //   alignSelf:width>400?'center':null,

  //   // display:flex; 
  //   // flex-direction:column; 
  //   paddingBottom:300
  // },

  // div3: {  
  //   maxWidth:750,
  //   alignSelf:width>750?'center':null,

  //   padding:width/30,
  //   marginTop:height/50,
  // },

  div0: {
    padding:'1.5rem',
    paddingLeft:width>800?'20%':'1.5rem',
    paddingRight:width>800?'20%':'1.5rem',
    textAlign:'center',
    display:'flex',
    flexDirection:'column'
  },

  divM: {
    marginLeft:width>800?'20%':0,
    marginRight:width>800?'20%':0,
  },

  // DEPRECATED
  div1: {
    padding:'1rem',
    paddingBottom:window.location.pathname.includes('/pos')?'3rem':'8rem',
    boxSizing:'border-box',
    display:'flex', 
    flexDirection:'column',
    flex:1,
    overflow:'hidden',
    transition: 'all .2s ease',
    minHeight:'80vh',

    width:700,
    alignSelf:'center',
    maxWidth:'100%',
    // width:      window.innerWidth>700? 700: '100%', 
    // alignSelf:  window.innerWidth>700? 'center': 'unset'
  },

  center:{
    width:700,
    alignSelf:'center',
    maxWidth:'100%'
  },


  tileContStyle: {
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap:'wrap', 
    marginBottom:'2rem'
  },

  div1NoPadding: {
    paddingLeft:width>800?'20%':0,
    paddingRight:width>800?'20%':0,
  },

  pageView1:{
    ...fullWidth
  },

  width400: {
      maxWidth:400,
      alignSelf:'center'
  },

  cover: {
    position:'absolute', width:'100%', height:'100%', top:0, left:0, display:'flex', justifyContent:'space-evenly', alignItems:'center', flexDirection:'column', zIndex:99999999
  },

  varStart2Style: { minWidth:150 },

  styleText1UpperNormal : { fontWeight:'normal', textTransform:'uppercase', fontSize:'1rem' },
  

}