import React, { useState, useRef, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { getIcon2, Loading } from '../Mini-components/Functions';




export function PdfViewer({ v, file }) {

	const [numPages, setNumPages] 		= useState(null);
	const [pageNumber, setPageNumber] 	= useState(1);

	function onDocumentLoadSuccess({ numPages }) { setNumPages(numPages) }

	// const onDocumentLoadSuccess = ({ numPages }) => { alert('Loaded a file with  +pages!'); setNumPages(numPages); setLoading(false); 		console.log('loadsuccess', loading)}
	// const goToPrevPage 			= () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1)
	// const goToNextPage 			= () => setPageNumber( pageNumber + 1 >= numPages ? numPages : pageNumber + 1 )
	
	const ref 					= useRef(null);
	useEffect(() => { console.log('width', ref.current ? ref.current.offsetWidth : 0) }, [ref.current]);
	let width = ref?.current?.offsetWidth||200

	return (
		<div style={{display:'flex', flex:1, flexDirection:'column'}} ref={ref}>

			<Document  file={file} onLoadSuccess={onDocumentLoadSuccess} loading={<Loading v={v} />} >

				{ Array.apply(null, Array(numPages))
					.map((x, i)=>i+1)
					.map( pageNumber => (
						<Page key={pageNumber}  pageNumber={pageNumber} width={width} loading={<Loading v={v} />} /> 
					))
				}
			</Document>

			{/* <nav>
				<button onClick={goToPrevPage}>Prev</button>
				<button onClick={goToNextPage}>Next</button>
				<p>Page {pageNumber} of {numPages}</p>
			</nav> */}
		</div>
	)
};

export default PdfViewer;