import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import Page from './client';
import { Button } from '@material-ui/core/';

import Style from './styles';
import { customerFeedback, copyToClipboard, domain, getIcon2, logo2, getAppText2, getLocalStorageSize, YYYY, getWhiteLabelConfig, currentVersion, getVariables, countInstances, handleLink2, renderLogo3, trimHttps, width } from './Mini-components/Functions'


class Footer extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			loading:true
		};
	}

	componentDidMount() {
	}

	shareIcons = ({ v }) => {

		let social          = v.info?.social

		if (social?.active) {

			let language        = v.language
			let color			= v.bt||'white'

			return (
				<div style={Object.assign({}, Style.flexColOnly, { marginBottom:'4rem'})}>
					
					<div style={Object.assign({}, Style.textMB,  { color, textAlign:'center' })}>{social["title_"+language] || social.title}</div>
					<div style={Object.assign({}, Style.textSSB, { color, textAlign:'center', marginTop:20 })}>{social["description_"+language] || social.description}</div>
					<div style={Object.assign({}, Style.flexRow, { marginTop:'1.5rem', color, flexWrap:'wrap' })}>						
						{social.facebookLink   	? <Button onClick={() => window.open( social.facebookLink) }  	style={Object.assign({}, 	Style.navButton, { boxShadow:'none', borderRadius:99, backgroundColor:'#3d5a9a',  })}>{getIcon2({ icon:'facebook', color, fontSize:'2.5rem' })}</Button> :null}
						{social.instagramLink   ? <Button onClick={() => window.open( social.instagramLink) } 	style={Object.assign({}, 	Style.navButton, { boxShadow:'none', borderRadius:99, backgroundImage:'linear-gradient(to right bottom, #feda75, #fa7e1e,#d62976, #962fbf, #4f5bd5)' })}>{getIcon2({ icon:'instagram', color, fontSize:'2.5rem' })}</Button> :null}
						{social.tiktokLink		? <Button onClick={() => window.open( social.tiktokLink) } 		style={Object.assign({}, 	Style.navButton, { boxShadow:'none', borderRadius:99, backgroundImage:'linear-gradient(to right bottom,#ff0050, #000, #00f2ea)' })}>{getIcon2({ icon:'tiktok', color, fontSize:'2rem' })}</Button> :null}
						{social.telegramLink	? <Button onClick={() => window.open( social.telegramLink) } 	style={Object.assign({}, 	Style.navButton, { boxShadow:'none', borderRadius:99, backgroundColor:'#0084c5' })}>{getIcon2({ icon:'telegram', color, fontSize:'2rem' })}</Button> :null}
					</div>
				</div>
			)
		}
		else return null
	}

	venueFooter = ({ v }) => {

		if (v.customer && v.pathname3) {

			let color			= v.bt||'white'
			let manager    		= v.manager
			let footerPageKey	= v.info?.footerPageKey

			if 		(footerPageKey=='none') 	return null
			else if (footerPageKey) 			return <Page {...this.props} pageKey={v.info.footerPageKey} />
			else return (
				<div style={Object.assign({}, Style.flexCol, Style.boxShadowTop, v.div7, { padding:'3rem 2rem', paddingTop:100, paddingBottom:100 })}>
					
					{ this.shareIcons({ v }) }
					{ renderLogo3({ img:v.info?.logo, size:'5rem' })}
					{v?.info?.website? <Button 	onClick={() => handleLink2({link:v.info.website}) } 	style={Object.assign({}, 		   Style.textXSB, Style.wrap, { color, marginTop:10 })}>{getAppText2({v, text:'visit us at'})}{" "}{trimHttps({url:v.info.website})}</Button> :null}
					<Button 					onClick={() => customerFeedback({ props:this.props }) } style={Object.assign({}, 		   Style.textB, { marginTop:10, color, fontFamily:'inherit' })}>{getIcon2({ icon:'feedback', fontSize:'1.3rem', marginRight:10 })}{getAppText2({v, text:'Feedback'})}</Button>
				</div>
			)
		}
		else return null
	}

	resellerFooter = ({ v }) => {

		let wlc	    				= v.wlc
		let footerPageKey			= wlc.footerPageKey

		if 		(footerPageKey=='none') 	return null
		else if (footerPageKey)	 			return <Page {...this.props} pageKey={wlc.footerPageKey} />
		else if (wlc)  {
			let customer    	= v.customer

			return (
				<div style={Object.assign({}, Style.flexCol, Style.boxShadowTop, Style.textSB, v.div7, { padding:'3rem 2rem', paddingTop:60, paddingBottom:170 })}>
					{ logo2({ img:wlc.logo, size:'3.5rem', style:{} })}
					{wlc.footerText?        <div onClick={() => window.open( wlc.homepageLink) } style={Object.assign({}, { lineHeight:1.5, textAlign:'center' })}>{wlc.footerText}</div> :null}
					{customer?
						<div style={Style.flexRow}>
							{ wlc.footerButtonText?  			<Button onClick={() => window.open( wlc.homepageLink) } style={Object.assign({}, v.button, Style.textXSB, { marginTop:15, borderRadius:99, minHeight:'unset' })}>{getAppText2({v, text:wlc.footerButtonText})}</Button> :null}
							{ wlc.refer?  						<Button onClick={() => this.props.updateDialog({text:`Refer ${wlc.businessName}`, description:`Enter The Details Of Someone That Might Be Interested in ${wlc.businessName}`, fields:[{placeholder:'Your Name'}, {placeholder:'Their Name'}, {placeholder:'Their Email'}], link:'customerReferral', icon:'heart' }) } style={Object.assign({}, v.button, Style.textXSB, { marginTop:15, borderRadius:99, minHeight:'unset', marginLeft:4 })}>{getAppText2({v, text:'Refer'})}</Button> :null}
						</div>
					:null}
					<div onClick={() => window.open( wlc.homepageLink) } style={Object.assign({}, Style.textXSB, { fontWeight:500, lineHeight:1.5, textAlign:'center', marginTop:15  })}>C{currentVersion} {getLocalStorageSize()} {wlc.businessDetails || ""}</div>
					<div onClick={() => window.open( wlc.homepageLink) } style={Object.assign({}, Style.textXSB, { paddingTop:4, textTransform:'uppercase' })}>{wlc.businessName || domain} © {YYYY}</div>
					{ wlc.lastLineText?  								<div onClick={() => window.open( wlc.lastLineLink||"") } style={Object.assign({}, { fontSize:'1rem', fontWeight:500, lineHeight:1.5, textAlign:'center', marginTop:15  })}>{wlc.lastLineText}</div> :null}
					{ wlc.platformType?.includes('customDomain') ?  	<div onClick={() => window.open( `https://${ wlc.platformType=='customDomainHF'?'get.hotefy.com':'orderlina.com' }?referral=PoweredBy-${v.venueTitle}`) } style={Object.assign({}, { fontSize:'1rem', fontWeight:500, lineHeight:1.5, textAlign:'center', marginTop:15  })}>Powered By {wlc.platformType=='customDomainHF'?'Hotefy':'Orderlina'}</div> :null}
					<div style={Object.assign({}, {height:30, backgroundImage:v&&v.lg})} />
				</div>
			)
		}
		else return null
	}

	render() {

		let loading			= this.props.orderlinaStore.loading
		let v               = this.props.v

		if (loading || !v)	return null
		else {
			let menuImages              = v?.info?.menuImageActive 	&& v?.info?.menuImages
			let menuPDF         		= v?.info?.menuPDFActive	&& v?.info?.menuPDF 	// DEPRECATED
			let pathname 		        = this.props.location.pathname.toString().toLowerCase()
			let slashes 		        = countInstances('/', pathname)

			if 		( v.customer && slashes===1 && !menuPDF && !menuImages  ) 	return null  // Welcome page
			else if ( v.wlc.homepageMenuLink && slashes===0) 					return null  // Menu set as home eg. cafedelseoul.menu
			else if ( pathname.includes('/pos') 
					  || pathname.includes('/chat') 
					  || pathname.includes('/editpage')
					  || pathname.includes('/pricing') ) return null 
			else {
				return (
					<div style={{  }}>
						{v.customer && v.pathname2? <div style={{ ...v.div, margin:'20rem'}} /> :null}

						{ this.venueFooter({ v }) }
						{ this.resellerFooter({ v }) }
					</div>
				)
			}
		}
	}
}

export default Footer

