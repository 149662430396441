
export const firebase_config = {
    apiKey: "AIzaSyAQRWwygitZPHFfbrLMXzqdBsDjIx5Bexg",
    authDomain: "demo.orderlina.menu",
    databaseURL: "https://newsdeklive.firebaseio.com",
    projectId: "newsdeklive",
    storageBucket: "newsdeklive.appspot.com",
    messagingSenderId: "830804306988",
    appId: "1:830804306988:web:1ceeb6b54ba4c0c7"
};
export const stripe_public_key = "pk_live_d6m8BejUqWbxOHMzcnbbnrNT" 
