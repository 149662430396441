import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';
// import { initializeFirebase } from './push-notification';

// Google Analytics
import ReactGA from 'react-ga';

// App
import App from './App';

// REDUX      
import { Provider } from 'react-redux'
import { store } from './redux';

// Functions
import { currentVersion } from './Mini-components/Functions'

// User Experience
import * as FullStory from '@fullstory/browser';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// GOOGLE ANALYTICS ?
ReactGA.initialize('UA-146497863-3');



// SENTRY
let isDev = window.location.href.includes("localhost") || window.location.href.includes("ngrok")
if (!isDev) {
	Sentry.init({
		dsn							: "https://6e5e807723a54f7d907e36cc46a58c4d@o4504750422753280.ingest.sentry.io/4504750424588288",
		integrations				: [new BrowserTracing(), new Sentry.Replay()],
		tracesSampleRate			: 1, // Set tracesSampleRate to 1.0 to capture 100%. of transactions for performance monitoring. We recommend adjusting this value in production
		replaysSessionSampleRate	: 0,
		replaysOnErrorSampleRate	: 1.0,
	})

	// LOYVERSE USERS
	if (window.location.href.includes("register")) FullStory.init({ orgId:'VJB9M' })
}

// Log Current Version
console.log('App Starting, Current Version C', currentVersion)

const routing = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
)
ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// initializeFirebase();
