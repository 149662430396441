import React, { Component } from 'react';

import Firebase from '../Firebase';

import Style from '../styles';
import { Card, Button, CircularProgress, TextField } from '@material-ui/core/';
import { KeyboardArrowDown } from '@material-ui/icons/';
import { IoIosCloseCircle } from 'react-icons/io';
import { FaCaretDown, FaCaretUp, FaExpandArrowsAlt } from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';



import { handleError, getVariables, trim, readyPage, social, iconPage, renderNotes, basketItems, Loading, CardFlat, getLanguage } from '../Mini-components/Functions'
import { navBar } from '../Mini-components/NavBar'






class NameUserPass extends Component {

	constructor() {
		super();
		this.state = {
		};
	}

    componentDidMount() {
        let v = this.props.v
        readyPage({ props:this.props, v })
		this.verify()
	}
	
    capitalize(str){  return str.charAt(0).toUpperCase() + str.slice(1) }


	render() {

        let v               =   this.props.v
		let orderlinaStore 	= 	{...this.props.orderlinaStore}
		let clientKey 		= 	orderlinaStore.clientKey
        let language 		=   v.language

		let nameUserPass 	= 	v.info?.verify?.nameUserPass


		if ( this.state.loading || !orderlinaStore || orderlinaStore.loading || !orderlinaStore[clientKey] || !orderlinaStore.userConfig ) return <Loading />


        // else if (this.props.navigation.state.routeName==="Welcome") {
            
        //     return (
        //         <div style={{flex:1, paddingTop:40}}>

        //                 {/* Note to Customer */}
        //                 <div style={[{flexWrap: "wrap" , fontSize:width/34, fontFamily:'Poppins-Regular', color:'white', textAlign: 'center', paddingBottom:15}]}>{nameUserPass["instructions_"+language] || nameUserPass.instructions}</div>
                        
        //                 {/* Name */}
                        
        //                 <div style={ pageStyle.userPassBox }>
        //                     <div style={{ width: '17%', alignItems: 'flex-end'}}></div>
        //                     <div style={{backgroundColor: 'transparent', flex: 1}}>
        //                         <TextInput 
        //                             autoCorrect={false}
        //                             onLayout={()=> { }}
        //                             onFocus={()=> { }}
        //                             // secureTextEntry={true}
        //                             style={[pageStyle.customTextInput, {}] }
        //                             value={ orderlinaStore.userConfig.venues && orderlinaStore.userConfig.venues[clientKey] && orderlinaStore.userConfig.venues[clientKey].credentials && orderlinaStore.userConfig.venues[clientKey].credentials.name || "" }
        //                             onChangeText={(text) => { this.saveToRedux(text, "name") }}
        //                             placeholder={orderlinaStore.config.appText.l23[language] || orderlinaStore.config.appText.l23.english }
        //                         />
        //                     </div>
        //                     <div style={{ width: '17%', alignItems: 'flex-start'}}>{ orderlinaStore[clientKey].loginError?<div><IconMCI style={{alignSelf:'center'}} name="close-outline" size={Platform.OS === 'ios' ? 30 : 30} color={'red'} /></div>: orderlinaStore[clientKey].verified ? <div><IconMCI style={{alignSelf:'center'}} name="check" size={Platform.OS === 'ios' ? 25 : 25} color={'black'} /></div>:null}</div>
        //                 </div>
                        
        //                 {/* Username */}
                        
        //                 <div style={ [pageStyle.userPassBox, {borderTopWidth:0.5, borderTopColor:'lightgrey'}] }>
        //                     <div style={{ width: '17%', alignItems: 'flex-end'}}></div>
        //                     <div style={{backgroundColor: 'transparent', flex: 1}}>
        //                         <TextInput 
        //                             autoCorrect={false}
        //                             // onLayout={()=> {  }}
        //                             onFocus={()=> { }}
        //                             // secureTextEntry={true}
        //                             style={[pageStyle.customTextInput,{ }]}
        //                             value={ orderlinaStore.userConfig.venues && orderlinaStore.userConfig.venues[clientKey] && orderlinaStore.userConfig.venues[clientKey].credentials && orderlinaStore.userConfig.venues[clientKey].credentials.username || "" }
        //                             onChangeText={(text) => { this.saveToRedux(text, "username") }}
        //                             placeholder={orderlinaStore.config.appText.l24[language] || orderlinaStore.config.appText.l24.english }
        //                         />
        //                     </div>
        //                     <div style={{ width: '17%', alignItems: 'flex-start'}}>{ orderlinaStore[clientKey].loginError?<div><IconMCI style={{alignSelf:'center'}} name="close-outline" size={Platform.OS === 'ios' ? 30 : 30} color={'red'} /></div>: orderlinaStore[clientKey].verified ? <div><IconMCI style={{alignSelf:'center'}} name="check" size={Platform.OS === 'ios' ? 25 : 25} color={'black'} /></div>:null}</div>
        //                 </div>
                        
        //                 {/* Password */}

        //                 <div style={ [pageStyle.userPassBox, {borderTopWidth:0.5, borderTopColor:'lightgrey'}] }>
        //                     <div style={{ width: '17%', alignItems: 'flex-end'}}></div>
        //                     <div style={{backgroundColor: 'transparent', flex: 1}}>
        //                         <TextInput 
        //                             autoCorrect={false}
        //                             onLayout={()=> { }}
        //                             onFocus={()=> { }}
        //                             secureTextEntry={true}
        //                             style={[pageStyle.customTextInput,{}]}
        //                             value={ orderlinaStore.userConfig.venues && orderlinaStore.userConfig.venues[clientKey] && orderlinaStore.userConfig.venues[clientKey].credentials && orderlinaStore.userConfig.venues[clientKey].credentials.password || "" }
        //                             onChangeText={(text) => { this.saveToRedux(text, "password") }}
        //                             placeholder={orderlinaStore.config.appText.l25[language] || orderlinaStore.config.appText.l25.english }
        //                         />
        //                     </div>
        //                     <div style={{ width: '17%', alignItems: 'flex-start'}}>{ orderlinaStore[clientKey].loginError?<div><IconMCI style={{alignSelf:'center'}} name="close-outline" size={Platform.OS === 'ios' ? 30 : 30} color={'red'} /></div>: orderlinaStore[clientKey].verified ? <div><IconMCI style={{alignSelf:'center'}} name="check" size={Platform.OS === 'ios' ? 25 : 25} color={'black'} /></div>:null}</div>
        //                 </div>

        //                 {/* Continue Button */}

        //                 <TouchableOpacity style={[pageStyle.userPassBox,{backgroundColor:'#D50000'}]}
        //                     onPress={() => {    

        //                         this.setState({loading:true})

        //                         if ( orderlinaStore[clientKey].verified ) {
        //                             this.saveNameUserPass()
        //                             this.props.navigation.navigate('Menu')
        //                         } 
        //                         else if (this.verify()) {
        //                             this.saveNameUserPass()
        //                             this.props.navigation.navigate('Menu')
        //                         }
        //                         else { 
        //                             orderlinaStore[clientKey].loginError=true
        //                             this.props.updateOrderlinaStore(orderlinaStore)
        //                             this.setState({loading:false}) 
        //                         }
                                    
        //                     }}
        //                 >
        //                     {  this.state.loginError ?
        //                         <div style={[{flex:0, fontSize:16, fontFamily:'Poppins-Regular', color:'white', textAlign: 'center'}]}>{orderlinaStore.config.appText.l84[language] || orderlinaStore.config.appText.l84.english }</div>
        //                         :<div style={[{flex:0, fontSize:16, fontFamily:'Poppins-Regular', color:'white', textAlign: 'center'}]}>{orderlinaStore.config.appText.l27[language] || orderlinaStore.config.appText.l27.english }</div>
        //                     }
        //                 </TouchableOpacity>
        //         </div>
            
        //     )
        // }

        else {
            return (
                <div style={{marginTop:10}}>
					{/* Note to Customer */}
					<div style={Style.textSB}>{nameUserPass["instructions_"+language] || nameUserPass.instructions}</div>

					{/* Name, Username, Password  */}
                    <input autoComplete="none"
                        placeholder={orderlinaStore.config.appText.l23[language] || orderlinaStore.config.appText.l23.english} 
                        value={ this.state.name || orderlinaStore.userConfig.venues && orderlinaStore.userConfig.venues[clientKey] && orderlinaStore.userConfig.venues[clientKey].credentials && orderlinaStore.userConfig.venues[clientKey].credentials.name || "" } 
                        onChange={(e) => { this.setState({name:e.target.value}); this.saveToRedux(e.target.value, "name") } }
                        style={ Object.assign({}, Style.textSB, { borderBottom:'5px solid red', marginTop:10, backgroundColor:orderlinaStore[clientKey].loginError?'#d5000021':'#F0F0F0' })}
                    />
                    <input autoComplete="none"
                        placeholder={orderlinaStore.config.appText.l24[language] || orderlinaStore.config.appText.l24.english} 
                        value={ this.state.username || orderlinaStore.userConfig.venues && orderlinaStore.userConfig.venues[clientKey] && orderlinaStore.userConfig.venues[clientKey].credentials && orderlinaStore.userConfig.venues[clientKey].credentials.username || ""  } 
                        onChange={(e) => { this.setState({username:e.target.value}); this.saveToRedux(e.target.value, "username")  } }
                        style={ Object.assign({}, Style.textSB, { borderBottom:'5px solid red', backgroundColor:orderlinaStore[clientKey].loginError?'#d5000021':'#F0F0F0' })}
                    />
                    <input autoComplete="none"
                        placeholder={orderlinaStore.config.appText.l25[language] || orderlinaStore.config.appText.l25.english} 
                        value={ this.state.password || orderlinaStore.userConfig.venues && orderlinaStore.userConfig.venues[clientKey] && orderlinaStore.userConfig.venues[clientKey].credentials && orderlinaStore.userConfig.venues[clientKey].credentials.password || "" } 
                        onChange={(e) => { this.setState({password:e.target.value}); this.saveToRedux(e.target.value, "password") } }
                        style={ Object.assign({}, Style.textSB, { borderBottom:'5px solid red', backgroundColor:orderlinaStore[clientKey].loginError?'#d5000021':'#F0F0F0' })}
                    />
                </div>
            )
        }
	}

    // saveNameUserPass = async () => {

    //     console.log('name User Pass saving')

    //     let v               =   this.props.v
	// 	let orderlinaStore 	= 	{...this.props.orderlinaStore}
	// 	let clientKey 		= 	v.clientKey
    //     let uid             = 	v.user && v.user.uid;
    //     let credentials     = 	orderlinaStore.userConfig.venues[clientKey].credentials || {}

    //     Firebase.database().ref("users/" + uid + "/venues/" + clientKey )
    //         .update({ credentials })
    //         .then(() => {  console.log('saved name-user-pass to fb'); this.setState({loading:false}) })
    //         .catch((error) => handleError({ v,  error, props:this.props }) )
    // }    

    saveToRedux = async (text, type) => {

        let orderlinaStore = {...this.props.orderlinaStore}
        let clientKey = orderlinaStore.clientKey;
        orderlinaStore[clientKey].loginError = false

        if (!orderlinaStore.userConfig.venues[clientKey].credentials)  { orderlinaStore.userConfig.venues[clientKey].credentials = {} }
        if ( type==="name" ) 		{ orderlinaStore.userConfig.venues[clientKey].credentials.name = text }
        if ( type==="username" )	{ orderlinaStore.userConfig.venues[clientKey].credentials.username = text }
        if ( type==="password" )	{ orderlinaStore.userConfig.venues[clientKey].credentials.password = text }

        this.props.updateOrderlinaStore(orderlinaStore);
        this.verify()
    }

    verify = () => {

        console.log('name user Pass verifying')
        let v               = this.props.v
        let orderlinaStore  = {...this.props.orderlinaStore}
        let clientKey       = v.clientKey

        if (
                orderlinaStore.userConfig && 
                orderlinaStore.userConfig.venues && 
                orderlinaStore.userConfig.venues[clientKey] && 
                orderlinaStore.userConfig.venues[clientKey].credentials && 
                orderlinaStore.userConfig.venues[clientKey].credentials.name && 
                orderlinaStore.userConfig.venues[clientKey].credentials.username && 
                orderlinaStore.userConfig.venues[clientKey].credentials.password
            ) 
            {
                
                let name        = orderlinaStore.userConfig.venues[clientKey].credentials.name.toLowerCase()
                let username    = orderlinaStore.userConfig.venues[clientKey].credentials.username.toLowerCase()
                let password    = orderlinaStore.userConfig.venues[clientKey].credentials.password.toLowerCase()

                // Credentials List

                let credentials = v.info?.verify?.nameUserPass.list
                let credentialsArray = Object.entries(credentials).map(item => ({  username: item[1].username,  password: item[1].password  }))
                let credentialsArrayIncludesText = credentialsArray.filter(item => item.username.toLowerCase()===username && item.password.toLowerCase()===password )

                if ( credentialsArrayIncludesText.length > 0 && name !=="") {
                    console.log("name: ", name)
                    console.log(true, true)
                    if ( orderlinaStore[clientKey].verified===false || orderlinaStore[clientKey].verified===undefined ) { orderlinaStore[clientKey].verified=true; this.props.updateOrderlinaStore(orderlinaStore) }
                    setTimeout(()=>{ this.setState({refresh:'refresh'}) }, 500)
                    return true
                }
                else { 
                    console.log(true, false)
                    if ( orderlinaStore[clientKey].verified===true || orderlinaStore[clientKey].verified===undefined ) { orderlinaStore[clientKey].verified=false; this.props.updateOrderlinaStore(orderlinaStore) }
                    return false
                }
            }
        else { 
            console.log(false)
            if ( orderlinaStore[clientKey].verified===true || orderlinaStore[clientKey].verified===undefined ) { orderlinaStore[clientKey].verified=false; this.props.updateOrderlinaStore(orderlinaStore) }
            return false
        }
    }


}
export default NameUserPass

