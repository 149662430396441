import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import {stripe_public_key} from '../Config'
import StripeCardElement from './StripeCardElement';

const StripeElement = (props) => {

  const stripePromise = loadStripe(stripe_public_key);

  return (
      <Elements stripe={stripePromise}>
          <StripeCardElement {...props} />
      </Elements>
  )
}

export default StripeElement;