import React, { Component } from 'react';

import Style from '../styles';


import { seperator2, checkAppVersion, getVariables, getWhiteLabelConfig, sendEmail2, dialog, RenderBadgeL, Loading, footer, renderHelper, trim, getIcon, mHomeTile, readyPage, olgrey, olred, seperator, domain, isAdmin, getUserEmail, newUser, domainShort, getAppText, getLocalStorageSize, isGod, saveVisitsCount, notifications, setPayMethod, isGod2, LogDiv2, cloudFunction, isAdmin3 } from '../Mini-components/Functions'



class MHome extends Component {

	constructor() {
		super();
		this.state = {
		};
	}


    componentDidMount() {
		let v = this.props.v
		readyPage({ props:this.props, v })
		saveVisitsCount({v, props:this.props})
		setTimeout(()=>{ checkAppVersion(this.props) }, 5000)
	}

	render() {

		let v					= 	this.props.v
		let orderlinaStore 		= 	{...this.props.orderlinaStore}
		let lastManaged 		= 	orderlinaStore.lastManaged

		if 	 ( !orderlinaStore.user || !v.info ) return <Loading />
		else {
			let language 		= 	v.language
			return (
				<div style={Object.assign({}, Style.div1, {  })}>

					{ notifications({props:this.props, v}) }

					<div style={Style.tileContStyle}>
						{ mHomeTile({ v, name:'Orders',			link:"/manager/manageorders",    	   	icon:'orders'		}) }
						{ mHomeTile({ v, name:'Alerts',			link:"/manager/alerts",    	  		   	icon:'addAlert'		}) }
					</div>

				</div>
			)
		}
	}


}
export default MHome

