import React, { Component } from 'react';
import Firebase from '../Firebase';
import PushId from 'pushid';

import { handleLink3, RenderHelper7, Button3, getAppText2, renderHelper6, handleError, getVariables, getAppText, Loading, renderHelper, getIcon, footer, olgrey, updateClientSearch, getWhiteLabelConfig, getUserEmail, domain, renderHelper5, getVenueNameTrim, trim, getUser, readyPage, Input2, logDiv, renderNavButton, getIcon2 } from '../Mini-components/Functions'

import Style from '../styles';
import { Card, Button } from '@material-ui/core/';
import { FiMoreHorizontal } from 'react-icons/fi';
import Subscribe from './Subscribe';
import MSubscription from './MSubscription';



class MLanding extends Component {

	constructor() {
		super();
		this.state = {
			loading:true
		};
	}

    componentDidMount() {
        let v =   this.props.v
        readyPage({ props:this.props, v })

        this.FBSyncOFF()
        this.getClients({v})

        this.createDemoClient()
    }

    createDemoClient = async () => {

        let v                           = this.props.v               
        let searchParams                = this.props.v.searchParams
        let venueTitle                  = searchParams.get('venueTitle')
        let venueUrl                    = searchParams.get('venueUrl') || trim(venueTitle)
        let venueWebsiteFull            = searchParams.get('venueWebsite')
        let venueWebsite                = venueWebsiteFull?.replace('https://', '')?.replace('http://', '')

        if (venueTitle) {

            setTimeout(() => { this.createNewClient({ clientKey:"-MTd-98WVV7H3ygptDQ5", venueTitle, venueUrl, venueWebsite })}, 1000);
            setTimeout(() => { handleLink3({v, link:'/manager/info'}) }, 4000);
            setTimeout(() => { handleLink3({v, link:venueWebsiteFull }) }, 1000);
            setTimeout(() => { handleLink3({v, link:`https://www.google.com/search?q=${venueTitle}+logo&tbm=isch&ved=2ahUKEwj5xP6NusL2AhVwRmwGHbj6AEYQ2-cCegQIABAA&oq=hotelname+logo&gs_lcp=CgNpbWcQAzoHCCMQ7wMQJzoECAAQQzoFCAAQgAQ6BggAEAcQHlCfAliIEGC5EmgAcAB4AIABbYgBrASSAQM2LjGYAQCgAQGqAQtnd3Mtd2l6LWltZ8ABAQ&sclient=img&ei=YI4tYvmUHfCMseMPuPWDsAQ&bih=686&biw=1440` }) }, 1000);
            setTimeout(() => { handleLink3({v, link:`https://www.google.com/search?q=${venueTitle}&tbm=isch&ved=2ahUKEwj5xP6NusL2AhVwRmwGHbj6AEYQ2-cCegQIABAA&oq=hotelname+logo&gs_lcp=CgNpbWcQAzoHCCMQ7wMQJzoECAAQQzoFCAAQgAQ6BggAEAcQHlCfAliIEGC5EmgAcAB4AIABbYgBrASSAQM2LjGYAQCgAQGqAQtnd3Mtd2l6LWltZ8ABAQ&sclient=img&ei=YI4tYvmUHfCMseMPuPWDsAQ&bih=686&biw=1440` }) }, 1000);
            setTimeout(() => { handleLink3({v, link:`https://demo.hotefy.com/${venueUrl}` }) }, 4000);
        }
    }

    FBSyncOFF = async () => {
        let orderlinaStore = {...this.props.orderlinaStore}
		let lastManaged = orderlinaStore.lastManaged

        Firebase.database().ref("clients/" + lastManaged)
            .off('value')
    }

    time = () => {
        // returns time in minutes
        return ( Math.floor(Date.now()/1000/60) )
    }

    getClients = async (p) => {

        let {v} = p
        Firebase.database().ref("client_idx/")
            .once('value') 
            .then(snapshot => {
                let clients = snapshot.val() 
                this.setState({ clients, loading:false })
                this.props.updateLoading(false)
                this.getManagedClients()
            })
            .catch((error)=> { handleError({ v,  error, props:this.props }) })
    }

    filterArray = (text) => {
        let search = text.toLowerCase();
        this.setState({
            searchTerm : search,
            filter: this.state.clients && this.state.clients.filter(obj => obj.name.toLowerCase().includes(search))
        });
    }

    getManagedClients = () => {
        
        this.setState({ loading:true })
        let uid = this.props.orderlinaStore.user && this.props.orderlinaStore.user.uid

		if (uid) { 
			Firebase.database().ref("users/" + uid + "/clients") // Get Managed Client Keys
				.once("value")
				.then((snap) => {	
                    let managedClients  = []
                    let count           = Object.keys(snap.val()).length
                    let counter         = 0           

                    Object.entries(snap.val()).forEach( item => {
                        managedClients.push( this.state.clients[item[0]]? { ...this.state.clients[item[0]], clientKey:item[0] } : {name:'INACTIVE VENUE', clientKey:item[0]} )
                        counter += 1
                        if (counter===count) this.setState({ managedClients, loading:false })
					})
				})
				.catch((error) => { console.log('removed error', error); this.setState({managedClients:"", loading:false}) })
		}
        

    }

	render() {

        let v					= 	this.props.v
        let clients             =   this.state.clients && this.state.clients.length

		if (this.state.loading || !v) return <Loading v={v} props={this.props} />
		else {
            let whiteLabelConfig    =   v.wlc
            let managedClients      =   this.state.managedClients || []
            let managedSearch       =   this.state.managedSearch || ""
            let managedFiltered     =   managedClients.filter(item => !managedSearch || JSON.stringify(item).toLowerCase().includes(managedSearch.toLowerCase()) ) || []

			return (
				<div style={Object.assign({}, Style.div1, { })}>

                    {this.state.view=='subscribe'?<MSubscription {...this.props} then={()=>{ this.goManage({clientKey:this.state.clientKey, v, justCreated:true }) }} />:
					<div style={Style.flexColOnly} >

                        {managedClients.length? 
                            <div style={Style.flexColOnly}>

                                {/* Search Box */}
                                { managedFiltered.length>5 || managedSearch? <Input2 v={v} instructions='Search By Name or Address' icon='search' placeholder={'Search'} value={managedSearch} styleCont={{ marginTop:20 }} onChange={(e)=>this.setState({managedSearch:e.target.value, error:""})}  setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus}  /> :null}

                                { managedFiltered.length<30?
                                    Object.entries(managedFiltered).map((item,index) => { item = { ...item[1], key:item[1].clientKey, titleKey:item[0], index:index  }; return (
                                        <Card key={item.key} style={Object.assign({}, Style.card, { backgroundColor:Style.olgrey, display:'flex', flexDirection:'row', justifyContent:'center', padding:'1rem', minHeight:68})} >
                                            <Card style={Object.assign({}, Style.backgroundPic, Style.borderRadius, { backgroundImage: "url(" + item.pic + ")" })} />
                                            <div className="flex ellipsis" style={{flex: 1, display:'flex', flexDirection:'column', alignItems:'space-between', justifyContent:'space-between'}}>
                                                
                                                <button onClick={() => this.props.updateDialog({ text:`${item.title}`, description:`Delete OR Duplicate Venue. NB. Selecting DELETE will permanently delete this venue for all users`,  icon:'alert', button3Icon:'openInNewTab', button3:()=>this.goManage({clientKey:item.clientKey, v, newTab:true}),  button2Icon:'trash', button2:()=> this.deleteClient({ item, v }), iconOk:'copy', buttonText:'Duplicate', link:()=> this.createNewClient({ clientKey:item.clientKey, venueTitle:item.title+" DUPLICATE", v }) }) } style={{  backgroundColor:'transparent', display:'flex', alignItems: 'flex-end', justifyContent: 'flex-end', borderWidth:0, width:'100%' }}><FiMoreHorizontal style={{ fontSize:20, color:'black'}} /></button>

                                                <button style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', padding:25, paddingRight:10, backgroundColor:'transparent', borderWidth:0, fontFamily:'inherit' }}>
                                                    <div style={Object.assign({}, Style.textB, Style.wrap, {textAlign:'center', fontFamily:'inherit' })}>{item.title || item.name}</div>
                                                    <div style={Object.assign({}, Style.wrap,{marginTop:10, textAlign:'center', fontFamily:'inherit' })}>{item.address}</div>
                                                </button>

                                                <button onClick={() => console.log(this.state) } style={{ backgroundColor:'transparent', display:'flex', alignItems: 'flex-end', justifyContent: 'flex-end', borderWidth:0, width:'100%' }}><FiMoreHorizontal style={{ fontSize:20, color:'transparent'}} /></button>
                                                {/* <Button onClick={() => this.goManage({clientKey:item.key, v}) } style={Object.assign({}, Style.navButton, { width:'4rem', alignSelf:'flex-end', backgroundImage:whiteLabelConfig.linearGradient })} >{getIcon('next', Style.navIconSize, 'white')}</Button> */}
                                                { renderNavButton({ v, style:{ ...v.div4, alignSelf:'flex-end' }, icon:'next', onClick:(event)=>this.goManage({clientKey:item.key, v, event}) }) }
                                            </div>
                                        </Card>
                                    )})
                                    :
                                    RenderHelper7({ props:{ v, shouldRender:true, title:"Long List: Please Search Above", style:{marginTop:20} }})
                                }
                            </div>
                        :
                        RenderHelper7({ props:{ v, icon:'page', h1:"STEP 1", title:"Enter your Venue Name and Click NEXT", style:{marginTop:40} } })}


                        { whiteLabelConfig?.hideCreateVenue 
                            ?   null
                            :   this.state.showCreateNewVenue||!managedFiltered.length
                                ?   <div style={Object.assign({}, Style.flexColOnly, {marginTop:20})}>
                                        <Input2 InputType='input' v={v} icon={'edit'} instructions='Create a New Venue' placeholder='Venue Name' value={this.state.venueName} styleCont={{  }} onChange={(e)=>{ console.log(this.state.venueName); this.setState({venueName:e.target.value, error:""}) }}  setState={(e)=>this.setState(e)} inputFocus={this.state.inputFocus}  />
                                        <Button3 v={v} icon={'next'} text={'Next'} onKeyDown={(e) => { if (e.key=='Enter') return null }} onClick={() => this.createNewClient({ clientKey:whiteLabelConfig.templateKey || "-M4SoZdriiBpbIxRY3ww", venueTitle:this.state.venueName||"New Venue", v }) } style={{ marginTop:15 }} />
                                    </div>
                                :   getIcon2({ icon:'addCircle', onClick:()=>this.setState({showCreateNewVenue:true}), style:{alignSelf:'center', fontSize:'4rem', marginTop:20 } })
                        }

					</div>
                }
				</div>
			);
        }
	}



    createNewClient = async ({ clientKey, venueTitle, venueUrl, venueWebsite }) => {

        this.setState({ loading:true })

        let v               = this.props.v
        let chosenUrlName   = venueUrl || trim(venueTitle)
        let uid             = v.userInfo?.uid || ""
        let email           = v.userInfo?.email || ""

        // If Venue name exists
        Firebase.database().ref("clientSearch/" + chosenUrlName)
            .once('value')
            .then(snapshot => {
                let urlName = snapshot.val()? chosenUrlName+(Math.floor(Math.random()*1000000)) : chosenUrlName 

                Firebase.database().ref("clients/" + clientKey + '/info' ).once("value")
                    .then( (snap) => {
                        let info                                = snap.val()
                        info.title                              = venueTitle
                        info.name                               = urlName
                        info.url                                = urlName // new
                        info.lat                                = ""
                        info.lon                                = ""
                        info.email                              = email || ""
                        info.website                            = venueWebsite || ""
                        info.domain                             = domain || ""
                        info.countryCodePhone                   = ""
                        info.payments.connectedStripeAccountId  = ""
                        info.users                              = { [uid]:{ "email":email, "role":["manager"] } }
                        info.plan                               = ""
                        info.subscription                       = ""
                        info.alerts                             = ""
                        info.payments.payPalClientId            = ""
                        info.payments.payPalClientSecret        = ""
                        info.integrations                       = ""
                        // copiedClientData.orders                 = ""
                        // copiedClientData.orders_archived        = ""
                        // copiedClientData.awaitingPayment        = ""
                        // copiedClientData.chat                   = ""
        
                        let newClientKey    = Firebase.database().ref("clients/").push().key
                        let x               = { [newClientKey] : { "email":email, "role":["manager"] } }    
        
                        // Update Users Client and then ClientSearch
                        Firebase.database().ref("users/" + uid + "/clients")
                            .update( x )
                            .then(() => { updateClientSearch(newClientKey, info, v.uid) })
                            .catch((error) => { handleError({ v,  error, props:this.props }); this.setState({ modal1:[false, ''], loading:false }) })
        
                        // Save to Main Client Node
                        Firebase.database().ref("clients/" + newClientKey + '/info')
                            .update(info)
                            .then(() => { 
                                if      ( v.wlc.paywall && v.pathname3.includes("venuesnewsignup"))     this.handleChange2({ type:'subscribe', clientKey:newClientKey })
                                else                                                                    this.goManage({clientKey:newClientKey, v, justCreated:true }) 
                            })
                            .catch((error) => { handleError({ v,  error, props:this.props }); this.setState({ modal1:[false, ''], loading:false }) })
        
                        // Also add to venues@orderlina.com user for suppport access
                        Firebase.database().ref("users/Ez9XDaS6IWQMHp9MZtyppSZJ8YL2/clients")
                            .update( x )
                            .then(() => console.log('added client to venues@orderlina.com'))
                            .catch((error) => console.log('error adding client to venues@orderlina.com', error) )

                        // If ADMIN UID exists, then add to it
                        if (v?.wlc?.adminUid) {                            
                            Firebase.database().ref(`users/${v.wlc.adminUid}/clients`)
                                .update( x )
                                .then(() => console.log('added client to admin uid'))
                                .catch((error) => console.log('error adding client to admin uid', error) )
                        }
                    })
                    .catch((error) => { handleError({ v,  error, props:this.props }); this.setState({ modal1: [false, ''], loading: false }) })
            })
            .catch((error)=> { return handleError({ v,  error, props:this.props }) })
    }

    deleteClient = async ({ item, v }) => {

        this.setState({ loading: true });
        let uid             = this.props.orderlinaStore.user.uid
        let venueUrl        = item.name

        // Remove ClientSearch
        Firebase.database().ref("clientSearch/" + venueUrl )
            .remove()
            .then(() => { console.log('clientSearch reference removed') })
            .catch((error) => { console.log(error) })

        // Remove Search
        Firebase.database().ref("client_idx/" + item.key )
            .remove()
            .then(() => { 
                console.log('Client_idx removed') 

                // Remove Client
                Firebase.database().ref("clients/" + item.key )
                    .remove()
                    .then(() => { 
                        console.log('Client removed') 

                        // Remove from User's Managed Clients
                        Firebase.database().ref("users/" + uid + "/clients/" + item.key)
                            .remove()
                            .then(() => { console.log('Removed from Managed Clients'); this.getManagedClients(); this.setState({ modal1: [false, ''], loading: false }) })
                            .catch((error) => { handleError({ v,  error, props:this.props }); this.setState({ modal1: [false, ''], loading:false }) })
                    })
                    .catch((error) => { handleError({ v,  error, props:this.props }); this.setState({ modal1: [false, ''], loading: false }) })

            })
            .catch((error) => { handleError({ v,  error, props:this.props }); this.setState({ modal1: [false, ''], loading:false }) })
    }


    handleChange2 = async ({ type, clientKey }) => {

        let v       = this.props.v

        if (type=='subscribe') {
            this.props.clientSync({ clientKey })
            this.setState({ view:'subscribe', loading:false, clientKey })
        }
    }

    goManage = async ({ clientKey, v, event, justCreated, newTab }) => {
        
		this.props.updateLoading(true)
        this.props.clientSync({ clientKey })

        // v.history.push('/manager/home')
        newTab
            ?   handleLink3({ v, link:`${v.domainHttps}/manager/home` })
            :   handleLink3({ v, linkSameTab: justCreated&&v.browserConfigGlobal?.afterCreateVenueNavLink || '/manager/home', event })
	}

}
export default MLanding

